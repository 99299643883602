import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Input, Label, Row, Form } from 'reactstrap';
import swal from 'sweetalert';
import ListingAdServices from '../Apiservices/ListingAdServices';
import Header from '../Header/NewHeader';
import MobileAdPreview from '../MobileAdPreview';
import './fasadEditAndSetting.css';
import { IMG_URL } from '../../config'
import Login from "../Login/Login";
import { API_BASE } from '../../config'
import CreatableSelect from 'react-select/creatable';
import CampaignService from '../Apiservices/CampaignService';
import Autosuggest from 'react-autosuggest';
import Headerlogo from '../../images/headerlogo.png';
import FasadServices from '../Apiservices/FasadServices';
import moment from 'moment';
import Axios from 'axios';

import AdsServices from '../Apiservices/Ads_Services';
const auth = new Login();
const listingAdServices = new ListingAdServices();
const campaignService = new CampaignService();
const Ads_Services = new AdsServices();

var regionsArray = []
const fasadService = new FasadServices();

class adEditListing extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            redirect: false,
            alert: false,
            region: 'Sweden',
            loader: false,
            edited: true,
            geolocation: [],
            listingAd: props.location.state.listingAd ? props.location.state.listingAd : {},
            previousPath: props.location.state.previousPath ? props.location.state.previousPath
                : "",
            userProfile: props.location.state.userProfile ? props.location.state.userProfile
                : {},
            listingData: props.location.state.listingData ? props.location.state.listingData
                : {},
            startDate: props.location.state.listingData && props.location.state.listingData.startDate
                ? props.location.state.listingData.startDate
                : new Date(date.setDate(date.getDate() + 1)).toISOString().substr(0, 10),
            endDate: props.location.state.listingData && props.location.state.listingData.endDate
                ? props.location.state.listingData.endDate
                : new Date(date.setDate(date.getDate() + 7)).toISOString().substr(0, 10),
            message: props.location.state.reverseToEdit ? props.location.state.listingData.message
                : "",
            businessName: props.location.state.reverseToEdit ? props.location.state.listingData.bussinesName
                : "",
            website: props.location.state.reverseToEdit ? props.location.state.listingData.website
                : "http://",
            listingAdImages: props.location.state.reverseToEdit ? [props.location.state.listingData.listingImages]
                : [],
            previewImage: props.location.state.reverseToEdit ? props.location.state.previewImage
                : "",
            headline: props.location.state.reverseToEdit ? props.location.state.listingData.headline
                : "",
            description: props.location.state.reverseToEdit ? props.location.state.listingData.description
                : "",
            pathUrl: this.props.location.state.pathUrl ? this.props.location.state.pathUrl
                : "",
            regionsArray: this.props.location.state.reverseToEdit ? this.props.location.state.listingData.region
                : [],
            regionSplit: this.props.location.state.reverseToEdit ? this.props.location.state.listingData.region
                : [],
            budget: this.props.location.state.budget ? this.props.location.state.budget : {},
            agentImage: false,
            imageUpdated: false,
            videoUpdated: false,
            mediaType: props.location.state.reverseToEdit ? props.location.state.listingData.mediaType : "image",
            listingAdId: props.location.state.reverseToEdit ? props.location.state.listingData.listingAdId : ""
        };
        console.log(this.props.location.state.listingData, "ong")

        this._onSuggestionSelected = this._onSuggestionSelected.bind(this);
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onChangeListingImage = (e) => {
        // this.setState({ listingAdImages: e.target.files[0], previewImage: URL.createObjectURL(e.target.files[0]) });
        const imageFile = e.target.files[0];
        console.log("imagevalue " + imageFile);
        if (imageFile.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
            // if (this.state.carouselAd === true) {
            //     this.setState({ mediaType: "carouselImages" });
            //     this.setState({ carouselAdImages: [... this.state.carouselAdImages, e.target.files] })
            // } else {
            if (this.props.location.state.reverseToEdit === true) {
                this.setState({ imageUpdated: true, videoUpdated: false });
            }
            this.setState({ mediaType: "image" });
            this.setState({ listingAdImages: e.target.files, previewImage: URL.createObjectURL(e.target.files[0]) });
            // }
        }
        else {
            if (this.props.location.state.reverseToEdit === true) {
                this.setState({ videoUpdated: true, imageUpdated: false });
            }
            this.setState({ mediaType: "video" });
            this.setState({ listingAdVideos: e.target.files, previewVideo: URL.createObjectURL(e.target.files[0]) });
        }
        // e.preventDefault()
        // this.setState({ loader: true })
        // let formData = new FormData();
        // formData.append('listingAdImage', e.target.files[0]);

        // Axios({
        //     method: 'post',
        //     url: API_BASE + 'listingAd/uploadimage',
        //     data: formData,
        //     // headers: { 'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem('token'))}` }
        //     headers: { 'Authorization': `Bearer ${localStorage.getItem("mytoken")}` }

        // })
        //     .then((result) => {
        //         if (result.status == 200) {
        //             this.setState({ listingAdImages: [result.data] });
        //             this.setState({ previewImage: result.data });
        //             console.log(result.data);
        //             this.setState({ loader: false });
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         this.setState({ loader: false });
        //         this.setState({ alert: true })
        //     });

    }

    componentDidMount() {
        let array = []
        this.state.regionsArray.forEach((Social, index) => {
            Social.type === 'country' ?
                array.push(Social.name) : (Social.type === 'region' || Social.type === 'geo_market') ?
                    array.push(Social.name + ', ' + Social.country_name) :
                    this.state.geolocation.length > 0 ?
                        array.push(Social.name + ', ' + Social.region + ', ' + Social.country_name) :
                        array.push(Social.name + ', ' + Social.region + ' +10miles')
        });
        this.setState({ regionsArray: array })
    };

    geolocations = (newValue) => {
        Ads_Services.fbGeoLoaction(newValue.value)
            .then(result => {
                let responseJson = result.data;
                if (responseJson) {
                    this.setState({ geolocation: responseJson });
                    this.setState({ alert: true });
                }
            })
            .catch(err => {
                this.setState({ alert: true });
                swal("Oops", err.response.data.error.message, "error");
            });
    };

    updateListingAd = (e) => {
        e.preventDefault()
        // let locations = JSON.stringify(this.state.regionsArray)
        if (this.state.headline && this.state.message && this.state.businessName
            && this.state.description && this.state.website
            && this.state.regionsArray && this.state.regionsArray.length > 0
            && this.state.listingAdImages && this.state.listingAdImages.length > 0) {

            this.setState({ loader: true });

            let formData = new FormData();
            formData.append('headline', this.state.headline);
            formData.append('message', this.state.message);
            formData.append('businessName', this.state.businessName);
            // formData.append('custom_description', this.state.custom_description);
            formData.append('description', this.state.description);
            formData.append('website', this.state.website);
            formData.append('region', this.state.region);
            formData.append('agentImage', this.state.agentImage)
            // formData.append('country', getLocation.country_name)

            formData.append('locations', JSON.stringify(this.state.regionSplit))
            formData.append('startDate', this.state.startDate)
            formData.append('endDate', this.state.endDate)
            formData.append('imageUpdated', this.state.imageUpdated)
            formData.append('videoUpdated', this.state.videoUpdated)
            formData.append('mediaType', this.state.mediaType)
            formData.append('extListingId', this.props.location.state.listingAd.extListingId)
            if (this.state.imageUpdated === true) {
                for (const listingImage of this.state.listingAdImages) {
                    formData.append('listingAdFile', listingImage);
                }
            } else if (this.state.videoUpdated) {
                for (const listingAdVideo of this.state.listingAdVideos) {
                    formData.append('listingAdFile', listingAdVideo);
                }
            }
            // let data = {
            //     headline: this.state.headline,
            //     message: this.state.message,
            //     businessName: this.state.businessName,
            //     description: this.state.description,
            //     website: this.state.website,
            //     region: this.state.region,
            //     listingAdImages: this.state.listingAdImages,
            //     locations: this.state.regionSplit,
            //     startDate: this.state.startDate,
            //     endDate: this.state.endDate,
            //     agentImage: this.state.agentImage,
            //     extListingId:this.props.location.state.listingAd.extListingId
            // }

            return Ads_Services.updateListingAd(this.state.listingAdId, formData)
                .then((result) => {
                    console.log(result, "Hello")
                    if (result.status == 200) {
                        this.setState({ listingData: result.data })
                        console.log(result.data, "Hello")

                        this.setState({ loader: false });
                        this.setState({ redirect: true });
                    }
                })
                .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err, 'err')
                    swal("Oops", err.response.data.error.message, "error");
                });
        }
        else {
            swal("Oops", "please add regions", "warning");
        }
    };

    onChangeAutoSearch = (event, { newValue }) => {
        this.setState({ region: newValue })
    }

    handleRemoveSocial(idx) {
        let someArray = this.state.regionsArray;
        someArray.splice(idx, 1);
        this.setState({ regionsArray: someArray });
        let someArraySplit = this.state.regionSplit;
        someArraySplit.splice(idx, 1);
        this.setState({ regionSplit: someArraySplit });
        // this.getEstimates(someArray)
    }

    handleInputVlaueChange(e, idx) {
        let nextSocialData = this.state.regionsArray.slice();
        nextSocialData[idx] = e.target.value;
        this.setState({ regionsArray: nextSocialData });
    }

    _onSuggestionSelected(event, { suggestion, suggestionValue, index, method }) {
        // this.regionsArray = [...this.state.regionsArray]
        let _suggestion
        suggestion.type === 'country' ?
            _suggestion = suggestion.name : (suggestion.type === 'region' || suggestion.type === 'geo_market') ?
                _suggestion = suggestion.name + ', ' + suggestion.country_name :
                _suggestion = suggestion.name + ', ' + suggestion.region + ', ' + suggestion.country_name

        // console.log(suggestion, 'suggestion')
        // console.log(_suggestion, '_suggestion')
        this.state.regionsArray.push(_suggestion);
        this.state.regionSplit.push(suggestion);
        // this.setState({ regionsArray: regionsArray });
        // this.getEstimates(regionsArray)
        console.log(this.state.regionsArray, "suggestion selected")
    }

    onDateChange = (e) => {
        if (e.target.name === 'startDate') {
            let diffInTime = new Date(this.state.endDate).getTime() - new Date(e.target.value).getTime();
            let diffInDays = diffInTime / (1000 * 3600 * 24);

            if (diffInDays < 2 || diffInDays > 10) {
                return swal("Error", "Please select a period between 2-10 days", "error");
            }
        }

        if (e.target.name === 'endDate') {
            let diffInTime = new Date(e.target.value).getTime() - new Date(this.state.startDate).getTime();
            let diffInDays = diffInTime / (1000 * 3600 * 24);

            if (diffInDays < 2 || diffInDays > 10) {
                return swal("Error", "Please select a period between 2-10 days", "error");
            }
        }

        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        const inputProps = {
            placeholder: "Type to add more locations",
            value: this.state.region,
            onChange: this.onChangeAutoSearch
        };
        // if (!auth.loggedIn()) {
        //     return (<Redirect to="/new-login" />)
        // }
        if (this.state.redirect == true) {
            return (
                <Redirect to={{
                    pathname: "/Adpreviewpublish",
                    state: {
                        listingAd: this.state.listingAd,
                        userProfile: this.state.userProfile,
                        previousPath: this.state.previousPath,
                        listingData: this.state.listingData,
                        previousPath: this.props.location.pathname,
                        previewImage: this.state.previewImage,
                        previewVideo: this.state.previewVideo,
                        budget: this.state.budget,
                        edited: true
                    }
                }} />
            )
        }
        return (
            <div className="createaddnew">
                <Row className="headerlogostyle">
                    <Link className="logostylefasad" to="/">
                        <img className="logostylefasad" src={Headerlogo}></img></Link>
                </Row>
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="createadnewmain">
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="createadnewprotion1">
                                <p className="topheadingcreateadnew">{this.props.location.state.reverseToEdit ? "Edit ad." : "Create ad."}</p>
                            </Row>
                            <Label className="settingname-label" > Period </Label>
                            <Row className="data1data2">
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Row className="data1row">
                                        <Input id="startdateinput"
                                            type="date" name="startDate" value={this.state.startDate} onChange={this.onDateChange} >
                                        </Input>
                                    </Row>
                                </Col>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Row className="data2row">
                                        <Input id="enddateinput"
                                            type="date" name="endDate" value={this.state.endDate} onChange={this.onDateChange}>
                                        </Input>
                                    </Row>
                                </Col>
                            </Row>
                            <Col lg="12" md="12" sm="12" xs="12">
                                <Row>
                                    <Label className="settingname-label" >Target region<span className="cityzip-label-span">(country/region/city/zip code)</span></Label>
                                    <Autosuggest
                                        suggestions={this.state.geolocation}
                                        onSuggestionsFetchRequested={this.geolocations}
                                        getSuggestionValue={(item) => {
                                            this.setState({ selectedRegion: item })
                                            return item.type === 'country' ? item.name
                                                : (item.type === 'region' || item.type === 'geo_market') ? item.name + ', ' + item.country_name
                                                    : item.name + ', ' + item.region + ', ' + item.country_name
                                        }}
                                        renderSuggestion={(item) => {
                                            return (
                                                <h6>{item.type === 'country' ? item.name
                                                    : (item.type === 'region' || item.type === 'geo_market') ? item.name + ', ' + item.country_name
                                                        : item.name + ', ' + item.region + ', ' + item.country_name}</h6>
                                            )
                                        }}
                                        onSuggestionSelected={this._onSuggestionSelected}
                                        inputProps={inputProps}
                                    />
                                </Row>
                                <table className="table mt-3 bordered table-hover  white-table addNewSocial">
                                    <tbody>
                                        {this.state.regionsArray.map((Social, idx) => (
                                            <tr key={idx} className="row Social">
                                                <td className="col-11 socialInput">
                                                    <Input
                                                        className="selectedresults"
                                                        type="text"
                                                        placeholder={`Add New # ${idx + 1}`}
                                                        value={Social
                                                            // Social.type === 'country' ?
                                                            //     Social.name : (Social.type === 'region' || Social.type === 'geo_market') ?
                                                            //         Social.name + ', ' + Social.country_name :
                                                            //         this.state.geolocation.length > 0 ?
                                                            //             Social.name + ', ' + Social.region + ', ' + Social.country_name :
                                                            //             Social.name + ', ' + Social.region + ' +10miles'
                                                        }
                                                        onChange={e => this.handleInputVlaueChange(e, idx)}
                                                    />
                                                </td>
                                                <Row className="crosssighns">
                                                    <p onClick={() => this.handleRemoveSocial(idx)}>x</p>
                                                </Row>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Col>
                            <Form onSubmit={this.props.location.state.reverseToEdit ? this.updateListingAd : this.updateListingAd}>
                                <Row className="formcreateadnew">
                                    <Label className="createadnew-label" > Message* <span className="createadnew-label-span">(max: 300)</span></Label>
                                    <Input type="text"
                                        name="message"
                                        id="createadnewinput"
                                        placeholder="Message"
                                        value={this.state.message}
                                        maxLength={300}
                                        onChange={this.onChange}
                                        required />
                                    {/* <Label className="createadnew-label" > Business Name* <span className="createadnew-label-span">(max: 25)</span></Label>
                                    <Input type="text"
                                        name="businessName"
                                        id="createadnewinput"
                                        placeholder="Business Name"
                                        value={this.state.businessName}
                                        maxLength={25}
                                        onChange={this.onChange}
                                        required /> */}
                                    <Label className="createadnew-label" > Headline* <span className="createadnew-label-span">(max: 60)</span></Label>
                                    <Input type="text"
                                        name="headline"
                                        id="createadnewinput"
                                        placeholder="Headline"
                                        value={this.state.headline}
                                        maxLength={60}
                                        onChange={this.onChange}
                                        required />
                                    
                                    <Label className="createafternote-label" > Website* <span className="createadnew-label-span">(max: 300)</span> </Label>
                                    <Input type="url"
                                        pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                                        required
                                        name="website"
                                        id="createadnewinput"
                                        placeholder="https://example.com"
                                        value={this.state.website}
                                        onChange={this.onChange}
                                        required
                                    />
                                    <Label className="createadnew-label" > Description* <span className="createadnew-label-span">(max: 300)</span></Label>
                                    <Input type="text"
                                        name="description"
                                        id="createadnewinput"
                                        placeholder="Description"
                                        value={this.state.description}
                                        maxLength={300}
                                        onChange={this.onChange}
                                        required />

                                    {/* <Row className="formcreateadnew">
                                        <Col lg="1" md="1" sm="1" xs="1">
                                            <Row className='checkboxInput'>
                                                <Input type='checkbox'
                                                    name='agentImage'
                                                    id='createadnewinput'
                                                    checked={this.state.agentImage}
                                                    onChange={e => this.setState({ agentImage: e.target.checked })}
                                                />
                                            </Row>
                                        </Col>
                                        <Col lg="8" md="8" sm="8" xs="8">
                                            <Row>
                                                <Label className="createadnew-label-span" >Include agent image</Label>
                                            </Row>
                                        </Col>
                                    </Row> */}

                                    <Row className="createadnewstate">
                                        <Col>
                                            <Row className="createadnewbtns">
                                                <Row className="createadnew-btn">
                                                    <Button class="createadnew-img-btn">+ Attach Media</Button>
                                                    <Input
                                                        type="file"
                                                        name="listingAdImages"
                                                        onChange={this.onChangeListingImage}
                                                    //multiple 
                                                    />

                                                </Row>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row className="createadnewbtns">
                                                {this.props.location.state.reverseToEdit
                                                    ?
                                                    <Button className="createadnewsubmitfasad" type="submit">Next</Button>
                                                    :
                                                    <Button className="createadnewsubmitfasad" type="submit">Next</Button>}
                                            </Row>
                                        </Col>
                                        <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                                            <div className='overlay-box'>
                                                <PropagateLoader
                                                    // css={override}
                                                    sizeUnit={"px"}
                                                    size={25}
                                                    color={'white'}
                                                    loading={this.state.loader}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </Row>
                            </Form>
                        </Col>
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} xs={12} >
                            {this.state.mediaType === "image" && <MobileAdPreview headline={this.state.headline} message={this.state.message} website={this.state.website} description={this.state.description} previewImage={this.state.previewImage || (this.state.listingAdImages && this.state.listingAdImages.length > 0 && IMG_URL + this.state.listingAdImages[0])} mediaType={this.state.mediaType} />}
                            {this.state.mediaType === "video" && <MobileAdPreview headline={this.state.headline} message={this.state.message} website={this.state.website} description={this.state.description} previewVideo={this.state.previewVideo || (this.state.listingAdVideos && this.state.listingAdVideos.length > 0 && IMG_URL + this.state.listingAdVideos[0])} mediaType={this.state.mediaType} />}

                        </Col>
                    </Row>
                </Col>
            </div>
        );
    }
}

export default adEditListing;