import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    PaymentRequestButtonElement,
    CardElement,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    Elements,
    ElementsConsumer,
} from '@stripe/react-stripe-js';

import PaymentServices from "../Apiservices/PaymentServices";
const paymentServices = new PaymentServices();

const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: '18px',
            color: '#424770',
            letterSpacing: '0.025em',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            errorMessage: null,
            token: null,
        };
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { stripe, elements } = this.props;
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        paymentServices.checkout()
            .then(async result => {
                let checkout = await stripe.redirectToCheckout({
                    // sessionId: 'cs_test_iDJBWZyTBDMlf9oqacZXkpYchsqsOsfbgOvE1Fw4zl9QVzpg9cqKQ4ni'
                    sessionId: result.data.id
                })
                if (checkout.error) {
                    console.log(checkout)
                    alert(checkout.error.message)
                }
            })
            .catch(err => {
                console.log(err)
                this.setState({ alert: false, loader: false });
            });

    };

    render() {
        const { stripe } = this.props;
        const { name, token } = this.state;
        let aa = new URLSearchParams(window.location.search);
        console.log('props', aa.get('session_id'))

        return (
            <form onSubmit={this.handleSubmit}>
                <button type="submit" disabled={!stripe}>
                    Add
                </button>
            </form>
        );
    }
}

const InjectedCheckoutForm = () => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} />
        )}
    </ElementsConsumer>
);

const stripePromise = loadStripe("pk_test_rLWUz2XBkX02I7VHZTrOM4k700wpCOqDMr");
// const stripePromise = typeof window === "undefined" ? null : window.Stripe("pk_test_rLWUz2XBkX02I7VHZTrOM4k700wpCOqDMr");

const Pay = () => {
    return (
        <Elements stripe={stripePromise}>
            <InjectedCheckoutForm />
        </Elements>
    );
};

export default Pay;
