import moment from 'moment';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Col, Input, Row } from 'reactstrap';
import image from "../../images/Resurs 860.png";
import Header from '../Header/NewHeader';
import './InfoAdSetting.css';
import MobileAdPreview from '../MobileAdPreview';
import { IMG_URL } from '../../config'
import Login from "../Login/Login";
import Location from "../Apiservices/GetUserLocationFromIp";
import ListingAdServices from '../Apiservices/ListingAdServices';
import { PropagateLoader } from "react-spinners";

const location = new Location()
const auth = new Login();
const listingAdServices = new ListingAdServices();

class InfoAdSetting extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            alert: false,
            loader: false,
            currentCampaign: this.props.location.state.currentCampaign,
            regions: this.props.location.state.currentCampaign.region,
            userProfile: this.props.location.state.userProfile,
            afterNoteUrl: ""
        };
        console.log(this.props.location.state.userProfile)
        this.createAfterNoteImage = this.createAfterNoteImage.bind(this);
    }

    GetFormattedDate(date) {
        var todayTime = new Date(date);
        var month = moment(todayTime.getMonth() + 1);
        var day = moment(todayTime.getDate());
        var year = moment(todayTime.getFullYear());
        return day + "-" + month + "-" + year;
    }

    createAfterNoteImage = async (e) => {
        this.setState({ loader: true })
        let data = {
            propertyUrl: this.state.currentCampaign.listingAd.listingAdImages[0],
            agentUrl: this.state.userProfile.userImage,
            agentName: this.state.userProfile.name,
            agentPhone: this.state.userProfile.phoneNumber
        }
        try {
            let createAfterNoteImage = await listingAdServices.createAfterNote(data)
            console.log(createAfterNoteImage.data, "image afternote")
            if (createAfterNoteImage.status == 200) {
                this.setState({ afterNoteUrl: createAfterNoteImage.data });
                this.setState({ loader: false });
                this.setState({ redirect: true });
            }

        }
        catch (err) {
            console.log(err, "fetchData")
            this.setState({ loader: false });
            this.setState({ alert: true });
        }
    };

    render() {
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }
        if (this.state.redirect == true) {
            return (<Redirect
                to={{
                    pathname: "/Sold-out",
                    state: {
                        currentCampaign: this.state.currentCampaign,
                        userProfile: this.state.userProfile,
                        afterNoteUrl: this.state.afterNoteUrl
                    }
                }}
            />)
        }
        const startDate = moment(this.state.currentCampaign.startDate);
        const endDate = moment(this.state.currentCampaign.endDate);
        const dateDifference = endDate.diff(startDate);
        const diffDuration = moment.duration(dateDifference);
        return (
            <div className="createinfoadsetting">
                <Header />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="infoadsettingmain">
                        <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }} >
                            <Row className="infoadsettingprotion1">
                                <Row className="topheadinginfoadsetting">
                                    <p>Info and ad settings.</p>
                                </Row>

                            </Row>
                            <Row className="formagencycampaign">
                                {/* <Row className="infoadsettingheading2">
                                    <p>Target URL</p>
                                </Row>
                                <Row className="infoadsettingheading3">
                                    <p>http://www.fastighetsbyran.se/id2543</p>
                                </Row> */}
                                <Row className="infoadsettingheading4">
                                    <p>Period</p>
                                </Row>

                                <Row className="infoadsettingheading5">
                                    <p>Start {this.GetFormattedDate(this.state.currentCampaign.startDate)}</p>
                                </Row>
                                <Row className="infoadsettingheading5">
                                    <p> End {this.GetFormattedDate(this.state.currentCampaign.endDate)}</p>
                                </Row>
                                <Row className="infoadsettingheading5">
                                    <p> ({diffDuration.days()} Days) </p>
                                </Row>
                                <Row className="infoadsettingheading4">
                                    <p>Region/-s</p>
                                </Row>
                                {this.state.regions.map((item, idx) => (
                                    <Row className="infoadsettingheading5">

                                        <p>{item.type === 'country' ? item.name : (item.type === 'region' || item.type === 'geo_market') ? item.name + ', ' + item.country_name : item.name + ', ' + item.region + ', ' + item.country_name}</p>

                                    </Row>
                                ))}
                                <Row className="infoadsettingheading4">
                                    <p>Budget</p>
                                </Row>
                                <Row className="infoadsettingheading5">
                                    <p>{this.state.currentCampaign.budgetAmount}   {this.state.currentCampaign.currency} </p>
                                </Row>
                                <Row className="infoadsettingheading4">
                                    <p>Estimated Reach</p>
                                </Row>
                                <Row className="infoadsettingheading5">
                                    <p>{this.state.currentCampaign.estimatedReach} </p>
                                </Row>
                                {/* <Row className="infoadsettingstate">
                                    <Button
                                        className="infoadsettingsubmit"
                                        type="submit"> + Create ad </Button>
                                </Row> */}
                                <Row className="infoadstatestss">
                                    <Col>
                                        <Link to={{
                                            pathname: "/edit-ad-new",
                                            state: {
                                                currentCampaign: this.state.currentCampaign,
                                            }
                                        }} style={{ textDecoration: "none" }}>
                                            <Row className="infoadbtn">
                                                <Row className="infoad-wrapper">
                                                    <Button class="infoad-btnn">Edit ad</Button>
                                                    <Input
                                                        type="submit" />
                                                </Row>
                                            </Row>
                                        </Link>
                                    </Col>

                                    <Col>
                                        <Row className="infoadsetting-btns">
                                            <Button
                                                className="shareinfoadsetting"
                                            > Boost ad</Button>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Link to={{
                                            // pathname: this.state.redirect == true ? "/Sold-out" : "/Sold-out",
                                            // state: {
                                            //     currentCampaign: this.state.currentCampaign,
                                            //     userProfile: this.state.userProfile,
                                            //     afterNoteUrl: this.state.afterNoteUrl
                                            // }
                                        }} style={{ textDecoration: "none" }}>
                                            <Row className="btnsinfoad">
                                                <Button
                                                    className="dashboardshareinfoad"
                                                    type="submit"
                                                    onClick={this.createAfterNoteImage}> Mark as sold </Button>
                                            </Row>
                                        </Link>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 5, offset: 1 }} sm={{ size: 12, offset: 0 }} xs={{ size: 12, offset: 0 }} >
                            <MobileAdPreview headline={this.state.currentCampaign.listingAd.headline} message={this.state.currentCampaign.listingAd.message} website={this.state.currentCampaign.listingAd.website} description={this.state.currentCampaign.listingAd.description} previewImage={IMG_URL + this.state.currentCampaign.listingAd.listingAdImages[0]} />
                        </Col>

                    </Row>
                    <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                        <div className='overlay-box'>
                            <PropagateLoader
                                // css={override}
                                sizeUnit={"px"}
                                size={25}
                                color={'white'}
                                loading={this.state.loader}
                            />
                        </div>
                    </div>
                </Col>
            </div>
        );
    }
}

export default InfoAdSetting;