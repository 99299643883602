import React from 'react'
import axios from 'axios';
import { API_BASE } from '../../config'

let instance = axios.create({
    baseURL: API_BASE + 'auth',
});

class Authservice extends React.Component {

    postLogin(data) {
        return instance.post('login', data)
    }

    resetPassword(data) {   
        return instance.post('resetPassword', data)
    }

    postContactUs(data){
        return instance.post('/contactUs',data)
    }

    signupRequest(data){
        return instance.post('/signupRequest',data)
    }
    signupAgencyRequest(data){
        return instance.post('/signup',data)
    }
}
export default Authservice;