import React, { Component } from 'react';
import { Button, Card, CardImg, CardSubtitle, CardTitle, Col, Input, Row } from 'reactstrap';
import Eyes from '../../images/Resurs 670.png';
import Calenders from '../../images/Resurs 680.png';
import Clock from '../../images/Resurs 690.png';
import Graphs from '../../images/wq.jpeg';
import Header from '../Header/NewHeader';
import { Link, Redirect } from 'react-router-dom';
import './Style.css';
import Login from "../Login/Login";

const auth = new Login();

class NewActiveAds extends Component {

    render() {

        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }

        return (
            <div className="activead">
                <Header />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="activeadmain">
                        <Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12}>
                            <Row className="activeadprotion1">
                                <Col>
                                    <p className="activeadtopheading">Active ads.</p>
                                </Col>
                                <Col>
                                    <Row>
                                        <Input
                                            id="activeadselect"
                                            type="select">
                                            <option>Newest</option>
                                            <option >old</option>
                                            <option >old</option>
                                            <option >old</option>
                                        </Input>
                                        <Button
                                            className="activeadcreatead"
                                            type="submit"> + Create ad </Button>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={{ size: 12 }} md={{ size: 12 }} sm={12} xs={12}>
                            <Row className="activeadcardportionlast">
                                <Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
                                    <Row className="activeadcampaignlistbottom">
                                        <Card className="activeadcardsizebottom">
                                            <CardTitle className="titleofad">Artillerigatan 43</CardTitle>
                                            <CardSubtitle className="subtitleofad">Ostermalm</CardSubtitle>
                                            <CardImg className="activeadbottomcardimg" src={Graphs} alt="image" />
                                            <Row className="activeadbtmtext">
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Eyes}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        19 jan
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Calenders}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        2 weeks
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Clock}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        10764
</Row>
                                                </Col>
                                            </Row>
                                            <Row className="activeadcardbtmtxtrow">
                                                <Col>
                                                    <Row className="activeadcancel">
                                                        Cancel
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="activeadview">
                                                        View
</Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Col>
                                <Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
                                    <Row className="activeadcampaignlistbottom">
                                        <Card className="activeadcardsizebottom">
                                            <CardTitle className="titleofad">Artillerigatan 43</CardTitle>
                                            <CardSubtitle className="subtitleofad">Ostermalm</CardSubtitle>
                                            <CardImg className="activeadbottomcardimg" src={Graphs} alt="image" />
                                            <Row className="activeadbtmtext">
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Eyes}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        19 jan
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Calenders}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        2 weeks
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Clock}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        10764
</Row>
                                                </Col>
                                            </Row>
                                            <Row className="activeadcardbtmtxtrow">
                                                <Col>
                                                    <Row className="activeadcancel">
                                                        Cancel
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="activeadview">
                                                        View
</Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Col><Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
                                    <Row className="activeadcampaignlistbottom">
                                        <Card className="activeadcardsizebottom">
                                            <CardTitle className="titleofad">Artillerigatan 43</CardTitle>
                                            <CardSubtitle className="subtitleofad">Ostermalm</CardSubtitle>
                                            <CardImg className="activeadbottomcardimg" src={Graphs} alt="image" />
                                            <Row className="activeadbtmtext">
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Eyes}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        19 jan
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Calenders}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        2 weeks
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Clock}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        10764
</Row>
                                                </Col>
                                            </Row>
                                            <Row className="activeadcardbtmtxtrow">
                                                <Col>
                                                    <Row className="activeadcancel">
                                                        Cancel
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="activeadview">
                                                        View
</Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Col><Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
                                    <Row className="activeadcampaignlistbottom">
                                        <Card className="activeadcardsizebottom">
                                            <CardTitle className="titleofad">Artillerigatan 43</CardTitle>
                                            <CardSubtitle className="subtitleofad">Ostermalm</CardSubtitle>
                                            <CardImg className="activeadbottomcardimg" src={Graphs} alt="image" />
                                            <Row className="activeadbtmtext">
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Eyes}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        19 jan
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Calenders}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        2 weeks
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Clock}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        10764
</Row>
                                                </Col>
                                            </Row>
                                            <Row className="activeadcardbtmtxtrow">
                                                <Col>
                                                    <Row className="activeadcancel">
                                                        Cancel
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="activeadview">
                                                        View
</Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Col><Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
                                    <Row className="activeadcampaignlistbottom">
                                        <Card className="activeadcardsizebottom">
                                            <CardTitle className="titleofad">Artillerigatan 43</CardTitle>
                                            <CardSubtitle className="subtitleofad">Ostermalm</CardSubtitle>
                                            <CardImg className="activeadbottomcardimg" src={Graphs} alt="image" />
                                            <Row className="activeadbtmtext">
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Eyes}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        19 jan
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Calenders}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        2 weeks
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Clock}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        10764
</Row>
                                                </Col>
                                            </Row>
                                            <Row className="activeadcardbtmtxtrow">
                                                <Col>
                                                    <Row className="activeadcancel">
                                                        Cancel
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="activeadview">
                                                        View
</Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Col><Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
                                    <Row className="activeadcampaignlistbottom">
                                        <Card className="activeadcardsizebottom">
                                            <CardTitle className="titleofad">Artillerigatan 43</CardTitle>
                                            <CardSubtitle className="subtitleofad">Ostermalm</CardSubtitle>
                                            <CardImg className="activeadbottomcardimg" src={Graphs} alt="image" />
                                            <Row className="activeadbtmtext">
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Eyes}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        19 jan
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Calenders}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        2 weeks
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Clock}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        10764
</Row>
                                                </Col>
                                            </Row>
                                            <Row className="activeadcardbtmtxtrow">
                                                <Col>
                                                    <Row className="activeadcancel">
                                                        Cancel
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="activeadview">
                                                        View
</Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Col><Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
                                    <Row className="activeadcampaignlistbottom">
                                        <Card className="activeadcardsizebottom">
                                            <CardTitle className="titleofad">Artillerigatan 43</CardTitle>
                                            <CardSubtitle className="subtitleofad">Ostermalm</CardSubtitle>
                                            <CardImg className="activeadbottomcardimg" src={Graphs} alt="image" />
                                            <Row className="activeadbtmtext">
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Eyes}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        19 jan
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Calenders}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        2 weeks
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Clock}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        10764
</Row>
                                                </Col>
                                            </Row>
                                            <Row className="activeadcardbtmtxtrow">
                                                <Col>
                                                    <Row className="activeadcancel">
                                                        Cancel
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="activeadview">
                                                        View
</Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Col><Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
                                    <Row className="activeadcampaignlistbottom">
                                        <Card className="activeadcardsizebottom">
                                            <CardTitle className="titleofad">Artillerigatan 43</CardTitle>
                                            <CardSubtitle className="subtitleofad">Ostermalm</CardSubtitle>
                                            <CardImg className="activeadbottomcardimg" src={Graphs} alt="image" />
                                            <Row className="activeadbtmtext">
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Eyes}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        19 jan
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Calenders}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        2 weeks
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Clock}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        10764
</Row>
                                                </Col>
                                            </Row>
                                            <Row className="activeadcardbtmtxtrow">
                                                <Col>
                                                    <Row className="activeadcancel">
                                                        Cancel
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="activeadview">
                                                        View
</Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Col>
                                <Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
                                    <Row className="activeadcampaignlistbottom">
                                        <Card className="activeadcardsizebottom">
                                            <CardTitle className="titleofad">Artillerigatan 43</CardTitle>
                                            <CardSubtitle className="subtitleofad">Ostermalm</CardSubtitle>
                                            <CardImg className="activeadbottomcardimg" src={Graphs} alt="image" />
                                            <Row className="activeadbtmtext">
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Eyes}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        19 jan
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Calenders}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        2 weeks
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="contentcenter">
                                                        <img src={Clock}></img>
                                                    </Row>
                                                    <Row className="contentcenter">
                                                        10764
</Row>
                                                </Col>
                                            </Row>
                                            <Row className="activeadcardbtmtxtrow">
                                                <Col>
                                                    <Row className="activeadcancel">
                                                        Cancel
</Row>
                                                </Col>
                                                <Col>
                                                    <Row className="activeadview">
                                                        View
</Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

            </div>
        );
    }
}

export default NewActiveAds;