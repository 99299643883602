import React from 'react'
import axios from 'axios';
import FasadPreviewPublish from "../FasadPreiew/FasadPreviewAndPublish";
import { API_BASE } from '../../config'

// const auth = new FasadPreviewPublish();
// let instance = axios.create({
//     baseURL: API_BASE,
//     headers: {
//         'Authorization': `Bearer ${this.getToken()}`
//     }
// });

class FasadServices extends React.Component {

    fasadLogin = (data) => {
        try {
            return new Promise((resolve, reject) => {
                return axios({
                    method: 'POST',
                    url: API_BASE + "auth/fasad",
                    headers: {},
                    data: data
                }).then(result => {
                    if (result.status == '200') {
                        this.setToken(JSON.stringify(result.data.token));
                    }
                    return resolve(result.data.token)
                })
            })
        }
        catch (err) {
            console.log(err, 'error')
            return Promise.reject(err)
        }
    }

    getFasadListingData = async (data) => {
        return new Promise(async (resolve, reject) => {
            let token = await this.getToken()
            //console.log(token, "token")
            // let result = await axios({
            //     method: 'POST',
            //     url: API_BASE + "fasad/listing",
            //     headers: {
            //         Accept: 'application/json',
            //         Authorization: "Bearer " + token
            //     },
            //     data: data
            // })

            let fasadListing = await axios({
                method: 'POST',
                url: API_BASE + "fasad/" + data.listingId,
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token
                }
            })
            return resolve(fasadListing)
        })
    }

    fbGeoLoaction = async (name) => {
        return new Promise(async (resolve, reject) => {
            let token = await this.getToken()
            let fbLocations = await axios({
                method: 'GET',
                url: API_BASE + "campaign/fblocation?location=" + name,
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token
                }
            })
            return resolve(fbLocations)
        })
    }

    getEstimatedUser(data) {
        return new Promise(async (resolve, reject) => {
            let token = await this.getToken()
            let getEstimatedUser = await axios({
                method: 'POST',
                url: API_BASE + "campaign/estimatedReach",
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token
                },
                data: data
            })
            return resolve(getEstimatedUser)
        })
    }

    updateListingAd(listingAdId, data) {
        return new Promise(async (resolve, reject) => {
            let token = await this.getToken()
            return axios({
                method: 'PUT',
                url: API_BASE + "listingAd/" + listingAdId,
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token
                },
                data: data
            }).then(result => {
                return resolve(result)
            }).catch(err => {
                return reject(err)
            })
        })
    }

    setToken(token) {
        // Saves user token to sessionStorage
        sessionStorage.setItem('token', token);
    }

    getToken() {
        // Retrieves the user token from sessionStorage
        return JSON.parse(sessionStorage.getItem('token'));
    }
}
export default FasadServices;