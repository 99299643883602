import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import Samosa from "../../images/Resurs 270.png";
import Header from '../Header/NewHeader';
import './ShowcasePortfolio.css';
import Login from "../Login/Login";

const auth = new Login();

class ShowcasePortfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previousPath: props.location.state.previousPath,
            userProfile: props.location.state.userProfile,
            campaignType: props.location.state.campaignType ? props.location.state.campaignType : 'agent',
            redirect: false,
            alert: false,
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange = () => {
        this.setState({});
    };

    render() {

        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }

        return (
            <div className="showcaseportfolio">
                <Header backButton={this.state.previousPath}
                    previousData={{
                        userProfile: this.state.userProfile,
                        campaignType: this.state.campaignType,
                        previousPath: this.props.location.pathname
                    }}
                />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="shoecaseportfoliomain">
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="showcaseportfolioprotion1">
                                <p className="topheadingshowcaseportfolio">Showcase Portfolio.</p>
                            </Row>
                            <Row className="formshowcaseportfolio">
                                <Label className="showcaseportfolio-label" > Add listings to include in your ad </Label>
                                <Row className="checkboxesshowcase">

                                    <Row className="portfoliocheckboxess">
                                        <Col lg={{ size: 12 }} md={{ size: 12 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
                                            <label className="portfoliocontainer2">
                                                <input
                                                    type="checkbox"
                                                    name=""
                                                    onChange={this.toggleChangeRecurring}></input>Tomtebogatan 34
                                                        <span className="portfoliocheckmark2"></span>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="portfoliocheckboxess">
                                        <Col lg={{ size: 12 }} md={{ size: 12 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
                                            <label className="portfoliocontainer2">
                                                <input
                                                    type="checkbox"
                                                    name=""
                                                    onChange={this.toggleChangeRecurring}></input>Tomtebogatan 34
                                                        <span className="portfoliocheckmark2"></span>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="portfoliocheckboxess">
                                        <Col lg={{ size: 12 }} md={{ size: 12 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
                                            <label className="portfoliocontainer2">
                                                <input
                                                    type="checkbox"
                                                    name=""
                                                    onChange={this.toggleChangeRecurring}></input>Tomtebogatan 34
                                                        <span className="portfoliocheckmark2"></span>
                                            </label>
                                        </Col>
                                    </Row>
                                </Row>
                                <Row className="showcaseportfoliostats">

                                    <Col>
                                        <Row className="portfolionext-btn">
                                            <Link to={{
                                                pathname: "/create-ad-new",
                                                state: {
                                                    userProfile: this.state.userProfile,
                                                    campaignType: this.state.campaignType,
                                                    previousPath: this.props.location.pathname
                                                }
                                            }}>
                                                <Button className="portfolionext">Next</Button>
                                            </Link>
                                        </Row>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="showcaseportfolioprotion2">
                                <img src={Samosa} height="auto" width="100%"></img>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div>
        );
    }
}

export default ShowcasePortfolio;