import React from 'react'
import axios from 'axios';
import { API_BASE } from '../../config'

// const auth = new FasadPreviewPublish();
// let instance = axios.create({
//     baseURL: API_BASE,
//     headers: {
//         'Authorization': `Bearer ${this.getToken()}`
//     }
// });

class Ads_Services extends React.Component {

 getexternalListingData = async (data) => {
        return new Promise(async (resolve, reject) => {
            let token = await data.token
            localStorage.setItem("mytoken",token)
            let externalId = await axios({
                method: 'GET',
                url: API_BASE + "vendor/getexternalListing/" + data.extListingId,
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token
                }
            })
            return resolve(externalId)
        })
    }
    updateListingAd(id,data) {
        return new Promise(async (resolve, reject) => {
            console.log("in id rout ", id)
            let token = localStorage.getItem("mytoken");
            console.log("mytoken ",token)
            return axios({
                method: 'PUT',
                url: API_BASE + "vendor/"+ id,
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token
                    
                },
                
                data: data
            }).then(result => {
                return resolve(result)
            }).catch(err => {
                return reject(err)
            })
        })
    }
    fbGeoLoaction = async (name) => {
        return new Promise(async (resolve, reject) => {
            let token = localStorage.getItem("mytoken");

            let fbLocations = await axios({
                method: 'GET',
                url: API_BASE + "campaign/fblocation?location=" + name,
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token
                }
            })
            return resolve(fbLocations)
        })
    }

}
export default Ads_Services;