import React from 'react';
import { Link, withRouter } from 'react-router-dom';
// import * as Scroll from 'react-scroll';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import Goback from '../../images/goback.png';
// import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Headerlogo from '../../images/headerlogo.png';
import Login from '../Login/Login';
import './NewHeader.css';
import UserServices from "../Apiservices/UserService";

const userServices = new UserServices();
const Auth = new Login();
const userId = Auth.getId()
let temp;
class NewHeader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            backHidden: props.backHidden ? props.backHidden : false,
            backButton: props.backButton ? props.backButton : "/dashboard",
            previousData: props.previousData ? props.previousData : {},
            role: '',
            email:''
        };

        this.toggle = this.toggle.bind(this);
        this.changeNavItem = this.changeNavItem.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }


    componentDidMount() {
        userServices.getUserProfile(userId)
            .then((result) => {

                let responseJson = result.data.user
                this.setState({ userProfile: responseJson });
                this.setState({ role: responseJson.role });
                // this.setState({ email: responseJson.email });
                temp=responseJson.email
            })
            .catch(err => {
                console.log(err)
                // alert("Oops", "oh no", "error");
            });
    };

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }


    changeNavItem(currentRoute) {
        if (currentRoute == "/") {
            this.setState({
                hideValu: true
            });
        }
        if (currentRoute == "/pre-release" || currentRoute == "/parkingspace") {
            this.setState({
                hideNavOnLoginPage: true
            })
        }
    }

    handleLogout() {
        Auth.logout();
        this.setState({ redirect: true });
        window.location.replace("/")
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <Navbar className="navbar-custom-1" color="#f1f1f1" light expand="md">
                {/* <Container> */}
                <NavbarBrand className="logo-style" tag={Link} to='/dashboard'> <img src={Headerlogo}></img></NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    {!this.state.backHidden && <Nav navbar>
                        <NavItem className="goback">
                            <Link to={{
                                pathname: this.state.backButton,
                                state: this.state.previousData
                            }}>
                                <img src={Goback}></img>
                            </Link>
                        </NavItem>
                    </Nav>}

                    
                    {this.state.role == 'agent' ?
                        <Nav className="ml-auto" navbar>
                            <NavItem className="setting">
                                <NavLink tag={Link} Link to="/create-profile" >Profile</NavLink>
                            </NavItem>
                            <NavItem className="home">
                                <NavLink tag={Link} Link to="/" onClick={this.handleLogout} >Log out</NavLink>
                            </NavItem>
                        </Nav>
                        :
                        <Nav className="ml-auto" navbar>
                     {    temp=="agency@brightbee.co" &&    <NavItem className="home">
                                <NavLink tag={Link} Link to="/signupagency" >Sign Up Agency</NavLink>
                            </NavItem>
                           }
                            <NavItem className="setting">
                                <NavLink tag={Link} Link to="/create-profile" >Profile</NavLink>
                            </NavItem>
                            <NavItem className="home">
                                <NavLink tag={Link} Link to="/Users" >Users</NavLink>
                            </NavItem>
                            <NavItem className="home">
                                <NavLink tag={Link} Link to="/new-Payment" >Settings</NavLink>
                            </NavItem>

                            <NavItem className="home">
                                <NavLink tag={Link} Link to="/" onClick={this.handleLogout} >Log out</NavLink>
                            </NavItem>
                        </Nav>
                    }

                </Collapse>
                {/* </Container> */}
            </Navbar>
        );
    }
}
export default withRouter(NewHeader);