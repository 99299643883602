import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Card, CardImg, Col, Input, Row, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Addphoto from '../../images/addphoto.png';
import Graphchart from '../../images/state.png';
import { PropagateLoader } from "react-spinners";
import CampaignServices from "../Apiservices/CampaignService";
import UserServices from "../Apiservices/UserService";
import Header from '../Header/NewHeader';
import Login from "../Login/Login";
import './Style.css';
import './Dashboard_Centered.css'
import { IMG_URL } from '../../config';
import { connect } from 'react-redux';
import { Player } from 'video-react';

// import swal from 'sweetalert';
const moment = require('moment');

const userServices = new UserServices();
const campaignService = new CampaignServices();
const auth = new Login();
const userId = auth.getId()

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.pageSize = 9;
        this.pagesCount = 0;
        this.state = {
            previousPath: props.location.state && props.location.state.previousPath ? props.location.state.previousPath : '/dashboard',
            userProfile: {},
            redirect: false,
            alert: false,
            invalid: '',
            loader: true,
            // name: this.props.location.state.userinfo.name,
            name: "",
            // userImage: this.props.location.state.userinfo.userImage,
            userImage: "",
            campaigns: [],
            countryName: '',
            countryCode: '',
            currentPage: 0,
            loadpages: ""
        };
    }

    handleClick(e, index) {

        e.preventDefault();

        this.setState({
            currentPage: index
        });

    }

    componentDidMount() {
        userServices.getUserProfile(userId)
            .then((result) => {
                let responseJson = result.data.user
                this.setState({ userProfile: responseJson, loader: false });
                this.setState({ name: responseJson.name });
                this.setState({
                    userImage: responseJson.userImage.startsWith('images/user/') ?
                        IMG_URL + responseJson.userImage
                        : responseJson.userImage

                });
                localStorage.setItem("currentRole", responseJson.role)

            })
            .catch(err => {
                console.log(err)
            });

        campaignService.getActiveCampaigns()
            .then(async (result) => {
                let responseJson = result.data
                this.setState({ campaigns: responseJson });
                this.pagesCount = await Math.ceil(responseJson.length / this.pageSize);
                this.setState({ loadpages: this.pagesCount })
            })
            .catch(err => {
                // alert("Oops", "oh no", "error");
                console.log(err)
            });
    };

    GetFormattedDate(date) {
        var todayTime = new Date(date);
        var month = moment(todayTime.getMonth() + 1);
        var day = moment(todayTime.getDate());
        var year = moment(todayTime.getFullYear());
        return day + "-" + month + "-" + year;
    }

    render() {
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }
        console.log("checkpages " + this.pagesCount);

        let pagination = this.pagesCount ?
            <Pagination className='pagination'>
                <PaginationLink
                    onClick={e => this.handleClick(e, this.state.currentPage - 1)}
                    previous
                    href="#"
                />
                {
                    [...Array(this.pagesCount)].map((page, i) =>
                        <PaginationItem active={i === this.state.currentPage} key={i}>
                            <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                                {i + 1}
                            </PaginationLink>
                        </PaginationItem>
                    )
                }

                <PaginationItem disabled={this.state.currentPage >= this.pagesCount - 1}>
                    <PaginationLink
                        onClick={e => this.handleClick(e, this.state.currentPage + 1)}
                        next
                        href="#"
                    />
                </PaginationItem>

            </Pagination> : '';

        return (
            <div className="dashboard" id="dashbrd">
                <Header backHidden={true} />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="dashboardmain">
                        <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} xs={12} order="12">
                            <Row className="dashboardprotion1">
                                <p className="dashboardtopheading">Campaign Dashboard.</p>
                            </Row>
                            <Row className="dashboardleft">
                                <Link to={{
                                    pathname: "/new-campaigntype",
                                    state: {
                                        userProfile: this.state.userProfile,
                                        previousPath: this.props.location.pathname
                                    }
                                }}>
                                    <Button
                                        className="createcampaign"
                                        type="submit"> + Create new campaign </Button>
                                </Link>
                            </Row>

                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} order="1">
                            <Row className="dashboardprotion2">
                                {this.state.userImage == "" ? (
                                    <img src={Addphoto} className="imagesize"></img>

                                ) : (
                                        <img src={this.state.userImage} className="imagesize"></img>

                                    )}
                                {/* <img src={Addphoto} className="imagesize"></img> */}
                                {/* <img src={this.state.userimg !== '' ? this.state.userimg : familyDoctor} width='30%' alt="lady-doctor" className="img" /> */}
                                {/* <img src={this.state.userImage !== '' ? IMG_URL + this.state.userImage : Addphoto} className="imagesize"></img> */}
                            </Row>
                            <Row className="nametext">
                                <div className="wecome-div">
                                    <p>Welcome <br /><span> {this.state.name} </span></p>
                                </div>

                            </Row>
                        </Col>
                        <Col lg={{ size: 12 }} md={{ size: 12 }} sm={12} xs={12} order="1">
                            <Row className="cardportionlast">
                                <Col lg={{ size: 4 }} md={{ size: 4 }} sm={12} xs={12}>
                                    <Row className="bottompart1">
                                        <p>Active Campaigns.</p>
                                    </Row>
                                    <Row className="bottompartbutton">
                                        <Link to={{
                                            pathname: "/all-campaigns",
                                            state: {
                                                userProfile: this.state.userProfile
                                            }
                                        }}>
                                            <Button
                                                type="submit"
                                                className="seeallcampaign">
                                                See all campaigns
                                            </Button>
                                        </Link>
                                    </Row>
                                </Col>
                                <Col lg={{ size: 12 }} md={{ size: 12 }} sm={12} xs={12}>
                                    <Row className='bottompagination'>
                                        {pagination}
                                    </Row>
                                </Col>
                                {this.state.campaigns ?
                                    this.state.campaigns
                                        .slice(
                                            this.state.currentPage * this.pageSize,
                                            (this.state.currentPage + 1) * this.pageSize
                                        )
                                        .map((campaign, i) => {
                                            let imageUrl = " "
                                            if (campaign.listingAd.listingAdImages[0] && campaign.listingAd.mediaType === 'image') {
                                                imageUrl = campaign.listingAd.listingAdImages[0].startsWith("https://") ? campaign.listingAd.listingAdImages[0] : IMG_URL + campaign.listingAd.listingAdImages[0]
                                            } else if (campaign.listingAd.thumbnailImage && campaign.listingAd.mediaType === 'video') {
                                                imageUrl = campaign.listingAd.thumbnailImage.startsWith("https://") ? campaign.listingAd.thumbnailImage : IMG_URL + campaign.listingAd.thumbnailImage
                                            } else {
                                                imageUrl = campaign.listingAd.listingAdImages[0].startsWith("https://") ? campaign.listingAd.listingAdImages[0] : IMG_URL + campaign.listingAd.listingAdImages[0]
                                            }
                                            console.log(imageUrl, "imageUrl")
                                            return (
                                                <Col lg={{ size: 4 }} md={{ size: 4 }} sm={12} xs={12} key={i}>
                                                    <Link to={{
                                                        // pathname: "/info-ad-setting",
                                                        pathname: "/new-statistics",
                                                        state: {
                                                            currentCampaign: campaign,
                                                            userProfile: this.state.userProfile
                                                        }
                                                    }} style={{ textDecoration: "none" }}>
                                                        <Row className="campaignlistbottom">
                                                            <Card className="cardsizebottom">

                                                                {campaign.listingAd.listingAdImages.length > 0 && <CardImg className="bottomcardimg" src={(campaign && campaign.listingAd && campaign.listingAd.listingAdImages.length > 0 && imageUrl)} alt="Card image cap" />}

                                                                {/* {campaign.listingAd.listingAdFile.length>0 && <Player style={{height:'150px'}}  playsInline className="bottomcardimg" src={IMG_URL + (campaign && campaign.listingAd && campaign.listingAd.listingAdFile.length > 0 && campaign.listingAd.listingAdFile[0])} alt="Card image cap" />} */}
                                                                {campaign.listingAd.listingAdVideos.length > 0 && <CardImg className="bottomcardimg" src={(campaign && campaign.listingAd && campaign.listingAd.thumbnailImage && imageUrl)} alt="Card image cap" />}


                                                            </Card>
                                                        </Row>
                                                        <Row className="btmtext">
                                                            <p> <span className="cardbottomtext">{campaign.listingAd.description}</span> / Created: {this.GetFormattedDate(campaign.startDate)} </p>
                                                        </Row>
                                                    </Link>
                                                </Col>
                                            )
                                        }) : ''}
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                    <div className='overlay-box'>
                        <PropagateLoader
                            // css={override}
                            sizeUnit={"px"}
                            size={25}
                            color={'white'}
                            loading={this.state.loader}
                        />
                    </div>
                </div>

            </div >
        );
    }
}

export default Dashboard;
