/* New Grid Strcuture - will start small but will become a template library */
import styled from "styled-components";

export const Container = styled.div`
  font-family: "BuenosAiresTrial-Bold";
`;

export const StatContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  margin: 1em;
  grid-template-areas:
    "header menu"
    "content content";

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    grid-template-rows: auto;
    margin: 0;
    grid-template-areas:
      "header"
      "menu"
      "content";
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  grid-area: content;
  border-top: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
  padding-top: 4em;
  align-content: center;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    grid-template-columns: 4fr;
  }

`;

export const GridHeader = styled.div`
  grid-area: header;
  padding-left: 15px;
  p {
    height: auto;
    font-family: "BuenosAiresTrial-Bold";
    font-size: 43px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #283447;
  }
`;

export const GridMenu = styled.div`
  grid-area: menu;
  justify-self: end;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    justify-self: auto;
  }
`;

export const GridNumbers = styled.div``;

export const GridGraph = styled.div``;

export const GridPreview = styled.div``;

const Button = styled.button`
  background-color: #283447;
  border: 1px;
  width: auto;
  height: 45px;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0 1em;
  border-radius: 4px;
`;

export const OutlineButtton = styled(Button)`
  border: 1px solid #283447;
  color: #283447;
  background-color: #f1f1f1;
  padding: 8px 20px;
  font-size: 15px;
  height: 45px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

export const SecondaryButton = styled(Button)`
  background-color: #75d0d4;
  border: 1px;
  width: 10em;
  height: 45px;
  font-family: BuenosAiresTrial;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #283447;
`;

export const PrimaryButton = styled(Button)`
  width: 10em;
`;
