import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Afternote from '../../images/Resurs 120.png';
import Portfolio from '../../images/Resurs 160.png';
import Header from '../Header/NewHeader';
import './NewAgentad.css';
import Login from "../Login/Login";

const auth = new Login();

class NewAgentad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previousPath: props.location.state.previousPath,
            userProfile: props.location.state.userProfile,
            campaignType: props.location.state.campaignType ? props.location.state.campaignType : 'agent',
            redirect: false,
            alert: false,
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange = () => {
        this.setState({});
    };

    render() {
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }

        return (
            <div className="newagentad">
                <Header backButton={this.state.previousPath}
                    previousData={{
                        userProfile: this.state.userProfile,
                        campaignType: this.state.campaignType,
                        previousPath: this.props.location.pathname
                    }}
                />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="newagentadmain">
                        <Col lg={{ size: 10, offset: 1}} md={{ size: 10, offset: 1 }} sm={12} xs={12}>
                            <Row className="newagentadprotion1">
                                <p className="newagentadtopheading">Agent ad.</p>
                            </Row>

                            <Row className="newagentadstate">

                                {/* <Col lg={{ size: 4, offset:2 }} md={{ size: 4, offset:2 }} sm={12} xs={12}>
                                    <Link to={{
                                        // pathname: "/showcase-portfolio",
                                            pathname: "/create-ad-new",
                                          
                                        state: {
                                            userProfile: this.state.userProfile,
                                            campaignType: this.state.campaignType,
                                            previousPath: this.props.location.pathname
                                        }
                                    }} style={{ textDecoration: 'none' }}>
                                        <Row className="newagentadlistbottom">

                                            <Row className="newagentadcardsizebottom">
                                                <Row className="titleofnewagentad">Portfolio</Row>
                                                <Row className="chooseimagecssagentad">
                                                    <img src={Afternote}></img>
                                                </Row>
                                                <Row className="agentadtext">
                                                    <Col>
                                                        <p>Showcase your portfolio to potential sellers</p>
                                                    </Col>
                                                </Row>
                                                <Row className="agentadbtmbutton">

                                                    <p>+ Create ad</p>
                                                </Row>
                                            </Row>

                                        </Row>
                                    </Link>

                                </Col> */}
                                <Col lg={{ size: 4, offset: 4 }} md={{ size: 4, offset: 4 }} sm={12} xs={12}>
                                    <Link to={{
                                        pathname: "/create-after-note",
                                        state: {
                                            userProfile: this.state.userProfile,
                                            campaignType: this.state.campaignType,
                                            previousPath: this.props.location.pathname
                                        }
                                    }} style={{ textDecoration: 'none' }}>
                                        <Row className="newagentadlistbottom">
                                            <Row className="newagentadcardsizebottom">
                                                <Row className="titleofnewagentad">After note</Row>
                                                <Row className="chooseimagecssagentad">
                                                    <img src={Portfolio}></img>
                                                </Row>
                                                <Row className="agentadtext">
                                                    <Col>
                                                        <p>Showcase your portfolio to potential sellers</p>
                                                    </Col>
                                                </Row>
                                                <Row className="agentadbtmbutton">

                                                    <p>+ Create ad</p>
                                                </Row>
                                            </Row>
                                        </Row>
                                    </Link>

                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Col>

            </div>
        );
    }
}

export default NewAgentad;
