import React, { Component } from 'react';
import './AgentCampaign.css';
import { Row, Col, Button } from 'reactstrap';
import { Link , Redirect} from 'react-router-dom';
import Header from '../Header/NewHeader';
import image from "../../images/Resurs 890.png";
import Login from "../Login/Login";

const auth = new Login();

class AgentCampaign extends Component {

    render() {
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }
        return (
            <div className="createagentcampaign">
                <Header />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="agentcampaignmain">
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="agentcampaignprotion1">
                                <Row className="topheadingagentcampaign">
                                    <p >Agent campaign.</p>
                                </Row>

                            </Row>
                            <Row className="formagencycampaign">
                                <Row className="agentheading2">
                                    <p>Alexander Eriksson</p>
                                </Row>
                                <Row className="agentheading3">
                                    <p>Lorem ipsum dolor sit amet, consec adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet</p>
                                </Row>

                                <Row className="agentstate">
                                    <Button
                                        className="agentsubmit"
                                        type="submit"> + Create ad </Button>
                                </Row>
                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="listingadprotion2">
                                <img src={image} height="500" width="auto"></img>
                            </Row>
                        </Col>

                    </Row>
                </Col>
            </div>
        );
    }
}

export default AgentCampaign;