import React, { Component } from 'react';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
// import Left from '../../images/leftarrow.png'
// import Right from '../../images/rightarrow.png'
import Bg from '../../images/bg.png';
import Header from '../Header';
import './Style.css';
// const toggle = () => setModal(!modal);

class Newcampaign extends Component {
    constructor(props) {
        super(props);
        this.state = { dropdownOpen: false };
        this.state = { Draftlunchmodal: false };
        this.state = { Draftlunchmodal2: false };
        this.toggle = this.toggle.bind(this);
        this.Drafttoggle = this.Drafttoggle.bind(this);
        this.Drafttoggle2 = this.Drafttoggle2.bind(this);
    }
    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    Drafttoggle() {
        this.setState({
            Draftlunchmodal: !this.state.Draftlunchmodal
        });
    }
    Drafttoggle2() {
        this.setState({
            Draftlunchmodal2: !this.state.Draftlunchmodal2
        });
    }
    render() {

        return (
            <div>
                <Header />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="topheader1">
                        <p className="compname">Campaign Name Here</p>
                    </Row>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="topheader2">
                        {/* <Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                <Row className="leftarrow">
                    <img src={Left} alt="" height="33px"></img>
                </Row>
              </Col> */}
                        <Col lg={12} md={12} sm={12} xs={12}><Row className="centertext">Campaign ad</Row></Col>
                        {/* <Col  lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                  <Row className="rightarrow"><img src={Right} alt="rightarrow" height="20px"></img></Row>
                  </Col> */}
                    </Row>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Row className="displaydate33">
                        <Row className="test133">Preview:</Row>
                        <Row className="editimgg">
                            <img className="editimg" src={Bg} alt="bg.png"></img>
                        </Row>
                    </Row>
                </Col>
                <Col lg={{ size: 6, offset: 3, leftset: 2 }} md={12} sm={12} xs={12}>
                    <div className="btnportion">
                        <Button className="btnstyle" onClick={this.Drafttoggle}>Ad URL</Button>
                    </div>
                </Col>
                <Col lg={{ size: 6, offset: 3, leftset: 2 }} md={12} sm={12} xs={12}>
                    <div className="btnportion">
                        <Button className="btnstyle">Determine the heading</Button>
                    </div>
                </Col>
                <Col lg={{ size: 6, offset: 3, leftset: 2 }} md={12} sm={12} xs={12}>
                    <div className="btnportion">
                        <Button className="btnstyle">Determine image</Button>
                    </div>
                </Col>
                <Col lg={{ size: 6, offset: 3, leftset: 2 }} md={12} sm={12} xs={12}>
                    <div className="btnportion">
                        <Button className="btnstyle">Determine text</Button>
                    </div>
                </Col>
                <Col lg={{ size: 6, offset: 3, leftset: 2 }} md={12} sm={12} xs={12}>
                    <div className="btnportion">
                        <Button className="btnstyle">Determine description</Button>
                    </div>
                </Col>

                <Modal isOpen={this.state.Draftlunchmodal} size="lg" className="Draft-modal-block-size" toggle={this.toggle}>
                    <Col xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}>
                        <div className="Draft-block-modal-body-costum">
                            <ModalBody>
                                <p className="modal2-heading11">
                                    Uppdatera annonsens innehåll
                                </p>
                                <Row className="date1">
                                    <Input
                                        type="text"
                                        placeholder="URL"
                                        id="url22"
                                    >
                                    </Input>
                                </Row>
                                <Row className="modalimg">
                                    <img className="modalimg2" src={Bg} alt="bg.png"></img>
                                </Row>
                                <Row className="downloadbtn">
                                    <Button className="downbtn">HÄMTA ALLA BILDER</Button>
                                </Row>
                                <Row className="address11">
                                    <Input
                                        type="text"
                                        placeholder="URL"
                                        id="address1"
                                    >
                                    </Input>
                                </Row>
                                <Row className="testarea">
                                    <Input
                                        type="textarea"
                                        placeholder="some text here"
                                        id="textarea1"
                                    >
                                    </Input>
                                </Row>
                                <Row className="testarea">
                                    <Input
                                        type="textarea"
                                        placeholder="some text here"
                                        id="textarea1"
                                    >
                                    </Input>
                                </Row>
                                <Row>
                                    <ModalFooter className="modalfootercustom">
                                        <Col>  <p className="cancelbtn" onClick={this.Drafttoggle}>ABVRYT</p></Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>  <p className="addbtn" onClick={this.Drafttoggle2} >UPPDATERA</p>{' '}</Col>


                                    </ModalFooter>
                                </Row>

                            </ModalBody>
                        </div>
                    </Col>
                </Modal>
            </div>
        );
    }
}

export default Newcampaign;
