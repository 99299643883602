import React, { Component } from "react";
import pdf from './Privacy_Policy_Brightee_Marketing_AB.pdf'

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <embed src={pdf} style={{ width: '100%', height: '2100px'}}></embed>
        );
    }
}

export default PrivacyPolicy;
