import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Col, Input, Row } from 'reactstrap';
import Graphchart from '../../images/state.png';
import './Newstatistics.css';
import Login from "../Login/Login";
import MobileAdPreview from '../MobileAdPreview';
import { IMG_URL } from '../../config'
import CampaignService from '../Apiservices/CampaignService';
import { PropagateLoader } from "react-spinners";
import Chart from "react-google-charts";
import moment from 'moment';
import dummy1024 from "../../images/dummy-1024.png";

const auth = new Login();
const campaignService = new CampaignService();

class CustomerStatistics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            alert: false,
            currentCampaignId: this.props.match.params.campaignid,
            loader: false,
            stats: [],
            listigAd: {},
            headline: '',
            message: '',
            description: '',
            website: '',
            previewImage: dummy1024,
            agencyImage: '',
            ctr: "-",
            clicks: "-",
            impressions: "-",
            reach: "-",
            frequency: "-",
            firstDay: ""
        };
    }

    componentDidMount() {
        this.setState({ loader: true });
        let campaignId = this.state.currentCampaignId.split('-');
        console.log("CampaignId", typeof campaignId);
        campaignService.getStatsOfCampaignForVendor(campaignId[0])
            .then((result) => {
                let responseJson = result.data.stats.data;
                console.log(responseJson)
                if (responseJson !== undefined) {
                    this.setState({ loader: false });
                    this.setState({ stats: responseJson });
                    this.setState({ firstDay: responseJson[0].date_start });
                    this.setState({
                        ctr: responseJson
                            .reduce((totalCtr, currentObj) => currentObj.ctr ?
                                totalCtr + Number(currentObj.ctr) : totalCtr, 0)
                    });
                    this.setState({
                        ctr: this.state.ctr / responseJson.length
                    });
                    this.setState({
                        ctr: this.state.ctr.toFixed(1) + '%'
                    });
                    this.setState({
                        clicks: responseJson
                            .reduce((totalClicks, currentObj) => totalClicks + Number(currentObj.unique_inline_link_clicks), 0)
                    });
                    this.setState({
                        impressions: responseJson
                            .reduce((totalImpressions, currentObj) => totalImpressions + Number(currentObj.impressions), 0)
                    });
                    this.setState({
                        frequency: responseJson
                            .reduce((totalFrequency, currentObj) => totalFrequency + Number(currentObj.frequency), 0)
                    });
                    this.setState({
                        frequency: this.state.frequency.toFixed(1) + '%'
                    });
                    this.setState({
                        reach: responseJson
                            .reduce((totalReach, currentObj) => totalReach + Number(currentObj.reach), 0)
                    });
                }
                else {
                    this.setState({ loader: false });
                }
            })
            .catch(err => {
                this.setState({ loader: false });
                console.log(err)
                alert("No stats to show", "No stats found", "error");
            });

        campaignService.getListingOfCampaignForVendor(campaignId[1])
            .then((result) => {
                console.log(result, 'result')
                this.setState({ listigAd: result.data })
                this.setState({ headline: result.data.headline })
                this.setState({ message: result.data.message })
                this.setState({ website: result.data.website })
                this.setState({ description: result.data.description })
                this.setState({ previewImage: IMG_URL + result.data.listingAdImages[0] })
                let agencyImage, userImage
                if (result.data.user.role == 'agent') {
                    agencyImage = IMG_URL + result.data.user.agency.userImage;
                } else {
                    agencyImage = IMG_URL + result.data.user.userImage;
                }
                this.setState({ agencyImage: agencyImage });
            }).catch(err => {
                this.setState({ loader: false });
                console.log(err)
            });

        this.setState({ loader: false });
    };

    render() {
        const chart =
            <Chart
                // className="statsChart"
                width={'600px'}
                height={'400px'}
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={[
                    ['Date', 'Time displayed', 'People reached', 'Clicks'],

                    [this.state.stats[0] ? moment(this.state.stats[0].date_start).format('MMM Do') : 0,
                    this.state.stats[0] ? Number(this.state.stats[0].impressions) : 0,
                    this.state.stats && this.state.stats.length && this.state.stats[0].reach ? Number(this.state.stats[0].reach) : 0,
                    this.state.stats && this.state.stats.length && this.state.stats[0].unique_inline_link_clicks ? Number(this.state.stats[0].unique_inline_link_clicks) : 0],
                    [this.state.stats[1] ? moment(this.state.stats[1].date_start).format('MMM Do') : 0,
                    this.state.stats[1] ? Number(this.state.stats[1].impressions) : 0,
                    this.state.stats && this.state.stats.length && this.state.stats[1] ? Number(this.state.stats[1].reach) : 0,
                    this.state.stats && this.state.stats.length && this.state.stats[1] ? Number(this.state.stats[1].unique_inline_link_clicks) : 0],
                    [this.state.stats[2] ? moment(this.state.stats[2].date_start).format('MMM Do') : 0,
                    this.state.stats[2] ? Number(this.state.stats[2].impressions) : 0,
                    this.state.stats && this.state.stats.length && this.state.stats[2] ? Number(this.state.stats[2].reach) : 0,
                    this.state.stats && this.state.stats.length && this.state.stats[2] ? Number(this.state.stats[2].unique_inline_link_clicks) : 0],
                    [this.state.stats[3] ? moment(this.state.stats[3].date_start).format('MMM Do') : 0,
                    this.state.stats[3] ? Number(this.state.stats[3].impressions) : 0,
                    this.state.stats && this.state.stats.length && this.state.stats[3] ? Number(this.state.stats[3].reach) : 0,
                    this.state.stats && this.state.stats.length && this.state.stats[3] ? Number(this.state.stats[3].unique_inline_link_clicks) : 0],
                    [this.state.stats[4] ? moment(this.state.stats[4].date_start).format('MMM Do') : 0,
                    this.state.stats[4] ? Number(this.state.stats[4].impressions) : 0,
                    this.state.stats && this.state.stats.length && this.state.stats[4] ? Number(this.state.stats[4].reach) : 0,
                    this.state.stats && this.state.stats.length && this.state.stats[4] ? Number(this.state.stats[4].unique_inline_link_clicks) : 0],
                    [this.state.stats[5] ? moment(this.state.stats[5].date_start).format('MMM Do') : 0,
                    this.state.stats[5] ? Number(this.state.stats[5].impressions) : 0,
                    this.state.stats && this.state.stats.length && this.state.stats[5] ? Number(this.state.stats[5].reach) : 0,
                    this.state.stats && this.state.stats.length && this.state.stats[5] ? Number(this.state.stats[5].unique_inline_link_clicks) : 0],
                    // [this.state.stats[1].date_start, Number(this.state.stats[1].frequency), Number(this.state.stats[1].impressions), Number(this.state.stats[1].clicks)],
                    // [this.state.stats[2].date_start, Number(this.state.stats[2].frequency), Number(this.state.stats[2].impressions), Number(this.state.stats[2].clicks)],
                    // [this.state.stats[3].date_start, Number(this.state.stats[3].frequency), Number(this.state.stats[3].impressions), Number(this.state.stats[3].clicks)],
                    // [this.state.stats[4].date_start, Number(this.state.stats[4].frequency), Number(this.state.stats[4].impressions), Number(this.state.stats[4].clicks)],
                    // [this.state.stats[5].date_start, Number(this.state.stats[5].frequency), Number(this.state.stats[5].impressions), Number(this.state.stats[5].clicks)],              
                ]}
                options={{
                    chartArea: { width: '70%' },
                    backgroundColor: 'transparent',
                    colors: ['#ffdfda', '#f9ab93', '#283447'],
                    legend: { position: 'bottom', maxLines: 1 },
                }}
                legendToggle
            />

        return (
            <div className="newstatistics">
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="newstatisticsmain1">
                        <Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12} order="12">
                            <Row className="newstatisticsprotion1">
                                <Col>
                                    <Row className="newstatisticstopheading">
                                        <p>Statistics.</p>
                                    </Row>
                                    <Row className="newstatisticsaddress">
                                        <p>{this.state.description}</p>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className="imageagency">
                                    <img className="agencyimage" src={this.state.agencyImage} alt='agency image' />                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="newstatisticsmain">
                        <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} xs={12} order="12">
                            {/* <Row className="newstatisticsprotion1">
                                <p className="newstatisticstopheading">Statistics.</p>
                            </Row>
                            <p className="newstatisticstextheading">{this.state.description}</p> */}
                            <Row className="newstatisticsdstate">
                                <Col>
                                    <Row className="newstatisticsnumber">
                                        <p>{this.state.clicks}</p>
                                    </Row>
                                    <Row className="newstatisticstextheading">
                                        <p>Clicks</p>
                                    </Row>
                                    <Row className="newstatisticstext">
                                        <p>People have clicked your ad
                                        and viewed the property page
                                        </p>
                                    </Row>
                                </Col>

                                <Col>
                                    <Row className="newstatisticsnumber">
                                        <p>{this.state.ctr}</p>
                                    </Row>
                                    <Row className="newstatisticstextheading">
                                        <p>Click frequency</p>
                                    </Row>
                                    <Row className="newstatisticstext">
                                        <p>The percentage of people that saw your ad and
                                        then clicked to see your property page
                                        </p>
                                    </Row>
                                </Col>

                            </Row>
                            <Row className="newstatisticsdstate">
                                <Col>
                                    <Row className="newstatisticsnumber">
                                        <p>{this.state.reach}</p>
                                    </Row>
                                    <Row className="newstatisticstextheading">
                                        <p>People reached</p>
                                    </Row>
                                    <Row className="newstatisticstext">
                                        <p>The amount of unique individuals that saw your ad
                                        </p>
                                    </Row>
                                </Col>

                                <Col>
                                    <Row className="newstatisticsnumber">
                                        <p>{this.state.impressions}</p>
                                    </Row>
                                    <Row className="newstatisticstextheading">
                                        <p>Times displayed</p>
                                    </Row>
                                    <Row className="newstatisticstext">
                                        <p>The total number of times your ad was shown
                                        </p>
                                    </Row>
                                </Col>

                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} order="1">
                            <Row className="mobilepreviewVendor">
                                <MobileAdPreview headline={this.state.headline} message={this.state.message} website={this.state.website} description={this.state.description} previewImage={this.state.previewImage} />
                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 0 }} md={{ size: 4, offset: 0 }} sm={12} xs={12}>
                            <Row className="chartContainerRowVendor">
                                {chart}
                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12}>
                            <Row className="chartContainerRow">
                                {/* {chart} */}
                            </Row>
                        </Col>
                    </Row>
                    <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                        <div className='overlay-box'>
                            <PropagateLoader
                                // css={override}
                                sizeUnit={"px"}
                                size={25}
                                color={'white'}
                                loading={this.state.loader}
                            />
                        </div>
                    </div>
                </Col>

            </div>
        );
    }
}

export default CustomerStatistics;
