import React, { Component } from 'react';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Form, Input, Row, Nav, NavItem, FormGroup, Label } from 'reactstrap';
import swal from 'sweetalert';
import './Signup.css';
import { Link, Redirect } from 'react-router-dom';
import LandingPageHeader from "../Header/LandingPageHeader";
import NewHeader from "../Header/NewHeader";
import signupimage from "../../images/listing-ad.png";
import Footer from '../Footer/Footer';
import Apiservices from '../Apiservices/AuthService';
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Privacy from "./privacyPolicyModal";
const Auth = new Apiservices();
class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            alert: false,
            invalid: '',
            role: '',
            loader: false,
            countries: [],
            country: "Sweden",
            firstName: "",
            lastName: "",
            email: "",
            phoneNo: "",
            company: "",
            jobTitle: "",
            check: false,
            openPrivacyModal: false
        };
        this.handelChange = this.handelChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fieldsValidation = this.fieldsValidation.bind(this);
        this.signupRequest = this.signupRequest.bind(this);
        this.toggleCheck = this.toggleCheck.bind(this);
        // this.scrollTomid = this.scrollTomid.bind(this);

    }

    scrollTomid() {
        scroll.scrollTo(1);
    }

    componentDidMount() {
        this.scrollTomid();
        fetch("https://restcountries.eu/rest/v2/all")
            .then(res => res.json())

            .then(countries =>

                this.setState({ countries: countries }))
            .catch(err => console.log("Error:", err));
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        console.log({ [e.target.name]: e.target.value }, "data");
    }



    handelChange = even => {
        var newValue = even.nativeEvent.target.value;
        console.log(newValue)
        setTimeout(() => {
            this.setState({ country: newValue });
        }, 1000);
    };

    fieldsValidation = () => {
        if (this.state.firstName == "") {
            return "First name is missing"
        }
        else if (this.state.lastName == "") {
            return "Last name is missing"
        }
        else if (this.state.email == "") {
            return "Email is missing"
        }
        else if (this.state.phoneNo == "") {
            return "Phone number is missing"
        }
        else if (this.state.country == "") {
            return "Country is missing"
        }
        else if (this.state.jobTitle == "") {
            return "Job title is missing"
        }
        else if (this.state.company == "") {
            return "Company is missing"
        }
        else if (this.state.country == "") {
            return "Country is missing"
        }
        else if (this.state.check == false) {
            return "Please read terms and conditions"
        }
        return null;
    };

    signupRequest(e) {
        e.preventDefault()
        console.log()
        if (this.fieldsValidation() != undefined) {
            // alert(this.fieldsValidation())
            swal("oops", this.fieldsValidation(), "warning");
        }
        else {
            // if (this.state.name &&
            //    this.state.phoneNumber && this.state.description && this.state.country) {
            this.setState({ loader: true });
            let data = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNo: this.state.phoneNo,
                email: this.state.email,
                country: this.state.country,
                company: this.state.company,
                jobTitle: this.state.jobTitle
            }
            Auth.signupRequest(data)
                .then((result) => {
                    let responseJson = result;
                    if (responseJson.status == '200') {
                        this.setState({ redirect: true });
                        this.setState({ loader: false });
                        swal("Done", "Thank you for submitting your details! A member of our team will be in touch shortly", "success")
                    }
                })
                .catch(err => {
                    this.setState({ alert: true });
                    this.setState({ invalid: err.response.data.error.message });
                    swal("Oops", err.response.data.error.message, "error");
                });
        }
        // else {
        //    swal("Oops", "Something is missing", "warning");

        // }
    }

    toggleCheck = () => {
        this.setState(prevState => ({
            check: !prevState.check,
        }));
    }

    openPrivacyPolicy = () => {
    this.setState(prevState => ({
      openPrivacyModal: !prevState.openPrivacyModal
    }));
  };

    render() {
        if (this.state.redirect == true) {
            return (
                <Redirect to={{
                    pathname: "/landing-page",
                }} />
            )
        }
        return (
            <div id="wrapped">
                <div className="part1">
                    <LandingPageHeader />
                    <Row className="signup">
                        <Col lg={7} md={7} sm={12} xs={12} style={{ padding: 0 }} >
                            <Row className="gets">
                                <p className="getsp">Get started!</p>
                            </Row>
                            <Row className="customFieldRows">
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Label className="label" > First name </Label>
                                    <Row className="dataInputBlock">
                                        <Input
                                            className="in"
                                            type="text" name="firstName" placeholder="First name"
                                            onChange={this.onChange}  >

                                        </Input>
                                    </Row>
                                </Col>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Label className="label" > Last name </Label>
                                    <Row className="dataInputBlock">
                                        <Input
                                            className="in"
                                            onChange={this.onChange}
                                            type="text" name="lastName" placeholder="Last name" >
                                        </Input>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="customFieldRows">
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Label className="label" > E-mail </Label>
                                    <Row className="dataInputBlock">
                                        <Input
                                            className="in"
                                            onChange={this.onChange}
                                            type="text" name="email" placeholder="E-mail"  >
                                        </Input>
                                    </Row>
                                </Col>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Label className="label" > Phone </Label>
                                    <Row className="dataInputBlock">
                                        <Input
                                            className="in"
                                            onChange={this.onChange}
                                            type="number" name="phoneNo" placeholder="Phone" >
                                        </Input>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="customFieldRows">
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Label className="label" > Company </Label>
                                    <Row className="dataInputBlock">
                                        <Input
                                            className="in"
                                            onChange={this.onChange}
                                            type="text" name="company" placeholder="Company"  >
                                        </Input>
                                    </Row>
                                </Col>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Label className="label" > Job title </Label>
                                    <Row className="dataInputBlock">
                                        <Input
                                            className="in"
                                            onChange={this.onChange}
                                            type="text" name="jobTitle" placeholder="Job title">
                                        </Input>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="customFieldRows">
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Label className="label" > Country </Label>
                                    <Row className="dataInputBlock">
                                        <Input className="in" type="select" name="country" placeholder="Country" onChange={this.handelChange} >
                                            <option value="Sweden">Sweden</option>
                                            {this.state.countries.map((country, index) =>
                                                <option value={country.name}>
                                                    {country.name}
                                                </option>
                                            )}
                                        </Input>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="customFieldRows">
                                <Col style={{ padding: 0 }}>
                                    <Label className="checkl" check>
                                        <Input className="checkbox" type="checkbox"
                                            checked={this.state.check}
                                            onChange={this.toggleCheck} />
                                        <p className="pcheck">I have read and agree <br></br>to the<span className="policyText" onClick={this.openPrivacyPolicy}> Privacy Policy </span></p>
                                        <Privacy open={this.state.openPrivacyModal} toggleModal={this.openPrivacyPolicy} />
                                    </Label>
                                </Col>
                            </Row>
                            <Row className="customFieldRows">
                                <Col style={{ padding: 0 }}>
                                    <Button className="signupb" onClick={this.signupRequest}>Sign up</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={{ size: 5, offset: 0 }} md={{ size: 5, offset: 0 }} sm={{ size: 10, offset: 1}} xs={{ size: 10, offset: 1 }}>
                            <Row className="signupimage ">
                                <img src={signupimage} width="100%" height="auto"></img>
                            </Row>
                        </Col>
                    </Row>
                    <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                        <div className='overlay-box'>
                            <PropagateLoader
                                // css={override}
                                sizeUnit={"px"}
                                size={25}
                                color={'white'}
                                loading={this.state.loader}
                            />
                        </div>
                    </div>
                </div>
                <Footer backHidden={true} />
            </div >
        );
    }
}

export default Signup;
