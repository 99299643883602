import React, { Component } from 'react';
import './NewCampaigntype.css';
import {
    Row,
    Col
} from 'reactstrap';
import { PropagateLoader } from "react-spinners";
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header/NewHeader';
import Agency from '../../images/Resurs 10.png';
import Agent from '../../images/Resurs 80.png';
import Listiingnew from '../../images/Resurs 90.png';
import UserService from '../Apiservices/UserService';
import Login from "../Login/Login";
import Location from "../Apiservices/GetUserLocationFromIp";

const location = new Location()
const auth = new Login();

const userService = new UserService();

class NewCampaigntype extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previousPath: props.location.state && props.location.state.previousPath ? props.location.state.previousPath : '/dashboard',
            userProfile: props.location.state.userProfile,
            campaignType: "",
            redirect: false,
            alert: false,
            country: "",
            loader: true
        };
    }

    componentDidMount() {
        return location.getGeoInfo()
            .then((result) => {
                console.log(result.country_name, "hadjkahsjld")
                this.setState({ country: result.country_name, loader: false });
            })
    };

    onChange = (e) => {
        this.setState({});
    };

    render() {

        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }

        return (
            <div className="changecampaigntype">
                <Header backButton={this.state.previousPath} />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="changecampaigntypemain">
                        <Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12}>
                            <Row className="changecampaigntypeprotion1">
                                <p className="changecampaigntypetopheading">Choose ad type.</p>
                            </Row>
                            <Row className="changecampaigntypestate">
                                {this.state.userProfile.role === "agency" && <Col lg={{ size: 4 }} md={{ size: 4 }} sm={12} xs={12}>
                                    <Link to={{
                                        pathname: "/create-ad-new",
                                        state: {
                                            userProfile: this.state.userProfile,
                                            campaignType: "agency",
                                            previousPath: this.props.location.pathname
                                        }
                                    }} style={{ textDecoration: 'none' }}>
                                        <Row className="changecampaigntypelistbottom">
                                            <Row className="changecampaigntypecardsizebottom">
                                                <Row className="titleofchangecampaigntype">Agency Ad</Row>
                                                <Row className="chooseimagecss">
                                                    <img src={Agency}></img>
                                                </Row>
                                                <Row className="campaigntypetext">
                                                    <Col>
                                                        <p>Claim your region with our powerful Agency Ad, stay top of mind with your target group</p>
                                                    </Col>
                                                </Row>
                                                <Row className="campaigntypebtmbutton">

                                                    <p>+ Create ad</p>
                                                </Row>
                                            </Row>
                                        </Row>
                                    </Link>

                                </Col>}

                                <Col
                                    lg={this.state.userProfile.role === "agency" ? { size: 4 } : { size: 4, offset: 2 }}
                                    md={this.state.userProfile.role === "agency" ? { size: 4 } : { size: 4, offset: 2 }}
                                    sm={this.state.userProfile.role === "agency" ? { size: 12 } : { size: 12 }}
                                    xs={this.state.userProfile.role === "agency" ? { size: 12 } : { size: 12 }}
                                >
                                    <Link to={{
                                        pathname: "/new-agentad",
                                        state: {
                                            userProfile: this.state.userProfile,
                                            campaignType: "agent",
                                            previousPath: this.props.location.pathname
                                        }
                                    }} style={{ textDecoration: 'none' }}>
                                        <Row className="changecampaigntypelistbottom">

                                            <Row className="changecampaigntypecardsizebottom">
                                                <Row className="titleofchangecampaigntype">Agent  Ad</Row>
                                                <Row className="chooseimagecss">
                                                    <img src={Agent}></img>
                                                </Row>
                                                <Row className="campaigntypetext">
                                                    <Col>
                                                        <p>Digital after-notes in a certain zip-code or showcase your portfolio to potential sellers</p>
                                                    </Col>
                                                </Row>
                                                <Row className="campaigntypebtmbutton">

                                                    <p>+ Create ad</p>
                                                </Row>
                                            </Row>
                                        </Row>
                                    </Link>

                                </Col>

                                <Col lg={{ size: 4 }} md={{ size: 4 }} sm={12} xs={12}>
                                    <Link to={{
                                        pathname: (this.state.country === "Sweden" || this.state.country === "United Kingdom") ? "listing-ad" : "/create-ad-new",
                                        state: {
                                            userProfile: this.state.userProfile,
                                            campaignType: "listing",
                                            previousPath: this.props.location.pathname
                                        }
                                    }} style={{ textDecoration: 'none' }}>
                                        <Row className="changecampaigntypelistbottom">
                                            <Row className="changecampaigntypecardsizebottom">
                                                <Row className="titleofchangecampaigntype">Listing Ad</Row>
                                                <Row className="chooseimagecss">
                                                    <img src={Listiingnew}></img>
                                                </Row>
                                                <Row className="campaigntypetext">
                                                    <Col>
                                                        <p>Boost your current listing and reach your buyers every time they go on social media</p>
                                                    </Col>
                                                </Row>
                                                <Row className="campaigntypebtmbutton">

                                                    <p>+ Create ad</p>
                                                </Row>
                                            </Row>
                                        </Row>
                                    </Link>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                    <div className='overlay-box'>
                        <PropagateLoader
                            // css={override}
                            sizeUnit={"px"}
                            size={25}
                            color={'white'}
                            loading={this.state.loader}
                        />
                    </div>
                </div>

            </div >
        );
    }
}

export default NewCampaigntype;
