import React, { Component } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import Cup from "../../images/Resurs 490.png";
import Header from '../Header/NewHeader';
import CampaignService from '../Apiservices/CampaignService';
import swal from 'sweetalert';
import './EditAdNew.css';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import MobileAdPreview from '../MobileAdPreview';
import { IMG_URL } from '../../config'
import Login from "../Login/Login";
import { PropagateLoader } from "react-spinners";

var auth = new Login();
var campaignService = new CampaignService();
var regionsArray = []

class EditAdNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            alert: false,
            userProfile: props.location.state.userProfile ? props.location.state.userProfile
                : props.location.state.currentCampaign.user,
            campaignType: props.location.state.campaignType ? props.location.state.campaignType
                : props.location.state.currentCampaign.campaignType,
            currentCampaign: props.location.state.currentCampaign,
            listingData: props.location.state.listingData ? props.location.state.listingData
                : props.location.state.currentCampaign.listingAd,
            budget: props.location.state.budget ? props.location.state.budget
                : {
                    amount: props.location.state.currentCampaign.budgetAmount,
                    currency: props.location.state.currentCampaign.currency,
                    estimatedReach: props.location.state.currentCampaign.estimatedReach,
                    days: props.location.state.currentCampaign.maxDays
                },
            loader: false,
            geolocation: [],
            startDate: props.location.state.campaignFields ? props.location.state.campaignFields.startDate
                : props.location.state.currentCampaign.startDate.substr(0, 10),
            endDate: props.location.state.campaignFields ? props.location.state.campaignFields.endDate
                : props.location.state.currentCampaign.endDate.substr(0, 10),
            // region: props.location.state.campaignFields ? props.location.state.campaignFields.region
            //     : props.location.state.currentCampaign.region,
            region: '',
            selectedRegion: {},
            status: props.location.state.currentCampaign.status,
            regionsArray: props.location.state.currentCampaign.region

        };
        this._onSuggestionSelected = this._onSuggestionSelected.bind(this);
        console.log(props.location.state.currentCampaign.startDate, 'StartDate');
    }

    componentDidMount() {
        regionsArray = this.state.regionsArray
    };

    geolocations = (newValue) => {
        campaignService.fbGeoLoaction(newValue.value)
            .then(result => {
                let responseJson = result.data;
                if (responseJson) {
                    this.setState({ geolocation: responseJson });
                    this.setState({ alert: true });
                }
            })
            .catch(err => {
                swal("Oops", err.response.data.error.message, "error");
            });
    };

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleRemoveSocial(idx) {
        let someArray = this.state.regionsArray;
        someArray.splice(idx, 1);
        this.setState({ regionsArray: someArray });
    }

    handleInputVlaueChange(e, idx) {
        let nextSocialData = this.state.regionsArray.slice();
        nextSocialData[idx] = e.target.value;
        this.setState({ regionsArray: nextSocialData });
    }

    _onSuggestionSelected(event, { suggestion, suggestionValue, index, method }) {
        regionsArray.push(suggestion)
        this.setState({ regionsArray: regionsArray });
        console.log(regionsArray)
    }


    deleteCampaign = (e) => {
        this.setState({ loader: true });
        let fbCampaignId = this.state.currentCampaign.campaignResourceId
        campaignService.deleteFbCampaign(this.state.currentCampaign._id, fbCampaignId)
            .then(result => {
                let responseJson = result.data;
                // console.log(responseJson.result)
                if (responseJson.deleteFbCampaign.success == true) {
                    this.setState({ redirect: true });
                    this.setState({ loader: false });
                }
            })
            .catch(err => {
                // swal("Oops", err.response.data.error.message, "error");
                swal("Oops", err, "error");

                this.setState({ loader: false });

            });
    };

    GetFormattedDate(date) {
        var todayTime = new Date(date);
        var month = moment(todayTime.getMonth() + 1);
        var day = moment(todayTime.getDate());
        var year = moment(todayTime.getFullYear());
        return day + "-" + month + "-" + year;
    }

    onChangeAutoSearch = (event, { newValue }) => {
        this.setState({ region: newValue })
    }

    activeCampaign = () => {
        this.setState({ loader: true });
        let data = {
            fbCampaignId: this.state.currentCampaign.campaignResourceId
        }
        campaignService.activeFbCampaign(this.state.currentCampaign._id, data)
            .then(result => {
                let responseJson = result;
                console.log(responseJson)
                if (responseJson.status == 200) {
                    // this.setState({ redirect: true });
                    this.setState({ currentCampaign: responseJson.data });
                    this.setState({ status: responseJson.data.status });
                    this.setState({ loader: false });
                    // window.location.replace("/edit-ad-new")

                }
            })
            .catch(err => {
                swal("Oops", err, "error");
                this.setState({ loader: false });

            });
    }

    pauseCampaign = () => {
        this.setState({ loader: true });
        let data = {
            fbCampaignId: this.state.currentCampaign.campaignResourceId
        }
        campaignService.pauseFbCampaign(this.state.currentCampaign._id, data)
            .then(result => {
                let responseJson = result;
                console.log(responseJson)
                if (responseJson.status == 200) {
                    this.setState({ currentCampaign: responseJson.data });
                    this.setState({ status: responseJson.data.status });
                    this.setState({ loader: false });
                    // window.location.replace("/edit-ad-new")

                }
            })
            .catch(err => {
                swal("Oops", err, "error");
                this.setState({ loader: false });

            });
    }

    render() {

        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }
        else if (this.state.redirect == true) {
            return (<Redirect to="/dashboard" />)
        }

        const inputProps = {
            placeholder: "Type to add more locations",
            value: this.state.region,
            onChange: this.onChangeAutoSearch
        };
        const isStartDate = !(new Date(this.state.startDate) > new Date())
        return (
            <div className="editadnew">
                <Header />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="editadmain">
                        <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} xs={12} >
                            <Row className="editadprotion1">
                                <p className="topheadingeditadnew">Edit Ad.</p>
                            </Row>
                            <Row className="formeditadnew">
                                {/* <Label className="editadnewname-label" > Target URL(required) </Label>
                                <Input id="targetinputeditad"
                                    type="select">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                </Input> */}
                                <Label className="editadnewname-label" > Period </Label>
                                <Row className="data1data2editadnew">
                                    <Col lg="6" md="6" sm="6" xs="6">
                                        <Row className="data1roweditadnew">
                                            <Input id="startdateinputeditad"
                                                type="date" name="startDate" value={this.state.startDate} onChange={this.onChange} disabled={isStartDate}>
                                            </Input>
                                        </Row>
                                    </Col>
                                    <Col lg="6" md="6" sm="6" xs="6">
                                        <Row className="data2roweditad">
                                            <Input id="enddateinputeditad"
                                                type="date" name="endDate" value={this.state.endDate} onChange={this.onChange} >
                                            </Input>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* <Label className="editadnewname-label" > Country </Label>
                                <Input id="countryinputeditadnew"
                                    type="text"
                                    name=""
                                    placeholder="Type to add more locations"
                                    onChange={this.geolocations}
                                    required>
                                </Input> */}
                                <Label className="editadnewname-label" > Target region (country/region/city/zip code) </Label>
                                {/* <Input type="select"
                                    id="editadnewuserName"
                                    type="select"
                                    name="region" onChange={this.onChange}
                                    required >
                                    {this.state.geolocation && this.state.geolocation.length === 0 && <option value={this.state.region}>Sweden</option>}
                                    {this.state.geolocation && this.state.geolocation.map((location) => {
                                        return (
                                            <option value={location.canonicalName}>{location.canonicalName}</option>
                                        )
                                    })}
                                </Input> */}
                                <Autosuggest
                                    suggestions={this.state.geolocation}
                                    onSuggestionsFetchRequested={this.geolocations}
                                    // onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={(item) => {
                                        this.setState({ selectedRegion: item })
                                        return item.type === 'country' ? item.name : (item.type === 'region' || item.type === 'geo_market') ? item.name + ', ' + item.country_name : item.name + ', ' + item.region + ', ' + item.country_name
                                    }}
                                    renderSuggestion={(item) => {
                                        return (
                                            <h6>{item.type === 'country' ? item.name : (item.type === 'region' || item.type === 'geo_market') ? item.name + ', ' + item.country_name : item.name + ', ' + item.region + ', ' + item.country_name}</h6>
                                        )
                                    }}
                                    onSuggestionSelected={this._onSuggestionSelected}
                                    inputProps={inputProps}
                                />

                                <table className="table mt-3 bordered table-hover  white-table addNewSocial">
                                    <tbody>
                                        {this.state.regionsArray.map((Social, idx) => (
                                            <tr key={idx} className="row Social">
                                                <td className="col-11 socialInput">
                                                    <Input
                                                        className="selectedresults"

                                                        type="text"
                                                        placeholder={`Add New # ${idx + 1}`}
                                                        value={Social.type === 'country' ? Social.name : (Social.type === 'region' || Social.type === 'geo_market') ? Social.name + ', ' + Social.country_name : Social.name + ', ' + Social.region + ', ' + Social.country_name}
                                                        onChange={e => this.handleInputVlaueChange(e, idx)}
                                                    />
                                                </td>

                                                {/* <td className="col-1 closingLink" style={{padding: 0}}>
                                                        <div
                                                            className="crossarrow"
                                                            onClick={() => this.handleRemoveSocial(idx)}
                                                        >
                                                            X
                                                        </div>
                                                    </td> */}
                                                <Row className="crosssighns">
                                                    <p onClick={() => this.handleRemoveSocial(idx)}>x</p>
                                                </Row>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <Row className="settingsaveroweditad">
                                    <Col lg="4" md="4" sm="4" xs="4">
                                        <Row className="roweditadcancel">
                                            <p className="cancelad" onClick={this.deleteCampaign}>Cancel ad</p>
                                        </Row>

                                    </Col>
                                    <Col lg="4" md="4" sm="4" xs="4">
                                        <Row className="btn-wrapper-editadnew">
                                            {this.state.currentCampaign.status == "PAUSED"
                                                ? <Button class="editadnew-img-btnn" onClick={(e) => { this.activeCampaign(e) }}>Active</Button>
                                                : <Button class="editadnew-img-btnn" onClick={(e) => { this.pauseCampaign(e) }}>Pause Ad</Button>
                                            }
                                            {/* <Button class="editadnew-img-btnn">Pause ad</Button> */}
                                            {/* <Input
                                                type="submit" /> */}
                                        </Row>
                                    </Col>
                                    <Col lg="4" md="4" sm="4" xs="4">
                                        <Row className="justitybutton">
                                            <Link to={{
                                                pathname: "/PublishAndPreview",
                                                state: {
                                                    campaignType: this.state.campaignType,
                                                    userProfile: this.state.userProfile,
                                                    previousPath: this.props.location.pathname,
                                                    listingData: this.state.listingData,
                                                    budget: this.state.budget,
                                                    selectedRegion: this.state.regionsArray,
                                                    currentCampaign: this.state.currentCampaign,
                                                    campaignFields: {
                                                        startDate: this.state.startDate,
                                                        endDate: this.state.endDate,
                                                        region: this.state.regionsArray,
                                                        fbPageId: this.state.fbPageId,
                                                        isEdit: true,
                                                    }
                                                }
                                            }}>
                                                <Button className="savesettingeditad3"
                                                    type="submit"> Next </Button>
                                            </Link>
                                        </Row>
                                    </Col>
                                    <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                                        <div className='overlay-box'>
                                            <PropagateLoader
                                                // css={override}
                                                sizeUnit={"px"}
                                                size={25}
                                                color={'white'}
                                                loading={this.state.loader}
                                            />
                                        </div>
                                    </div>

                                </Row>
                            </Row>
                        </Col>

                        {/* <Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={12} xs={12} >
                            <Row className="editadprotion2">
                                <img src={Cup} height="auto" width="100%"></img>
                            </Row>
                        </Col> */}
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 5, offset: 1 }} sm={{ size: 12, offset: 0 }} xs={{ size: 12, offset: 0 }} >
                            <MobileAdPreview headline={this.state.listingData.headline} message={this.state.listingData.message} website={this.state.listingData.website} description={this.state.listingData.description} previewImage={IMG_URL + this.state.listingData.listingAdImages[0]} />
                        </Col>

                    </Row>
                </Col>
            </div>
        );
    }
}

export default EditAdNew;
