import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardImg, Col, Row } from 'reactstrap';
// import Left from '../../images/leftarrow.png'
// import Right from '../../images/rightarrow.png'
import House from '../../images/house.jpg';
import Header from '../Header';
import './Style.css';
// import Bg from '../../images/bg.png';
class Statistics extends Component {
  // constructor(props) {
  //     super(props);
  // }
  render() {

    return (
      <div>
        <Header />
        <Col lg={12} md={12} sm={12} xs={12} >
          <Row className="topheader1">
            <p className="compname">campaign name here</p>
          </Row>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12} >
          <Row className="topheader2">
            {/* <Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                <Row className="leftarrow">
                    <img src={Left} alt="" height="33px"></img>
                </Row>
              </Col> */}
            <Col lg={12} md={12} sm={12} xs={12}><Row className="centertext">Kampanjer</Row></Col>
            {/* <Col  lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                  <Row className="rightarrow"><img src={Right} alt="rightarrow" height="20px"></img></Row>
                  </Col> */}
          </Row>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Row>
            <Card id="overviewtopp" >
              <a href="">
                <CardImg className="overviewimage" src={House} alt="Card image cap" />
              </a>
              {/* <CardBody className="Card_Bodyactive" style={{padding:2}}>
                <h5>Hemnet</h5>
                <p>0 aktiva kampanjer</p> 
              </CardBody> */}
            </Card>
          </Row>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Row className="overviewlinks">
            <Col lg={4} md={4} sm={4} xs={4}>
              <Link to="/overview">
                <Row className="overview11">
                  <p className="part1">INFO</p>
                </Row>
              </Link>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
              <Link to="/info-annons">
                <Row className="overview22">
                  <p className="part2">ANNONS</p>
                </Row>
              </Link>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
              <Link to="/statistics">
                <Row className="overview33">
                  <p className="part2">STATISTIK</p>
                </Row>
              </Link>
            </Col>

            <Col className="borderbotomstat" lg={2} md={4} sm={4} xs={12}>
              <Row className="statistics">
                <p className="part22">2019-04-01</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Visningar: 3392</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Klick: 14</p>
              </Row>
            </Col>
            <Col className="borderbotomstat" lg={2} md={4} sm={4} xs={12}>
              <Row className="statistics">
                <p className="part22">2019-04-01</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Visningar: 3392</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Klick: 14</p>
              </Row>
            </Col><Col className="borderbotomstat" lg={2} md={4} sm={4} xs={12}>
              <Row className="statistics">
                <p className="part22">2019-04-01</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Visningar: 3392</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Klick: 14</p>
              </Row>
            </Col><Col className="borderbotomstat" lg={2} md={4} sm={4} xs={12}>
              <Row className="statistics">
                <p className="part22">2019-04-01</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Visningar: 3392</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Klick: 14</p>
              </Row>
            </Col><Col className="borderbotomstat" lg={2} md={4} sm={4} xs={12}>
              <Row className="statistics">
                <p className="part22">2019-04-01</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Visningar: 3392</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Klick: 14</p>
              </Row>
            </Col><Col className="borderbotomstat" lg={2} md={4} sm={4} xs={12}>
              <Row className="statistics">
                <p className="part22">2019-04-01</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Visningar: 3392</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Klick: 14</p>
              </Row>
            </Col><Col className="borderbotomstat" lg={2} md={4} sm={4} xs={12}>
              <Row className="statistics">
                <p className="part22">2019-04-01</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Visningar: 3392</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Klick: 14</p>
              </Row>
            </Col><Col className="borderbotomstat" lg={2} md={4} sm={4} xs={12}>
              <Row className="statistics">
                <p className="part22">2019-04-01</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Visningar: 3392</p>
              </Row>
              <Row className="statistics2">
                <p className="part22">Klick: 14</p>
              </Row>
            </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
              <Row className="statisticsbtn">
                <Button className="statbtn1">DELA TILL EN KUND</Button>
              </Row>
              <Row className="statisticsbtn2">
                <Button className="statbtn1">RADERA KAMPANJ</Button>
              </Row>
            </Col>
          </Row>
        </Col>

      </div>
    );
  }
}

export default Statistics;
