import React, { Component, useState } from 'react';
import './Style.css';
import {
	Row,
	Col,
	Button,

} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header/index.js';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import swal from 'sweetalert';
// import Spinner from 'react-spinner-material';
import Apiservices from '../Apiservices/Urlservice';
import Login from '../Login/Login';
const urlpost = new Apiservices();
const auth = new Login();

// const toggle = () => setModal(!modal);
// const date = new Date()
// var day = date.getDate();
// var month = date.getMonth() + 1;
// var year = date.getFullYear();
// console.log(date, 'hello')
class Createadd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// propertyname: this.props.location.state.propertyname,
			// finalUrl: this.props.location.state.propertydetail.website ? this.props.location.state.propertydetail.website : '',
			// heading1: this.props.location.state.propertydetail.name ? this.props.location.state.propertydetail.name : '',
			// description: this.props.location.state.propertydetail.description ? this.props.location.state.propertydetail.description : '',
			// imageUrl: this.props.location.state.propertydetail.propertyImages ? this.props.location.state.propertydetail.propertyImages : '',
			// campaign: this.props.location.state.campaign ? this.props.location.state.campaign : '',
			// amount: this.props.location.state.amount,
			// campaignType: this.props.location.state.campaignType ? this.props.location.state.campaignType : '',
			// propertydetail: this.props.location.state.propertydetail,
			campaignType: this.props.location.state.campaignType,
			userinfo: this.props.location.state.userinfo,
			agentdetails: this.props.location.state.agentdetails,
			name: this.props.location.state.name,
			email: this.props.location.state.email,
			phoneNumber: this.props.location.state.phoneNumber,
			agentImages: this.props.location.state.agentImages,
			message: this.props.location.state.message,
			website: this.props.location.state.website,
			addcreate: {},
			height: 250,
			width: 300,
			// heading1: "",
			heading2: "",
			profile: {},
			iddetail: "",
			redirect: false,
			alert: false,
			invalid: '',
			isLoaded: true,
			loading: false
		};
		this.onChange = this.onChange.bind(this);
		this.addcreate = this.addcreate.bind(this);
	}

	async componentDidMount() {
		let Id = auth.getId();
		const res = await urlpost.userprofile(Id);
		this.setState({ profile: res.data });
	}

	onChange(e) {
		console.log("ghfgj", e.target.value)
		this.setState({ [e.target.name]: e.target.value });
	}

	addcreate = () => {
		let description = this.state.description.substring(0, 80);
		if (this.state)
			this.setState({ loading: true }); {
			urlpost
				.createadd({
					campaign: this.state.campaign,
					heading1: this.state.heading1,
					heading2: this.state.heading2,
					description: this.state.description,
					finalUrl: this.state.finalUrl,
					height: this.state.height,
					width: this.state.width,
					imageUrl: 'https://realreach.s3.eu-north-1.amazonaws.com/' + this.state.imageUrl[0]
				})
				.then(result => {
					let responseJson = result.data;
					if (responseJson) {
						this.setState({ addcreate: responseJson });
						this.setState({ redirect: true });
						this.setState({ alert: true });
						this.setState({ loading: false });
					}
				})
				.catch(err => {
					this.setState({ alert: true });
					this.setState({ loading: false });
					this.setState({ invalid: err.response.data.error.message });
					swal("Oops", err.response.data.error.message, "error");
				});
		}
	};

	render() {
		//   console.log(this.state.profile)
		//   if (this.state.redirect === true && this.state.profile.user.card === false) {
		//     return (
		//       <Redirect to={{
		//         pathname: "/card-info",
		//       }} />
		//     )
		// }
		// else
		//   if (this.state.redirect === true && this.state.profile.user.card === true) {
		//     return (
		//       <Redirect to={{
		//         pathname: "/billing",
		//         state: {
		//           amount: this.state.amount,
		//           profile: this.state.profile
		//         }
		//       }} />
		//     )
		// }
		return (
			<div className="custom-container">
				<Header />
				{/* <Col lg={12} md={12} sm={12} xs={12} >
          <Row className="topheader1">
              <p className="compname">campaign name here</p>
          </Row>
          </Col> */}
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheadernew2">
						{console.log("campaignnpublish", this.state.campaignType)}
						{/* <Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                  <Row className="leftarrow">
                  <Link to={{
                  pathname: "/new-ad",
                //   state: {
                //   brokerLink: this.state.brokerLink,
                //   propertyname: this.state.propertyname,
                //   description: this.state.description,
                //   imgUrl: this.state.imgUrl
                //   }
                }}>
                    <img src={Left} alt="" height="33px"></img>
                    </Link>
                    </Row>
              </Col> */}
						{/* <Col lg={12} md={12} sm={12} xs={12}><Row className="centertext">lägg till vy</Row></Col> */}
						{/* <Col  lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                  <Row className="rightarrow">
                  <Link to={{
                  pathname: "/budget",
                //   state: {
                //     _id: this.props.location.state._id,
                //     name: this.props.location.state.name,
                //     resourceName: this.props.location.resourceName,
                //     resourceId: this.props.location.resourceId,
                //     brokerLink: this.state.brokerLink,
                //     propertyname: this.state.propertyname,
                //     description: this.state.description,
                //     imgUrl: this.state.imgUrl,
                //     redirect: true
                //   }
                }}>
                  <img src={Right} alt="rightarrow" height="20px"></img>
                </Link>
                </Row>
                </Col> */}
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12}>
					<Row>


						<Col className="margins-add" lg={6} md={6} sm={12} xs={12} order={12}>
							<Row>
								{/* <h1 className="heading-add">Create Add</h1>
            <Form>
            <ModalBody>
            <Row className="namefields">
                <label className="labelproperty">Heading</label>
            <Col lg={12} md={12} sm={12} xs={12} >
            <FormGroup>
            <Input
            id="datenew11"
            maxLength="30"
            type="text"
            name="heading1"
            placeholder="namn"
            onChange={this.onChange}
            value={this.state.propertydetail.name}
            />
            </FormGroup>
            </Col>
            </Row>
            <Row className="namefields">
            <label className="labelproperty">Link</label>
            <Col lg={12} md={12} sm={12} xs={12}>
            <FormGroup>
            <Input
            value={this.state.description}
            id="datenew11"
            type="text"
            name="finalUrl"
            value= {this.state.propertydetail.website}
            placeholder= "Link"
            onChange={this.onChange} />
            </FormGroup>
            </Col>
            </Row>
            <Row className="namefields">
                <label className="labelproperty">Image</label>
                {this.state.propertydetail.propertyImages.map(image => {
                    let images = 'https://realreach.s3.eu-north-1.amazonaws.com/'+image
                    return(
            <Col className="container999" lg={3} md={3} sm={12} xs={12} >
            <FormGroup>
            <img src={Left} width="280" height="250"/>
            <button className="btn">x</button>
            </FormGroup>
            </Col>
            )})}
            </Row>
            </ModalBody>
            </Form>
             */}
								<Col>
									<Row className="approverow">
										<Link to={{
											pathname: "/budget",
											state: {
												campaignType: this.state.campaignType,
												userinfo: this.state.userinfo,
												agentdetails: this.state.agentdetails
											}
										}}>
											<Button className="btnapprove" >Approve</Button>
										</Link>
									</Row>
									{/* <Link to="/edit-agent"> */}
									<Link to={{
										pathname: "/edit-agent",
										state: {
											campaignType: this.state.campaignType,
											userinfo: this.state.userinfo,
											agentdetails: this.state.agentdetails,
											name: this.state.name,
											email: this.state.email,
											phoneNumber: this.state.phoneNumber,
											agentImages: this.state.agentImages,
											message: this.state.message,
											website: this.state.website,
										}
									}}>
										<Row className="editrow">
											<Button className="btnedit">Edit</Button>
										</Row>
									</Link>
								</Col>
							</Row>
						</Col>




						<Col className="preview-add" lg={6} md={6} sm={12} xs={12} order={1}>
							<h1 className="heading-add">Ad Preview</h1>
							<Row className="mobilelayout">
								<Row className="imgpreview">
									{/* <label className="labelproperty">Headinng</label>
            <Col lg={7} md={7} sm={12} xs={12} >
            {this.state.propertydetail.propertyImages.map(image => {
                    let images = 'https://realreach.s3.eu-north-1.amazonaws.com/'+image
                    return(
            <Col lg={12} md={12} sm={12} xs={12} >
            <FormGroup>
            <img src={images} width="300" height="250"/>
            <button className="btn">x</button>
            </FormGroup>
             </Col>
            )})}
            </Col> */}
								</Row>
								<Row className="speakermobile">
								</Row>
								<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }}>
									<Row className="slider11">
										<Carousel autoPlay interval={3000} infiniteLoop showThumbs={false} showStatus={true}>
											{this.state.agentdetails.agentImages && this.state.agentdetails.agentImages.map(image => {
												let images = 'https://realreach.s3.eu-north-1.amazonaws.com/' + image
												return (
													<div>
														<img className="imgimg" src={images} />
													</div>
												)
											})}
										</Carousel>
									</Row>
								</Col>
								<Row className="headingpreviewad">
									{/* <h3>{this.state.propertydetail.name}</h3>    */}
									<h5>{this.state.agentdetails.name}</h5>

								</Row>
								<Row className="websitepreviewad">
									{/* <h5>{this.state.propertydetail.website}</h5>  */}
									<h5>{this.state.agentdetails.message}</h5>

								</Row>
								<Row className="websitepreviewad">
									{/* <h5>{this.state.propertydetail.description}</h5>  */}
									<h5>{this.state.agentdetails.phoneNumber}</h5>
								</Row>
							</Row>
						</Col>

					</Row>
				</Col>
				{/* <Col lg={12} md={12} sm={12} xs={12}>
            <Row className="createadbtn">
                <Spinner marginLeft={-100} size={40} spinnerColor={"#000000"} spinnerWidth={1} visible={this.state.loading} />
                <Button className="btnbtncreate" onClick={this.addcreate}>Create Add</Button>
            </Row>
            </Col> */}
				{/* <Col lg={12} md={12} sm={12} xs={12}>
          <Row>
            <Col lg={{ size:12}}  md={{ size: 12}}  sm={12} xs={12}>
                    <Row>
                    <Link to={{
                  pathname: "/new-ad",
                //   state: {
                //     name: campaign.name,
                //     _id: campaign._id
                //   }
                }}>
          <div id="oneTwo" >
              <CardBody className="Card_Body" style={{padding:2}}>
                <h5 className="textdecoration">{this.state.heading}</h5>
                <h5 className="textdecoration">{this.state.heading}</h5>
                <h5 className="textdecoration">{this.state.heading}</h5>
                <p className="textdecoration">{this.state.description}</p>
                <p className="textdecoration">{this.state.description}</p>
                <p className="textdecoration">{this.state.brokerLink}</p>
              </CardBody>
              <CardImg  src={this.state.imgUrl} alt="Card image cap" />
            </div>
            </Link>

            </Row>
                  </Col>
          </Row>
        </Col> */}

			</div>
		);
	}
}

export default Createadd;
