import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardImg, CardText, Col, Row } from 'reactstrap';
// import Left from '../../images/leftarrow.png'
// import Right from '../../images/rightarrow.png'
import House from '../../images/house.jpg';
import Header from '../Header';
import './Style.css';

class Overview extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {

		return (
			<div>
				<Header />
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheader1">
						<p className="compname">Campaign Name Here</p>
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheader2">
						{/* <Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                <Row className="leftarrow">
                    <img src={Left} alt="" height="33px"></img>
                </Row>
              </Col> */}
						<Col lg={12} md={12} sm={12} xs={12}><Row className="centertext">Promotions</Row></Col>
						{/* <Col  lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                  <Row className="rightarrow"><img src={Right} alt="rightarrow" height="20px"></img></Row>
                  </Col> */}
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12}>
					<Row>
						<Card id="overviewtopp" >
							<a href="">
								<CardImg className="overviewimage" src={House} alt="Card image cap" />
							</a>
							{/* <CardBody className="Card_Bodyactive" style={{padding:2}}>
                <h5>Hemnet</h5>
                <p>0 aktiva kampanjer</p> 
              </CardBody> */}
						</Card>
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12}>
					<Row className="overviewlinks">
						<Col lg={4} md={4} sm={4} xs={4}>
							<Link to="/overview">
								<Row className="overview11">
									<p className="part1">INFO</p>
								</Row>
							</Link>
						</Col>
						<Col lg={4} md={4} sm={4} xs={4}>
							<Link to="/info-annons">
								<Row className="overview22">
									<p className="part2">ADVERTISEMENT</p>
								</Row>
							</Link>
						</Col>
						<Col lg={4} md={4} sm={4} xs={4}>
							<Link to="/statistics">
								<Row className="overview33">
									<p className="part2">STATISTICS</p>
								</Row>
							</Link>
						</Col>
						<Col className="colborder" lg={6} md={6} sm={6} xs={12}>
							<Row className="overview44">
								<p className="info1">1/7</p>

							</Row>
							<Row className="coltext">
								<p>Start Date</p>
							</Row>
						</Col>
						<Col className="colborder" lg={6} md={6} sm={6} xs={12}>
							<Row className="overview44">
								<p className="info2">1/8</p>
							</Row>
							<Row className="coltext">
								<p>End Date</p>
							</Row>
						</Col>
						<Col className="cardcol" lg={6} md={6} sm={12} xs={12}>
							<Card className="cardheight">
								<Row>
									<Col lg={4} md={4} sm={6} xs={12}>
										{/* {this.props.doctor.imagePath == null ? (
                  <img src={House} className="doctor-img-doc-list" alt="doctor"/>
                ) : ( */}
										<img src={House} className="image_Fitting" alt="doctor" />
										{/* )} */}
									</Col>
									<Col className="col-profile-doc-heading" lg={6} md={6} sm={6} xs={12}>
										<CardText >
											<p className="headingtopp"> <h3>Small</h3></p>
											<p className="heading2"> some address here <br />  dsadasdas: <span className="colorspan">1111</span></p>
										</CardText>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col className="lastover" lg={12} md={12} sm={12} xs={12}>
							<Row className="lastoverview">
								<p className="personoverview">14500</p>
							</Row>
							<Row className="lastoverview2">
								<p className="personoverview2">People come reachable</p>
							</Row>
						</Col>
						<Col className="overbtnn" lg={12} md={12} sm={12} xs={12}>
							<Row className="lastbtn">
								<Button className="btndela">Share to a customer</Button>
							</Row>
						</Col>
					</Row>
				</Col>

			</div>
		);
	}
}

export default Overview;
