import React, { Component } from 'react';
import './AdPreview.css';
import { Row, Col, Button } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header/NewHeader';
import Mobile from '../../images/Resurs 860.png';
import MobileAdPreview from '../MobileAdPreview';
import { IMG_URL } from '../../config'
import Login from "../Login/Login";

const auth = new Login();

class AdPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            alert: false,
            reverseToEdit: true,
            previousPath: props.location.state.previousPath,
            userProfile: props.location.state.userProfile,
            campaignType: props.location.state.campaignType
                ? props.location.state.campaignType
                : 'listing',
            listingData: props.location.state.listingData,
            previewImage: this.props.location.state.previewImage
                ? this.props.location.state.previewImage
                : props.location.state.listingData
                    ? IMG_URL + props.location.state.listingData.listingAdImages[0]
                    : "" ,
                    previewVideo: this.props.location.state.previewVideo
                ? this.props.location.state.previewVideo
                : props.location.state.listingData
                    ? IMG_URL + props.location.state.listingData.listingAdVideos[0]
                    : "" ,
            mediaType:this.props.location.state.mediaType
            ? this.props.location.state.mediaType
            : props.location.state.listingData
                ? props.location.state.listingData.mediaType
                : "" ,
            pathUrl: this.props.location.state.pathUrl
                ? this.props.location.state.pathUrl
                : ""
        };
        console.log(this.state.mediaType, "mediaType")
        console.log(this.state.listingData, "listingData")
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        
        localStorage.setItem("User_Email",this.state.userProfile.email);
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }
        return (
            <div className="soldout">
                <Header backHidden={true} />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="soldoutmain">
                        <Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12}>
                            <Row className="soldoutprotion1">
                                <p className="soldouttopheading">Ad preview.</p>
                            </Row>

                            <Row className="soldoutstate">

                                <Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
                                    <Row className="soldout-wrapper">
                                        <Link to={{
                                            pathname: "/create-ad-new",
                                            // pathname: this.state.previousPath.toString(),
                                            state: {
                                                userProfile: this.state.userProfile,
                                                campaignType: this.state.campaignType,
                                                previousPath: this.props.location.pathname,
                                                listingData: this.state.listingData,
                                                 reverseToEdit: this.state.reverseToEdit,
                                                pathUrl: this.state.pathUrl,
                                                mediaType:this.state.mediaType
                                                
                                            }
                                        }}>
                                            <Button className="soldout-btnnnn">Edit</Button>
                                        </Link>
                                    </Row>
                                </Col>

                                <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12}>
                                    <Row className="soldoutcenter">

                                     {this.state.mediaType==="image" && <MobileAdPreview headline={this.state.listingData.headline} message={this.state.listingData.message} website={this.state.listingData.website} description={this.state.listingData.description} previewImage={this.state.previewImage} mediaType={this.state.mediaType} />}
                                     {this.state.mediaType==="video" && <MobileAdPreview headline={this.state.listingData.headline} message={this.state.listingData.message} website={this.state.listingData.website} description={this.state.listingData.description} previewVideo={this.state.previewVideo} mediaType={this.state.mediaType} />}
                                     {this.state.mediaType==="dummy" && <MobileAdPreview headline={this.state.listingData.headline} message={this.state.listingData.message} website={this.state.listingData.website} description={this.state.listingData.description} previewImage={this.state.previewImage} mediaType={this.state.mediaType} />}
                                     {this.state.mediaType===undefined && <MobileAdPreview headline={this.state.listingData.headline} message={this.state.listingData.message} website={this.state.listingData.website} description={this.state.listingData.description} previewImage={this.state.previewImage} mediaType={this.state.mediaType} />}

                                    </Row>
                                </Col>

                                <Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
                                    <Row className="soldoutcenter">
                                        <Row className="soldout-btns">
                                            <Link to={{
                                                pathname: "/setting",
                                                state: {
                                                    userProfile: this.state.userProfile,
                                                    campaignType: this.state.campaignType,
                                                    previousPath: this.props.location.pathname,
                                                    listingData: this.state.listingData,
                                                    previewImage: this.state.previewImage,
                                                    previewVideo:this.state.previewVideo,
                                                    mediaType:this.state.mediaType
                                                }
                                            }}>
                                                <Button className="sharesoldout">Approve</Button>
                                            </Link>
                                        </Row>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

            </div>
        );
    }
}

export default AdPreview;
