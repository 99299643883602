import React, { Component } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import swal from 'sweetalert';
import bg from '../../images/bg.png';
import Fill1 from '../../images/fill-1.png';
import Apiservices from '../Apiservices/AuthService';
import './Style.css';

const Auth = new Apiservices();

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            userinfo: {},
            redirect: false,
            alert: false,
            invalid: ''
        };
        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    login(e) {
        e.preventDefault()
        if (this.state.email && this.state.password) {

            Auth.postLogin(this.state).then((result) => {
                let responseJson = result;
                if (responseJson.status == '200') {
                    // this.setToken(JSON.stringify(responseJson.data.token));
                    this.setId(JSON.stringify(responseJson.data.user._id));
                    this.setState({ userinfo: responseJson.data.user });
                    setTimeout(() => {
                        this.setToken(JSON.stringify(responseJson.data.token));
                        this.setState({ redirect: true });

                    }, 200)
                    this.setState({ alert: false });
                    { console.log("ioioioioioi", this.state.userinfo) }
                }
                // console.log("userinfo", responseJson.data.user);
            })
                .catch(err => {
                    this.setState({ alert: true });
                    this.setState({ invalid: err.response.data.error.message });
                    swal("Oops", "Invalid email or password", "error");
                });

        }

    }

    setToken(token) {
        // Saves user token to sessionStorage
        sessionStorage.setItem('token', token);
    }

    setId(userId) {
        // Saves user token to sessionStorage
        sessionStorage.setItem('userId', userId);
    }

    getToken() {
        // Retrieves the user token from sessionStorage
        return JSON.parse(sessionStorage.getItem('token'));
    }
    getId() {
        // Retrieves the user token from sessionStorage
        return JSON.parse(sessionStorage.getItem('userId'));
    }

    logout() {
        // Clear user token and profile data from sessionStorage
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userId');
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken();
        return !!token;
    }

    render() {
        if (this.loggedIn()) {
            // return window.location.replace("/home");
            return (
                window.location.replace("/home")
            )

        }
        if (this.state.redirect && this.loggedIn()) {

            return (
                window.location.replace("/home")
                // <Redirect to={{
                //   pathname: "/home",
                //   state: {
                //     userinfo1: this.state.userinfo
                //   }
                // }} />
            )
        }

        return (
            <div>
                <Col lg={12} md={12} sm={12} xs={12} className="main">
                    <Row>
                        <Col lg={5} md={5} sm={12} xs={12} className="portion1">
                            <Row>
                                <Row className="toptext">
                                    Real estate marketing with RealReach.
              </Row>
                                <Row className="logintext">
                                    Log in here for beta access
              </Row>
                                <Row className="margin-row">
                                    <Input
                                        type="email"
                                        name="email"
                                        pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                        placeholder="Enter username"
                                        id="email-login"
                                        onChange={this.onChange}
                                        required>
                                    </Input>
                                </Row>
                                <Row className="margin-row">
                                    <Input
                                        type="password"
                                        name="password"
                                        placeholder="Enter password"
                                        id="email-login"
                                        onChange={this.onChange}
                                        required>
                                    </Input>
                                </Row>
                                <Row className="btnlogin">
                                    <Button
                                        type="submit"
                                        refresh="true"
                                        className="loginbtn1"
                                        onClick={this.login}>SIGN IN
                    </Button>
                                </Row>
                            </Row>
                        </Col>
                        <Col lg={7} md={7} sm={12} xs={12} className="portion2">
                            <Row>
                                <Row className="imagemobile">
                                    <img src={bg} alt="bg.png" className="loginimg"></img>

                                    <Col lg={{ size: 2, leftset: 1 }} md={{ size: 2, leftset: 1 }} sm={{ size: 2, leftset: 1 }} xs={{ size: 2, leftset: 1 }}>
                                        <Row>
                                            <img src={Fill1} alt="fill-1.png" className="fill11"></img>
                                        </Row>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div>
        );
    }
}

export default Login;
