import React from 'react'
import axios from 'axios';
import Login from "../Login/Login";

const auth = new Login();

let instance = axios.create({
    baseURL: 'http://3.248.189.221:5002/api',
    // baseURL: 'http://192.168.18.200:4000/api',
    headers: { 'Authorization': `Bearer ${auth.getToken()}` }
});

class Authservice extends React.Component {

    addnewproperty(data) {
        return instance.post('property', data)
    }

    list_property() {
        return instance.get('property', {})
      }

    list_campaigns() {
        return instance.get('campaign', {})
      }

    newcampaign(data) {
        return instance.post('campaign', data)
    }

    newgroup(data) {
        return instance.post('adGroup', data)
    }

    addcampagin(data) {
        return instance.post('campaign', data)
    }

    list_campaign(id) {
        return instance.get('campaign/property', {params: { property: id }} )
      }

    budget(data) {
        return instance.get('budget', data)
    }

    bidding(data) {
        return instance.get('biddingStrategy', data)
    }

    createadd(data) {
        return instance.post('ad', data)
    }

    updatecampaign(id, data) {
        return instance.put('campaign/'+id, data)
    }

    campaigndelete(id) {
        return instance.delete('campaign/'+id)
    }

    userprofile(id) {
        return instance.get('user/'+ id  )
      }

    billpay(data) {
        return instance.post('payment/charge', data)
    }

    adlist(id) {
        return instance.get('ad', {params: { campaign: id }} )
    }

    createagent(data) {
        return instance.post('agent', data )
      }

    editAgent(Id, data) {
        return instance.put("agent/"+ Id, data);
    }

    editproperty(Id, data) {
        return instance.put("property/"+ Id, data);
    }

    singlecampaign(Id) {
        return instance.get("budget/"+ Id);
    }

    geoLoaction(name) {
        return instance.get("campaign/location?location=" + name);
    }

}
export default Authservice;