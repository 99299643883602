import React, { Component, useState } from 'react';
import './Style.css';
import {
	Row,
	Col,

	Form, ModalBody,
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header/index.js';
// import Spinner from 'react-spinner-material';
// import swal from 'sweetalert';
import Apiservices from '../Apiservices/Urlservice';
import Agency from '../../images/agency.png';
import Agent from '../../images/agenticon.png';
import List from '../../images/listing.png';

const urlpost = new Apiservices();
// import Left from '../../images/leftarrow.png'
// import Right from '../../images/rightarrow.png'
// import DatePicker from 'react-date-picker';
var startDate;
var endDate;
// const toggle = () => setModal(!modal);
// const date = new Date()
// var day = date.getDate();
// var month = date.getMonth() + 1;
// var year = date.getFullYear();
// console.log(date, 'hello')
class Newcampaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userinfo: this.props.location.state.userinfo,
			campaignType: "",
			redirect: false,
			alert: false,
			invalid: '',
			isLoaded: true,
			loading: false
		};
		var now = new Date(this.state.startDate);
		startDate = now.toLocaleDateString();
		var now1 = new Date(this.state.endDate);
		endDate = now1.toLocaleDateString();
		this.onChange = this.onChange.bind(this);
		console.log("userinfocampaaigntype", this.state.userinfo)
	}

	handleChange = date => {
		// let newdate = new Date(date).toISOString();
		this.setState({
			startDate: date
		});
		console.log("fdadsd", date.toLocaleDateString())
		// console.log("fdadsd",  newdate.split('T', 1))
	};
	handleChangeend = date1 => {
		this.setState({
			endDate: date1
		});
		console.log("ghfgj", date1.toLocaleDateString())
	};

	onChange(e) {
		console.log("value", e.target.value)
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		if (this.state.campaignType === "agent") {
			return (
				<Redirect to={{
					pathname: "/agent-ad",
					state: {
						campaignType: this.state.campaignType,
						userinfo: this.state.userinfo
					}
				}} />
			)
		}
		else
			if (this.state.campaignType == "agency") {
				return (
					<Redirect to={{
						pathname: "/add-property",
						state: {
							campaignType: this.state.campaignType,
							userinfo: this.state.userinfo
						}
					}} />
				)
			}
			else
				if (this.state.campaignType == "listing" && this.state.userinfo.country == "sweden") {
					return (
						<Redirect to={{
							pathname: "/hemnet-url",
							state: {
								campaignType: this.state.campaignType,
								userinfo: this.state.userinfo
							}
						}} />
					)
				}
		// else {
		//   return (
		//     <Redirect to={{
		//       pathname: "/add-property",
		//       state: {
		//         campaignType: this.state.campaignType,
		//         userinfo: this.state.userinfo
		//       }
		//     }} />
		//   )
		// }
		//     if (this.state.redirect == true) {
		//       return (
		//         <Redirect to={{
		//           pathname: "/Create-add",
		//           state: {
		//             name: this.state.name,
		//             startDate: this.state.startDate,
		//             endDate: this.state.endDate,
		//             property: this.state.property,
		//             brokerLink: this.state.brokerLink,
		//             propertyname: this.state.propertyname,
		//             description: this.state.description,
		//             propertyImages: this.state.propertyImages[0],
		//             campaign: this.state.campaign,
		//             amount: this.state.amount,
		//             campaignType: this.state.campaign.campaignType
		//           }
		//         }} />
		//       )
		//   }
		return (
			<div className="custom-container">
				<Header />
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheadernew2">
					</Row>
				</Col>
				<Col className="marginnew" lg={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} sm={12} xs={12}>
					<h1 className="heading-toppp">Campaign Type</h1>
					<Form>
						<ModalBody>
							<Row className="namefields">
								{/* <label className="labelproperty">Campaign Type</label> */}
								<Col lg={12} md={12} sm={12} xs={12}>
									{/* <FormGroup>            
            <Input
                      type="select"
                      id="datenew11"
                      name="campaignType"
                      onChange={this.onChange}
                    >
                      <option>Select Campaign Type</option>
                      <option value="agency">AGENCY</option>
                      <option value="agent">AGENT</option>
                      <option value="listing">LISTING</option>
                    </Input>
            </FormGroup>             */}
									<Row className="checkboxes">
										<Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 12 }} xs={{ size: 12 }}>
											<label className="imagecampaintype">
												<input
													className="imagecampaintype"
													type="radio"
													name="campaignType"
													onChange={this.onChange}
													value="agent"
												></input>
												<div className="imagetype">
													<img className="imgsetting" src={Agent} width="auto" height="50"></img>
													<h5 className="titlecampaign">Agent</h5>
												</div>
												{/* <div class="overlay">

                          <div class="text">AGENT</div>
                        </div> */}
												{/* <span class="checkmark2"></span> */}
											</label>
										</Col >
										<Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 12 }} xs={{ size: 12 }}>
											<label class="imagecampaintype">
												<input
													className="imagecampaintype"
													type="radio"
													name="campaignType"
													onChange={this.onChange}
													value="agency"
												></input>
												<div className="imagetype">
													<img className="imgsetting" src={Agency} width="auto" height="50"></img>
													<h5 className="titlecampaign">Agency</h5>
												</div>
												{/* <div class="overlay">
                       
                          <div class="text">Agency</div>
                        </div> */}
												{/* <span class="checkmark2"></span> */}
											</label>
										</Col >
										<Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 12 }} xs={{ size: 12 }}>
											<label class="imagecampaintype">
												<input
													className="imagecampaintype"
													type="radio"
													name="campaignType"
													onChange={this.onChange}
													value="listing"
												></input>
												<div class="imagetype">
													<img className="imgsetting" src={List} width="auto" height="50"></img>
													<h5 className="titlecampaign">listing</h5>
												</div>
												{/* <div class="overlay">
                       
                          <div class="text">Listing</div>
                        </div> */}
												{/* <span class="checkmark2"></span> */}
											</label>
										</Col >
									</Row>
								</Col>
							</Row>
						</ModalBody>
					</Form>
					{/* <Col lg={12} md={12} sm={12} xs={12} >
              <Row>
            <Col lg={6} md={6} sm={6} xs={6}>
            <Row className="leftarrownew">
                  <Link to={{
                  pathname: "/",
                  state: {
                  }
                }}>
                    <Button className="backbtn">Back</Button>
                    </Link>
                    </Row>
            </Col>
           
            <Col lg={6} md={6} sm={6} xs={6}>
                <Row className="nextbtn1">
                    {console.log("dashdiaudha", this.state.campaignType)}
                    <Link to={{
                  pathname: "/add-property",
                  state: {
                      campaignType: this.state.campaignType
                  }
                }}>
                    <Button className="nextbtnn">Next</Button>
                    </Link>
                </Row>
            </Col>
            </Row>
            </Col> */}
				</Col>
			</div>
		);
	}
}

export default Newcampaign;
