import moment from 'moment';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Row } from 'reactstrap';
import swal from 'sweetalert';
import { IMG_URL } from '../../config';
import CampaignService from '../Apiservices/CampaignService';
import PaymentService from '../Apiservices/PaymentServices';
import Header from '../Header/NewHeader';
import Login from "../Login/Login";
import MobileAdPreview from '../MobileAdPreview';
import './NewpublishPreview.css';
import Location from "../Apiservices/GetUserLocationFromIp";

const location = new Location()

const auth = new Login();
const campaignService = new CampaignService();
const paymentService = new PaymentService();

class PublishPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previousPath: props.location.state.previousPath,
            userProfile: props.location.state.userProfile,
            pageId: props.location.state.userProfile.role == "agency" ? props.location.state.userProfile.pageId
                : props.location.state.userProfile.role == "agent" ? props.location.state.userProfile.agency.pageId
                    : "",
            campaignType: props.location.state.campaignType ? props.location.state.campaignType : 'listing',
            redirect: false,
            alert: false,
            listingData: props.location.state.listingData,
            budget: props.location.state.budget,
            estimatedReach: props.location.state.estimatedReach,
            currency: props.location.state.budget.currency,
            startDate: props.location.state.campaignFields.startDate,
            endDate: props.location.state.campaignFields.endDate,
            region: props.location.state.campaignFields.region,
            fbPageId: props.location.state.campaignFields.fbPageId,
            selectedRegion: props.location.state.selectedRegion,
            campaignData: {},
            loader: false,
            isEdit: props.location.state.campaignFields.isEdit,
            currentCampaign: props.location.state.currentCampaign,
            country: "",
            previewImage: props.location.state.previewImage ? props.location.state.previewImage : IMG_URL + props.location.state.listingData.listingAdImages[0],
            previewVideo: props.location.state.previewVideo ? props.location.state.previewVideo : IMG_URL + props.location.state.listingData.listingAdVideos[0],

            mediaType: props.location.state.mediaType,
        };
    }

    componentDidMount() {
        return location.getGeoInfo()
            .then((result) => {
                this.setState({ country: result.country_name });
            })
    };

    createGoogleCampaign = (e) => {
        // let regions = []
        // for (let i in this.state.selectedRegion) {
        //     let region = getRegion[i]
        //         let key = {
        //             key: region.key,
        //             type: region.type
        //         }
        //         regions.push(key)
        //     }
        let pageId
        if (this.state.pageId != null) {
            pageId = this.state.pageId
        }
        else {
            pageId = 101495074664563
        }
        this.setState({ loader: true });
        campaignService.createGoogleCampaign({
            // pageId: pageId,
            campaignType: this.state.campaignType,
            listingAd: this.state.listingData._id,
            budgetAmount: this.state.budget.amount,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            currency: this.state.budget.currency,
            estimatedReach: this.state.estimatedReach,
            maxDays: this.state.budget.days,
            region: this.state.region,
            country: this.state.country
            // regionKey: this.state.selectedRegion ? this.state.selectedRegion.key : 'SE',
            // regionType: this.state.selectedRegion ? this.state.selectedRegion.type : 'country',
        }).then(result => {
            let responseJson = result.data;
            if (responseJson) {
                console.log("campaign response", responseJson)
                this.setState({ campaignData: responseJson });
                this.setState({ loader: false });
                const startDate = moment(this.state.startDate);
                const endDate = moment(this.state.endDate);
                const dateDifference = endDate.diff(startDate);
                const diffDuration = moment.duration(dateDifference);
                const totalDays = diffDuration.days()
                let amount = this.state.budget.amount
                swal({
                    title: amount + " " + this.state.budget.currency,
                    text: "Your ad has been created but is inactive. If you would like to activate the ad you will be charged the above amount.",
                    icon: "success",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((editCampign) => {
                        if (editCampign) {

                            this.setState({ loader: true });
                            let data = {
                                campaignId: this.state.campaignData.facebookAd.adGroup.campaign._id,
                                listingId: this.state.campaignData.facebookAd.adGroup.campaign.listingAd._id,
                                amount: amount,
                                currency: this.state.budget.currency,
                                adId: this.state.campaignData.facebookAd._id,
                                country: this.state.country,
                                description: this.state.listingData.description,
                            }

                            paymentService.charge(data)
                                .then((result) => {
                                    let responseJson = result
                                    this.setState({ loader: false });
                                    swal("Done", "Ad published", "success");
                                    this.setState({ redirect: true });

                                })
                                .catch(err => {
                                    this.setState({ loader: false });
                                    swal("Oops", err.response.data.error.message.message, "error");

                                });

                        } else {
                            this.setState({ redirect: true });

                        }
                    });
            }
        }).catch(err => {
            this.setState({ loader: false });
            swal("oops", err.response.data.error.message.message, "error");
        });
    };

    getFormattedDateDiff = (date1, date2) => {
        var b = moment(date1),
            a = moment(date2),
            intervals = ['months', 'weeks', 'days', 'hours'],
            out = [];

        for (var i = 0; i < intervals.length; i++) {
            var diff = a.diff(b, intervals[i]);
            b.add(diff, intervals[i]);
            out.push(diff);
        }
        return out;
    };

    createFacebookCampaign = (e) => {
        this.setState({ loader: true });

        campaignService.createFacebookCampaign({
            campaignType: this.state.campaignType,
            listingAd: this.state.listingData._id,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            fbPageId: this.state.fbPageId,
            budget: this.state.budget._id,
            currency: this.state.budget.currency
        }).then(result => {
            let responseJson = result.data;
            if (responseJson) {
                this.setState({ campaignData: responseJson });
                this.setState({ redirect: true });
                this.setState({ alert: true });
                this.setState({ loader: false });

            }
        }).catch(err => {
            this.setState({ alert: true });
            this.setState({ loader: false });

            // swal("Oops", err.response.data.error.message, "error");
        });
    };

    createCampaign = (e) => {
        // this.createFacebookCampaign(e)
        this.createGoogleCampaign(e)
    }

    updateCampaign = (e) => {
        this.setState({ loader: true });
        const isStartDate = new Date(this.state.startDate) > new Date()
        const startDate = isStartDate ? { startDate: this.state.startDate } : {}
        campaignService.updateGoogleCampaign(this.state.currentCampaign._id, {
            budget: this.state.budget._id,
            ...startDate,
            endDate: this.state.endDate,
            region: this.state.region,
            // region: this.state.selectedRegion ? this.state.region : this.state.currentCampaign.region,
            regionKey: this.state.selectedRegion ? this.state.selectedRegion.key : this.state.currentCampaign.regionKey,
            regionType: this.state.selectedRegion ? this.state.selectedRegion.type : this.state.currentCampaign.regionType,
        }).then(result => {
            let responseJson = result.data;
            if (responseJson) {
                this.setState({ currentCampaign: responseJson });
                this.setState({ alert: true });
                this.setState({ loader: false });
                this.setState({ redirect: true });
            }
        }).catch(err => {
            console.log(err)
            this.setState({ loader: false });
            this.setState({ alert: true });
            swal("Oops", "error", "error");

        });
    }

    render() {
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }

        if (this.state.redirect === true) {
            return (
                <Redirect to={{
                    pathname: "/dashboard",
                }} />
            )
        }
        const startDate = moment(this.state.startDate);
        const endDate = moment(this.state.endDate);
        const dateDifference = endDate.diff(startDate);
        const diffDuration = moment.duration(dateDifference);
        return (
            <div className="publishpreviewad">
                <Header backHidden={true} />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="publishpreviewadmain">
                        <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }} >
                            <Row className="publishpreviewadprotion1">
                                <Row className="publishpreviewadtopheading">
                                    <p >Preview and publish.</p>
                                </Row>
                            </Row>
                            <Row className="publishpreviewadtargets">
                                {/* <Row className="publishpreviewadheading2">
                                    <p>Target URL</p>
                                </Row>
                                <Row className="publishpreviewadheading3">
                                    <p>http://www.fastighetsbyran.se/id2543</p>
                                </Row> */}
                                <Row className="publishpreviewadheading4">
                                    <p>Period</p>
                                </Row>

                                <Row className="publishpreviewadheading5">
                                    <Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 4 }} xs={{ size: 4 }}>
                                        <Row>
                                            <p>Start</p>
                                        </Row>
                                    </Col>
                                    <Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 4 }} xs={{ size: 4 }}>
                                        <Row>
                                            <p>{this.state.startDate}</p>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="publishpreviewadheading5">
                                    <Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 4 }} xs={{ size: 4 }}>
                                        <Row>
                                            <p>End</p>
                                        </Row>
                                    </Col>
                                    <Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 4 }} xs={{ size: 4 }}>
                                        <Row>
                                            <p>{this.state.endDate}</p>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="publishpreviewadheading5">
                                    <p>({diffDuration.days() +1} days)</p>
                                </Row>
                                <Row className="publishpreviewadheading4">
                                    <p>Region/-s</p>
                                </Row>
                                {this.state.region.map((item, idx) => (
                                    <Row className="publishpreviewadheading5">
                                        <p>{item.type === 'country' ? item.name : (item.type === 'region' || item.type === 'geo_market') ? item.name + ', ' + item.country_name : item.name + ', ' + item.region + ', ' + item.country_name}</p>
                                    </Row>
                                ))}
                                <Row className="publishpreviewadheading4">
                                    <p>Budget</p>
                                </Row>
                                <Row className="publishpreviewadheading5">
                                    <p>{this.state.budget.amount} {this.state.budget.currency} </p>
                                </Row>
                                {/* <Row className="publishpreviewadheading4">
                                    <p>Estimated Reach</p>
                                </Row>
                                <Row className="publishpreviewadheading5">
                                    <p>{this.state.estimatedReach}</p>
                                </Row> */}
                                <Row className="publishpreviewadbuttons">
                                    <Col lg={{ size: 4 }}>
                                        <Row className="publishpreviewadbtn">
                                            <Row className="publishpreviewad-wrapper">
                                                <Link to={{
                                                    pathname: this.state.previousPath.toString(),
                                                    state: {
                                                        campaignType: this.state.campaignType,
                                                        userProfile: this.state.userProfile,
                                                        previousPath: this.state.previousPath,
                                                        listingData: this.state.listingData,
                                                        // budget: this.state.budget,
                                                        estimatedReach: this.state.estimatedReach,
                                                        currentCampaign: this.state.currentCampaign,
                                                        mediaType: this.state.mediaType,
                                                        campaignFields: {
                                                            startDate: this.state.startDate,
                                                            endDate: this.state.endDate,
                                                            fbPageId: this.state.fbPageId,
                                                            region: this.state.region
                                                        }
                                                    }
                                                }}>
                                                    <Button class="previewpublish-btnn">Go back</Button>
                                                </Link>
                                            </Row>
                                        </Row>
                                    </Col>

                                    <Col lg={{ size: 4 }}>
                                        <Row className="publishpreviewad-btns">
                                            {console.log('sss', this.state.isEdit)}
                                            <Button className="sharepublishpreviewad"
                                                onClick={this.state.isEdit ? this.updateCampaign : this.createCampaign}>
                                                Publish</Button>
                                        </Row>
                                    </Col>
                                    <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                                        <div className='overlay-box'>
                                            <PropagateLoader
                                                // css={override}
                                                sizeUnit={"px"}
                                                size={25}
                                                color={'white'}
                                                loading={this.state.loader}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 5, offset: 1 }} sm={{ size: 12, offset: 0 }} xs={{ size: 12, offset: 0 }} >
                            {this.state.mediaType === "image" && <MobileAdPreview headline={this.state.listingData.headline} message={this.state.listingData.message} website={this.state.listingData.website} description={this.state.listingData.description} previewImage={this.state.previewImage} mediaType={this.state.mediaType} />}
                            {this.state.mediaType === "video" && <MobileAdPreview headline={this.state.listingData.headline} message={this.state.listingData.message} website={this.state.listingData.website} description={this.state.listingData.description} previewVideo={this.state.previewVideo} mediaType={this.state.mediaType} />}
                            {this.state.mediaType === undefined && <MobileAdPreview headline={this.state.listingData.headline} message={this.state.listingData.message} website={this.state.listingData.website} description={this.state.listingData.description} previewImage={this.state.previewImage} mediaType={this.state.mediaType} />}

                        </Col>

                    </Row>
                </Col>
            </div>
        );
    }
}

export default PublishPreview;