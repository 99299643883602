import React, { Component } from 'react';
import './NewBudget.css';
import { Row, Col } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header/NewHeader';
import Login from "../Login/Login";
import Location from "../Apiservices/GetUserLocationFromIp";
import smallBudget from '../../images/Resurs 930.png';
import mediumBudget from '../../images/Resurs 940.png';
import largeBudget from '../../images/Resurs 950.png';

const location = new Location()
const auth = new Login();

class NewBudget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // previousPath: props.location.state.previousPath,
            // userProfile: props.location.state.userProfile,
            // campaignType: props.location.state.campaignType ? props.location.state.campaignType : 'listing',
            redirect: false,
            alert: false,
            // listingData: props.location.state.listingData,
            currency: "",
            smallBudget: 0,
            mediumBudget: 0,
            largeBudget: 0,
            currencySymbol: "",
            campaignType: this.props.campaignType,
            estimatedReach: this.props.estimatedReach,
            borderSmall: false,
            borderMedium: false,
            borderLarge: false,
            border: false,
            smallRecomendedDays: "Recommended for short campaigns. Maximum 7 days",
            larageRecomendedDays: "Recommended for longer campaigns. Maximum 30 days",
            daysForSmall: 7,
            daysForLarge: 30
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        // let  myemail=localStorage.getItem("User_Email");
        let myemail =JSON.parse(sessionStorage.getItem("agency_Email"));
        console.log("email", myemail)


        return location.getGeoInfo()
            .then((result) => {

                console.log("curruncyyy", result.currency, "type", this.state.campaignType)

                if (result.currency == "SEK" && this.state.campaignType === 'listing') {
                    if (myemail === "info@stadshem.se") {
                        this.setState({ currency: "SEK" });
                        this.setState({ currencySymbol: "sek" });
                        this.setState({ smallBudget: 400 });
                        this.setState({ largeBudget: 400 });
                    } else {
                        this.setState({ currency: "SEK" });
                        this.setState({ currencySymbol: "sek" });
                        this.setState({ smallBudget: 800 });
                        this.setState({ largeBudget: 2500 });
                    }

                } else if (result.currency == "SEK") {
                    if (myemail === "info@stadshem.se") {
                        this.setState({ currency: "SEK" });
                        this.setState({ currencySymbol: "sek" });
                        this.setState({ smallBudget: 400 });
                        this.setState({ mediumBudget: 400 });
                        this.setState({ largeBudget: 400 });
                    } else {
                        this.setState({ currency: "SEK" });
                        this.setState({ currencySymbol: "sek" });
                        this.setState({ smallBudget: this.state.campaignType === 'agent' && myemail === "agency@karlssonuddare.se" ? 300 : 500 });
                        this.setState({ mediumBudget: 1000 });
                        this.setState({ largeBudget: 2500 });
                    }
                }
                else if (result.currency == "GBP" && this.state.campaignType === 'listing') {
                    if (myemail === "mark.readings@kwuk.com") {
                        this.setState({ currency: "GBP" });
                        this.setState({ currencySymbol: "£" });
                        this.setState({ smallBudget: 50 });
                        this.setState({ largeBudget: 200 });
                    } else if (myemail === "mark@nicholsonsestateagents.co.uk") {
                        this.setState({ currency: "GBP" });
                        this.setState({ currencySymbol: "£" });
                        this.setState({ smallBudget: 49, daysForSmall: 7 });
                        this.setState({ largeBudget: 84, daysForLarge: 14 });
                        this.setState({ smallRecomendedDays: "Recommended for short campaigns. Maximum 7 days" });
                        this.setState({ larageRecomendedDays: "Recommended for short campaigns. Maximum 14 days" });
                    }
                    else {
                        this.setState({ currency: "GBP" });
                        this.setState({ currencySymbol: "£" });
                        this.setState({ smallBudget: 65 });
                        this.setState({ largeBudget: 200 });
                    }

                }
                else if (result.currency == "GBP") {
                    if (myemail === "mark@nicholsonsestateagents.co.uk") {
                        this.setState({ currency: "GBP" });
                        this.setState({ currencySymbol: "£" });
                        this.setState({ smallBudget: 28 });
                        this.setState({ mediumBudget: 49 });
                        this.setState({ largeBudget: 84 });
                    } else {
                        this.setState({ currency: "GBP" });
                        this.setState({ currencySymbol: "£" });
                        this.setState({ smallBudget: 35 });
                        this.setState({ mediumBudget: 65 });
                        this.setState({ largeBudget: 200 });
                    }
                }
                else if (this.state.campaignType === 'listing') {
                    this.setState({ currency: "USD" });
                    this.setState({ currencySymbol: "$" });
                    this.setState({ smallBudget: 80 });
                    this.setState({ largeBudget: 250 });
                }
                else {
                    this.setState({ currency: "USD" });
                    this.setState({ currencySymbol: "$" });
                    this.setState({ smallBudget: 50 });
                    this.setState({ mediumBudget: 110 });
                    this.setState({ largeBudget: 260 });
                }

            })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            estimatedReach: nextProps.estimatedReach,
        });
    }

    selectBudget = (budget) => (e) => {
        if (this.state.campaignType === 'listing') {
            budget.type === "Small" && this.setState({ borderSmall: !this.state.borderSmall, borderMedium: false, borderLarge: false });
            budget.type === "Large" && this.setState({ borderSmall: false, borderMedium: false, borderLarge: !this.state.borderLarge });
        } else {
            budget.type === "Small" && this.setState({ borderSmall: !this.state.borderSmall, borderMedium: false, borderLarge: false });
            budget.type === "Medium" && this.setState({ borderSmall: false, borderMedium: !this.state.borderMedium, borderLarge: false });
            budget.type === "Large" && this.setState({ borderSmall: false, borderMedium: false, borderLarge: !this.state.borderLarge });
        }
        this.props.selectedBudget(budget);
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    render() {
        // if (!auth.loggedIn()) {
        //     return (<Redirect to="/new-login" />)
        // }

        return (
            <div className="changecampaigntype">
                {/* <Header backButton={this.state.previousPath}
                    previousData={{
                        userProfile: this.state.userProfile,
                        campaignType: this.state.campaignType,
                        previousPath: this.props.location.pathname,
                        listingData: this.state.listingData
                    }}
                /> */}
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row>
                        <Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12}>
                            <Row className="changebudgetprotion1">
                                <p className="changebudgettopheading">Choose budget.</p>
                            </Row>

                            <Row className="changebudgetstate">


                                <Col lg={{ size: 4 }} md={{ size: 4 }} sm={12} xs={12}>
                                    <Link onClick={this.selectBudget({
                                        type: "Small",
                                        amount: this.state.smallBudget,
                                        currency: this.state.currency,
                                        days: this.state.daysForSmall,
                                        estimatedReach: this.state.estimatedReach
                                    })} style={{ textDecoration: 'none' }}>
                                        <Row className="changecampaigntypelistbottom" >
                                            <Row className="changebudgetcardsizebottom" style={{ border: this.state.borderSmall ? '5px solid lightgray' : '0' }}>
                                                <Row className="titleofchangebudget">Small <span className="newbudgetprice-label-span">- {this.state.smallBudget} {this.state.currencySymbol}</span></Row>
                                                <Row className="chooseimagecss">
                                                    <img src={smallBudget}></img>
                                                </Row>
                                                <Row className="campaigntypetext">
                                                    <Col>
                                                        <p>{this.state.smallRecomendedDays}</p>
                                                    </Col>
                                                </Row>
                                                {/* <Row className="campaigntypetextestimate">
                                                    <Col>
                                                        <p>Estimated Reach: {this.state.estimatedReach}</p>
                                                    </Col>
                                                </Row> */}
                                                <Row className="campaigntypebtmbutton">

                                                    <p>+ Create ad</p>
                                                </Row>
                                            </Row>
                                        </Row>
                                    </Link>

                                </Col>

                                {
                                    this.state.campaignType !== 'listing' ?
                                        <Col lg={{ size: 4 }} md={{ size: 4 }} sm={12} xs={12}>
                                            <Link onClick={this.selectBudget({
                                                type: "Medium",
                                                amount: this.state.mediumBudget,
                                                currency: this.state.currency,
                                                days: 14,
                                                estimatedReach: this.state.estimatedReach
                                            })} style={{ textDecoration: 'none' }}>
                                                <Row className="changecampaigntypelistbottom">
                                                    <Row className="changebudgetcardsizebottom" style={{ border: this.state.borderMedium ? '5px solid lightgray' : '0' }}>
                                                        <Row className="titleofchangebudget">Medium <span className="newbudgetprice-label-span">- {this.state.mediumBudget} {this.state.currencySymbol}</span></Row>
                                                        <Row className="chooseimagecss">
                                                            <img src={mediumBudget}></img>
                                                        </Row>
                                                        <Row className="campaigntypetext">
                                                            <Col>
                                                                <p>Recommended for standard campaigns. Maximum 14 days.</p>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row className="campaigntypetextestimate">
                                                    <Col>
                                                        <p>Estimated Reach: {this.state.estimatedReach}</p>
                                                    </Col>
                                                </Row> */}
                                                        <Row className="campaigntypebtmbutton">
                                                            <p>+ Create ad</p>
                                                        </Row>
                                                    </Row>
                                                </Row>
                                            </Link>
                                        </Col>
                                        : ''
                                }

                                <Col lg={{ size: 4 }} md={{ size: 4 }} sm={12} xs={12}>
                                    <Link onClick={this.selectBudget({
                                        type: "Large",
                                        amount: this.state.largeBudget,
                                        currency: this.state.currency,
                                        days: this.state.daysForLarge,
                                        estimatedReach: this.state.estimatedReach
                                    })} style={{ textDecoration: 'none' }}>
                                        <Row className="changecampaigntypelistbottom">
                                            <Row className="changebudgetcardsizebottom" style={{ border: this.state.borderLarge ? '5px solid lightgray' : '0' }}>
                                                <Row className="titleofchangebudget">Large <span className="newbudgetprice-label-span">- {this.state.largeBudget} {this.state.currencySymbol}</span></Row>
                                                <Row className="chooseimagecss">
                                                    <img src={largeBudget}></img>
                                                </Row>
                                                <Row className="campaigntypetext">
                                                    <Col>
                                                        <p>{this.state.larageRecomendedDays}</p>
                                                    </Col>
                                                </Row>
                                                {/* <Row className="campaigntypetextestimate">
                                                    <Col>
                                                        <p>Estimated Reach: {this.state.estimatedReach}</p>
                                                    </Col>
                                                </Row> */}
                                                <Row className="campaigntypebtmbutton">
                                                    <p>+ Create ad</p>
                                                </Row>
                                            </Row>
                                        </Row>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

            </div>
        );
    }
}

export default NewBudget;
