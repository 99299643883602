import React from 'react'
import axios from 'axios';
import { API_BASE } from '../../config'
import Login from "../Login/Login";

const auth = new Login();

let instance = axios.create({
    baseURL: API_BASE + 'campaign',
    headers: {
        'Authorization': `Bearer ${auth.getToken()}`
    }
});

let vendorInstance = axios.create({
    baseURL: API_BASE + 'vendor'
});

class CampaignService extends React.Component {

    getUserCampaigns() {
        return instance.get('/')
    }
    getActiveCampaigns() {
        return instance.get('/activeCampaigns')
    }
    getInactiveCampaigns() {
        return instance.get('/InactiveCampaigns')
    }
    createGoogleCampaign(data) {
        return instance.post('/', data)
    }

    updateGoogleCampaign(campaignId, data) {
        return instance.put('/' + campaignId, data)
    }

    createFacebookCampaign(data) {
        return instance.post('/fbCampaign', data)
    }

    geoLoaction(name) {
        return instance.get("/location?location=" + name);
    }

    activeFbCampaign(campaignId, data) {
        return instance.post("/activefbCampaign/" + campaignId, data);
    }

    pauseFbCampaign(campaignId, data) {
        return instance.post("/pausefbCampaign/" + campaignId, data);
    }

    deleteFbCampaign(campaignId, fbCampaignId) {
        return instance.delete("/deletefbCampaign/" + campaignId + "/" + fbCampaignId);
    }
    fbGeoLoaction(name) {
        return instance.get("/fblocation?location=" + name);
    }

    getEstimatedUser(data) {
        return instance.post("/estimatedReach", data);
    }

    getStatsOfCampaign(campaignId) {
        return instance.get("/campaignstats/" + campaignId);
    }

    exportToPDF(data) {
        return instance.post("/exportpdf", data);
    }

    fetchPDF() {
        return instance.get("/fetchpdf");
    }

    getStatsOfCampaignForVendor(campaignId) {
        return vendorInstance.get("/campaignstatistics/" + campaignId);
    }

    getListingOfCampaignForVendor(listingId) {
        return vendorInstance.get("/getListing/" + listingId)
    }
    deleteMultipleCampaign() {
        return instance.post("/deletMultipleCampaign/")
    }
}

export default CampaignService;
