import React, { Component } from 'react';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Form, Input, Row } from 'reactstrap';
import swal from 'sweetalert';
import Apiservices from '../Apiservices/AuthService';
import './ContactUs.css';
import { Link, Redirect } from 'react-router-dom';

const Auth = new Apiservices();

class ContactUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            phoneNo: '',
            redirect: false,
            alert: false,
            invalid: '',
            loader: false
        };
        this.onChange = this.onChange.bind(this);
        this.contactUsMethod = this.contactUsMethod.bind(this);

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });

    }
    contactUsMethod(e) {
        e.preventDefault()
        if (this.state.email && this.state.name && this.state.phoneNo) {
            this.setState({ loader: true });

            Auth.postContactUs(this.state)
                .then((result) => {
                    //console.log(result, "Hello g ")
                    let responseJson = result;
                    if (responseJson.status == '200') {

                        if (result.data.sent) {
                            swal("Done", "Message sent!", "success");

                        }
                        else {
                            swal("Oops", "Try again", "error");

                        }
                        setTimeout(() => {

                            this.setState({ redirect: true });
                            this.setState({ loader: false });

                        }, 1000)
                        this.setState({ alert: false });
                    }
                    // console.log("userinfo", responseJson.data.user);
                })
                .catch(err => {
                    this.setState({ alert: true });
                    this.setState({ invalid: err.response.data.error.message });
                    swal("Oops", "Try again", "error");
                    this.setState({ loader: false });

                });

        }

    }


    render() {

        return (

            <div className="mainContactUs">
                {/* <Header/> */}
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row >
                        <Col lg={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} sm={12} xs={12}>
                            <Row className="ContactUsWelcomeheading">
                                <Row className="ContactUsWelcometext">
                                    <p>Contact us!</p>
                                </Row>
                            </Row>
                        </Col>
                        <Col lg={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} sm={12} xs={12}>
                            <Row className="ContactUsformbodymain">
                                <Form className="ContactUsformclass">
                                    <Input
                                        className="ContactUsemailinput"
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        onChange={this.onChange}
                                        required />
                                    <Input
                                        className="ContactUsemailinput"
                                        type="email"
                                        name="email"
                                        pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                        placeholder="Email"
                                        onChange={this.onChange}
                                        required />
                                    <Input
                                        className="ContactUsemailinput"
                                        type="text"
                                        name="phoneNo"
                                        placeholder="Phone #"
                                        onChange={this.onChange}
                                        required />
                                    <Button
                                        className="loginhomebtn"
                                        type="submit"
                                        refresh="true"
                                        onClick={this.contactUsMethod} > Send </Button>
                                </Form>

                            </Row>
                        </Col>
                        <Col lg={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} sm={12} xs={12}>

                            <Link to={{
                                pathname: "/",
                            }} style={{ textDecoration: 'none' }} >

                                <Row className="forgotAccount">
                                    <p>  Back</p>
                                </Row>
                            </Link>
                        </Col>
                        <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                            <div className='overlay-box'>
                                <PropagateLoader
                                    // css={override}
                                    sizeUnit={"px"}
                                    size={25}
                                    color={'white'}
                                    loading={this.state.loader}
                                />
                            </div>
                        </div>
                    </Row>
                </Col>
            </div>
        );
    }
}

export default ContactUs;
