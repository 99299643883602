import React, { Component } from 'react';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Form, Input, Row } from 'reactstrap';
import swal from 'sweetalert';
import LandingPageHeader from "../Header/LandingPageHeader";
import Apiservices from '../Apiservices/AuthService';
import './NewLogin.css';
import { Link, Redirect } from 'react-router-dom';

const Auth = new Apiservices();

class NewLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            userinfo: {},
            redirect: false,
            alert: false,
            invalid: '',
            profileCompleted: false,
            role: '',
            loader: false
        };
        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    login(e) {
        e.preventDefault()
        if (this.state.email && this.state.password) {
            this.setState({ loader: true });
            console.log("Hi I am here now");
            Auth.postLogin(this.state)
                .then((result) => {
                     console.log(result, "Hello g ")
                    let responseJson = result;
                    if (responseJson.status == '200') {
                        // this.setToken(JSON.stringify(responseJson.data.token));
                        this.setId(JSON.stringify(responseJson.data.user._id));
                        this.setagencyEmail(JSON.stringify(responseJson.data.user.role === 'agency' ?
                        responseJson.data.user.email: responseJson.data.user.agency.email));
                        this.setState({ userinfo: responseJson.data.user });
                        // this.setState({ completed: responseJson.data.user.profileCompleted });
                        this.setToken(JSON.stringify(responseJson.data.token));
                        this.setState({ profileCompleted: responseJson.data.user.profileCompleted });
                        this.setState({ role: responseJson.data.user.role });
                        localStorage.setItem("currentRole", responseJson.data.user.role);
                        console.log(this.state.profileCompleted)
                        setTimeout(() => {

                            this.setState({ redirect: true });
                            this.setState({ loader: false });

                        }, 1000)
                        this.setState({ alert: false });
                    }
                    // console.log("userinfo", responseJson.data.user);
                })
                .catch(err => {
                    this.setState({ alert: true });
                    this.setState({ invalid: err.response.data.error.message });
                    swal("Oops", "Invalid email or password", "error");
                    this.setState({ loader: false });

                });

        }

    }

    setToken(token) {
        // Saves user token to sessionStorage
        sessionStorage.setItem('token', token);
    }

    setId(userId) {
        // Saves user token to sessionStorage
        sessionStorage.setItem('userId', userId);
    }

    getToken() {
        // Retrieves the user token from sessionStorage
        return JSON.parse(sessionStorage.getItem('token'));
    }
    setagencyEmail(egencyEmail) {
        sessionStorage.setItem('agency_Email', egencyEmail);
    }
    getagencyEmail() {
        return JSON.parse(sessionStorage.getItem('agency_Email'));
    }
    getId() {
        // Retrieves the user token from sessionStorage
        return JSON.parse(sessionStorage.getItem('userId'));
    }


    logout() {
        // Clear user token and profile data from sessionStorage
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userId');
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken();
        return !!token;
    }

    render() {
        if (this.loggedIn()) {

            if ((this.state.role == 'agent' && this.state.profileCompleted == true && this.state.redirect == true) || this.state.role == 'agency') {

                //return (
                    // <Redirect to={{
                    //     pathname: "/dashboard",
                    //     state: {
                    //       userinfo: this.state.userinfo,
                    //     //   campaignBudget: this.state.campaignBudget,
                    //     //   campaigndetails: this.state.campaigndetails
                    //     }
                    // }} />
                    window.location.replace("/dashboard")
                //)
            }
            else if (this.state.profileCompleted == false) {
                return (
                    // <Redirect to={{
                    //     pathname: "/create-profile",
                    //     state: {
                    //         userinfo: this.state.userinfo
                    //     }
                    // }} />
                    window.location.replace("/create-profile")
                )
            }
        }
        return (

            <div className="mainlogin">
                <div className="headerCustomPadding">
                    <LandingPageHeader />
                </div>
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} sm={12} xs={12}>
                            <Row className="welcomeheading">
                                <Row className="Welcometext">
                                    <p>Welcome!</p>
                                </Row>
                            </Row>
                        </Col>
                        <Col lg={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} sm={12} xs={12}>
                            <Row className="formbodymain">
                                <Form className="formclass">
                                    <Input
                                        className="emailinput"
                                        type="email"
                                        name="email"
                                        pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                        placeholder="Email"
                                        onChange={this.onChange}
                                        required />
                                    <Input className="passwordfields"
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        onChange={this.onChange}
                                        required />

                                    <Button
                                        className="loginhomebtn"
                                        type="submit"
                                        refresh="true"
                                        onClick={this.login} > Log in </Button>
                                </Form>

                            </Row>
                        </Col>
                        <Col lg={{ size: 4, offset: 4 }} md={{ size: 4, offset: 4 }} sm={{ size: 6, offset: 3 }} xs={{ size: 6, offset: 3 }} style={{ textDecoration: 'none' }}>
                            <Row className="contactandforgotpass">
                                <Col lg={{ size: 6 }} md={{ size: 6 }} sm={6} xs={6} style={{ textDecoration: 'none', padding: 0 }}>
                                    <Row className="forgotAccount2">
                                        <Link to={{
                                            pathname: "/contactUs",
                                        }} style={{ textDecoration: 'none', color: "#283447" }} >


                                            <p>Contact us</p>
                                        </Link>
                                    </Row>

                                </Col>
                                <Col lg={{ size: 6 }} md={{ size: 6 }} sm={6} xs={6} style={{ textDecoration: 'none', padding: 0 }}>
                                    <Row className="forgotAccount1">
                                        <Link to={{
                                            pathname: "/resetPassword",
                                        }} style={{ textDecoration: 'none', color: "#283447" }} >


                                            <p>Forgot your password ?</p>

                                        </Link>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                            <div className='overlay-box'>
                                <PropagateLoader
                                    // css={override}
                                    sizeUnit={"px"}
                                    size={25}
                                    color={'white'}
                                    loading={this.state.loader}
                                />
                            </div>
                        </div>
                    </Row>
                </Col>
            </div>
        );
    }
}

export default NewLogin;
