import moment from 'moment';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Row } from 'reactstrap';
import swal from 'sweetalert';
import { IMG_URL } from '../../config';
import CampaignService from '../Apiservices/CampaignService';
import PaymentService from '../Apiservices/PaymentServices';
import Header from '../Header/NewHeader';
import MobileAdPreview from '../MobileAdPreview';
import './fasadpublishpreview.css';
import Headerlogo from '../../images/headerlogo.png';
import AdsServices from '../Apiservices/Ads_Services';
import ListingAdServices from '../Apiservices/ListingAdServices';

const Ads_Services = new AdsServices();
const campaignService = new CampaignService();
const paymentService = new PaymentService();
const listingAdServices = new ListingAdServices();

var regionsArray = []
const date = new Date();
class AdPreviewAndPublish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            alert: false,
            fasadId: "",
            clientSecret: "",
            reverseToEdit: true,
            estimatedReach: 0,
            currency: "kr",
            region: "",
            selectedRegion: "",
            campaignData: {},
            loader: false,
            isEdit: false,
            listingId: "",
            website: props.location.state && props.location.state.listingData.website
                ? props.location.state.listingData.website : "",
            previewImage: props.location.state && props.location.state.listingData.listingAdImages[0]
                ? IMG_URL+ props.location.state.listingData.listingAdImages[0] : "",
            previewVideo: props.location.state && props.location.state.listingData.listingAdVideos[0]
                ? IMG_URL + props.location.state.listingData.listingAdVideos[0] : "",
            startDate: props.location.state && props.location.state.listingData.startDate
                ? this.GetFormattedDate(props.location.state.listingData.startDate)
                : new Date(date.setDate(date.getDate() + 1)).toISOString().substr(0, 10),
            endDate: props.location.state && props.location.state.listingData.endDate
                ? this.GetFormattedDate(props.location.state.listingData.endDate)
                : new Date(date.setDate(date.getDate() + 7)).toISOString().substr(0, 10),
            regionsArray: props.location.state && props.location.state.listingData.locations
                ? props.location.state.listingData.locations
                : [],
            description: props.location.state && props.location.state.listingData.description
                ? props.location.state.listingData.description
                : "",
            headline: props.location.state && props.location.state.listingData.headline
                ? props.location.state.listingData.headline
                : "",
            noOfRooms: props.location.state && props.location.state.listingData.noOfRooms
                ? props.location.state.listingData.noOfRooms
                : 0,
            message: props.location.state && props.location.state.listingData.message
                ? props.location.state.listingData.message
                : "",
            listingImages: props.location.state && props.location.state.listingData.listingAdImages
                ? props.location.state.listingData.listingAdImages[0]
                : "",
            listingVideos: props.location.state && props.location.state.listingData.listingAdVideos
                ? props.location.state.listingData.listingAdVideos[0]
                : "",
            mediaType: props.location.state && props.location.state.listingData.mediaType
                ? props.location.state.listingData.mediaType
                : "",
            businessName: props.location.state && props.location.state.listingData.businessName
                ? props.location.state.listingData.businessName
                : "",
            edited: props.location.state && props.location.state.edited
                ? true : false,
            maxDays: props.location.state && props.location.state.budget
                ? props.location.state.budget.days
                : 0,
            listingAd: props.location.state && props.location.state.listingAd
                ? props.location.state.listingAd : {},
            budget: props.location.state && props.location.state.budget
                ? props.location.state.budget : {},
            budgetType: props.location.state && props.location.state.budget
                ? props.location.state.budget.type : "",
            token: "",
            listingAdId:props.location.state && props.location.state.listingData._id
            ? props.location.state.listingData._id : ""
        };
        console.log(this.state.startDate, "start state")
        console.log(this.state.endDate, "end date")
        this.createListingAd = this.createListingAd.bind(this);
    }

    componentDidMount = async () => {
        try {
            console.log("in component did mount")

            regionsArray = this.state.regionsArray
            if (this.state.edited == false) {
                console.log("in if check")

                let search = new URLSearchParams(window.location.search);
                let extListingId = search.get('extListingId')
                let token = search.get('token')
                // this.setState({ loader: true });         
                let listingIddata = {
                    extListingId: extListingId,
                    token: token
                }

                // this.setState({ token: listingIddata.token });
                let getexternallistingdata = await Ads_Services.getexternalListingData(listingIddata)
                let data = getexternallistingdata.data;
                let media = getexternallistingdata.data.mediaType
                console.log("mediaType", media)
                let imageUrl, videoUrl
                if (media === 'image') {
                    imageUrl = data.listingAdImages[0].startsWith('https://')
                        ? data.listingAdImages[0] : IMG_URL + data.listingAdImages[0]
                    console.log("image url", imageUrl)
                } else {
                    videoUrl = data.listingAdVideos[0].startsWith('https://')
                        ? data.listingAdVideos[0] : IMG_URL + data.listingAdVideos[0]
                    console.log("video url", videoUrl)
                }
                console.log("data", data)
                this.setState({ listingAd: data });
                this.setState({
                    description: data.description,
                    headline: data.headline ? data.headline : 'Pris på förfrågan',
                    message: data.message,
                    listingImages: data.listingAdImages[0],
                    businessName: data.businessName,
                    website: data.website,
                    region: "sweden",
                    regionsArray: data.locations,
                    previewImage: data.listingAdImages[0],
                    listingAdVideos: data.listingAdVideos[0],
                    mediaType: media,
                    listingAdId: data._id,
                    previewImage: imageUrl,
                    previewVideo: videoUrl
                });
                if (data.budget === 'large') {
                    let budget = {
                        type: "large",
                        amount: 2500,
                        currency: "kr",
                        days: 9,
                        estimatedReach: this.state.estimatedReach
                    }
                    this.setState({ budget: budget });

                }
                else if (data.budget === 'medium') {
                    let budget = {
                        type: "medium",
                        amount: 1000,
                        currency: "kr",
                        days: 7,
                        estimatedReach: this.state.estimatedReach
                    }
                    this.setState({ budget: budget });

                }

                else {
                    let budget = {
                        type: "medium",
                        amount: 1000,
                        currency: "kr",
                        days: 7,
                        estimatedReach: this.state.estimatedReach
                    }
                    this.setState({ budget: budget });
                    this.setState({ endDate: this.state.endDate})
                    // this.setState({ endDate: new Date(date.setDate(date.getDate() + 7)).toISOString().substr(0, 10) });

                }
                this.setState({ loader: false });
            }
        }

        catch (err) {
            this.setState({ loader: false });
            console.log(err.response.data.error.message)
            swal("oops", err.response.data.error.message, "error");
        }
    }

    GetFormattedDate(date) {
        var check = moment(date, 'YYYY-MM-DD');
        console.log(check, 'Checking');
        var month = check.format('MM');
        var day = check.format('DD');
        var year = check.format('YYYY');

        console.log(year + "-" + month + "-" + day);
        return year + "-" + month + "-" + day;
    }

    setToken(token) {
        // Saves user token to sessionStorage
        sessionStorage.setItem('token', token);
    }

    getToken() {
        // Retrieves the user token from sessionStorage
        return JSON.parse(sessionStorage.getItem('token'));
    }

    createListingAd = (e) => {
        if (this.state.headline && this.state.message
            && this.state.businessName
            && this.state.description && this.state.website
            && this.state.listingAd.listingAdImages
            && this.state.listingAd.listingAdImages.length > 0) {
            this.setState({ loader: true });
            let formData = new FormData();
            formData.append('headline', this.state.headline);
            formData.append('message', this.state.message);
            formData.append('businessName', this.state.businessName);
            formData.append('description', this.state.description);
            formData.append('website', this.state.website);
            formData.append('region', this.state.region);
            for (const listingImage of this.state.listingAd.listingAdImages) {
                formData.append('listingAdImages', listingImage);
            }
            listingAdServices.createListingAd(formData)
                .then(result => {
                    console.log(result, "Hello")
                    if (result.status == 200) {

                        this.setState({ listingAd: result.data })
                        this.setState({ redirect: true });
                        this.setState({ loader: false });
                    }
                }).catch(err => {
                    this.setState({ alert: true });
                    this.setState({ loader: false });
                    swal("Oops", err.response.data.error.message, "error");
                });
        }
        else {
            swal("Oops", "Field is missing", "warning");

        }
    };

    okButton = () => {
        swal({
            title: "Success",
            text: "Tack! Din annons är nu publicerad och går live inom 24h. Du kan närsomhelst logga in på ditt Brightbee-konto för att följa dina resultat.",
            icon: "success",
            buttons: true,
            dangerMode: true,
        })
            .then(() => {
                return window.location.replace("https://brightbee.co");
            })
    };
    getFormattedDateDiff = (date1, date2) => {
        var b = moment(date1),
            a = moment(date2),
            intervals = ['months', 'weeks', 'days', 'hours'],
            out = [];
        for (var i = 0; i < intervals.length; i++) {
            var diff = a.diff(b, intervals[i]);
            b.add(diff, intervals[i]);
            out.push(diff);
        }
        return out;
    };

    render() {
        if (this.state.redirect === true) {
            return (
                <Redirect to={{
                    pathname: "/",
                }} />
            )
        }
        const startDate = moment(this.state.startDate, 'YYYY-MM-DD');
        const endDate = moment(this.state.endDate, 'YYYY-MM-DD');
        const dateDifference = endDate.diff(startDate);
        const diffDuration = moment.duration(dateDifference);
        return (
            <div className="publishpreviewad">
                <Row className="headerlogostyle">
                    <Link className="logostylefasad" to="/">
                        <img className="logostylefasad" src={Headerlogo}></img></Link>
                </Row>
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="publishpreviewadmain">
                        <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }} >
                            <Row className="publishpreviewadprotion1">
                                <Row className="publishpreviewadtopheading">
                                    <p >Preview and publish.</p>
                                </Row>
                            </Row>
                            <Row className="publishpreviewadtargets">
                                <Row className="publishpreviewadheading4">
                                    <p>Period</p>
                                </Row>
                                <Row className="publishpreviewadheading5">
                                    <Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 4 }} xs={{ size: 4 }}>
                                        <Row>
                                            <p>Start</p>
                                        </Row>
                                    </Col>
                                    <Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 4 }} xs={{ size: 4 }}>
                                        <Row>
                                            <p>{this.state.startDate}</p>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="publishpreviewadheading5">
                                    <Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 4 }} xs={{ size: 4 }}>
                                        <Row>
                                            <p>End</p>
                                        </Row>
                                    </Col>
                                    <Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 4 }} xs={{ size: 4 }}>
                                        <Row>
                                            <p>{this.state.endDate}</p>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="publishpreviewadheading5">
                                    <p>({diffDuration.days()} days)</p>
                                </Row>
                                <Row className="publishpreviewadheading4">
                                    <p>Region/-s</p>
                                </Row>
                                {console.log(this.state.regionsArray, "araay")}
                                {this.state.regionsArray && this.state.regionsArray.length > 0 && this.state.regionsArray.map((item, idx) => (
                                    <Row className="publishpreviewadheading5">
                                        <p>{(item.type === 'country' || item.type === 'geo_market') ? item.name : (item.type === 'region' || item.type === 'geo_market') ? item.name + ', ' + item.country_name : item.name + ', ' + item.region + ', ' + item.country_name}</p>
                                    </Row>
                                ))}

                                <Row className="publishpreviewadheading4">
                                    <p>Budget</p>
                                </Row>
                                <Row className="publishpreviewadheading5">
                                    <p>{this.state.budget.amount} {this.state.currency} </p>
                                </Row>
                                <Row className="publishpreviewadbuttons">
                                    <Col lg={{ size: 4 }}>
                                        <Row className="publishpreviewadbtn">
                                            <Row className="publishpreviewad-wrapper">
                                                <Link to={{
                                                    pathname: "/adEditListing",
                                                    state: {
                                                        previousPath: this.props.location.pathname,
                                                        estimatedReach: this.state.estimatedReach,
                                                        budget: this.state.budget,
                                                        listingAd: this.state.listingAd,
                                                        reverseToEdit: this.state.reverseToEdit,
                                                        region: this.state.region,
                                                        previewImage: this.state.previewImage,
                                                        previewVideo: this.state.previewVideo,
                                                        listingData: {
                                                            description: this.state.description,
                                                            headline: this.state.headline,
                                                            noOfRooms: this.state.noOfRooms,
                                                            message: this.state.message,
                                                            listingImages: this.state.listingImages,
                                                            listingVideos: this.state.listingVideos,
                                                            bussinesName: this.state.businessName,
                                                            website: this.state.website,
                                                            startDate: this.state.startDate,
                                                            endDate: this.state.endDate,
                                                            fbPageId: this.state.fbPageId,
                                                            region: this.state.regionsArray,
                                                            regionsArray: this.state.regionsArray,
                                                            mediaType: this.state.mediaType,
                                                            listingAdId: this.state.listingAdId
                                                        }
                                                    }
                                                }}>
                                                    <Button class="previewpublish-btnn">Edit</Button>
                                                </Link>
                                            </Row>
                                        </Row>
                                    </Col>

                                    <Col lg={{ size: 4 }}>
                                        <Row className="publishpreviewad-btns">
                                            <Button className="sharepublishpreviewad" onClick={this.okButton}>Publish</Button>
                                        </Row>
                                    </Col>
                                    <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                                        <div className='overlay-box'>
                                            <PropagateLoader
                                                sizeUnit={"px"}
                                                size={25}
                                                color={'white'}
                                                loading={this.state.loader}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 5, offset: 1 }} sm={{ size: 12, offset: 0 }} xs={{ size: 12, offset: 0 }} >
                            {this.state.mediaType === "image" && <MobileAdPreview headline={this.state.headline} message={this.state.message} website={this.state.website} description={this.state.description} previewImage={this.state.previewImage || (this.state.listingAdImages && this.state.listingAdImages.length > 0 && IMG_URL + this.state.listingAdImages[0])} mediaType={this.state.mediaType} />}
                            {this.state.mediaType === "video" && <MobileAdPreview headline={this.state.headline} message={this.state.message} website={this.state.website} description={this.state.description} previewVideo={this.state.previewVideo || (this.state.listingAdVideos && this.state.listingAdVideos.length > 0 && IMG_URL + this.state.listingAdVideos[0])} mediaType={this.state.mediaType} />}

                        </Col>
                        <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                            <div className='overlay-box'>
                                <PropagateLoader
                                    sizeUnit={"px"}
                                    size={25}
                                    color={'white'}
                                    loading={this.state.loader}
                                />
                            </div>
                        </div>

                    </Row>
                </Col>
            </div>
        );
    }
}

export default AdPreviewAndPublish;