import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardImg, Col, Row } from 'reactstrap';
import House from '../../images/house.jpg';
import Header from '../Header';
import './Style.css';
// import Left from '../../images/leftarrow.png'
// import Right from '../../images/rightarrow.png'

class Active extends Component {

	constructor(props) {
		super(props);
	}
	render() {

		return (
			<div>
				<Header />
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheader1">
						<p className="compname">campaign name here</p>
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheader2">
						{/* <Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                <Row className="leftarrow">
                    <img src={Left} alt="" height="33px"></img>
                </Row>
              </Col> */}
						<Col lg={12} md={12} sm={12} xs={12}><Row className="centertext">Promotions</Row></Col>
						{/* <Col  lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                  <Row className="rightarrow"><img src={Right} alt="rightarrow" height="20px"></img></Row>
                  </Col> */}
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12}>
					<Row className="budget-annons">
						<Col lg={6} md={6} sm={6} xs={6}>
							<Link to="/active">
								<Row className="budget">
									<p className="part1">ACTIVE</p>
								</Row>
							</Link>
						</Col>
						<Col lg={6} md={6} sm={6} xs={6}>
							<Link to="/inactive">
								<Row className="annnons">
									<p className="part2">INACTIVE</p>
								</Row>
							</Link>
						</Col>

						<Col lg={3} md={4} sm={6} xs={12}>
							<Row>
								<Card id="oneTwo" >
									<a href="/">
										<CardImg className="activeimage" src={House} alt="Card image cap" />
									</a>
									<CardBody className="Card_Bodyactive" style={{ padding: 2 }}>
										<h5>Hemnet</h5>
										<p>0 aktiva kampanjer</p>
									</CardBody>
								</Card>
							</Row>
						</Col>
					</Row>
				</Col>

			</div>
		);
	}
}

export default Active;
