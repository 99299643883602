import React, { Component } from 'react';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Input, Label, Row, Table } from 'reactstrap';
import swal from 'sweetalert';
import UserServices from '../Apiservices/UserService';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header/NewHeader';
import './Users.css';
import './User_Centered.css';
import Login from "../Login/Login";

const auth = new Login();
const userService = new UserServices();

const TableRows = {
    textAlign: "center",
    color: "black",
    fontFamily: "Roboto",
    borderBottom: "1px solid lightgray"
}

const tabledesign = {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    color: "#000000",
    fontFamily: " BuenosAiresTrial",
    fontWeight: 'normal',
    borderSpacing: "0",
    textstyle: "none",
    lineHeight: "2em",
    borderCollapse: "collapse"

};

class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            name: '',
            redirect: false,
            alert: false,
            loader: false,
            users: []
        };
        this.onChange = this.onChange.bind(this);
        this.inviteAgent = this.inviteAgent.bind(this);
    }

    componentDidMount() {
        userService.getUsers()
            .then((result) => {
                let responseJson = result.data
                console.log(responseJson, "data")
                this.setState({ users: responseJson });

            })
            .catch(err => {
                swal("Oops", err.response.data.error.message, "error");
            });
    };

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    inviteAgent(e) {
        e.preventDefault()
        if (this.state.email && this.state.name) {
            this.setState({ loader: true });
            let data = {
                name: this.state.name,
                email: this.state.email
            }
            userService.inviteAgent(data)
                .then((result) => {
                    let responseJson = result;
                    if (responseJson.status == '200') {
                        swal("Done", "Login details have been sent to the user email", "success")
                        this.setState({ name: "", email: "" });
                        setTimeout(() => {
                            this.setState({ redirect: true });
                            this.setState({ loader: false });

                        }, 1000)

                        console.log(this.state.name)
                        this.componentDidMount()
                    }
                })
                .catch(err => {
                    swal("Oops", err.response.data.error.message, "error");
                    this.setState({ loader: false });
                });
        }
        else {
            swal("Oops", "Something is missing", "warning");

        }
    }

    deleteSingleUser = (id) => {
        userService.deleteAgent(id)
        setTimeout(() => {
            window.location.reload(true);
        }, 1000);

    }

    render() {
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }

        return (
            <div className="userList">
                <Header />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="usermain">
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="usersprotion1">
                                <p className="topheadingusers">Users.</p>
                            </Row>
                            <Row className="formusers">
                                <Label className="usersname-label" > Name </Label>
                                <Input type="text"
                                    name="name"
                                    id="users-name"
                                    placeholder="Name"
                                    value={this.state.name}
                                    onChange={this.onChange}
                                    required />

                                <Label className="usersname-label" > E-mail </Label>
                                <Input type="email"
                                    name="email"
                                    id="users-userName"
                                    value={this.state.email}
                                    placeholder="E-mail"
                                    onChange={this.onChange}
                                    required />
                                {/* <Label className="paymentname-label" > User type </Label>
                                <Input
                                    type="select"
                                    id="users-userName"
                                    name="campaignType"
                                    onChange={this.onChange}
                                >
                                    <option>User type</option>
                                    <option value="agency">AGENCY</option>
                                    <option value="agent">AGENT</option>
                                    <option value="listing">LISTING</option>
                                </Input> */}

                                <Button className="saveusers"
                                    type="submit"
                                    onClick={this.inviteAgent}> Invite </Button>
                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="usersprotion2">
                                <Table responsive
                                    style={tabledesign}
                                    striped
                                    hover
                                    size="sm"
                                    fontFamily="BuenosAiresTrial">

                                    <thead>
                                        <tr>
                                            <th>Active Users</th>
                                            <th>Active listings</th>
                                            <th>Active ads</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.users &&
                                            this.state.users.map((user) => {
                                                return (
                                                    <tr>

                                                        <td>{user.name}</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td style={TableRows}><button onClick={(e) => { if (window.confirm('Are you sure to delete this user ?')) this.deleteSingleUser(user._id) }}>Delete</button></td>

                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </Table>
                            </Row>
                            <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                                <div className='overlay-box'>
                                    <PropagateLoader
                                        // css={override}
                                        sizeUnit={"px"}
                                        size={25}
                                        color={'white'}
                                        loading={this.state.loader}
                                    />
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Col>
            </div>
        );
    }
}

export default Users;
