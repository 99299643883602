import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import swal from 'sweetalert';
import {
  Button,
  Card,
  CardImg,
  CardSubtitle,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap";
import { IMG_URL } from "../../config";
import Eyes from "../../images/Resurs 670.png";
import CampaignServices from "../Apiservices/CampaignService";
import Header from "../Header/NewHeader";
import Login from "../Login/Login";
import Calenders from "../../images/Resurs 680.png";
import Clock from "../../images/Resurs 690.png";
import Graphs from "../../images/wq.jpeg";
import "./AllCampaigns.css";
const moment = require("moment");

const auth = new Login();
const campaignService = new CampaignServices();
let showDefault = "default";
class AllCampaigns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userProfile: "",
      redirect: false,
      alert: false,
      invalid: "",
      name: "",
      userImage: "",
      campaigns: [],
      loader: true,
      loading: true,
      responseData: [],
      dataFound: false
    };
  }

  componentDidMount() {
    if (showDefault === "default") {
      campaignService
        .getUserCampaigns()
        .then((result) => {
          let responseJson = result.data;
          this.setState({
            campaigns: responseJson,
            loading: false,
            loader: false,
            responseData: result.data
          });
        })
        .catch((err) => {
          console.log(err);
          // alert("Oops", "oh no", "error");
        });
    }

  }
  deleteButton = () => {
    this.setState({ loader: true })
    if (this.state.responseData.length === 0) {
      swal("Sorry!", "No data for delete!", "error")
      this.setState({ loader: false })
    }
    else {
      let pausedArray = this.state.responseData.filter(x => x.status === "PAUSED")
      console.log(pausedArray)
      if (pausedArray.length === 0) {
        this.setState({ loader: false })
        swal("Sorry!", "No test data with PAUSED status!", "error");
      } else {
        campaignService.deleteMultipleCampaign().then(result => {
          console.log(result.data, "result.data")
          swal("Successfully deleted!", "", "success")
            .then(() => {
              window.location.reload()
            });
        })
          .catch(error => {
            console.log(error, 'Campaign not delete')
          })
      }
    }

  }
  onChange = (e) => {
    console.log(e.target.value)
    this.setState({ loader: true });
    showDefault = "changed";

    if (e.target.value == "Active") {
      campaignService.getActiveCampaigns()
        .then(async (result) => {
          console.log(result.data, 'result data')
          let responseJson = result.data
          if (responseJson.length === 0) {
            this.setState({ dataFound: true })
          }else{
            this.setState({ dataFound: false })
          }
          this.setState({ campaigns: responseJson });
          this.setState({ loader: false });

        })
        .catch(err => {
          this.setState({ loader: false });
          console.log(err)
        });
    }
    else if (e.target.value == "Inactive") {

      campaignService.getInactiveCampaigns()
        .then(async (result) => {
          let responseJson = result.data
          console.log(responseJson , 'responseJson')
          if (responseJson.length === 0) {
            this.setState({ dataFound: true })
          }else{
            this.setState({ dataFound: false })
          }
          this.setState({ campaigns: responseJson });
          this.setState({ loader: false });

        })
        .catch(err => {
          this.setState({ loader: false });
          console.log(err)
        });
    }
    else {
      campaignService
        .getUserCampaigns()
        .then((result) => {
          let responseJson = result.data;
          if (responseJson.length === 0) {
            this.setState({ dataFound: true })
          }else{
            this.setState({ dataFound: false })
          }
          this.setState({ campaigns: responseJson });
          this.setState({ loader: false });

        })
        .catch((err) => {
          console.log(err);
          this.setState({ loader: false });
        });
    }
  };

  GetFormattedDate(date) {
    var todayTime = new Date(date);
    var month = moment(todayTime.getMonth() + 1);
    var day = moment(todayTime.getDate());
    var year = moment(todayTime.getFullYear());
    return day + "-" + month + "-" + year;
  }

  render() {
    if (!auth.loggedIn()) {
      return <Redirect to="/new-login" />;
    }

    return (
      <div className="allcampaigns">
        <Header />
        <Col lg={12} md={12} sm={12} xs={12}>
          <Row className="allcampaignmain">
            <Col
              lg={{ size: 12, offset: 0 }}
              md={{ size: 12, offset: 0 }}
              sm={12}
              xs={12}
            >
              <Row className="allcampaignprotion1">
                <Col>
                  <p className="allcampaigntopheading">All Campaigns.</p>
                </Col>
                <Col>
                  <Row>
                    {/* <Button className="deleteButton" onClick={this.deleteButton}>
                      Delete
                    </Button> */}
                    <Input id="allcampaignsselect" type="select" onChange={this.onChange}>

                      <option>All</option>
                      <option>Active</option>
                      <option>Inactive</option>
                    </Input>
                    <Button className="allcampaignscreatead" type="submit">
                      {" "}
                      + Create campaign{" "}
                    </Button>
                  </Row>
                </Col>
              </Row>

              {this.state.dataFound === true ?
                < Row >
                  <Col>
                  </Col>
                  <Col>
                    <Row className='dataNotFound'>
                      Data not found
                 </Row>
                  </Col>
                  <Col>
                  </Col>
                </Row> : ""}
            </Col>
            <Col lg={{ size: 12 }} md={{ size: 12 }} sm={12} xs={12}>
              <Row className="allcampaigncardportionlast">
                {this.state.campaigns &&
                  this.state.campaigns.map((campaign) => {
                    let imageUrl = ""
                    if (campaign.listingAd.listingAdImages[0] && campaign.listingAd.mediaType === 'image') {
                      imageUrl = campaign.listingAd.listingAdImages[0].startsWith("https://") ? campaign.listingAd.listingAdImages[0] : IMG_URL + campaign.listingAd.listingAdImages[0]
                  } else if (campaign.listingAd.thumbnailImage && campaign.listingAd.mediaType === 'video') {
                      imageUrl = campaign.listingAd.thumbnailImage.startsWith("https://") ? campaign.listingAd.thumbnailImage : IMG_URL + campaign.listingAd.thumbnailImage
                  } else {
                      imageUrl = campaign.listingAd.listingAdImages[0].startsWith("https://") ? campaign.listingAd.listingAdImages[0] : IMG_URL + campaign.listingAd.listingAdImages[0]
                  }
                    return (
                      <Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
                        <Row className="allcampaigncampaignlistbottom">
                          <Card className="allcampaigncardsizebottom">
                            <CardTitle className="titleofallcampaign">
                              {campaign.listingAd && (
                                campaign.listingAd.description
                              )}
                            </CardTitle>
                            <CardSubtitle className="subtitleofallcamapign">
                              {campaign.platform}
                            </CardSubtitle>
                            {campaign.listingAd && (
                              <CardImg
                                className="allcampaignbottomcardimg"
                                src={imageUrl}
                                alt="image"
                              />
                            )}
                            <Row className="allcampaignbtmtext">
                              <Col>
                                <Row className="contentcenterallcampaigneye1">
                                  <img src={Eyes}></img>
                                </Row>
                                <Row className="contentcenterallcampaigneye">
                                  {this.GetFormattedDate(campaign.startDate)}
                                </Row>
                              </Col>
                              <Col>
                                <Row className="contentcenterallcampaign">
                                  <img src={Calenders}></img>
                                </Row>
                                <Row className="contentcenterallcampaign">
                                  2 weeks
                                </Row>
                              </Col>
                              <Col>
                                <Row className="contentcenterallcampaign">
                                  <img src={Clock}></img>
                                </Row>
                                <Row className="contentcenterallcampaign">
                                  10764
                                </Row>
                              </Col>
                            </Row>

                            <Row className="activeadcardbtmtxtrow">
                              <Col>
                                <Row className="activeadcancel">Cancel</Row>
                              </Col>
                              <Col>
                                <Row className="activeadview">View</Row>
                              </Col>
                            </Row>
                          </Card>
                        </Row>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
            <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
              <div className='overlay-box'>
                <PropagateLoader
                  // css={override}
                  sizeUnit={"px"}
                  size={25}
                  color={'white'}
                  loading={this.state.loader}
                />
              </div>
            </div>
          </Row>
        </Col>
      </div >
    );
  }
}

export default AllCampaigns;
