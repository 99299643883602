import React from 'react';
import { Button, Form, ModalBody, Input, Col, Row, FormGroup } from 'reactstrap'
import './Style.css'
import { Redirect, Link } from 'react-router-dom';
import Header from '../Header/index.js';
// import Spinner from 'react-spinner-material';
// import Loader from 'react-loader-spinner'
import swal from 'sweetalert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Placeholderimage from '../../images/imageplaceholder.png';
import Apiservices from '../Apiservices/Urlservice';
import LoadingOverlay from 'react-loading-overlay'
// import BounceLoader from 'react-spinners/BounceLoader'
import styled from 'styled-components'
const urlpost = new Apiservices();

const StyledLoader = styled(LoadingOverlay)`
  width: 100%;
  height: auto;
  overflow: hidden;
  .MyLoader_overlay {
    background: rgba(255, 0, 0, 0.5);
  }
  &.MyLoader_wrapper--active {
    overflow: hidden;
  }
`

class Addpropert extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// finalUrl: this.state.property.website ? this.state.property.website : '',
			// heading1: this.state.property.name ? this.state.property.name : '',
			// descriptionad: this.property.description ? this.state.property.description : '',
			// imageUrl: this.state.property.propertyImages ? this.state.property.propertyImages : '', 
			userinfo: this.props.location.state.userinfo,
			campaignType: this.props.location.state.campaignType,
			name: "",
			description: "",
			website: "",
			propertyImages: [],
			region: "other",
			property: {},
			propertydetail: this.props.location.state.propertydetail,
			redirect: false,
			alert: false,
			loading: false,
			active: false
		};
		console.log("campaigntype", this.state.property)
		console.log("file", this.state.propertyImages)
		console.log("userinfoproperty", this.state.userinfo)
		this.onChange = this.onChange.bind(this);
		this.addproperty = this.addproperty.bind(this);
		this.onChangeFile = this.onChangeFile.bind(this);
	}

	onChange(e) {
		console.log("ghfgj", e.target.value)
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeFile(e) {
		console.log("file", e.target.files)
		this.setState({ [e.target.name]: e.target.files });
	}

	// async componentDidMount() {
	//   // console.log(`sdfghjoiuy ${JSON.stringify(res.data)}`);
	//   this.setState({ propertyImages: {Placeholderimage} });
	// }

	addproperty = () => {
		console.log("property", this.state.propertyImages)
		if (this.state.name && this.state.description && this.state.propertyImages
			&& this.state.website && this.state.region)
			this.setState({ active: true });
		{
			let formData = new FormData();

			formData.append('name', this.state.name);
			formData.append('description', this.state.description);
			for (const propertyImages of this.state.propertyImages) {
				formData.append('propertyImages', propertyImages);
			}
			formData.append('propertyImages', this.state.propertyImages);
			formData.append('website', this.state.website);
			urlpost
				.addnewproperty(formData)
				.then(result => {
					let responseJson = result.data;
					if (responseJson) {
						this.setState({ property: responseJson });
						this.setState({ propertyImages: responseJson.propertyImages });

						this.setState({ redirect: true });
						this.setState({ alert: true });
						this.setState({ active: false });
					}
					// swal("Good", "property has been added", "success");
				})
				.catch(err => {
					this.setState({ alert: true });
					this.setState({ active: false });
					this.setState({ invalid: err.response.data.error.message });
					swal("Oops", "Something is missing", "info");
				});
		}
	};
	render() {
		if (this.state.redirect == true) {
			return (
				<Redirect to={{
					pathname: "/ad-create",
					state: {
						userinfo: this.state.userinfo,
						campaignType: this.state.campaignType,
						property: this.state.property
					}
				}} />
			)
		}
		return (
			<div>

				<LoadingOverlay
					active={false}
					spinner
					text='Loading your content...'
				>
					<Header />

					<Col lg={12} md={12} sm={12} xs={12}>
						<Row>
							<Col className="margins" lg={6} md={6} sm={12} xs={12} >
								<h1 className="heading-toppp">Add new property</h1>
								{console.log("campaigntype", this.state.campaignType)}
								<Form>
									<ModalBody>
										<Row className="namefields">
											<label className="labelproperty">Name</label>
											<Col lg={12} md={12} sm={12} xs={12} >
												<FormGroup>
													<Input id="datenew11" maxLength="30" type="text" name="name" placeholder="Name" onChange={this.onChange} />
												</FormGroup>
											</Col>
										</Row>
										<Row className="namefields">
											<label className="labelproperty">Description</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="textarea"
														maxLength="90"
														name="description"
														placeholder="Description"
														onChange={this.onChange} />
												</FormGroup>
											</Col>
										</Row>
										<Row className="namefields">
											<label className="labelproperty">Select pictures</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="file"
														name="propertyImages"
														onChange={this.onChangeFile} multiple />
												</FormGroup>
											</Col>
										</Row>

										<Row className="namefields">
											<label className="labelproperty">Website</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="text"
														name="website"
														placeholder="Website"
														onChange={this.onChange} required />
												</FormGroup>
											</Col>
										</Row>
										<Col lg={12} md={12} sm={12} xs={12}>
											<Row className="spinnerfix">
												{/* <Loader
         type="Puff"
         color="#00BFFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
         visible={this.state.loading}
      />  */}
											</Row>
											{/* <Row className="creatreproperties">
            <Button className="btnbtncreateproperties" onClick={this.addproperty}>Add property</Button>  
            </Row> */}
										</Col>

									</ModalBody>
								</Form>
							</Col>

							<Col className="preview-add-propert" lg={6} md={6} sm={12} xs={12}>
								<h1 className="heading-property-pre">Ad Preview</h1>
								<Form>
									<ModalBody>
										<Row className="imgpreview">
										</Row>
										{/* {(this.state.propertyImages && this.state.propertyImages.length >= 0) && (
            <Col className="slider11" lg={12} md={12} md={12} sm={12}>
                    <Row>
            <Carousel autoPlay interval={3000} infiniteLoop showThumbs={false} showStatus={true}>
            {this.state.propertyImages.length >= 0  && this.state.propertyImages.map(image => {
                    let images = 'https://realreach.s3.eu-north-1.amazonaws.com/'+image
                    return(
                      <div>
                      <img className="imgimg" src={images} />
                  </div>
            )})}
            </Carousel>
            </Row>
            </Col>
            )} */}

										{/* {(this.state.propertyImages && this.state.propertyImages.length <= 0) && ( */}
										<Col className="slider11" lg={12} md={12} md={12} sm={12}>
											<Row>
												<Carousel autoPlay interval={3000} infiniteLoop showThumbs={false} showStatus={true}>
													<div>
														<img className="imgimg" src={Placeholderimage} />
													</div>
												</Carousel>
											</Row>
										</Col>
										{/* )} */}
										<Row className="headingpreview">
											<h3>{this.state.name}</h3>
										</Row>
										<Row className="websitepreview">
											<h5>{this.state.description}</h5>
										</Row>
									</ModalBody>
								</Form>
							</Col>
							<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }} >
								<Row>
									<Col lg={6} md={6} sm={6} xs={6}>
										<Row className="leftarrownew">
											<Link to={{
												pathname: "/campaign-type",
												state: {
													campaignType: this.state.campaignType,
													userinfo: this.state.userinfo
												}
											}}>
												<Button className="btnedit">Back</Button>
											</Link>
										</Row>
									</Col>

									<Col lg={6} md={6} sm={6} xs={6}>
										<Row className="nextbtn1">
											{/* <Link to={{
                  pathname: "/create-add",
                  state: {
                    userinfo: this.state.userinfo,
                    campaignType: this.state.campaignType,
                    name: this.state.name,
                    region: this.state.region,
                    description: this.state.description,
                    website: this.state.website,
                    propertyImages: this.state.propertyImages
                  }
                }}> */}
											<Button className="btnapprove" onClick={this.addproperty}>Next</Button>
											{/* </Link> */}
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</LoadingOverlay>
			</div>
		)
	}
}
export default (Addpropert);