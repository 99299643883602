import React from 'react'
import axios from 'axios';
import Login from "../Login/Login";
import { API_BASE } from '../../config'

const auth = new Login();

let instance = axios.create({
    baseURL: API_BASE + 'payment/',
    headers: { 'Authorization': `Bearer ${auth.getToken()}` }
});

class PaymentService extends React.Component {

    addCard(data) {
        return instance.post('', data)
    }

    charge(amount) {
        return instance.post('charge', amount)
    }
    checkout(data) {
        return instance.post('checkout', data)
    }

    addPaymentMethod(data) {
        return instance.post('paymentmethod', data)
    }
    deleteCard(id) {
        return instance.delete('/' + id)
    }
    deleteStripe(data){
        return instance.post('/deleteStripe' , data)
    }
}
export default PaymentService;
