import React, { Component, useState } from 'react';
import './Style.css';
import { Row, Col, Input, Button, FormGroup, Form, ModalBody } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header/index.js';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import swal from 'sweetalert';
// import Spinner from 'react-spinner-material';
import Apiservices from '../Apiservices/Urlservice';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import styled from 'styled-components';
import Login from '../Login/Login';
const urlpost = new Apiservices();

const auth = new Login();

const StyledLoader = styled(LoadingOverlay)`
  width: 100%;
  height: auto;
  overflow: hidden;
  .MyLoader_overlay {
    background: rgba(255, 0, 0, 0.5);
  }
  &.MyLoader_wrapper--active {
    overflow: hidden;
  }
`
class Agentad extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userinfo: this.props.location.state.userinfo,
			campaignType: this.props.location.state.campaignType,
			name: "",
			email: "",
			phoneNumber: "",
			agentImages: [],
			message: "",
			website: "",
			agentdetails: {},
			redirect: false,
			alert: false,
			invalid: '',
			isLoaded: true,
			loading: false,
			active: false
		};
		this.onChange = this.onChange.bind(this);
		this.onChangeFile = this.onChangeFile.bind(this);
		this.addagent = this.addagent.bind(this);
		console.log("agentuserinfo", this.state.userinfo)
	}

	onChange(e) {
		console.log("value", e.target.value)
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeFile(e) {
		console.log("imagefile", e.target.files)
		this.setState({ [e.target.name]: e.target.files });
	}

	addagent = () => {
		if (this.state.name && this.state.email && this.state.phoneNumber
			&& this.state.agentImages && this.state.message && this.state.website)
			this.setState({ active: true });
		{
			let formData = new FormData();

			for (const agentImages of this.state.agentImages) {
				formData.append('agentImages', agentImages);
			}
			formData.append('name', this.state.name);
			formData.append('email', this.state.email);
			formData.append('phoneNumber', this.state.phoneNumber);
			formData.append('message', this.state.message);
			formData.append('website', this.state.website);

			urlpost
				.createagent(formData)
				.then(result => {
					let responseJson = result.data;
					if (responseJson) {
						this.setState({ agentdetails: responseJson });
						this.setState({ agentImages: responseJson.agentImages });
						console.log("response", responseJson)
						this.setState({ redirect: true });
						this.setState({ alert: true });
						this.setState({ loading: false });
						this.setState({ active: false });
					}
				})
				.catch(err => {
					this.setState({ alert: true });
					this.setState({ active: false });
					// this.setState({ invalid: err.response.data.error.message });
					swal("Oops", err.response.data.error.message, "error");
				});
		}
	};
	render() {
		if (this.state.redirect == true) {
			return (
				<Redirect to={{
					pathname: "/create-add",
					state: {
						campaignType: this.state.campaignType,
						userinfo: this.state.userinfo,
						agentdetails: this.state.agentdetails,
						name: this.state.name,
						email: this.state.email,
						phoneNumber: this.state.phoneNumber,
						agentImages: this.state.agentImages,
						message: this.state.message,
						website: this.state.website,
					}
				}} />
			)
		}
		return (
			<div className="custom-container">
				<StyledLoader
					active={false}
					// spinner
					// text='Loading...'
					spinner={<BounceLoader />}
				>
					<Header />
					<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12} >
						<Row className="topheadernew2">
						</Row>
					</Col>
					<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12}>
						<Row>
							<Col className="margins-add" lg={5} md={5} sm={12} xs={12}>
								<h1 className="heading-agent-ad">Add listings to include in your ad:</h1>
								<Form>
									<ModalBody>
										<Row className="checkboxess">
											<Col lg={{ size: 10, leftset: 1 }} md={{ size: 10, leftset: 1 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
												<label className="container2">
													<input
														type="checkbox"
														name=""
														onChange={this.toggleChangeRecurring}></input>Tomtebogatan 34
                                <span className="checkmark2"></span>
												</label>
											</Col>
										</Row>
										<Row className="checkboxess">
											<Col lg={{ size: 10, leftset: 1 }} md={{ size: 10, leftset: 1 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
												<label className="container2">
													<input
														type="checkbox"
														name=""
														onChange={this.toggleChangeRecurring}></input>Wallmogatan 8
                                <span className="checkmark2"></span>
												</label>
											</Col>
										</Row>
										<Row className="checkboxess">
											<Col lg={{ size: 10, leftset: 1 }} md={{ size: 10, leftset: 1 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
												<label className="container2">
													<input
														type="checkbox"
														name=""
														onChange={this.toggleChangeRecurring}></input>Tomtebogatan 34
                                <span className="checkmark2"></span>
												</label>
											</Col>
										</Row>
										<Row className="checkboxess">
											<Col lg={{ size: 10, leftset: 1 }} md={{ size: 10, leftset: 1 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
												<label className="container2">
													<input
														type="checkbox"
														name=""
														onChange={this.toggleChangeRecurring}></input>Wallmogatan 8
                                <span className="checkmark2"></span>
												</label>
											</Col>
										</Row>
										<Row className="checkboxess">
											<Col lg={{ size: 10, leftset: 1 }} md={{ size: 10, leftset: 1 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
												<label className="container2">
													<input
														type="checkbox"
														name=""
														onChange={this.toggleChangeRecurring}></input>Tomtebogatan 34
                                <span className="checkmark2"></span>
												</label>
											</Col>
										</Row>
										<Row className="checkboxess">
											<Col lg={{ size: 10, leftset: 1 }} md={{ size: 10, leftset: 1 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
												<label className="container2">
													<input
														type="checkbox"
														name=""
														onChange={this.toggleChangeRecurring}></input>Wallmogatan 8
                                <span className="checkmark2"></span>
												</label>
											</Col>
										</Row>
										<Row className="checkboxess">
											<Col lg={{ size: 10, leftset: 1 }} md={{ size: 10, leftset: 1 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
												<label className="container2">
													<input
														type="checkbox"
														name=""
														onChange={this.toggleChangeRecurring}></input>Tomtebogatan 34
                                <span className="checkmark2"></span>
												</label>
											</Col>
										</Row>
										<Row className="checkboxess">
											<Col lg={{ size: 10, leftset: 1 }} md={{ size: 10, leftset: 1 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
												<label className="container2">
													<input
														type="checkbox"
														name=""
														onChange={this.toggleChangeRecurring}></input>Wallmogatan 8
                                <span className="checkmark2"></span>
												</label>
											</Col>
										</Row>
										<Row className="checkboxess">
											<Col lg={{ size: 10, leftset: 1 }} md={{ size: 10, leftset: 1 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
												<label className="container2">
													<input
														type="checkbox"
														name=""
														onChange={this.toggleChangeRecurring}></input>Tomtebogatan 34
                                <span className="checkmark2"></span>
												</label>
											</Col>
										</Row>
										<Row className="checkboxess">
											<Col lg={{ size: 10, leftset: 1 }} md={{ size: 10, leftset: 1 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
												<label className="container2">
													<input
														type="checkbox"
														name=""
														onChange={this.toggleChangeRecurring}></input>Wallmogatan 8
                                <span className="checkmark2"></span>
												</label>
											</Col>
										</Row>
										<Row className="checkboxess">
											<Col lg={{ size: 10, leftset: 1 }} md={{ size: 10, leftset: 1 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
												<label className="container2">
													<input
														type="checkbox"
														name=""
														onChange={this.toggleChangeRecurring}></input>Tomtebogatan 34
                                <span className="checkmark2"></span>
												</label>
											</Col>
										</Row>
										<Row className="checkboxess">
											<Col lg={{ size: 10, leftset: 1 }} md={{ size: 10, leftset: 1 }} sm={{ size: 10, leftset: 1 }} xs={{ size: 10, leftset: 1 }}>
												<label className="container2">
													<input
														type="checkbox"
														name=""
														onChange={this.toggleChangeRecurring}></input>Wallmogatan 8
                                <span className="checkmark2"></span>
												</label>
											</Col>
										</Row>
									</ModalBody>
								</Form>
							</Col>

							<Col className="preview-add" lg={6} md={6} sm={12} xs={12}>
								<h1 className="heading-agent-ad">Add your personal information:</h1>
								<Form>
									<ModalBody>
										<Row className="namefields">
											<label className="labelproperty">Name</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="text"
														name="name"
														placeholder="Name"
														onChange={this.onChange}
														required />
												</FormGroup>
											</Col>
										</Row>
										<Row className="namefields">
											<label className="labelproperty">E-mail</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="text"
														name="email"
														placeholder="E-mail"
														onChange={this.onChange}
														required />
												</FormGroup>
											</Col>
										</Row>
										<Row className="namefields">
											<label className="labelproperty">Website</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="text"
														name="website"
														placeholder="Website"
														onChange={this.onChange}
														required />
												</FormGroup>
											</Col>
										</Row>
										<Row className="namefields">
											<label className="labelproperty">Phone</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="number"
														name="phoneNumber"
														placeholder="Phone"
														onChange={this.onChange}
														required />
												</FormGroup>
											</Col>
										</Row>
										<Row className="namefields">
											<label className="labelproperty">Message</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="textarea"
														name="message"
														placeholder="Message"
														onChange={this.onChange}
														required />
												</FormGroup>
											</Col>
										</Row>
										<Row className="namefields">
											<label className="labelproperty">Select pictures</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="file"
														name="agentImages"
														onChange={this.onChangeFile} multiple />
												</FormGroup>
											</Col>
										</Row>
									</ModalBody>
								</Form>
							</Col>
						</Row>
					</Col>
					{/* <Col lg={12} md={12} sm={12} xs={12}>
            <Row className="createagentbtn">
                <Button className="btnbtncreateagentad">Submit</Button>
            </Row>
            </Col> */}
					<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 0 }} sm={12} xs={12} >
						<Row>
							<Col lg={6} md={6} sm={6} xs={6}>
								<Row className="leftarrownew">
									<Link to={{
										pathname: "/campaign-type",
										state: {
											campaignType: this.state.campaignType,
											userinfo: this.state.userinfo
										}

									}}>
										<Button className="btnedit">Back</Button>
									</Link>
								</Row>
							</Col>

							<Col lg={6} md={6} sm={6} xs={6}>
								<Row className="nextbtn1">
									{console.log("campaigntype", this.state.campaignType)}
									{/* <Link to={{
                  pathname: "/create-add",
                  state: {
                      campaignType: this.state.campaignType,
                      userinfo: this.state.userinfo,
                      name: this.state.name,
                      email: this.state.email,
                      message: this.state.message,
                      agentImages: this.state.agentImages,
                      phoneNumber: this.state.phoneNumber
                  }
                }}> */}
									<Button className="btnapprove" onClick={this.addagent}>Next</Button>
									{/* </Link> */}
								</Row>
							</Col>
						</Row>
					</Col>
				</StyledLoader>
			</div>
		);
	}
}

export default Agentad;
