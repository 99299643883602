import React, { Component } from 'react';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Form, Input, Row, Nav, NavItem,toggle,buttonLabel } from 'reactstrap';
import swal from 'sweetalert';
import './landingPage.css';
import './Custom_LandingPage.css';
import { Link, Redirect } from 'react-router-dom';
import Mobile from "../../images/BrightBee_FB-Ad.png";
import BackgroundComputer from "../../images/background-computer.png";
import LandingPageHeader from "../Header/LandingPageHeader";
import AddModal from "../Modals/Modals";
import AddModal2 from "../Modals2/Modals2";
import NewHeader from "../Header/NewHeader";

import bb1 from "../../images/BrightBee_Hemnet-Logo.png";
import bb2 from "../../images/BrightBee_Mäklarhuset-Logo.png";
import bb3 from "../../images/BrightBee_Notar-Logo.png";
import bb4 from "../../images/BrightBee_ErikOlsson-Logo.png";
import bb5 from "../../images/BrightBee_SkandiaMäklarna-Logo.png";
import BrightBee_FullyAutomated from "../../images/BrightBee_FullyAutomated@3x.png";
import bb6 from "../../images/BrightBee_Platform-Screens-1.png";
import bb7 from "../../images/BrightBee_Platform-Screens-2.png";
import bb8 from "../../images/BrightBee_Platform-Screens-3.png";
import imagegirl from "../../images/imagegirl.png";
import bb10 from "../../images/BrightBee_AI-Icon.png";
import bb11 from "../../images/BrightBee_Dashboard-Icon.png";
import bb12 from "../../images/BrightBee_RightType-Icon.png";
import stats from "../../images/stats.png";
import bb14 from "../../images/BrightBee_Small-Icon.png";
import paybyadd from "../../images/paybyadd.png";
import fixedperoffice from "../../images/fixedperoffice.png";
import bb17 from "../../images/BrightBee_RadioButtons.png";
import Footer from '../Footer/Footer';
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Player from "./reactPlayer";



class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            alert: false,
            invalid: '',
            role: '',
            loader: false,
            openVideo: false

        };
        this.scrollTomid = this.scrollTomid.bind(this);
    }

    scrollTomid() {
        scroll.scrollTo(870);
    }

    playVideo = () => {
    this.setState(prevState => ({
      openVideo: !prevState.openVideo
    }));
  };

    render() {
        return (
            <div id="wrapped">
                <div className="part1">
                    <LandingPageHeader />
                    <div className="part2">
                    <Row>
                        <Col lg={{ size: 4, offset: 2 }} md={{ size: 4, offset: 2 }} sm={{ size: 8, offset: 2}} xs={{ size: 8, offset: 2 }} >
                            <img src={Mobile} className="mobileimage"></img>
                        </Col>
                        <Col lg={{ size: 6, offset: 0}} md={{ size: 6, offset: 0 }} sm={{ size: 9, offset: 3 }} xs={{ size: 9, offset: 3 }}>
                            <div className="lessworkTextMain">
                                <p className="lessworkText">Less work.</p>
                                <p className="lessworkText">Better ads.</p>
                                <p className="lessworkText">Faster sales.</p>
                                <Link to={{
                                    // pathname: "/all-campaigns",
                                    // state: {
                                    // userProfile: this.state.userProfile
                                    // }
                                }}>
                                    <Button
                                        type="submit"
                                        className="readmore1"
                                        onClick={this.playVideo}>
                                        Watch Video
                                    </Button>
                                    <Player open={this.state.openVideo} toggleModal={this.playVideo} />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ size: 6, offset: 0, order:1 }} md={{ size: 6, offset: 0, order:1 }} sm={{ size: 12, offset: 0, order: 2 }} xs={{ size: 12, offset: 0, order: 2}} >
                            <Row className="realestate">
                                <p>Real estate marketing with the right results.</p>
                            </Row>
                            <Row className="text1">
                                <p>We trace your customers using our advanced Bright Targeting&#8482; system. To ensure that your ads will reach the right audience at the right time.</p>
                            </Row>
                            <Row className="readmorebutton2">
                                <Link to={{
                                    //  pathname: "/Modals",
                                    // state: {
                                    // userProfile: this.state.userProfile
                                    // }
                                }}>
                                     <AddModal/>
                                </Link>
                            </Row>
                        </Col>

                        <Col lg={{ size: 5, offset: 1, order: 2 }} md={{ size: 5, offset: 1, order: 2 }} sm={{ size: 12, offset: 0, order:1 }} xs={{ size: 12, offset: 0, order:1 }}  >
                                <img src={imagegirl} className="imgstyles5"></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ size: 6, offset: 0 }} md={{ size: 6, offset: 0 }} sm={12} xs={{ size: 12, offset: 0 }} >
                            <Row className="trusted">
                                {/* <Col lg={{ size: 4, offset: 0 }} md={{ size: 4, offset: 0 }} sm={4} xs={4}  style={{padding:0}}> */}
                                    <img  src={BrightBee_FullyAutomated} height="100%" width="100%"  ></img>
                                {/* </Col >
                                <Col lg={{ size: 4, offset: 0 }} md={{ size: 4, offset: 0 }} sm={4} xs={4}  style={{padding:0}}>
                                    <img src={bb6} height="auto" width="100%" ></img>
                                </Col >
                                <Col lg={{ size: 4, offset: 0 }} md={{ size: 4, offset: 0 }} sm={4} xs={4}  style={{padding:0}}>
                                    <img src={bb8} height="auto" width="100%" ></img>
                                </Col > */}
                            </Row>
                        </Col>
                        <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={{ size: 12, offset: 0 }} xs={{ size: 12, offset: 0 }}  >
                            <Row className="text4">
                                <p>Fully automated.</p>
                            </Row>
                            <Row className="text6">
                                <p>Use our 5-click platform or simply integrate your website to create your ads automatically.</p>
                            </Row>
                            <Row className="readmorebutton">
                                <AddModal2/>
                            </Row>
                        </Col>
                    </Row>
                   </div>
                </div>
                <Row className="background2">
                    <Col lg={{ size: 4, offset: 0 }} md={{ size: 4, offset: 0}} sm={{ size: 12, offset: 0}} xs={{ size: 12, offset: 0 }} style={{padding: '5%'}}>
                            <Row className="imagestyle6">
                                <img src={bb10} max-width="25%" height="auto"></img>
                            </Row>
                            <Row className="text8">
                                <p>AI targeting</p>
                            </Row>
                            <Row className="text9">
                                <p>AI identifies your audience and matches your ads with the right crowd.</p>
                            </Row>
                        </Col >
                        <Col lg={{ size: 4, offset: 0 }} md={{ size: 4, offset: 0 }} sm={{ size: 12, offset: 0}} xs={{ size: 12, offset: 0 }} style={{padding: '5%'}}>
                            <Row className="imagestyle6">
                                <img src={bb11} max-width="25%" height="auto"></img>
                            </Row>
                            <Row className="text8">
                                <p>Personal Dasboard</p>
                            </Row>
                            <Row className="text9">
                                <p>A personal dashboard with an overview over your campaigns, budget and interactions.</p>
                            </Row>
                        </Col >
                        <Col lg={{ size: 4, offset: 0}} md={{ size: 4, offset: 0 }} sm={{ size: 12, offset: 0}} xs={{ size: 12, offset: 0 }} style={{padding: '5%'}}>
                            <Row className="imagestyle6">
                                <img src={bb12} max-width="25%" height="auto"></img>
                            </Row>
                            <Row className="text8">
                                <p>The right type</p>
                            </Row>
                            <Row className="text9">
                                <p>Choose between our different ad types. Create ads for your Agency, Agent or Listing.</p>
                            </Row>
                        </Col >
                    </Row>
                <Row className="section6">
                        <Col >
                            <Row className="text11">
                                <p>Analytics in real time.</p>
                            </Row>
                            <Row className="text12">
                                <p>Track trends and conversions in no time.</p>
                            </Row>
                            <Row className="imagestyle7">
                                <img className="img7" src={stats} ></img>
                            </Row>
                        </Col>
                    </Row>
                <Row className="section7">
                    <Row className="text13">
                        <p>Best of all. Flexible subscriptions.</p>
                    </Row>
                    <Row style={{width: '100%', margin: 0}}>
							             <Col lg={{ size: 4, offset: 2 }} md={{ size: 4, offset: 2 }} sm={{ size: 6, offset: 0 }} xs={{ size: 6, offset: 0 }} >
                                <Row className="imagestyle8">
                                    <img src={paybyadd} maw-width="25%" height="auto"></img>
                                </Row>
                                <Row className="text111">
                                    <p>Pay by ad</p>
                                </Row>
                                <Row className="text9s">
                                    <p>Only pay for what you really need. Simple as that.</p>
                                </Row>
                            </Col >
                            <Col lg={{ size: 4, offset: 0 }} md={{ size: 4, offset: 0 }} sm={{ size: 6, offset: 0 }} xs={{ size: 6, offset: 0 }} >
                                <Row className="imagestyle8">
                                    <img src={fixedperoffice} maw-width="25%" height="auto"></img>
                                </Row>
                                <Row className="text111">
                                    <p>Fixed per office</p>
                                </Row>
                                <Row className="text9s">
                                        <p>A great option for multi-office agencies.</p>
                                </Row>
                            </Col >
                    </Row>
                </Row>
                <div  className="bfooter" >
                    <Link to={{
                        pathname: "/signup",
                    }}>
                        <button className="" style={{
                            background: '#75c9cd',
                            fontSize: 15,
                            border: '1px solid #75c9cd',
                            width: 102,
                            height: 46,
                            borderRadius: 5,
                            fontFamily: 'BuenosAiresTrial'
                        }}>Sign up</button>
                    </Link>
                </div>
                <Footer backHidden={true} />
            </div>
        );
    }
}

export default LandingPage;
// test
