
import React from 'react'
import axios from 'axios';
import { API_BASE } from '../../config'
import Login from "../Login/Login";
import App from '../../App';

const auth = new Login();

let instance = axios.create({
    baseURL: API_BASE + 'user',
    headers: {
        'Authorization': `Bearer ${auth.getToken()}`
        // 'Authorization': `Bearer ${'eyJhbGciOiJIUzUxMiJ9.eyJmaXJzdE5hbWUiOiJFbndhbGwxIiwibGFzdE5hbWUiOiJDZWNpbGlhMSIsInN1YiI6ImFkbWluMiIsInJvbGUiOiJST0xFX0FETUlOIiwiZ2VuZGVyIjoiIiwicGhvbmUiOiIiLCJpc3MiOiJkb3RlbGxfYmFja2VuZCIsImV4cCI6MTU1NjYyNDQxOSwiaWF0IjoxNTU2MDE5NjE5fQ.wm1nR0ORwALuBIemLOMM6hzkfaA9wEqKYQFJeoFFDJVAEGFR_PmlIr0I7hJrLZRf5zwKU_puyq6iexe6X37SVg'}`

    }
});

class UserService extends React.Component {
    getAuthUserProfile() {
        let userId = auth.getId()
        return instance.get('/' + userId)
    }

    updateUser(id, data) {
        return instance.put('/' + id, data)
    }

    getUserProfile(id) {
        return instance.get('/' + id)
    }

    inviteAgent(data) {
        return instance.post('/agent', data)
    }

    getUsers() {
        return instance.get('/agent')
    }

    deleteAgent(id) {
        return instance.delete('/' + id)
    }
}
export default UserService;
