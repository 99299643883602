import React, { Component } from 'react';
import './Style.css';
import {
	Row,
	Col,
	Card,
	CardImg,
	Input
} from 'reactstrap';
import { Link } from 'react-router-dom';
// import Left from '../../images/leftarrow.png'
// import Right from '../../images/rightarrow.png'
import House from '../../images/house.jpg';
import Bg from '../../images/bg.png';
import Header from '../Header';
class Annonsinfo extends Component {
	constructor(props) {
		super(props);
	}
	render() {

		return (
			<div>
				<Header />
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheader1">
						<p className="compname">campaign name here</p>
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheader2">
						{/* <Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                <Row className="leftarrow">
                    <img src={Left} alt="" height="33px"></img>
                </Row>
              </Col> */}
						<Col lg={12} md={12} sm={12} xs={12}><Row className="centertext">Kampanjer</Row></Col>
						{/* <Col  lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                  <Row className="rightarrow"><img src={Right} alt="rightarrow" height="20px"></img></Row>
              </Col> */}
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12}>
					<Row>
						<Card id="overviewtopp" >
							<a href="">
								<CardImg className="overviewimage" src={House} alt="Card image cap" />
							</a>
							{/* <CardBody className="Card_Bodyactive" style={{padding:2}}>
                <h5>Hemnet</h5>
                <p>0 aktiva kampanjer</p> 
              </CardBody> */}
						</Card>
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12}>
					<Row className="overviewlinks">
						<Col lg={4} md={4} sm={4} xs={4}>
							<Link to="/overview">
								<Row className="overview11">
									<p className="part1">INFO</p>
								</Row>
							</Link>
						</Col>
						<Col lg={4} md={4} sm={4} xs={4}>
							<Link to="/info-annons">
								<Row className="overview22">
									<p className="part2">ANNONS</p>
								</Row>
							</Link>
						</Col>
						<Col lg={4} md={4} sm={4} xs={4}>
							<Link to="/statistics">
								<Row className="overview33">
									<p className="part2">STATISTIK</p>
								</Row>
							</Link>
						</Col>
						<Col className="colborder11" lg={{ size: 8, offset: 2 }} md={8} sm={10} xs={12}>
							<Row className="overview55">
								<img className="annonsimage" src={Bg} alt=""></img>
							</Row>
						</Col>

						<Col lg={12} md={12} sm={12} xs={12}>
							<Row className="annonsinfoo22">
								<Row className="annonsinfolabel">
									<p className="label1"> Intressenter</p>
								</Row>
								<Row className="annonsinfoo">
									<Input
										type="text"
										placeholder="some data here"
										id="annonsinfoo"
									>
									</Input>
								</Row>
								<Row className="annonsinfolabel">
									<p className="label1"> Regioner</p>
								</Row>
								<Row className="annonsinfoo">
									<Input
										type="text"
										placeholder="some data here"
										id="annonsinfoo"
									>
									</Input>
								</Row>
								<Row className="annonsinfolabel">
									<p className="label1"> Hushåll</p>
								</Row>
								<Row className="annonsinfoo">
									<Input
										type="text"
										placeholder="some data here"
										id="annonsinfoo"
									>
									</Input>
								</Row>
								<Row className="annonsinfolabel">
									<p className="label1"> Barn hemma </p>
								</Row>
								<Row className="annonsinfoo">
									<Input
										type="text"
										placeholder="some data here"
										id="annonsinfoo"
									>
									</Input>
								</Row>
								<Row className="annonsinfolabel">
									<p className="label1"> Åldersgrupp</p>
								</Row>
								<Row className="annonsinfoo">
									<Input
										type="text"
										placeholder="some data here"
										id="annonsinfoo"
									>
									</Input>
								</Row>
							</Row>
						</Col>
					</Row>
				</Col>

			</div>
		);
	}
}

export default Annonsinfo;
