import React, { Component } from 'react';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Form, Input, Row } from 'reactstrap';
import swal from 'sweetalert';
import './Footer.css';
import { Link, Redirect } from 'react-router-dom';
import fb from "../../images/BrightBee_Facebook-Icon.png";
import insta from "../../images/BrightBee_Instagram-Icon.png";
import linkdin from "../../images/linkdin.png";
import google from "../../images/google.png";
import alogo from "../../images/alogo.png";


class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false
        };
    }

    render() {
        return (

            <div className="footermainss">
                <Col lg={12} md={12} sm={12} xs={12} style={{ padding: 0 }}  >
                
                    <Row className="mainrowss">
                    <Col lg={4} md={4} sm={4} xs={4} style={{ padding: 0 }}  >
                        <Row className="foot1">
                        <Col lg={{ size: 6, offset: 0}} md={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} xs={{ size: 12, offset: 0 }}>
                            <Row className="brightbeetitle">
                                <p>BrightBee</p>
                            </Row>
                            <Row className="emailcol1">
                                <p>hi@brightbee.co</p>
                            </Row>
                            <Col lg={10} md={12} sm={12} xs={12} style={{ padding: 0 }}  >
                            <Row className="socialfbicon">
                                 
                                <Col lg={{ size: 2, offset: 0}} md={{ size: 2, offset: 0}}sm={{ size: 4, offset: 0}} xs={{ size: 4, offset: 0}} style={{ padding: 0 }}>
                                  <a href="https://www.facebook.com/BrighbeeMarketing/">
                                        <img className="fbicon" src={fb} width="100%" height="auto"></img>
                                        </a>
                                </Col>
                                <Col lg={{ size: 2, offset: 1}} md={{ size: 2, offset: 1}}sm={{ size: 4, offset: 2}} xs={{ size: 4, offset: 2}} style={{ padding: 0 }}>
                                <a href="https://www.instagram.com/brightbee_official">
                                    <img src={insta} width="100%" height="auto"></img>
                                   </a>
                                </Col>
                                <Col lg={{ size: 2, offset: 1}} md={{ size: 2, offset: 1}} sm={{ size: 4, offset: 0}} xs={{ size: 4, offset: 0}} style={{ padding: 0 }}>
                                <a href=" https://www.linkedin.com/company/31461470/">
                                    <img src={linkdin} width="100%" height="auto"></img>
                                   </a>
                                </Col>
                                <Col lg={{ size: 2, offset: 1}} md={{ size: 2, offset: 1}}  sm={{ size: 4, offset: 2}} xs={{ size: 4, offset: 2}} style={{ padding: 0 }}>
                                    <img src={google} width="100%" height="auto"></img>
                                </Col>
                            </Row>
                            </Col>
                        </Col>
                        </Row>
                        </Col>
                        <Col lg={8} md={8} sm={8} xs={8} style={{ padding: 0 }}  >
                        <Row className="foot2">
                        <Col lg={{ size: 6, offset:0 ,order:1  }} md={{ size: 6, offset: 6,order:2 }} sm={{ size: 6, offset: 6, order:2}} xs={{ size: 6, offset:6 , order:2 }} style={{ padding: 0 }}   >
                            <Row className="foot2a">
                            </Row>
                        </Col>

                        <Col lg={{ size: 3, offset:3, order:2 }} md={{ size: 6, offset: 6 ,order:1}} sm={{ size: 6, offset: 6, order:1 }} xs={{  size: 6, offset: 6, order:1}}style={{ padding: 0 }} >
                        <Row className="foot2b">
                      
                                       
                        <Col lg={{ size: 8, offset:4 }} md={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} xs={{ size: 12, offset: 0 }}style={{ padding: 0 }} >
                                  <Row className="topheading1">
                                    <p>STOCKHOLM<br></br>
                                
                                
                                    BrightBee<br></br>
                              
                                    Slöjdgatan 9<br></br>
                               
                                    111 57 Stockholm</p>
                                </Row> 
                                </Col>
                            </Row> 
                        
                        </Col>

                        
                    
</Row>
</Col>
</Row>
                    <Row className="section200">

                        <p className="text4f">
                            Copyright 2024, BrightBee. All rights reserved. Slöjdgatan 9, 111 57 Stockholm
                        </p>

                    </Row>

                </Col>
            </div>
        );
    }
}

export default Footer;
// test