import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Input, Label, Row, Form } from 'reactstrap';
import swal from 'sweetalert';
import ListingAdServices from '../Apiservices/ListingAdServices';
import Header from '../Header/NewHeader';
import MobileAdPreview from '../MobileAdPreview';
import './CreateAdNew.css';
import './CreateAdNew_Custom.css';
import { IMG_URL } from '../../config'
import Login from "../Login/Login";
import CreatableSelect from 'react-select/creatable';
import Location from '../Apiservices/GetUserLocationFromIp';
import UserServices from '../Apiservices/UserService';
import './Custom_CreateAfterNote.css';
const location = new Location();
const userService = new UserServices();
var createOptionForHeadline = (label) => (
    {
        label,
        value: label,
    });

var createOptionForDescription = (label) => ({
    label,
    value: label,
});

var headlineOptions = [
    //createOptionForHeadline("Book a free property valuation"),
    //createOptionForHeadline("Sell your property quickly with us"),
    //createOptionForHeadline("Newly listed today!"),
    //createOptionForHeadline("Find out the value of your home"),

];

var descriptionOptions = [
    //createOptionForDescription("Contact us and let us help you find your perfect home."),
    //createOptionForDescription("Free valuation by an expert local agent."),
    //createOptionForDescription("Book a free property valuation today."),
]
var descriptionValue = null
var headlineValue = null
const auth = new Login();
const listingAdServices = new ListingAdServices();
let getrole = localStorage.getItem("currentRole");
class CreateAdNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // mediaType: "dummy",
            mediaType: props.location.state.mediaType ? props.location.state.mediaType: "image",
            previousPath: props.location.state.previousPath,
            userProfile: props.location.state.userProfile,
            campaignType: props.location.state.campaignType ? props.location.state.campaignType : 'agent',
            redirect: false,
            alert: false,
            testlocation: "",
            // headline: props.location.state.reverseToEdit ? props.location.state.listingData.headline : props.location.state.campaignType == "listing" ? "10 000 kr" : props.location.state.userProfile.name,
            // headline: props.location.state.reverseToEdit ? props.location.state.listingData.headline : props.location.state.campaignType == "listing" ? "10 000 kr" : "",

            message: props.location.state.reverseToEdit ? props.location.state.listingData.message : props.location.state.campaignType == "listing" ? "Property for sale around" : props.location.state.userProfile.description,
            message_english: "We find buyers and sellers quicker with our targeted advertising on social media - Contact us for more information!",
            // description: props.location.state.reverseToEdit ? props.location.state.listingData.description : props.location.state.campaignType == "listing" ? "Best property" : props.location.state.userProfile.phoneNumber,
            // description: props.location.state.reverseToEdit ? props.location.state.listingData.description : props.location.state.campaignType == "listing" ? "Best property" : "",

            businessName: "",
            website: props.location.state.reverseToEdit ? props.location.state.listingData.website : "http://",
            listingAdImages: props.location.state.reverseToEdit ? props.location.state.listingData.listingAdImages : props.location.state.campaignType == "listing" ? [] : [this.props.location.state.userProfile.userImage],
            listingAdVideos: props.location.state.reverseToEdit ? props.location.state.listingData.listingAdVideos : props.location.state.campaignType == "listing" ? [] : [this.props.location.state.userProfile.userImage],
            thumbnailImage: this.props.location.state.userProfile.userImage,
            region: 'other',
            listingData: {},
            loader: false,
            previewImage: props.location.state.reverseToEdit ? IMG_URL + props.location.state.listingData.listingAdImages[0] : props.location.state.campaignType == "listing" ? "" : this.props.location.state.userProfile.userImage.startsWith("https://") ? this.props.location.state.userProfile.userImage: IMG_URL +  this.props.location.state.userProfile.userImage,
            previewVideo: props.location.state.reverseToEdit ? IMG_URL + props.location.state.listingData.listingAdVideos[0] : props.location.state.campaignType == "listing" ? "" : this.props.location.state.userProfile.userImage.startsWith("https://") ? this.props.location.state.userProfile.userImage: IMG_URL +  this.props.location.state.userProfile.userImage,

            descriptionValue: props.location.state.reverseToEdit ? descriptionValue : undefined,
            headlineValue: props.location.state.reverseToEdit ? props.location.state.listingData.headlineValue : undefined,
            options1: headlineOptions,
            options2: descriptionOptions,
            headline: props.location.state.reverseToEdit ? props.location.state.listingData.headline : "",
            description: props.location.state.reverseToEdit ? props.location.state.listingData.description : "",
            isLoadingForHeadline: false,
            isLoadingForDescription: false,
            pathUrl: this.props.location.state.pathUrl ? this.props.location.state.pathUrl : "",
            isOpenMenuHeadline: false,
            isOpenMenuDescription: false,
            custom_headline: props.location.state.reverseToEdit ? props.location.state.listingData.headline : "",
            custom_description: props.location.state.reverseToEdit ? props.location.state.listingData.description : "",
            agentImage: false,
            users: [],
            agentImage: "",
            videoUpdated: false,
            imageUpdated: false,
            carouselAd: false,
            carouselAdImages: []

        };
        console.log(this.state.previewImage, 'previewImage');
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onChangeListingImage = (e) => {

        const imageFile = e.target.files[0];
        console.log("imagevalue " + imageFile);
        if (imageFile.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
            // if (this.state.carouselAd === true) {
            //     this.setState({ mediaType: "carouselImages" });
            //     this.setState({ carouselAdImages: [... this.state.carouselAdImages, e.target.files] })
            // } else {
            if (this.props.location.state.reverseToEdit === true) {
                this.setState({ imageUpdated: true, videoUpdated: false });
            }
            this.setState({ mediaType: "image" });
            this.setState({ listingAdImages: e.target.files, previewImage: URL.createObjectURL(e.target.files[0]) });
            // }
        }
        else {
            if (this.props.location.state.reverseToEdit === true) {
                this.setState({ videoUpdated: true, imageUpdated: false });
            }
            this.setState({ mediaType: "video" });
            this.setState({ listingAdVideos: e.target.files, previewVideo: URL.createObjectURL(e.target.files[0]) });
        }
    }

    openMenuHeadline = () => {
        this.setState({ isOpenMenuHeadline: true })
    };

    closeMenuHeadline = () => {
        this.setState({ isOpenMenuHeadline: false });
        this.setState({ headlineValue: this.custom_headline });
        this.setState({ headline: this.custom_headline });
        headlineValue = this.custom_headline
    };

    openMenuDescription = () => {
        this.setState({ isOpenMenuDescription: true })
    };

    closeMenuDescription = () => {
        this.setState({ isOpenMenuDescription: false });
        this.setState({ descriptionValue: this.custom_description });
        this.setState({ description: this.custom_description });
        descriptionValue = this.custom_description
        setTimeout(() => {
            console.log(this.state.custom_description, "Handle change")
            console.log(descriptionValue, "hello")
        }, 1000);
    };

    handleChangeForHeadline = (newValue) => {
        //this.setState({ headlineValue: newValue });
        //this.setState({ headline: newValue.value });
        this.setState({ custom_headline: newValue.value });
        //headlineValue = newValue
        // setTimeout(() => {
        //     console.log(this.state.headline, "Handle change")
        // }, 1000);
    };

    handleCreateForHeadline = (inputValue) => {
        if (inputValue.length < 30) {
            this.setState({ isLoadingForHeadline: true });
            setTimeout(() => {
                const { options1 } = this.state;
                const newOption = createOptionForHeadline(inputValue);
                this.setState({
                    isLoadingForHeadline: false,
                    options1: [...options1, newOption],
                    headlineValue: newOption,
                    headline: newOption.value,
                });
                headlineValue = newOption

            }, 1000);
            // setTimeout(() => {
            //     console.log(this.state.headline, "Handle create")
            // }, 1000);
        }
        else {
            swal("Oops", "Headline limit exceeds", "warning");

        }
    };

    handleChangeForDescription = (newValue) => {
        //this.setState({ descriptionValue: newValue });
        //this.setState({ description: newValue.value });
        this.setState({ custom_description: newValue.value });
        //descriptionValue = newValue
        /*setTimeout(() => {
            console.log(this.state.description, "Handle change")
            console.log(descriptionValue, "hello")
        }, 1000);*/
    };

    handleCreateForDescription = (inputValue) => {
        if (inputValue.length < 90) {

            this.setState({ isLoadingForDescription: true });
            setTimeout(() => {
                const { options2 } = this.state;
                const newOption = createOptionForDescription(inputValue);
                this.setState({
                    isLoadingForDescription: false,
                    options2: [...options2, newOption],
                    descriptionValue: newOption,
                    description: newOption.value,
                });
                descriptionValue = newOption

            }, 1000);
            setTimeout(() => {
                console.log(descriptionValue, "Handle create")
            }, 1000);
        }
        else {
            swal("Oops", "Description limit exceeds", "warning");

        }
    };

    componentDidMount() {



        location.getGeoInfo()
            .then(async (result) => {
                let location = result.country_name;
                this.setState({ testlocation: location });
                if (this.state.testlocation === "Sweden") {
                    this.setState({ message: this.state.message });

                }
                else {
                    this.setState({ message: this.state.message_english });
                }
            })
            .catch(err => {
                // alert("Oops", "oh no", "error");
            });

        userService.getUsers()
            .then((result) => {
                let responseJson = result.data
                console.log(responseJson, "data")
                this.setState({ users: responseJson });
            })
            .catch(err => {
                swal("Oops", err.response.data.error.message, "error");
            });

    };

    createListingAd = async (e) => {

        e.preventDefault()
        if (this.state.custom_headline && this.state.message && this.state.custom_description && this.state.website
            && (this.state.listingAdImages && this.state.listingAdImages.length > 0) || (this.state.listingAdVideos)) {
            console.log("andr aya ?", this.state.listingAdImages[0])
            this.setState({ loader: true });

            let getLocation = await location.getGeoInfo();
            let formData = new FormData();
            formData.append('custom_headline', this.state.custom_headline);
            formData.append('headline', this.state.custom_headline);
            formData.append('message', this.state.message);
            formData.append('businessName', this.state.businessName);
            formData.append('custom_description', this.state.custom_description);
            formData.append('description', this.state.custom_description);
            formData.append('website', this.state.website);
            formData.append('region', this.state.region);
            formData.append('agentImage', this.state.agentImage)
            formData.append('country', getLocation.country_name)
            formData.append('campaignType', this.state.campaignType)


            // for (const listingImage of this.state.listingAdImages) {
            // formData.append('listingAdImages', listingImage);
            formData.append('mediaType', this.state.mediaType)

            if (this.state.mediaType === 'image') {
                for (const listingImage of this.state.listingAdImages) {
                    formData.append('listingAdImages', listingImage);
                }
            }
            else {
                for (const listingVideo of this.state.listingAdVideos) {

                    formData.append('listingAdVideos', listingVideo);
                }
                // formData.append('thumbnailImage', this.props.location.state.userProfile.userImage);
            }


            if (this.state.custom_headline && this.state.message && this.state.businessName && this.state.custom_description && this.state.website
                && (this.state.listingAdImages && this.state.listingAdImages.length > 0) || (this.state.listingAdVideos)) {
                if (this.state.mediaType == 'image') {
                    listingAdServices.createListingAd(formData)
                        .then(result => {
                            console.log(result, "Hello")
                            if (result.status == 200) {
                                this.setState({ listingData: result.data })
                                this.setState({ redirect: true });
                                this.setState({ loader: false });
                            }
                        }).catch(err => {
                            this.setState({ alert: true });
                            this.setState({ loader: false });
                            // swal("Oops", err.response.data.error.message, "error");
                            swal("Oops", err.response.data != undefined ? err.response.data.error.message
                                : 'Error: Please try again', "error");
                        });
                } else {

                    listingAdServices.createListingAdVideo(formData)
                        .then(result => {
                            console.log(result, "Hello")
                            if (result.status == 200) {
                                this.setState({ listingData: result.data })
                                this.setState({ redirect: true });
                                this.setState({ loader: false });
                            }
                        }).catch(err => {
                            this.setState({ alert: true });
                            this.setState({ loader: false });
                            // swal("Oops", err.response.data.error.message, "error");
                            swal("Oops", err.response.data != undefined ? err.response.data.error.message
                                : 'Error: Please try again', "error");
                        });
                }
            }
        }
        else {
            swal("Oops", "Field is missing", "warning");
        }
    };

    updateListingAd = async (e) => {
        e.preventDefault()
        // if (this.state.custom_headline && this.state.message && this.state.businessName && this.state.custom_description && this.state.website && this.state.listingAdImages && this.state.listingAdImages.length > 0) {
        if (this.state.custom_headline && this.state.message && this.state.businessName && this.state.custom_description && this.state.website
            && (this.state.listingAdImages && this.state.listingAdImages.length > 0) || (this.state.listingAdVideos)) {

            this.setState({ loader: true });

            let getLocation = await location.getGeoInfo();
            let formData = new FormData();
            formData.append('custom_headline', this.state.custom_headline);
            formData.append('headline', this.state.custom_headline);
            formData.append('message', this.state.message);
            // formData.append('businessName', this.state.businessName);
            formData.append('custom_description', this.state.custom_description);
            formData.append('description', this.state.custom_description);
            formData.append('website', this.state.website);
            formData.append('region', this.state.region);
            formData.append('agentImage', this.state.agentImage)
            formData.append('country', getLocation.country_name)
            formData.append('campaignType', this.state.campaignType)
            formData.append('imageUpdated', this.state.imageUpdated)
            formData.append('videoUpdated', this.state.videoUpdated)
            formData.append('mediaType', this.state.mediaType)
            if (this.state.imageUpdated === true) {
                for (const listingImage of this.state.listingAdImages) {
                    formData.append('listingAdFile', listingImage);
                }
            } else if (this.state.videoUpdated) {
                for (const listingAdVideo of this.state.listingAdVideos) {
                    formData.append('listingAdFile', listingAdVideo);
                }
            }
            listingAdServices.updateListingAd(this.props.location.state.listingData._id, formData)
                .then(result => {
                    console.log(result, "Hello")
                    if (result.status == 200) {
                        this.setState({ listingData: result.data })
                        this.setState({ redirect: true });
                        this.setState({ loader: false });
                    }
                }).catch(err => {
                    this.setState({ loader: false });
                    swal("Oops", err.response.data.error.message, "error");
                });
        }
        else {
            swal("Oops", "Field is missing", "warning");
        }
    };

    render() {
        console.log("mymediatype" + this.state.mediaType);

        const { isLoading, headline, headlineValue, descriptionValue } = this.state;

        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }
        // if (this.state.redirect && this.loggedIn()) {
        if (this.state.redirect == true) {

            return (
                <Redirect to={{
                    pathname: "/ad-preview",
                    state: {
                        campaignType: this.state.campaignType,
                        userProfile: this.state.userProfile,
                        previousPath: this.state.previousPath,
                        listingData: this.state.listingData,
                        mediaType: this.state.mediaType
                    }
                }} />
            )
        }
        return (
            <div className="createaddnew">
                <Header backButton={this.state.previousPath}
                    previousData={{
                        userProfile: this.state.userProfile,
                        campaignType: this.state.campaignType,
                        previousPath: this.props.location.pathname,
                    }}
                    backHidden={this.props.location.state.reverseToEdit}
                />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="createadnewmain">
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="createadnewprotion1">
                                <p className="topheadingcreateadnew">{this.props.location.state.reverseToEdit ? "Edit ad." : "Create ad."}</p>
                            </Row>
                            <Form onSubmit={this.props.location.state.reverseToEdit ? this.updateListingAd : this.createListingAd}>
                                <Row className="formcreateadnew">

                                    <Label className="createadnew-label" > Message* <span className="createadnew-label-span">(max: 300)</span></Label>
                                    <Input type="text"
                                        name="message"
                                        id="createadnewinput"
                                        placeholder="Message"
                                        value={this.state.message}
                                        maxLength={300}
                                        onChange={this.onChange}
                                        required />
                                    {/* <Label className="createadnew-label" > Business Name* <span className="createadnew-label-span">(max: 25)</span></Label>
                                    <Input type="text"
                                        name="businessName"
                                        id="createadnewinput"
                                        placeholder="Business "
                                        value={this.state.businessName}
                                        maxLength={25}
                                        onChange={this.onChange}
                                        /> */}
                                    {/* <Label className="createadnew-label" > Headline* <span className="createadnew-label-span">(max: 30)</span></Label>
                                    <Input type="text"
                                        name="headline"
                                        id="createadnewinput"
                                        placeholder="Name"
                                        value={this.state.headline}
                                        maxLength={30}
                                        onChange={this.onChange}
                                        required /> */}

                                    <Label className="createadnew-label" > Headline* <span className="createadnew-label-span">(max: 60)</span></Label>
                                    <Input type="text"
                                        name="custom_headline"
                                        id="createadnewinput"
                                        placeholder="Select..."
                                        maxLength={60}
                                        ref="Headline1"
                                        onFocus={this.openMenuHeadline}
                                        onBlur={this.closeMenuHeadline}
                                        value={this.state.custom_headline}
                                        onChange={this.onChange}
                                        autoComplete="off"
                                        required />
                                    <Label className="createadnew-label" > Headline* <span className="createadnew-label-span">(max: 60)</span></Label>
                                    {this.state.pathUrl == "/listing-ad" ?
                                        <Input type="text"
                                            name="headline"
                                            className="hideHeadline"
                                            id="createadnewinput"
                                            placeholder="Headline"
                                            value={this.state.headline}
                                            maxLength={60}
                                            onChange={this.onChange}
                                            required />
                                        :
                                        <CreatableSelect
                                            menuIsOpen={this.state.isOpenMenuHeadline}
                                            id="selectinputeuserName"
                                            // isMulti
                                            isClearable
                                            isDisabled={this.state.isLoadingForHeadline}
                                            isLoading={this.state.isLoadingForHeadline}
                                            onChange={this.handleChangeForHeadline}
                                            onCreateOption={this.handleCreateForHeadline}
                                            options={this.state.options1}
                                            value={headlineValue}
                                        />
                                    }
                                    <Label className="createadnew-label" > Website* </Label>
                                    <Input type="url"
                                        pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                                        required
                                        name="website"
                                        id="createadnewinput"
                                        placeholder="https://example.com"
                                        value={this.state.website}
                                        onChange={this.onChange}
                                    />
                                    {/* <Label className="createadnew-label" > Description* <span className="createadnew-label-span">(max: 90)</span></Label>
                                    <Input type="textarea"
                                        name="description"
                                        id="createadnewinput"
                                        placeholder="Description"
                                        value={this.state.description}
                                        maxLength={90}
                                        onChange={this.onChange}
                                        required /> */}

                                    <Label className="createadnew-label" > Description* <span className="createadnew-label-span">(max: 300)</span></Label>
                                    <Input type="text"
                                        name="custom_description"
                                        id="createadnewinput"
                                        placeholder="Select..."
                                        maxLength={300}
                                        ref="Description1"
                                        onFocus={this.openMenuDescription}
                                        onBlur={this.closeMenuDescription}
                                        value={this.state.custom_description}
                                        onChange={this.onChange}
                                        autocomplete="off"
                                        required />
                                    <Label className="createadnew-label" > <span className="createadnew-label-span"></span></Label>
                                    {this.state.pathUrl == "/listing-ad" ?
                                        <Input type="text"
                                            name="description"
                                            className="hideDescription"
                                            id="createadnewinput"
                                            placeholder="Description"
                                            value={this.state.description}
                                            maxLength={90}
                                            onChange={this.onChange}
                                            required />
                                        :
                                        <CreatableSelect
                                            menuIsOpen={this.state.isOpenMenuDescription}
                                            id="selectinputeuserName"
                                            isClearable
                                            isDisabled={this.state.isLoadingForDescription}
                                            isLoading={this.state.isLoadingForDescription}
                                            onChange={this.handleChangeForDescription}
                                            onCreateOption={this.handleCreateForDescription}
                                            options={this.state.options2}
                                            value={descriptionValue}
                                        />
                                    }
                                    <Row className="formcreateadnew">
                                        {/* <Col lg="1" md="1" sm="1" xs="1">
                                            <Row className='checkboxInput'>
                                                <Input type='checkbox'
                                                    name='agentImage'
                                                    id='createadnewinput'
                                                    checked={this.state.agentImage}
                                                    onChange={e => this.setState({ agentImage: e.target.checked })}
                                                />
                                            </Row>
                                        </Col>
                                        <Col lg="8" md="8" sm="8" xs="8">
                                            <Row>
                                                <Label className="createadnew-label-span" >Include agent image</Label>
                                            </Row>
                                        </Col> */}
                                    </Row>


                                    {/* <Input type='checkbox' ><span className="createadnew-label-span" >Add agent image</span></Input> */}
                                    {/* <Row className="formcreateadnew">
                                        <Col>
                                            <Row className="createadnewbtns">
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Label className="createadnew-label-span" >Add agent image</Label>
                                            </Row>
                                        </Col>
                                    </Row> */}

                                    <Row className="createadnewstate">
                                        <Col>
                                            <Row className="createadnewbtns">
                                                <Row className="createadnew-btn">
                                                    <Button className="createadnew-img-btn">+ Attach Media</Button>
                                                    <Input
                                                        type="file"
                                                        name="listingAdImages"
                                                        onChange={this.onChangeListingImage}
                                                        multiple />

                                                </Row>
                                            </Row>
                                        </Col>

                                        <Col>
                                            <Row className="createadnewbtns">
                                                {this.props.location.state.reverseToEdit
                                                    ?
                                                    <Button className="createadnewsubmit" type="submit">Next</Button>
                                                    :
                                                    <Button className="createadnewsubmit" type="submit">Next</Button>}
                                            </Row>
                                        </Col>
                                        <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                                            <div className='overlay-box'>
                                                <PropagateLoader
                                                    // css={override}
                                                    sizeUnit={"px"}
                                                    size={25}
                                                    color={'white'}
                                                    loading={this.state.loader}
                                                />
                                            </div>
                                        </div>

                                    </Row>
                                </Row>
                            </Form>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} xs={12} >
                            {this.state.mediaType === "image" && <MobileAdPreview headline={this.state.custom_headline} message={this.state.message} website={this.state.website} description={this.state.custom_description} previewImage={this.state.previewImage || (this.state.listingAdImages && this.state.listingAdImages.length > 0 && IMG_URL + this.state.listingAdImages[0])} mediaType={this.state.mediaType} />}
                            {this.state.mediaType === "video" && <MobileAdPreview headline={this.state.custom_headline} message={this.state.message} website={this.state.website} description={this.state.custom_description} previewVideo={this.state.previewVideo || (this.state.listingAdVideos && this.state.listingAdVideos.length > 0 && IMG_URL + this.state.listingAdVideos[0])} mediaType={this.state.mediaType} />}
                            {this.state.mediaType === "dummy" && <MobileAdPreview headline={this.state.custom_headline} message={this.state.message} website={this.state.website} description={this.state.custom_description} previewImage={this.state.previewImage || (this.state.listingAdImages && this.state.listingAdImages.length > 0 && IMG_URL + this.state.listingAdImages[0])} mediaType={this.state.mediaType} />}
                            {this.state.mediaType === undefined && <MobileAdPreview headline={this.state.custom_headline} message={this.state.message} website={this.state.website} description={this.state.custom_description} previewImage={this.state.previewImage || (this.state.listingAdImages && this.state.listingAdImages.length > 0 && IMG_URL + this.state.listingAdImages[0])} mediaType={this.state.mediaType} />}

                        </Col>
                    </Row>
                </Col>
            </div>
        );
    }
}

export default CreateAdNew;
