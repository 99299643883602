import React, { Component } from "react";
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";
import video from './explainer.mp4';
import './reactPlayer.css';

class Player extends Component {
  render() {
    const { open, toggleModal } = this.props;
    return (
      <Modal
        open={open}
        onClose={toggleModal}
        styles={{
          modal: {
            maxWidth: "unset",
            width: "100%",
            height: "auto",
            padding: "unset"
          },
          overlay: {
            background: "none"
          },
          closeButton: {
            background: "yellow"
          }
        }}
        center
      >
        <video src={video} controls autoPlay width="100%" height="100%"> </video>
      </Modal>
    );
  }
}

export default Player;
