import React, { Component } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import Samosa from "../../images/Resurs 140.png";
import { Link , Redirect} from 'react-router-dom';
import Calenders from "../../images/Resurs 70.png";
import Header from '../Header/NewHeader';
import './NameOfCampaign.css';
import Login from "../Login/Login";

const auth = new Login();

class ListingAd extends Component {

    render() {
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }
        return (
            <div className="namecampaign">
                <Header />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="namecampaignmain">
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="namecampaignprotion1">
                                <p className="topheadingnamecampaign">Name of campaign.</p>
                            </Row>
                            <Row className="formnamecampaign">
                                <Label className="namecampaign-label" > Campaign </Label>
                                <Input type="select"
                                    name="url"
                                    id="namecampaign"
                                    onChange={this.onChange}
                                    required >
                                    <option>select here</option>
                                </Input>

                                <Label className="namecampaign-label" > Showing dates </Label>
                                <Row className="calendersrow">
                                    <img src={Calenders} width="auto" height="20" alt="image"></img>
                                    <p> Dec 24 15:00-17.00</p>
                                </Row>
                                <Row className="calendersrow">
                                    <img src={Calenders} width="auto" height="20" alt="image"></img>
                                    <p> Dec 24 15:00-17.00</p>
                                </Row>

                                <Row className="campaignstatestss">
                                    <Col lg={6} md={6} sm={6} xs={12}>
                                        <Row className="campaignsbtn">
                                            <Row className="campaignsname-wrapper">
                                                <button class="campaignnames-btnn">Mark as sold</button>
                                                <Input
                                                    type="submit" />
                                            </Row>
                                        </Row>
                                    </Col>

                                    <Col lg={6} md={6} sm={6} xs={12}>
                                        <Row className="createad-btns">
                                            <Button
                                                className="sharecampaignnames"
                                            > + Create ad </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="namecampaignprotion2">
                                <img src={Samosa} height="auto" width="100%"></img>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div>
        );
    }
}

export default ListingAd;