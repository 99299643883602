import React, { Component } from 'react';
import './App.css';
import Login from "./components/Login/NewLogin.js";
import Home from "./components/LandingPage/LandingPage.js";

class App extends Component {
  render() {
    return (
      // <div className="App">
      //  {/* <Login/> */}
      //  {/* <Header/> */}
        <Home/> 
      // {/* </div> */}
    );
  }
}

export default App;
