
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Input, Label, Row } from 'reactstrap';
import swal from 'sweetalert';
import { IMG_URL } from '../../config'
import Addphoto from '../../images/addphoto.png';
import UserServices from "../Apiservices/UserService";
import Header from '../Header/NewHeader';
import Login from "../Login/Login";
import './Style.css';
import './Profile.css';
import ReactDOM from 'react-dom';
import AvatarEditor from 'react-avatar-editor';


const userServices = new UserServices();
const auth = new Login();

const userId = auth.getId()

class CreateProfile extends Component {

   constructor(props) {
      super(props);

      this.state = {
         redirect: false,
         alert: false,
         invalid: '',
         // name: this.props.location.state.userinfo.name,
         name: "",
         // email: this.props.location.state.userinfo.email,
         email: "",
         description: "",
         phoneNumber: null,
         file: null,
         userDetail: {},
         userImage: "",
         profileCompleted: true,
         loader: false,
         country: "",
         previewImage: '',
         website: "http://",
         valid: false,
         imageScaleValue: 1,
         editor: null

      };
      this.onChange = this.onChange.bind(this);
      this.onChange1 = this.onChange1.bind(this);
      this.updateUser = this.updateUser.bind(this);
      this.onScaleImageChange = this.onScaleImageChange.bind(this);

   }

   componentDidMount() {
      userServices.getUserProfile(userId)
         .then((result) => {

            // console.log(result, "Hello g ")
            let responseJson = result.data.user
            this.setState({ userDetail: responseJson });
            this.setState({ name: responseJson.name });
            this.setState({ email: responseJson.email });
            this.setState({
               userImage: responseJson.userImage.startsWith('images/user/') ?
                  IMG_URL + responseJson.userImage
                  : responseJson.userImage
            });
            // this.setState({ phoneNumber: responseJson.phoneNumber });
            // this.setState({ description: responseJson.description });
            // this.setState({ country: responseJson.country });
            this.setState({ website: responseJson.website });
         })
         .catch(err => {
            // console.log(err, 'error')
            // swal("Oops", "oh no", "error");
         });
   };

   onChange(e) {
      this.setState({
         [e.target.name]: e.target.value
      });
   }

   onChange1(e) {
      // this.setState({ file: e.target.files[0] });
      this.setState({ file: e.target.files[0], previewImage: e.target.files[0] });

   }

   onScaleImageChange(e) {
      const imageScaleValue = parseFloat(e.target.value);
      this.setState({
         [e.target.name]: e.target.value
      })
   }

   handleChange = (newValue) => {
      this.setState({ value: newValue });
      this.setState({ selectValue: newValue.value });

      setTimeout(() => {
         console.log(this.state.selectValue, "Handle change")
      }, 1000);
   };

   // handleCreate = (inputValue) => {
   //    this.setState({ isLoading: true });
   //    setTimeout(() => {
   //       const { options } = this.state;
   //       const newOption = createOption(inputValue);
   //       this.setState({
   //          isLoading: false,
   //          options: [...options, newOption],
   //          value: newOption,
   //          selectValue: newOption.value,
   //       });
   //    }, 1000);
   //    setTimeout(() => {
   //       console.log(this.state.selectValue, "Handle create")
   //    }, 1000);
   // };

   fieldsValidation() {
      if (this.state.name == "") {
         return "Name is missing"
      }
      else if (this.state.email == "") {
         return "Email is missing"
      }
      // else if (this.state.description == "") {
      //    return "Description is missing"
      // }
      // else if (this.state.phoneNumber == null) {
      //    return "Phone number is missing"
      // }
      // else if (this.state.country == "") {
      //    return "Country is missing"
      // }
      else if (this.state.website == "") {
         return "Website is missing"
      }
      return null;
   };

   updateUser(e) {
      console.log()
      if (this.fieldsValidation() != undefined) {
         // alert(this.fieldsValidation())
         swal("oops", this.fieldsValidation(), "warning");
      }
      else {
         e.preventDefault()
         // if (this.state.name &&
         //    this.state.phoneNumber && this.state.description && this.state.country) {
         //const imageURL = this.editor.getImageScaledToCanvas().toDataURL();
         //console.log(this.editor.getImageScaledToCanvas().toDataURL());
         var blob = "";
         if (this.state.file != null) {
            const canvas = this.editor.getImageScaledToCanvas().toDataURL();
            var mime = canvas.split(',')[0].split(':')[1].split(';')[0];
            var binary = atob(canvas.split(',')[1]);
            var array = [];
            for (var i = 0; i < binary.length; i++) {
               array.push(binary.charCodeAt(i));
            }
            blob = new File([new Uint8Array(array)], "profile.png");
         }
         //this.setState({ file: blob });
         this.setState({ loader: true });
         let formData = new FormData();
         formData.append('name', this.state.name);
         // formData.append('phoneNumber', this.state.phoneNumber);
         formData.append('website', this.state.website);
         formData.append('userImage', this.state.file != null ? blob : this.state.userImage);
         formData.append('profileCompleted', this.state.profileCompleted);
         // formData.append('description', this.state.description);
         // formData.append('country', this.state.country);
         userServices.updateUser(userId, formData)
            .then((result) => {
               let responseJson = result;
               if (responseJson.status == '200') {
                  this.setState({ redirect: true });
                  this.setState({ loader: false });
               }
            })
            .catch(err => {
               this.setState({ alert: true });
               this.setState({ invalid: err.response.data.error.message });
               swal("Oops", err.response.data.error.message, "error");
            });
      }
      // else {
      //    swal("Oops", "Something is missing", "warning");

      // }
   }


   setEditorRef = (editor) => this.editor = editor

   render() {
      const { isLoading, options, value } = this.state;
      if (!auth.loggedIn()) {
         return (<Redirect to="/new-login" />)
      }

      if (this.state.redirect == true) {
         return (
            <Redirect to={{
               pathname: "/dashboard",
            }} />
         )
      }

      return (
         <div className="createprofile">
            <Header />
            <Col lg={12} md={12} sm={12} xs={12} >
               <Row className="createprofilemain">
                  <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} order="12">
                     <Row className="profileprotion1">
                        <p className="topheading">Create your profile.</p>
                     </Row>
                     <Row className="allfields">
                        <p>(All fields must be completed)</p>
                     </Row>
                     <Row className="formprofile">

                        <Label className="name-label" > Name </Label>
                        <Input type="text"
                           name="name"
                           id="exampleuserName"
                           placeholder="Name"
                           value={this.state.name}
                           onChange={this.onChange}
                           required />

                        {/* <Label className="name-label" > Phone </Label>
                        <Input type="number"
                           name="phoneNumber"
                           id="exampleuserName"
                           placeholder="Phone"
                           value={this.state.phoneNumber}
                           onChange={this.onChange}
                           required /> */}
                        <Label className="name-label" > E-mail</Label>
                        <Input type="email"
                           name="userName"
                           id="exampleuserName"
                           value={this.state.email}
                           disabled
                           required />
                        {/* <Label className="name-label" > Country</Label>
                        <Input type="text"
                           name="country"
                           id="exampleuserName"
                           value={this.state.country}
                           onChange={this.onChange}
                        /> */}
                        {/* <Label className="name-label" > Description </Label>
                        <Input type="textarea"
                           name="description"
                           id="profiledescriptionhere"
                           value={this.state.description}
                           placeholder="Description"
                           onChange={this.onChange}
                           required /> */}
                        <Label className="name-label" > Website* </Label>
                        <Input type="url"
                           pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                           required
                           name="website"
                           id="exampleuserName"
                           placeholder="https://example.com"
                           value={this.state.website}
                           onChange={this.onChange}
                        />

                        <Button className="saveprofile"
                           type="submit"
                           onClick={this.updateUser}
                        > Save </Button>

                     </Row>
                     <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                        <div className='overlay-box'>
                           <PropagateLoader
                              // css={override}
                              sizeUnit={"px"}
                              size={25}
                              color={'white'}
                              loading={this.state.loader}
                           />
                        </div>
                     </div>
                  </Col>

                  <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} order="1">
                     <Row className="profileprotion2">
                        {!this.state.userImage && !this.state.previewImage ? (


                           <AvatarEditor
                              ref={this.setEditorRef}
                              image={Addphoto}
                              width={250}
                              height={250}
                              border={20}
                              borderRadius={20}
                              color={[0, 0, 0, 0.6]} // RGBA
                              scale={this.state.imageScaleValue}
                              rotate={0}
                              disableHiDPIScaling={true}
                           />


                        ) : (

                              <AvatarEditor
                                 ref={this.setEditorRef}
                                 image={this.state.previewImage || this.state.userImage}
                                 width={250}
                                 height={250}
                                 border={20}
                                 borderRadius={20}
                                 color={[0, 0, 0, 0.6]} // RGBA
                                 scale={this.state.imageScaleValue}
                                 rotate={0}
                                 disableHiDPIScaling={true}
                              />
                           )}
                        {/* <img src={"https://realreach.s3.eu-north-1.amazonaws.com/" + this.state.userImage} width="300" height="300"></img> */}
                     </Row>
                     {/* <Row className="upload-btn-wrapper">
              <button class="btn">Upload a file</button>
                 <Input
                 type="file"/>
              </Row> */}
                     <Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12} order="1">
                        <Row>
                           {this.state.previewImage ? (


                              <input
                                 name="imageScaleValue"
                                 class="profileImageScale"
                                 type="range"
                                 step="0.01"
                                 min="0.1"
                                 max="10"
                                 value={this.state.imageScaleValue}
                                 onChange={this.onScaleImageChange}
                              />


                           ) : (
                                 <input
                                    name="imageScaleValue"
                                    class="profileImageScale"
                                    type="range"
                                    step="1"
                                    min="0.1"
                                    max="10"
                                    value={this.state.imageScaleValue}
                                    onChange={this.onScaleImageChange}
                                    style={{ display: "None" }}
                                 />
                              )}
                        </Row>
                        <Row className="upload-btn-wrapper">
                           <button class="btn">+ Add photo</button>
                           <Input
                              type="file"
                              name="userImage"
                              onChange={this.onChange1}
                           />

                        </Row>
                     </Col>
                  </Col>
               </Row>
            </Col>
         </div>
      );
   }
}

export default CreateProfile;
