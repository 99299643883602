import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Col, Input, Row } from 'reactstrap';
import Mobile from '../../images/Resurs 300.png';
import Header from '../Header/NewHeader';
import './NewSoldCampaign.css';
import Login from "../Login/Login";
import { IMG_URL } from '../../config'
import MobileAdPreview from '../MobileAdPreview';

const auth = new Login();

class SoldCampaign extends Component {

	constructor(props) {
		super(props);

		this.state = {
			redirect: false,
			alert: false,
			currentCampaign: this.props.location.state.currentCampaign,
			afterNoteUrl: this.props.location.state.afterNoteUrl.afterNoteImage,
			message: this.props.location.state.currentCampaign
				? "Jag har precis sålt " + this.props.location.state.currentCampaign.listingAd.description + " i ditt område och har flera intresserade spekulanter!"
				: "Jag har nyligen sålt detta objekt för i ditt  område och har flera intresserade spekulanter. Välkommen att kontakta mig för en kostnadsfri borådgivning.",
			headline: this.props.location.state.currentCampaign
				? "Vill du veta vad din bostad är värd?"
				: "Vill du veta vad din bostad är värd?",
			description: this.props.location.state.currentCampaign
				? this.props.location.state.currentCampaign.user.description
				: "Få en kostnadsfri borådgivning.",
			businessName: this.props.location.state.currentCampaign
				? this.props.location.state.currentCampaign.listingAd.businessName
				: props.location.state.currentCampaign.user.name,
			website: this.props.location.state.currentCampaign
				? this.props.location.state.currentCampaign.listingAd.website
				: this.props.location.state.currentCampaign.user.website,
		};
		console.log(this.props.location.state.afterNoteUrl, "hrloo")
	}

	render() {
		if (!auth.loggedIn()) {
			return (<Redirect to="/new-login" />)
		}
		return (
			<div className="soldout">
				<Header />
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="soldoutmain">
						<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12}>
							<Row className="soldoutprotion1">
								<p className="soldouttopheading">{this.state.currentCampaign.listingAd.description}</p>
							</Row>

							<Row className="soldoutstate">

								<Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>
									<Row className="soldout-wrapper">
										<Link to="/dashboard">
											<Button className="soldout-btn-css">No thanks</Button>
										</Link>
										<Input
											type="submit" />
									</Row>
								</Col>

								{/* <Col lg={{ size: 6 }} md={{ size: 6 }} sm={12} xs={12}>
									<Row className="soldoutcenter">
										<Row>
											<img src={IMG_URL + this.state.afterNoteUrl} alt="reload page" width="100%" height="auto"></img>
										</Row>
									</Row>
								</Col> */}
								<Col lg={{ size: 4, offset: 0 }} md={{ size: 5, offset: 0 }} sm={12} xs={12}>
									<Row className="soldoutcenter">

										<MobileAdPreview headline={this.state.headline} message={this.state.message} website={this.state.website} description={this.state.description} previewImage={IMG_URL + this.state.afterNoteUrl} />

									</Row>
								</Col>

								<Col lg={{ size: 3 }} md={{ size: 3 }} sm={12} xs={12}>


									<Row className="soldoutcenter">
										<Row className="soldout-btns">
											<Link to={{
												pathname: "/create-after-note",
												state: {
													currentCampaign: this.state.currentCampaign,
													userProfile: this.state.currentCampaign.user,
													afterNoteUrl: this.state.afterNoteUrl,
													previousPath: this.props.location.pathname,
												}
											}} style={{ textDecoration: "none" }}>
												<Button className="sharesoldout"> + After note  </Button>
											</Link>

										</Row>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>

			</div>
		);
	}
}

export default SoldCampaign;
