import React, { Component } from 'react';
import "./Style.css";
import {
	Row,
	Col,
	Button,
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
// import Left from '../../images/leftarrow.png'
// import Right from '../../images/rightarrow.png'
// import House from '../../images/dollar.jpg';
import swal from 'sweetalert';
import Spinner from 'react-spinner-material';
import Header from "../Header/index";
import Apiservices from '../Apiservices/Urlservice';
const urlpost = new Apiservices();

class Billing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			amount: this.props.location.state.amount,
			userinfo: this.props.location.state.userinfo,
			campaignBudget: this.props.location.state.campaignBudget,
			campaigndetails: this.props.location.state.campaigndetails,
			billingstatement: "",
			redirect: false,
			alert: false,
			loading: false
		}
		this.onChange = this.onChange.bind(this);
		console.log("user", this.state.userinfo.card.cardBrand)
	}

	// componentDidMount() {
	//   return urlpost.budget()
	//     .then(res => {
	//       // console.log(`sdfghjoiuy ${JSON.stringify(res.data)}`);
	//       this.setState({ budget: res.data });
	//     });
	// }

	onChange(e) {
		console.log("ghfgj", e.target.value)
		this.setState({ [e.target.name]: e.target.value });
	}

	paymentbill = () => {
		if (this.state.campaigndetails.campaign.budget.amount)
			this.setState({ loading: true });
		{
			let data = {
				amount: this.state.campaigndetails.campaign.budget.amount,
			}
			urlpost.billpay(data)
				.then(result => {
					let responseJson = result.data;
					if (responseJson) {
						this.setState({ billingstatement: responseJson });
						this.setState({ redirect: true });
						this.setState({ alert: true });
						this.setState({ loading: false });
					}
					swal("tack", "property has been added", "success");
				})
				.catch(err => {
					this.setState({ alert: true });
					this.setState({ loading: false });
					this.setState({ invalid: err.response.data.error.message });
					swal("Oops", err.response.data.error.message, "error");
				});
		}
	};

	render() {
		if (this.state.redirect == true) {
			return (
				<Redirect to={{
					pathname: "/",
				}} />
			)
		}
		return (
			<div className="custom-container">
				<Header />
				{/* <Col lg={12} md={12} sm={12} xs={12} >
          <Row className="topheader2">
              <Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                <Row className="leftarrow">
                <Link to={{
                  pathname: "/",
                }}>
                    <img src={Left} alt="" height="33px"></img>
                    </Link>
                </Row>
              </Col>
              <Col lg={10} md={10} sm={8} xs={8}><Row className="centertext">Kampanjsöversikt</Row></Col>
          </Row>
          </Col> */}

				<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12}>
					<Row className="paybilltop">
						<Col lg={{ size: 7, offset: 1 }} md={{ size: 7, offset: 1 }} sm={7} xs={7}>
							<Row>
								<h4>Payer Name</h4>
							</Row>
						</Col>
						<Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={4} xs={4}>
							<Row>
								<h4>{this.state.userinfo.card.cardHolderName} </h4>
							</Row>
						</Col>
						<Col lg={{ size: 7, offset: 1 }} md={{ size: 7, offset: 1 }} sm={7} xs={7}>
							<Row>
								<h4>Total Bill Is</h4>
							</Row>
						</Col>
						<Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={4} xs={4}>
							<Row>
								<h4>{this.state.campaigndetails.campaign.budget.amount} SEK </h4>
							</Row>
						</Col>
						<Col lg={{ size: 7, offset: 1 }} md={{ size: 7, offset: 1 }} sm={7} xs={7}>
							<Row>
								<h4>Card Brand</h4>
							</Row>
						</Col>
						<Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={4} xs={4}>
							<Row>
								<h4>{this.state.userinfo.card.cardBrand} </h4>
							</Row>
						</Col>
						<Col lg={{ size: 7, offset: 1 }} md={{ size: 7, offset: 1 }} sm={7} xs={7}>
							<Row>
								<h4>Card Number</h4>
							</Row>
						</Col>
						<Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={4} xs={4}>
							<Row>
								<h4>**** **** **** {this.state.userinfo.card.lastDigits} </h4>
							</Row>
						</Col>
						{/* <h3>Your total bill is: {this.state.amount} </h3> */}
					</Row>
				</Col>
				<Col className="carrrrddddd" lg={12} md={12} sm={12} xs={12}>
					<Row className="spinnerfix">
						<Spinner marginLeft={-100} size={40} spinnerColor={"#000000"} spinnerWidth={1} visible={this.state.loading} />
					</Row>
					<Row className="paymentbtn">

						<Button className="paymentpay" onClick={this.paymentbill} >Pay</Button>
					</Row>
					{/* <Col className="spinner">
               
            </Col> */}
				</Col>
			</div>
		);
	}
}

export default Billing;
