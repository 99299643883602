import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, Redirect } from 'react-router-dom';
import Spinner from 'react-spinner-material';
import { Button, Col, Form, FormGroup, Input, ModalBody, Row } from 'reactstrap';
import styled from 'styled-components';
import swal from 'sweetalert';
import Apiservices from '../Apiservices/Urlservice';
import Header from '../Header/index.js';
import './Style.css';
const urlpost = new Apiservices();
const StyledLoader = styled(LoadingOverlay)`
  width: 100%;
  height: auto;
  overflow: hidden;
  .MyLoader_overlay {
    background: rgba(255, 0, 0, 0.5);
  }
  &.MyLoader_wrapper--active {
    overflow: hidden;
  }
`

class ListingUrl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userinfo: this.props.location.state.userinfo,
			campaignType: this.props.location.state.campaignType,
			url: "",
			region: "native",
			property: {},
			redirect: false,
			alert: false,
			loading: false,
			active: false
		};
		console.log("campaigntype", this.state.campaignType)
		this.onChange = this.onChange.bind(this);
		this.addproperty = this.addproperty.bind(this);
		this.onChangeFile = this.onChangeFile.bind(this);
	}

	onChange(e) {
		console.log("ghfgj", e.target.value)
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeFile(e) {
		console.log("file", e.target.files)
		this.setState({ [e.target.name]: e.target.files });
	}

	addproperty = () => {
		if (this.state.url && this.state.region)
			this.setState({ active: true });
		this.setState({ active: true });
		{
			let formData = new FormData();

			formData.append('url', this.state.url);
			formData.append('region', this.state.region);
			//     for (const propertyImages of this.state.propertyImages) {
			//       formData.append('propertyImages', propertyImages);
			//   }
			// formData.append('propertyImages', this.state.propertyImages);
			// formData.append('website', this.state.website);
			urlpost
				.addnewproperty(formData)
				.then(result => {
					let responseJson = result.data;
					if (responseJson) {
						this.setState({ property: responseJson });
						// this.setState({ propertyImages: responseJson.propertyImages });

						this.setState({ redirect: true });
						this.setState({ alert: true });
						this.setState({ active: false });
						this.setState({ active: false });
					}
					// swal("Good", "property has been added", "success");
				})
				.catch(err => {
					this.setState({ alert: true });
					this.setState({ active: false });
					this.setState({ active: false });
					this.setState({ invalid: err.response.data.error.message });
					swal("Oops", err.response.data.error.message, "info");
				});
		}
	};
	render() {
		if (this.state.redirect == true) {
			return (
				<Redirect to={{
					pathname: "/ad-create",
					state: {
						campaignType: this.state.campaignType,
						userinfo: this.state.userinfo,
						property: this.state.property
					}
				}} />
			)
		}
		return (
			<div>

				{/* <StyledLoader
              active={false}
              // spinner
              // text='Loading...'
              spinner={<BounceLoader />}
            > */}
				<Header />
				<Col lg={12} md={12} sm={12} xs={12}>
					<Row>
						{this.state.userinfo.country == "sweden" && (
							<Col className="margins" lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12} >
								<h1 className="heading-toppp">Add new property</h1>
								<Form>
									<ModalBody>

										<Row className="namefields">
											<label className="labelpropertyUrl">Hemnet URL</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="text"
														name="url"
														placeholder="URL"
														onChange={this.onChange} />
												</FormGroup>
											</Col>
											<Link to={{
												pathname: "/gettning-url",
												state: {
													campaignType: this.state.campaignType,
													userinfo: this.state.userinfo
												}
											}}>
												<label className="labelpropertyhow">How?</label>
											</Link>
										</Row>
										{/* <Col lg={12} md={12} sm={12} xs={12}> 
        <Row className="creatreproperties">
            <Button className="btnbtncreateproperties" onClick={this.addproperty}>Add property</Button>  
            </Row>
            </Col> */}

									</ModalBody>
								</Form>
							</Col>
						)}

						<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }} >
							<Row>
								<Col lg={6} md={6} sm={6} xs={6}>
									<Row className="leftarrownew">
										<Link to={{
											pathname: "/campaign-type",
											state: {
												campaignType: this.state.campaignType,
												userinfo: this.state.userinfo
											}
										}}>
											<Button className="btnedit">Back</Button>
										</Link>
									</Row>
								</Col>

								<Col lg={6} md={6} sm={6} xs={6}>
									<Row className="spinnerfix">
										<Spinner marginLeft={-100} size={40} spinnerColor={"#000000"} spinnerWidth={1} visible={this.state.loading} />
									</Row>
									<Row className="nextbtn1">
										{/* <Link to={{
                  pathname: "/create-add",
                  state: {
                    userinfo: this.state.userinfo,
                    campaignType: this.state.campaignType
                  }
                }}> */}
										<Button className="btnapprove" onClick={this.addproperty}>Next</Button>
										{/* </Link> */}
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				{/* </StyledLoader> */}
			</div>
		)
	}
}
export default (ListingUrl);