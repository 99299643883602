import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Form, ModalBody, Row } from 'reactstrap';
import Pic1 from '../../images/pic111.png';
import Pic2 from '../../images/pic222.png';
import Pic3 from '../../images/pic333.png';
import Apiservices from '../Apiservices/Urlservice';
import Header from '../Header/index.js';
import './Style.css';
const urlpost = new Apiservices();
// import Left from '../../images/leftarrow.png'
// import Right from '../../images/rightarrow.png'
// import DatePicker from 'react-date-picker';
var startDate;
var endDate;
// const toggle = () => setModal(!modal);
// const date = new Date()
// var day = date.getDate();
// var month = date.getMonth() + 1;
// var year = date.getFullYear();
// console.log(date, 'hello')
class How extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userinfo: this.props.location.state.userinfo,
			campaignType: this.props.location.state.campaignType,
			redirect: false,
			alert: false,
			invalid: '',
			isLoaded: true,
			loading: false
		};
	}

	render() {
		return (
			<div>
				<Header />
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheadernew2">

					</Row>
				</Col>
				<Col className="marginnew" lg={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} sm={12} xs={12}>
					<h1 className="heading-toppp">How To Get URL From Hemnet</h1>
					<Form>
						<ModalBody>
							<Row className="namefields">
								<Col lg={12} md={12} sm={12} xs={12}>
									<Row className="checkboxes">

										<Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={{ size: 12 }} xs={{ size: 12 }}>
											<Row>
												<Card className="cardsizes">
													<img src={Pic1} height="250"></img>
												</Card>
											</Row>
											<Row>
												<p>Visit your ad page on Hemnet</p>
											</Row>
										</Col >
										<Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={{ size: 12 }} xs={{ size: 12 }}>
											<Row>
												<Card className="cardsizes">
													<img src={Pic2} alt="image" height="250" width="100%"></img>
												</Card>
											</Row>
											<Row>
												<p>Mark the entire address field, right- click and choose Copy</p>
											</Row>
										</Col >
										<Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={{ size: 12 }} xs={{ size: 12 }}>
											<Row>
												<Card className="cardsizes">
													<img src={Pic3} height="250" width="100%" ></img>
												</Card>
											</Row>

											<Row>
												<p>Right-click and choose Paste in the Hemnet URL field</p>
											</Row>
										</Col >
									</Row>
								</Col>
							</Row>
						</ModalBody>
					</Form>
					<Col lg={12} md={12} sm={12} xs={12}>
						<Row className="createadbtn">
							<Link to={{
								pathname: "/hemnet-url",
								state: {
									campaignType: this.state.campaignType,
									userinfo: this.state.userinfo
								}
							}}>
								<Button className="btnbtncreate" onClick={this.addcreate}>Proceed</Button>
							</Link>
						</Row>
					</Col>
				</Col>
			</div>
		);
	}
}

export default How;
