import React, { Component } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import Samosa from "../../images/samosa.png";
import Header from '../Header/NewHeader';
import './CreateCampaign.css';
import { Redirect } from 'react-router-dom';
import Login from "../Login/Login";

const auth = new Login();

class CreateCampaign extends Component {

        render() {
            if (!auth.loggedIn()) {
                return (<Redirect to="/new-login" />)
            }
        return (
            <div className="createcampaign">
                <Header />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="createcampaignmain">
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="createcampaignprotion1">
                                <p className="topheadingcreatecampaign">Create campaign.</p>
                            </Row>
                            <Row className="formcreatecampaign">
                                <Label className="createcampaignname-label" > Name </Label>
                                <Input type="text"
                                    name="name"
                                    id="paymentuserName"
                                    placeholder="Name"
                                    onChange={this.onChange}
                                    required />
                                <Label className="createcampaignname-label" > Location </Label>
                                <Input type="text"
                                    name="location"
                                    id="paymentuserName"
                                    placeholder="Location"
                                    onChange={this.onChange}
                                    required />
                                <Label className="createcampaignname-label" > Type </Label>
                                <Input type="select"
                                    name="userName"
                                    id="paymentuserName"
                                    placeholder="Card number"
                                    onChange={this.onChange}
                                    required />
                                <Label className="createcampaignname-label" > Showing dates(optional) </Label>
                                <Input type="date"
                                    name="date"
                                    id="paymentuserName"
                                    placeholder="Showing dates"
                                    onChange={this.onChange}
                                    required />
                                <Label className="createcampaignname-label" > Description(optional) </Label>
                                <Input type="textarea"
                                    name="description"
                                    id="paymentuserName"
                                    placeholder="Description"
                                    onChange={this.onChange}
                                    required />
                                <Row className="createcampaignstate">
                                    <Col>
                                        <Row className="createcampaignbtns">
                                            <Row className="pdf-btn-wrapper-createcampaign">
                                                <button class="createcampaign-img-btn">+ Add image</button>
                                                <Input
                                                    type="file" />
                                            </Row>
                                        </Row>
                                    </Col>

                                    <Col>
                                        <Row className="createcampaignbtns">
                                            <Button
                                                className="createcampaignsubmit"
                                                type="submit"> Next </Button>
                                        </Row>
                                    </Col>

                                </Row>
                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="createcampaignprotion2">
                                <img src={Samosa} height="550" width="auto"></img>
                            </Row>
                        </Col>

                    </Row>
                </Col>
            </div>
        );
    }
}

export default CreateCampaign;
