import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './Routers/AppRouter';

const jsx = (
    <AppRouter/>
)

ReactDOM.render(jsx, document.getElementById('root'));
