import React, { Component } from 'react';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Form, Input, Row } from 'reactstrap';
import swal from 'sweetalert';
import AuthServices from '../Apiservices/AuthService';
import './passwordReset.css';
import Login from "../Login/Login";

const auth = new Login();
const authService = new AuthServices();

class PasswordReset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            redirect: false,
            alert: false,
            invalid: '',
            role: '',
            loader: false
        };
        this.resetPassword = this.resetPassword.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    resetPassword(e) {
        e.preventDefault()
        if (this.state.email) {
            let data = {
                email: this.state.email
            }
            console.log(this.state.email)
            this.setState({ loader: true });
            authService.resetPassword(data)
                .then((result) => {
                    console.log(result, "Hello g ")
                    let responseJson = result;
                    if (responseJson.status == '200') {
                        swal("Done", "Password has been sent to your email", "success");
                        setTimeout(() => {
                            this.setState({ redirect: true });
                            this.setState({ loader: false });
                        }, 1000)
                    }
                    
                })
                .catch(err => {
                    this.setState({ alert: true });
                    swal("Oops", err.response.data.error.message, "error");
                    this.setState({ loader: false });
                });
        }
        else {
            swal("Oops", "Please provide email", "warning");

        }
    }

    render() {
        if (auth.loggedIn() || this.state.redirect == true) {
            return (
                window.location.replace("/dashboard")
            )
        }
        return (

            <div className="mainlogin">
                {/* <Header/> */}
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row >
                        <Col lg={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} sm={12} xs={12}>
                            <Row className="welcomeheading">
                                <Row className="Welcometext">
                                    <p>Reset Password!</p>
                                </Row>
                            </Row>
                        </Col>
                        <Col lg={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} sm={12} xs={12}>
                            <Row className="formbodymain">
                                <Form className="formclass">
                                    <Input
                                        className="emailinput"
                                        type="email"
                                        name="email"
                                        pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                        placeholder="Email"
                                        onChange={this.onChange}
                                        required />
                                    <Button
                                        className="loginhomebtn"
                                        type="submit"
                                        refresh="true"
                                        onClick={this.resetPassword} > Submit </Button>
                                </Form>

                            </Row>
                        </Col>

                        <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                            <div className='overlay-box'>
                                <PropagateLoader
                                    // css={override}
                                    sizeUnit={"px"}
                                    size={25}
                                    color={'white'}
                                    loading={this.state.loader}
                                />
                            </div>
                        </div>
                    </Row>
                </Col>
            </div>
        );
    }
}

export default PasswordReset;
