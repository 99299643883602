import React from 'react'
import axios from 'axios';


class GetUserLocationFromIp extends React.Component {

    getGeoInfo = () => {
       return axios.get('https://ipapi.co/json/')
            .then((response) => {
                let data = response.data;
                console.log(data)
                return data

            }).catch((error) => {
                console.log(error);
            });
    };

}
export default GetUserLocationFromIp;