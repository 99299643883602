import React from 'react'
import axios from 'axios';
import Login from "../Login/Login";
import { API_BASE } from '../../config'

const auth = new Login();
let instance = axios.create({
    baseURL: API_BASE + 'listingAd',
    headers: {
        'Authorization': `Bearer ${auth.getToken()}`
    }
});

class ListingAdServices extends React.Component {
    getListingAd(listingAdId) {
        let userId = auth.getId()
        return instance.get('/' + listingAdId)
    }

    createListingAd(data) {
        return instance.post('/', data)
    }

    createListingAdVideo(data) {
        return instance.post('/video', data)
    }

    updateListingAd(listingAdId, data) {
        return instance.put('/' + listingAdId, data)
    }

    createAfterNote(data) {
        return instance.post('/afternoteimage', data)
    }

    uploadListingAdImage(data){
        return instance.post('/uploadimage', data)
    }
}
export default ListingAdServices;