import React, { Component } from 'react';
import './AgencyCampaign.css';
import { Row, Col, Button } from 'reactstrap';
import { Link , Redirect } from 'react-router-dom';
import Header from '../Header/NewHeader';
import image from "../../images/agency-campaign.png";
import Login from "../Login/Login";

const auth = new Login();

class AgencyCampaign extends Component {

    render() {
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }
        return (
            <div className="createagencycampaign">
                <Header />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="agencycampaignmain">
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }} >
                            <Row className="agencycampaignprotion1">
                                <Row className="topheadingagencycampaign">
                                    <p >Agency campaign.</p>
                                </Row>

                            </Row>
                            <Row className="formagencycampaign">
                                <Row className="agencyheading2">
                                    <p>Sodermaklarna</p>
                                </Row>
                                <Row className="agencyheading3">
                                    <p>Sodermalm</p>
                                </Row>
                                <Row className="agencyheading4">
                                    <p>Hornsgatan 37,118 49 Stockgolm</p>
                                </Row>

                                <Row className="agencyheading6">
                                    <p>08-643 10 80</p>
                                </Row>
                                <Row className="agencyheading7">
                                    <p>mariatorget@sodermaklarna.</p>
                                </Row>
                                <Row className="agencystate">
                                    <Button
                                        className="agencysubmit"
                                        type="submit"> + Create ad </Button>
                                </Row>
                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="agencycampaignprotion2">
                                <img src={image} height="auto" width="100%"></img>
                            </Row>
                        </Col>

                    </Row>
                </Col>
            </div>
        );
    }
}

export default AgencyCampaign;