import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Input, Label, Row, Form } from 'reactstrap';
import swal from 'sweetalert';
import CampaignServices from "../Apiservices/CampaignService";
import ListingAdServices from '../Apiservices/ListingAdServices';
import Header from '../Header/NewHeader';
import './CreateAfterNote.css';
import './Custom_CreateAfterNote.css';
import MobileAdPreview from '../MobileAdPreview';
import { IMG_URL } from '../../config'
import Login from "../Login/Login";
import Location from '../Apiservices/GetUserLocationFromIp';
import UserServices from '../Apiservices/UserService';
const location = new Location();

const auth = new Login();

const listingAdServices = new ListingAdServices();
const campaignService = new CampaignServices();
let getrole = localStorage.getItem("currentRole");
const userService = new UserServices();
class CreateAfterNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            alert: false,
            region: 'other',
            listingData: {},
            campaigns: [],
            loader: false,
            afterNoteUrl: "",
            propertyUrl: "",
            agentUrl: "",
            next: false,
            selectedCampaign: null,
            editCampign: false,
            comingWithActiveCampiagn: props.location.state.previousPath == "/Sold-out"
                ? true
                : false,
            previousPath: props.location.state.previousPath
                ? props.location.state.previousPath
                : "",
            userProfile: props.location.state.userProfile
                ? props.location.state.userProfile
                : {},
            reverse: props.location.state.reverse
                ? props.location.state.reverse
                : false,
            currentCampaign: this.props.location.state.currentCampaign
                ? this.props.location.state.currentCampaign
                : {},
            disable: this.props.location.state.currentCampaign
                ? true
                : false,
            campaignType: props.location.state.campaignType
                ? props.location.state.campaignType
                : 'agent',
            message: this.props.location.state.currentCampaign
                ? "Jag har precis sålt " + this.props.location.state.currentCampaign.listingAd.description + " i ditt område och har flera intresserade spekulanter!"
                : "Jag har nyligen sålt detta objekt för i ditt  område och har flera intresserade spekulanter. Välkommen att kontakta mig för en kostnadsfri borådgivning.",
            message_english: this.props.location.state.currentCampaign
                ? "I recently sold " + this.props.location.state.currentCampaign.listingAd.description + "in your area and have several interested buyers looking for similar properties."
                : "I recently sold this property in your area and have several interested buyers looking for similar properties. Feel free to contact me for a free valuation/advice.",
            headline: this.props.location.state.currentCampaign
                ? "Vill du veta vad din bostad är värd?"
                : "Vill du veta vad din bostad är värd?",
            headlineenglish: this.props.location.state.currentCampaign
                ? "Want to know how much your home is worth?"
                : "Want to know how much your home is worth?",
            description: this.props.location.state.currentCampaign
                ? this.props.location.state.userProfile.description
                : "Få en kostnadsfri borådgivning.",
            descriptionenglish: this.props.location.state.currentCampaign
                ? this.props.location.state.userProfile.description
                : "Free advice from a local expert.",
            // client want the following things prefilled
            // message: "Jag har precis sålt " + this.props.location.state.currentCampaign.listingAd.description + " i ditt område och har flera intresserade spekulanter!",
            // headline: "Vill du veta vad din bostad är värd?",
            // description: this.props.location.state.userProfile.description,
            businessName: "",
            website: this.props.location.state.currentCampaign
                ? this.props.location.state.currentCampaign.listingAd.website
                : this.props.location.state.userProfile.website,
            listingAdImages: this.props.location.state.afterNoteUrl
                ? [this.props.location.state.afterNoteUrl]
                : this.props.location.state.currentCampaign
                    ? this.props.location.state.currentCampaign.listingAd.listingAdImages
                    : props.location.state.campaignType == "agent" || "agency"
                        ? [this.props.location.state.userProfile.userImage]
                        : [],
            previewImage: this.props.location.state.afterNoteUrl
                ? IMG_URL + this.props.location.state.afterNoteUrl
                : this.props.location.state.currentCampaign
                    ? IMG_URL + this.props.location.state.currentCampaign.listingAd.listingAdImages[0]
                    : props.location.state.campaignType == "agent" || "agency"
                        ? props.location.state.userProfile.userImage.startsWith("https://") ? props.location.state.userProfile.userImage : IMG_URL + props.location.state.userProfile.userImage
                        : "",
            afterNoteImage: "",
            customImage: "",
            testlocation: "",
            users: [],
            agentImage: "",
            attachimagecheck: "",
            user_agent: "",
            currentuser: "",
            user: ""


        };
        console.log(props.location.state.previousPath, "description")
        this.onChange = this.onChange.bind(this);
        this.onChange1 = this.onChange1.bind(this);
        this.onChangeListingImage = this.onChangeListingImage.bind(this);
        this.createListingAd = this.createListingAd.bind(this);
    }

    componentDidMount() {
        // this.setState({ loader: true })
        if (this.state.comingWithActiveCampiagn == false) {

            campaignService.getUserCampaigns()
                .then(result => {
                    let responseJson = result.data
                    this.setState({ campaigns: responseJson });

                })
                .catch(err => {
                    // alert("Oops", "oh no", "error");
                });
            location.getGeoInfo()
                .then((result) => {
                    let location = result.country_name;
                    this.setState({ testlocation: location });
                    if (this.state.testlocation === "Sweden") {
                        this.setState({ message: this.state.message });
                        this.setState({ headline: this.state.headline });
                        this.setState({ description: this.state.description });
                    }
                    else {
                        this.setState({ message: this.state.message_english });
                        this.setState({ headline: this.state.headlineenglish });
                        this.setState({ description: this.state.descriptionenglish });
                    }
                })
                .catch(err => {
                    // alert("Oops", "oh no", "error");
                });
        }
        userService.getUsers()
            .then((result1) => {

                let responseJson = result1.data
                console.log(responseJson, "usmandata")
                this.setState({ users: responseJson });
                // this.setState({ loader: false })


            })
            .catch(err => {
                swal("Oops", err.response.data.error.message, "error");
                // this.setState({ loader: false })

            });
        console.log("usmanmaqsood2 ")

        console.log("usmanmaqsood " + this.state.users)


    };

    createAfterNoteImage = async (e) => {
        this.setState({ loader: true })

        let getLocation = await location.getGeoInfo();

        let data = {
            propertyUrl: this.state.currentCampaign && this.state.customImage == '' ?
                this.state.currentCampaign.listingAd.listingAdImages[0] : this.state.customImage,
            agentUrl: this.state.agentImage ? this.state.agentImage : this.state.userProfile.userImage,
            // agentUrl: this.state.userProfile.userImage,
            agentName: this.state.currentuser ? this.state.currentuser.name : this.state.userProfile.name,
            //  agentName:  this.state.userProfile.name,

            agentPhone: this.state.userProfile.phoneNumber,
            country: getLocation.country_name
            // userId: '5e26ca580bf44f2118527f9a'
        }

        console.log("datttt", data);
        try {
            if (data.propertyUrl !== "") {
                if (data.agentUrl === "") {
                    swal("Sorry", "Please add your profile image", "warning")
                        .then(() => {
                            this.setState({ loader: false });
                        })
                } else {
                    let createAfterNoteImage = await listingAdServices.createAfterNote(data)
                    console.log(createAfterNoteImage.data, "image afternote")
                    if (createAfterNoteImage.status == 200) {
                        this.setState({ listingAdImages: createAfterNoteImage.data });
                        console.log(IMG_URL + this.state.listingAdImages.afterNoteImage)
                        this.setState({ previewImage: IMG_URL + createAfterNoteImage.data.afterNoteImage }, () => {
                            this.setState({ loader: false });
                        });
                        // this.setState({ redirect: true });
                    }
                }

            } else if (data.propertyUrl === "") {
                swal("Oops", "Something is missing", "Error");
            }
        }
        catch (err) {
            this.setState({ loader: false });
            this.setState({ alert: true });
        }
    };



    onChange1 = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        });
        this.setState({ customImage: '' });
        setTimeout(() => {
            console.log(this.state.selectedCampaign)
            console.log(this.state.campaigns[this.state.selectedCampaign])
            this.setState({ currentCampaign: this.state.campaigns[this.state.selectedCampaign] });
            // this.setState({ headline: "SÅLD - " + this.state.campaigns[this.state.selectedCampaign].listingAd.description });
            // this.setState({ website: this.state.campaigns[this.state.selectedCampaign].listingAd.website });
            //this.setState({ previewImage: IMG_URL + this.state.campaigns[this.state.selectedCampaign].listingAd.listingAdImages[0] });
            this.setState({ propertyUrl: this.state.campaigns[this.state.selectedCampaign].listingAd.listingAdImages[0] });
            this.setState({ message: `Jag har nyligen sålt ${this.state.campaigns[this.state.selectedCampaign].listingAd.description} i ditt  område och har flera intresserade spekulanter. Välkommen att kontakta mig för en kostnadsfri borådgivning.` });
            this.setState({ agentUrl: this.state.userProfile.userImage }, () => {
                this.createAfterNoteImage()
            });
            // this.setState({ message: this.state.campaigns[this.state.selectedCampaign].listingAd.message });
            // this.setState({ businessName: this.state.campaigns[this.state.selectedCampaign].listingAd.businessName });
            // this.setState({ description: this.state.campaigns[this.state.selectedCampaign].listingAd.description });
            // this.setState({ headline: this.state.campaigns[this.state.selectedCampaign].listingAd.description });
        }, 1000)
        // setTimeout(() => {
        //     this.createAfterNoteImage()
        // }, 1000)

    };
    onChangeImage = (e) => {
        let temp = this.state.users[e.target.value]
        this.setState({ currentuser: temp, user: temp._id });
        this.setState({
            user_agent: "clicked"
        });

        this.setState({
            agentImage: temp.userImage ? temp.userImage : ""
        });
        if (this.state.selectedCampaign) {
            this.createAfterNoteImage()
        }
        else if (this.state.attachimagecheck === "attached") {
            this.createAfterNoteImage()

        }

    };

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });

    };

    onChangeListingImage = async (e) => {
        console.log(e.target.files)
        // this.setState({ listingAdImages: e.target.files, previewImage: URL.createObjectURL(e.target.files[0]) });
        // this.setState({ listingAdImages: e.target.files });
        this.setState({ loader: true })
        let formData = new FormData();
        formData.append('listingAdImage', e.target.files[0]);
        listingAdServices.uploadListingAdImage(formData)
            .then((result) => {
                if (result.status == 200) {
                    this.setState({ customImage: result.data }, () => {
                        this.setState({ attachimagecheck: "attached" });
                        this.createAfterNoteImage()
                    });
                    console.log("imaggge uploaded", result.data);

                    // setTimeout(() => {



                    // }, 1500)
                }
            })
            .catch(err => {
                console.log(err)
                this.setState({ loader: false });
                this.setState({ alert: true })
            });
        // console.log(customImageKey, "listingAdImage");
        // if (customImage.status == 200) {
        //     // this.setState({ listingAdImages: createAfterNoteImage.data });
        //     // console.log(IMG_URL + this.state.listingAdImages.afterNoteImage)
        //     // this.setState({ previewImage: IMG_URL + this.state.listingAdImages.afterNoteImage });
        //     this.setState({ loader: false });
        // }
    }

    createListingAd = async (e) => {
        console.log("in create listing ad")
        e.preventDefault()
        if (this.state.headline && this.state.message
            && this.state.description && this.state.website && this.state.listingAdImages) {
            this.setState({ loader: true });
            this.setState({ listingAdImages: [this.state.afterNoteImage] });
            let formData = new FormData();
            formData.append('headline', this.state.headline);
            formData.append('message', this.state.message);
            formData.append('businessName', this.state.businessName);
            formData.append('description', this.state.description);
            formData.append('website', this.state.website);
            formData.append('region', this.state.region);
            formData.append('listingAdImages', this.state.listingAdImages.afterNoteImage);
            let role = localStorage.getItem('currentRole')
            if (role === 'agency') {
                formData.append('user', this.state.user);
            }
            // for (const listingImage of this.state.listingAdImages) {
            //     formData.append('listingAdImages', listingImage);
            // }
            listingAdServices.createListingAd(formData)
                .then(result => {
                    console.log(result, "Hello")
                    if (result.status == 200) {
                        this.setState({ listingData: result.data })
                        this.setState({ redirect: true });
                        this.setState({ loader: false });
                    }
                })
                .catch(err => {
                    this.setState({ alert: true });
                    this.setState({ loader: false });
                    console.log(err, "err")
                    swal("Oops", err.response.data.error.message, "error");
                });
        }
        else {
            swal("Oops", "Something is missing", "warning");
        }
    };

    render() {
        const items = [];
        {
            this.state.campaigns &&
                this.state.campaigns.map((campaign, index) => {



                })
        }

        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }
        if (this.state.redirect == true && this.state.editCampign == true) {

            return (
                <Redirect to={{
                    pathname: "/edit-ad-new",
                    state: {
                        currentCampaign: this.state.campaigns[this.state.selectedCampaign],

                    }
                }} />
            )
        }
        else if (this.state.redirect == true) {
            return (
                <Redirect to={{
                    pathname: "/ad-preview",
                    state: {
                        campaignType: this.state.campaignType,
                        userProfile: this.state.userProfile,
                        previousPath: this.props.location.pathname,
                        listingData: this.state.listingData,
                        previewImage: this.state.previewImage
                    }
                }} />
            )
        }

        return (

            <div className="createafternote">
                <Header backButton={this.state.previousPath}
                    previousData={{
                        userProfile: this.state.userProfile,
                        campaignType: this.state.campaignType,
                        previousPath: this.props.location.pathname
                    }}
                />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="createafternotemain">
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="createafternoteprotion1">
                                <p className="topheadingcreateafternote">Create after note.</p>
                            </Row>
                            <Form onSubmit={this.createListingAd}>
                                <Row className="formcreateaafternote">

                                    <Label className="createafternote-label" > Choose active campaign </Label>
                                    <Input id="targetinputafternote"
                                        type="select"
                                        name="selectedCampaign"
                                        disabled={this.state.disable}
                                        onChange={this.onChange1}>
                                        <option>Select campaign</option>
                                        {this.state.campaigns &&
                                            this.state.campaigns.map((campaign, index) => {
                                                return (

                                                    campaign.listingAd ? <option value={index} >{campaign.listingAd.description}</option> : ""
                                                )
                                            })}
                                    </Input>
                                    {getrole == "agency" && <Label className="createafternote-label" > Include Agent Image </Label>}
                                    {getrole == "agency" && <Input id="targetinputafternote"
                                        type="select"
                                        name="selectedCampaign"
                                        onChange={this.onChangeImage}>
                                        <option>Select Agent Image</option>
                                        {this.state.users &&
                                            this.state.users.map((users, index) => {
                                                return (

                                                    <option value={index} >{users.name}  </option>

                                                )
                                            })}
                                    </Input>}




                                    <Label className="createafternoteOR-label" > _______________ OR ________________ </Label>
                                    <Label className="createadnew-label" > Message* <span className="createadnew-label-span">(max: 300)</span></Label>
                                    <Input type="text"
                                        name="message"
                                        id="createadnewinput"
                                        placeholder="Message"
                                        value={this.state.message}
                                        maxLength={300}
                                        onChange={this.onChange}
                                        required />
                                   

                                    <Label className="createafternote-label" > Headline* <span className="createadnew-label-span">(max: 60)</span></Label>
                                    <Input type="text"
                                        name="headline"
                                        id="createafternoteinput"
                                        placeholder="Name"
                                        maxLength={60}
                                        value={this.state.headline}
                                        onChange={this.onChange}
                                        required />
                                    <Label className="createafternote-label" > Website* <span className="createadnew-label-span">(max: 300)</span> </Label>
                                    <Input type="url"
                                        pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                                        name="website"
                                        id="createafternoteinput"
                                        value={this.state.website}
                                        placeholder="https://example.com"
                                        onChange={this.onChange}
                                        value={this.state.website}
                                        required />
                                    <Label className="createafternote-label" > Description* <span className="createadnew-label-span">(max: 300)</span> </Label>
                                    <Input type="textarea"
                                        name="description"
                                        id="createafternoteinput"
                                        placeholder="Description"
                                        maxLength={300}
                                        value={this.state.description}
                                        onChange={this.onChange}
                                        required />

                                    <Row className="createafternotestate">
                                        <Col>
                                            <Row className="createafternotebtns">
                                                <Row className="createafternote-btn">
                                                    <Button class="createafternote-img-btn">+ Attach images</Button>
                                                    <Input
                                                        type="file"
                                                        name="listingAdImages"
                                                        onChange={this.onChangeListingImage}
                                                    // multiple
                                                    />
                                                </Row>
                                            </Row>
                                        </Col>

                                        <Col>
                                            <Row className="createafternotebtns">
                                                <Button className="createafternotesubmit" type="submit"> Next </Button>
                                            </Row>
                                        </Col>
                                        <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                                            <div className='overlay-box'>
                                                <PropagateLoader
                                                    // css={override}
                                                    sizeUnit={"px"}
                                                    size={25}
                                                    color={'white'}
                                                    loading={this.state.loader}
                                                />
                                            </div>
                                        </div>

                                    </Row>
                                </Row>
                            </Form>
                        </Col>

                        {/* <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="createafternoteprotion2">
                                <img src={Samosa} height="500" width="auto"></img>
                            </Row>
                        </Col> */}
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 5, offset: 1 }} sm={{ size: 12, offset: 0 }} xs={{ size: 12, offset: 0 }} >
                            {/* <Row className="infoadsettingprotion2">
                                <img src={image} height="auto" width="100%"></img>
                            </Row> */}
                            {/* <MobileAdPreview name="Test" businessName="BrightBee" description="Awesome"  /> */}

                            {/* <MobileAdPreview headline={this.state.headline} message={this.state.message} website={this.state.website} description={this.state.description} previewImage={this.state.previewImage || (this.state.listingAdImages && this.state.listingAdImages.length > 0 && IMG_URL + this.state.listingAdImages[0])} /> */}
                            <MobileAdPreview headline={this.state.headline} message={this.state.message} website={this.state.website} description={this.state.description} previewImage={this.state.previewImage} />

                        </Col>

                    </Row>
                </Col>
            </div>
        );
    }
}

export default CreateAfterNote;
