import React, { Component } from 'react';
import './Style.css';
import { Row, Col, Card, CardText, Button, Input } from 'reactstrap';
import House from '../../images/dollar.jpg'
import { Link, Redirect } from 'react-router-dom';
import Left from '../../images/leftarrow.png'
// import Right from '../../images/rightarrow.png'
// import Bg from '../../images/bg.png';
import Header from '../Header';
// import swal from 'sweetalert';
import Apiservices from '../Apiservices/Urlservice';

const urlpost = new Apiservices();
// const toggle = () => setModal(!modal);
class Annons extends Component {
	constructor(props) {
		super(props);
		this.state =
			{
				name: this.props.location.state.name,
				campaign: {},
				campaignBudget: this.props.location.state.budget_id,
				startDate: this.props.location.state.startDate,
				endDate: this.props.location.state.endDate,
				biddingStrategy: "",
				biddings: [],
				dropdownOpen: false,
				Draftlunchmodal: false,
				Draftlunchmodal2: false,
				redirect: false,
				alert: false
			};
		this.toggle = this.toggle.bind(this);
		this.Drafttoggle = this.Drafttoggle.bind(this);
		this.Drafttoggle2 = this.Drafttoggle2.bind(this);
		this.addcampagin = this.addcampagin.bind(this);
		this.onChange = this.onChange.bind(this);
	}
	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}
	Drafttoggle() {
		this.setState({
			Draftlunchmodal: !this.state.Draftlunchmodal
		});
	}
	Drafttoggle2() {
		this.setState({
			Draftlunchmodal2: !this.state.Draftlunchmodal2
		});
	}

	// addcampagin = () => {
	//     console.log("id", this.state.campaignBudget),
	//     console.log("name", this.state.name)
	//     console.log("srt", this.state.startDate)
	//     console.log("end", this.state.endDate)
	//     console.log("bind", this.state.biddingStrategy)
	//     if (this.state.name && this.state.startDate && this.state.endDate 
	//       && this.state.campaignBudget && this.state.biddingStrategy) {
	//       urlpost
	//         .newcampaign(this.state)
	//         .then(result => {
	//           let responseJson = result.data;
	//           if (responseJson) {
	//             this.setState({ campaign: responseJson });

	//             this.setState({ redirect: true });
	//             this.setState({ alert: true });
	//           }
	//         })
	//         .catch(err => {
	//           this.setState({ alert: true });
	//           this.setState({ invalid: err.response.data.error.message });
	//           swal("Oops", "kampanj namn finns redan", "error");
	//         });
	//     }
	//   };

	componentDidMount() {
		return urlpost.bidding()
			.then(res => {
				// console.log(`sdfghjoiuy ${JSON.stringify(res.data)}`);
				this.setState({ biddings: res.data });
			});
	}

	onChange(e) {
		console.log("ghfgj", e.target.value)
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		if (this.state.redirect == true) {
			return (<Redirect to={{
				pathname: "/ad-group",
				state: {
					name: this.state.name,
					_id: this.state.campaign._id
				}
			}} />)
		}
		return (
			<div>
				<Header />
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheader1">
						<p className="compname">{this.props.location.state.name}</p>
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheader2">
						<Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
							<Row className="leftarrow">
								<Link to={{
									pathname: "/budget",
									state: {
										name: this.state.campaign_name,
										redirect: true
									}
								}}>
									<img src={Left} alt="" height="33px"></img>
								</Link>
							</Row>
						</Col>
						<Col lg={10} md={10} sm={8} xs={8}><Row className="centertext">Kampanjsöversikt</Row></Col>
						{/* <Col  lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                  <Row className="rightarrow"><img src={Right} alt="rightarrow" height="20px"></img></Row>
                  </Col> */}
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12}>
					<Row className="budget-annons">
						{/* <Col lg={6} md={6} sm={6} xs={6}>
                <Link to="/budget">
            <Row className="budget">
               <p className="part1">BUDGET</p>
            </Row>
            </Link>
          </Col> */}
						<Col lg={12} md={12} sm={12} xs={12}>
							{/* <Link to="/bidding"> */}
							<Row className="annnons">
								<p className="part2">BIDDING</p>
							</Row>
							{/* </Link> */}
						</Col>
					</Row>
				</Col>
				{/* <Col lg={12} md={12} sm={12} xs={12}>
            <Row className="annonsmain">
                <Row className="test13">
                    <Button className="secondadtn">Ändra annonsen</Button>
                </Row>
                <Row className="editimgg">
                    <img className="editimg" src={Bg} alt="bg.png"></img>
                </Row>
            </Row>
          </Col> */}
				{/* <Col lg={{ size: 6, offset: 3, leftset: 2 }} md={12} sm={12} xs={12}>
            <div className="dataportion">
                    <Row>
                        <p className="boldheading">Intressenter</p>
                    </Row>
                    <Row>
                        <p className="lightheading">Intressenter</p>
                    </Row>
                    </div>
          </Col> */}
				<Col className="carrrrddddd" lg={12} md={12} sm={12} xs={12}>
					<div className="cardportion">
						{this.state.biddings &&
							this.state.biddings.map((bidd) => {
								return (
									<Col className="cardcol" lg={6} md={6} sm={12} xs={12}>
										<Card className="cardheight">
											<Row>
												<Col lg={4} md={4} sm={4} xs={12}>
													<img src={House} className="budgetimage" alt="doctor" />
												</Col>
												<Col className="col-profile-doc-heading" lg={6} md={6} sm={4} xs={10}>
													<CardText >
														<p className="headingtopp"> <h3>{bidd.name}</h3></p>
														<p className="heading2"><br />  Max bidding per click: <span className="colorspan">{bidd.cpcMaxBidding}</span></p>
													</CardText>
												</Col>
												<Col lg={2} md={2} sm={2} xs={2} >
													<Row className="radiobtnn">
														<label class="custom-container">
															<Input
																type="radio"
																name="biddingStrategy"
																value={bidd._id}
																onChange={this.onChange}
															>
															</Input>
															<span class="checkmark"></span>
														</label>
													</Row>
												</Col>
											</Row>
										</Card>
									</Col>
								)
							})}
						<Col>
							<Row className="nextbtn">
								<Button className="btnnext" onClick={this.addcampagin}>Create Campaign</Button>
							</Row>
						</Col>
					</div>
				</Col>
			</div>
		);
	}
}

export default Annons;
