import React from 'react';
import { Button, Form, ModalBody, Input, Col, Row, FormGroup } from 'reactstrap'
import './Style.css'
import { Redirect } from 'react-router-dom';
import { Card } from '@material-ui/core';
import { CardBody } from 'mdbreact';
import Header from '../Header/index.js';
import Spinner from 'react-spinner-material';
import swal from 'sweetalert';
import Apiservices from '../Apiservices/PaymentServices';
import Apiservicess from '../Apiservices/Urlservice';
import Login from '../Login/Login'
const auth = new Login();
const cardServices = new Apiservices();
const urlpost = new Apiservicess();

class Cardinfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isCard: false,
			cardHolderName: "",
			number: "",
			expiryMonth: "",
			expiryYear: "",
			cvc: "",
			redirect: false,
			alert: false,
			loading: false,
			userinfo: {}
		};
		this.onChange = this.onChange.bind(this);
		this.addCard = this.addCard.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	async componentDidMount() {
		this.setState({ loading: true });
		const result = await urlpost.userprofile(auth.getId());
		// console.log(`sdfghjoiuy ${JSON.stringify(res.data)}`);
		this.setState({ userinfo: result.data });
		this.setState({ isCard: result.data.user.card });
		console.log("userinfo", this.state.userinfo.user.card)
		this.setState({ loading: false });
	}

	addCard = () => {
		if (this.state.cardHolderName && this.state.number && this.state.expiryMonth
			&& this.state.expiryYear && this.state.cvc)
		// this.setState({loading: true});
		{
			let data = {
				cardHolderName: this.state.cardHolderName,
				cardNumber: this.state.number,
				expiryMonth: this.state.expiryMonth,
				expiryYear: this.state.expiryYear,
				cvc: this.state.cvc
			}
			cardServices.addCard(data)
				.then(result => {
					let responseJson = result.data;
					if (responseJson) {
						this.setState({ property: responseJson });
						this.setState({ redirect: true });
						this.setState({ alert: true });
						// this.setState({ loading: false });
					}
					swal("tack", "information has been added", "success");
				})
				.catch(err => {
					this.setState({ alert: true });
					this.setState({ invalid: err.response.data.error.message });
					swal("Oops", err.response.data.error.message, "error");
				});
		}
	};

	render() {
		if (this.state.redirect === true) {
			return (
				<Redirect to="/" />
			)
		}
		return (
			<div>
				<Header />
				{console.log('kkkkkkkk', this.state.isCard)}
				{this.state.isCard === false && (
					<Col className="margins" lg={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} sm={12} xs={12}>
						<h1 className="heading-reg">Enter Card Information</h1>
						<Form>
							<ModalBody>
								<Row className="namefields">
									<label className="labelproperty">Name</label>
									<Col lg={12} md={12} sm={12} xs={12} >
										<FormGroup>
											<Input
												id="datenew11"
												type="text"
												name="cardHolderName"
												placeholder="Cardholder's name"
												onChange={this.onChange} />
										</FormGroup>
									</Col>
								</Row>
								<Row className="namefields">
									<label className="labelproperty">Card number</label>
									<Col lg={12} md={12} sm={12} xs={12} >
										<FormGroup>
											<Input
												id="datenew11"
												// maxlength="16"
												// min="0"
												type="number"
												name="number"
												placeholder="Card number"
												onChange={this.onChange} />
										</FormGroup>
									</Col>
								</Row>
								<Row className="namefields">
									<label className="labelproperty">Expiry month</label>
									<Col lg={12} md={12} sm={12} xs={12}>
										<FormGroup>
											<Input
												id="datenew11"
												type="number"
												maxLength="2"
												name="expiryMonth"
												placeholder="Expiry Month"
												onChange={this.onChange} />
										</FormGroup>
									</Col>
								</Row>
								<Row className="namefields">
									<label className="labelproperty">Expiry year</label>
									<Col lg={12} md={12} sm={12} xs={12}>
										<FormGroup>
											<Input id="datenew11" type="number" name="expiryYear" placeholder="Expity year" onChange={this.onChange} />
										</FormGroup>
									</Col>
								</Row>
								<Row className="namefields">
									<label className="labelproperty">CVC</label>
									<Col lg={12} md={12} sm={12} xs={12}>
										<FormGroup>
											<Input id="datenew11" type="number" name="cvc" placeholder="CVC" onChange={this.onChange} />
										</FormGroup>
									</Col>
								</Row>
								<Row className="btncreatepropert">
									<Col lg={{ size: 3, offset: 5 }} md={{ size: 4, offset: 5 }} sm={{ size: 5, offset: 3 }} xs={{ size: 5, offset: 3 }}>
										<Button className="craeteproperty" onClick={this.addCard}>Submit</Button>
									</Col>
								</Row>
							</ModalBody>
						</Form>
					</Col>
				)}

				{this.state.isCard === true && (
					<Col className="margins" lg={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} sm={12} xs={12}>
						<h1 className="heading-reg">Card Information</h1>
						<Row className="cardinformation">
							<Col>
								<Row className="lefthead">
									<p className="leftheading">Card Holder's Name</p>
								</Row>
							</Col>
							<Col>
								<Row className="righthead">
									<p className="rightvalue">{this.state.userinfo.card.cardHolderName}</p>
								</Row>
							</Col>
						</Row>
						<Row className="cardinformation">
							<Col>
								<Row className="lefthead">
									<p className="leftheading">Card Type</p>
								</Row>
							</Col>
							<Col>
								<Row className="righthead">
									<p className="rightvalue">{this.state.userinfo.card.cardType}</p>
								</Row>
							</Col>
						</Row>
						<Row className="cardinformation">
							<Col>
								<Row className="lefthead">
									<p className="leftheading">Card Brand</p>
								</Row>
							</Col>
							<Col>
								<Row className="righthead">
									<p className="rightvalue">{this.state.userinfo.card.cardBrand}</p>
								</Row>
							</Col>
						</Row>
						<Row className="cardinformation">
							<Col>
								<Row className="lefthead">
									<p className="leftheading">Card Number</p>
								</Row>
							</Col>
							<Col>
								<Row className="righthead">
									<p className="rightvalue">****-****-****-{this.state.userinfo.card.lastDigits}</p>
								</Row>
							</Col>
						</Row>
					</Col>
				)}

			</div>
		)
	}
}
export default (Cardinfo);