
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Input, Label, Row } from 'reactstrap';
import swal from 'sweetalert';
import { IMG_URL } from '../../config'
import Addphoto from '../../images/addphoto.png';
import UserServices from "../Apiservices/UserService";
import Header from '../Header/NewHeader';
import Login from "../Login/Login";
import './Style.css';
import './Profile.css';
import ReactDOM from 'react-dom';
import AvatarEditor from 'react-avatar-editor';
import Apiservices from '../Apiservices/AuthService';
import signupimage from "../../images/listing-ad.png";

const userServices = new UserServices();
const auth = new Login();

const userId = auth.getId()
const Auth = new Apiservices();
class signupagency extends Component {

   
   constructor(props) {
      super(props);
      this.state = {
          redirect: false,
          alert: false,
          invalid: '',
          loader: false,
          name: "",
          email: "",
          password: "",
          pageId: "",
          officeId: "",
          instagramId:"",
          check: false,
      };
      this.handelChange = this.handelChange.bind(this);
      this.onChange = this.onChange.bind(this);
      this.fieldsValidation = this.fieldsValidation.bind(this);
      this.signupRequest = this.signupRequest.bind(this);
      this.toggleCheck = this.toggleCheck.bind(this);
      // this.scrollTomid = this.scrollTomid.bind(this);

  }




  onChange(e) {
      this.setState({ [e.target.name]: e.target.value });
      console.log({ [e.target.name]: e.target.value }, "data");
  }



  handelChange = even => {
      var newValue = even.nativeEvent.target.value;
      console.log(newValue)
      setTimeout(() => {
          this.setState({ country: newValue });
      }, 1000);
  };

  fieldsValidation = () => {
      
       if (this.state.email == "") {
          return "email is missing"
      }
     
      
      return null;
  };

  signupRequest(e) {
      e.preventDefault()
      console.log()
      if (this.fieldsValidation() != undefined) {
          swal("oops", this.fieldsValidation(), "warning");
      }
      else {
          this.setState({ loader: true });
          
          let myemail=this.state.email.toString();
          let split_email=this.state.email.split('@');
          let password_generate=split_email[1].split('.');
          let myName=password_generate[0].toString();
          let new_password=password_generate[0]+"%12";
         
          this.setState({ password: new_password });
          this.setState({ name: myName });


           let  data = 
           {
              name: myName,
              email: this.state.email,
              password: new_password,
              pageId:this.state.pageId,
              officeId: this.state.officeId,
              instagramId:this.state.instagramId
          }
         
       
        
         
          Auth.signupAgencyRequest(data)
              .then((result) => {
                  let responseJson = result;
                  if (responseJson.status == '200') {
                     //  this.setState({ redirect: true });
                      this.setState({ loader: false });
                      swal("Done", "Successfully done! Your Password is:  "+this.state.password, "success")
                  }
              })
              .catch(err => {
                  this.setState({ alert: true });
                  this.setState({ invalid: err.response.data.error.message });
                  swal("Oops", err.response.data.error.message, "error");
              });
      }

  }

  toggleCheck = () => {
      this.setState(prevState => ({
          check: !prevState.check,
      }));
  }

  openPrivacyPolicy = () => {
  this.setState(prevState => ({
    openPrivacyModal: !prevState.openPrivacyModal
  }));
};
  

   





   render() {
      const { isLoading, options, value } = this.state;
      if (!auth.loggedIn()) {
         return (<Redirect to="/new-login" />)
      }

      if (this.state.redirect == true) {
         return (
            <Redirect to={{
               pathname: "/dashboard",
            }} />
         )
      }

      return (
         <div className="createprofile">
            <Header />
            <Col lg={12} md={12} sm={12} xs={12} >
               <Row className="createprofilemain">
                  <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} order="12">
                     <Row className="profileprotion1">
                        <p className="topheading">Create Agency Account!</p>
                     </Row>
                     <Row className="customFieldRows">
                                
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Label className="label" > Email </Label>
                                    <Row className="dataInputBlock">
                                        <Input
                                            className="in"
                                            onChange={this.onChange}
                                            type="text" name="email" placeholder="Email" >
                                        </Input>
                                    </Row>
                                </Col>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Label className="label" > Facebook Page Id </Label>
                                    <Row className="dataInputBlock">
                                        <Input
                                            className="in"
                                            onChange={this.onChange}
                                            type="number" name="pageId" placeholder="Facebook Page Id" >
                                        </Input>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="customFieldRows">
                             
                            </Row>
                            <Row className="customFieldRows">
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Label className="label" > Office Id </Label>
                                    <Row className="dataInputBlock">
                                        <Input
                                            className="in"
                                            onChange={this.onChange}
                                            type="text" name="officeId" placeholder="Office Id"  >
                                        </Input>
                                    </Row>
                                </Col>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <Label className="label" > Instagram Id </Label>
                                    <Row className="dataInputBlock">
                                        <Input
                                            className="in"
                                            onChange={this.onChange}
                                            type="text" name="instagramId" placeholder="Instagram Id"  >
                                        </Input>
                                    </Row>
                                </Col>
                            </Row>
                          
                           
                            <Row className="customFieldRows">
                                <Col style={{ padding: 0 }}>
                                    <Button className="signupb" onClick={this.signupRequest}>Sign up</Button>
                                </Col>
                            </Row>
                        </Col>
                       
                    </Row>
                   
                    </Col>
                   
         </div>
      );
   }
}

export default signupagency;
