import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Input, Label, Row } from 'reactstrap';
import swal from 'sweetalert';
import Cup from "../../images/cup.png";
import PaymentServices from "../Apiservices/PaymentServices";
import UserServices from "../Apiservices/UserService";
import Header from '../Header/NewHeader';
import Login from "../Login/Login";
import './Payment.css';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    // CardElement,
    // useStripe,
    // useElements,
    Elements,
    ElementsConsumer,
} from '@stripe/react-stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../../config'

const userServices = new UserServices();
const auth = new Login();
const paymentServices = new PaymentServices();
const userId = auth.getId()
// const stripe = useStripe
// const elements = useElements
// const cElement = CardElement
const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: '15px',
            color: '#495057',
            letterSpacing: '0.025em',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};

class AddCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            alert: false,
            invalid: '',
            card: false,
            name: "",
            token: null,
            loader: false,
            user: null,
            cardData: "",
        };
    }

    componentDidMount() {
        userServices.getUserProfile(userId)
            .then((result) => {
                console.log(result.data, 'responseJson')
                this.setState({ cardData: result.data.card })
                let user = result.data.user
                if (user.role == 'agency' && user.card) {
                    this.setState({ user: user, card: true });
                } else if (user.role == 'agent') {
                    this.setState({ redirect: true });
                }
            })
            .catch(err => {
                swal("Oops", err, "info");
            });
    };
    deleteCard = () => {
        this.setState({loader : true})
        const { cardData } = this.state
        let dataToSend = {
            customerId: cardData.user.customer
        }
        swal({
            title: "Are you sure?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    paymentServices.deleteStripe(dataToSend)
                        .then(res => {
                            console.log(res.data)
                            let responseJson = res.data
                            if (responseJson) {
                                paymentServices.deleteCard(this.state.cardData._id)
                                    .then(res => {
                                        console.log(res.data)
                                        swal("Your card has been deleted!", {
                                            icon: "success",
                                        }).then(() => {
                                            window.location.reload()
                                        });


                                    })
                                    .catch(error => {
                                        console.log(error)
                                    })
                            }
                        })
                        .catch(error => {
                            console.log(error, 'API not called')
                        })


                }

            });

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    // sampleDelete = (ev) => {
    //     const { cardData } = this.state
    //     console.log(cardData)
    //     console.log(cardData.user.customer)
    //     let dataToSend = {
    //         customerId: cardData.user.customer
    //     }
    //     paymentServices.deleteStripe(dataToSend)
    //         .then(res => {
    //             console.log(res.data)
    //         })
    //         .catch(error => {
    //             console.log(error, 'API not called')
    //         })

    // }
    addCard = async (event) => {
        event.preventDefault();
        console.log(this.props)
        this.setState({ loader: true });
        const { stripe, elements } = this.props;
        const { name } = this.state;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        paymentServices.checkout()
            .then(async result => {
                let checkout = await stripe.redirectToCheckout({
                    sessionId: result.data.id
                })
                if (checkout.error) {
                    console.log(checkout)
                    alert(checkout.error.message)
                }
            })
            .catch(err => {
                console.log(err)
                this.setState({ alert: false, loader: false });
            });


    };

    render() {
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }
        if (this.state.redirect == true) {
            return (
                <Redirect to={{
                    pathname: "/dashboard",
                    state: {
                        userinfo: this.state.userinfo,
                    }
                }} />
            )
        }
        const { stripe } = this.props;
        return (
            <div className="createprofile">
                <Header />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="paymentmain">
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="paymentprotion1">
                                <p className="topheadingpayment">Card Information.</p>
                            </Row>
                            {this.state.card ?
                                <Row className="formpayment">
                                    <p className="paymentMethodDetail">Card holder name: {this.state.user && this.state.user.card.cardHolderName} </p>
                                    <p className="paymentMethodDetail">Card type: {this.state.user && this.state.user.card.cardBrand} </p>
                                    <p className="paymentMethodDetail">Card number: **** **** **** {this.state.user && this.state.user.card.lastDigits} </p>
                                    <div style={{ marginLeft: "0px" }}>
                                        <Link to="/dashboard">
                                            <Button className="paymentMethodbutton" type="submit"> OK </Button>
                                        </Link>
                                        {/* <Link to="/dashboard"> */}
                                        <Button className="paymentMethodbutton2" type="submit" onClick={this.deleteCard}> Delete </Button>

                                    </div>
                                </Row>
                                : <Button className="paymentMethodbutton" type="submit" onClick={this.addCard}> Add Card </Button>
                            }
                            {/* <Button onClick={this.sampleDelete}>sampleDelete</Button> */}
                            {/* <form onSubmit={this.addCard}>
                                <Row className="formpayment">
                                    <Label id="name" className="paymentname-label" > Card holder name </Label>
                                    <Input type="name"
                                        name="name"
                                        id="name"
                                        placeholder="Name"
                                        onChange={this.onChange}
                                        required />
                                    <Label id="cardNumber" className="paymentname-label" > Card number </Label>
                                    <CardNumberElement
                                        id="cardNumber"
                                        options={ELEMENT_OPTIONS}
                                    />
                                    <Label id="expiry" className="paymentname-label" > Expiry month </Label>
                                    <CardExpiryElement
                                        id="expiry"
                                        options={ELEMENT_OPTIONS}
                                    />
                                    <Label className="paymentname-label" > CVC </Label>
                                    <CardCvcElement
                                        id="cvc"
                                        options={ELEMENT_OPTIONS}
                                    />
                                    <Label className="paymentname-label" > Purchase histroy</Label>
                                    <Row className="btn-wrapper-payment">
                                        <button class="btn-payment">Export to excel</button>
                                        <Input
                                            type="file" />
                                    </Row>
                                    <Row className="paymentsaverow">
                                        <Button className="savepayment" type="submit" disabled={!stripe}> Add </Button>
                                    </Row>
                                </Row>
                            </form> */}
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="paymentMethodProtion2">
                                <img src={Cup} height="300" width="100%"></img>
                            </Row>
                        </Col>
                        <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                            <div className='overlay-box'>
                                <PropagateLoader
                                    // css={override}
                                    sizeUnit={"px"}
                                    size={25}
                                    color={'white'}
                                    loading={this.state.loader}
                                />
                            </div>
                        </div>

                    </Row>
                </Col>
            </div>
        );
    }
}

const InjectedCheckoutForm = () => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <AddCard stripe={stripe} elements={elements} />
        )}
    </ElementsConsumer>
);

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const NewPayment = () => {
    return (
        <Elements stripe={stripePromise}>
            <InjectedCheckoutForm />
        </Elements>
    );
};

export default NewPayment;
