import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Input, Label, Row } from 'reactstrap';
import swal from 'sweetalert';
import Samosa from "../../images/listing-ad.png";
import Sign from "../../images/Resurs 710.png";
import ListingAdServices from '../Apiservices/ListingAdServices';
import Header from '../Header/NewHeader';
import './ListingAd.css';
import './ListingAd_Custom.css';
import Login from "../Login/Login";

const auth = new Login();

const listingAdServices = new ListingAdServices();

class ListingAd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previousPath: props.location.state.previousPath,
            userProfile: props.location.state.userProfile,
            campaignType: props.location.state.campaignType ? props.location.state.campaignType : 'listing',
            redirect: false,
            alert: false,
            region: 'native',
            url: '',
            listingData: {},
            loader: false

        };
        this.onChange = this.onChange.bind(this);
        this.createListingAd = this.createListingAd.bind(this);
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    extractDomain = (url) => {
        var domain;
        //find & remove protocol (http, ftp, etc.) and get domain
        if (url.indexOf("://") > -1) {
            domain = url.split('/')[2];
        }
        else {
            domain = url.split('/')[0];
        }

        //find & remove www
        if (domain.indexOf("www.") > -1) {
            domain = domain.split('www.')[1];
        }

        domain = domain.split(':')[0]; //find & remove port number
        domain = domain.split('?')[0]; //find & remove url params

        return domain;
    }

    createListingAd = (e) => {
        var website = this.extractDomain(this.state.url)
        if (this.state.url) {
            this.setState({ loader: true });

            listingAdServices.createListingAd({
                url: this.state.url,
                region: this.state.region,
                domain: website
            }).then(result => {
                this.setState({ listingData: result.data })
                this.setState({ redirect: true });
                this.setState({ loader: false });

            }).catch(err => {
                this.setState({ loader: false });
                this.setState({ alert: true });
                swal("Oops", err.response.data.error.message, "error");
            });

        }
    };

    render() {
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }
        if (this.state.redirect) {
            return (
                <Redirect to={{
                    pathname: "/ad-preview",
                    state: {
                        campaignType: this.state.campaignType,
                        userProfile: this.state.userProfile,
                        previousPath: this.state.previousPath,
                        listingData: this.state.listingData,
                        pathUrl: this.props.location.pathname
                    }
                }} />
            )
        }
        return (
            <div className="createListing">
                <Header backButton={this.state.previousPath}
                    previousData={{
                        userProfile: this.state.userProfile,
                        campaignType: this.state.campaignType,
                        previousPath: this.props.location.pathname
                    }}
                />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="listingadmain">
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="listingadprotion1">
                                <p className="topheadinglistingad">Listing Ad.</p>
                            </Row>
                            <Row className="formlistingad">
                                <Label className="listingadname-label" > Paste the URL here </Label>
                                <Input type="text"
                                    name="url"
                                    id="paymentuserName"
                                    placeholder="URL"
                                    onChange={this.onChange}
                                    required />

                                <Row className="listingadstate">
                                    <Col>
                                        <Row className="createmanully">
                                            <Link to={{
                                                pathname: "/create-ad-new",
                                                state: {
                                                    userProfile: this.state.userProfile,
                                                    campaignType: this.state.campaignType,
                                                    previousPath: this.props.location.pathname
                                                }
                                            }}>
                                                <Button className="btncreatemanually">Create manually</Button>
                                            </Link>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="listingadbtns">
                                            <Button className="listingadsubmit" type="submit" onClick={this.createListingAd}> Next </Button>
                                            <Link to={{
                                                pathname: "/new-how",
                                                state: {
                                                    userProfile: this.state.userProfile,
                                                    campaignType: this.state.campaignType,
                                                    previousPath: this.props.location.pathname
                                                }
                                            }}>
                                                <img src={Sign} height="30" width="auto" className="questionmak"></img>
                                            </Link>
                                        </Row>
                                        <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                                            <div className='overlay-box'>
                                                <PropagateLoader
                                                    // css={override}
                                                    sizeUnit={"px"}
                                                    size={25}
                                                    color={'white'}
                                                    loading={this.state.loader}
                                                />
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="listingadprotion2">
                                <img src={Samosa} height="500" width="auto"></img>
                            </Row>
                        </Col>

                    </Row>
                </Col>
            </div>
        );
    }
}

export default ListingAd;
