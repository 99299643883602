import React, { Component } from 'react';
import './style.css';
import {
    Row, Col, Button, Card, CardColumns, CardImgOverlay, CardHeader, CardFooter, CardImg, CardText, CardBody, CardLink, CardTitle, CardSubtitle
} from 'reactstrap';
import iPhone from "../../images/iPhone.png";
import maleIcon from "../../images/user_male2-512.png";
import likeComments from "../../images/like-comments.png";
import dummy1024 from "../../images/dummy-1024.png";
import { Player } from 'video-react';
import "./video-react.css";
class MobileAdPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: props.message,
            description: props.description,
            previewImage: props.previewImage,
            previewVideo: props.previewVideo,

            headline: props.headline,
            website: props.website,
            mediaType: props.mediaType
        };
    }

    componentWillReceiveProps(nextProps) {


        this.setState({
            message: nextProps.message,
            description: nextProps.description,
            previewImage: nextProps.previewImage,
            previewVideo: nextProps.previewVideo,

            headline: nextProps.headline,
            website: nextProps.website,
            mediaType: nextProps.mediaType
        });
    }

    extractDomain = (url) => {
        var domain;
        //find & remove protocol (http, ftp, etc.) and get domain
        if(!url) {
            domain = '';
        }
        else if (url.indexOf("://") > -1) {
            domain = url.split('/')[2];
        }
        else {
            domain = url.split('/')[0];
        }

        //find & remove www
        if (domain.indexOf("www.") > -1) {
            domain = domain.split('www.')[1];
        }

        domain = domain.split(':')[0]; //find & remove port number
        domain = domain.split('?')[0]; //find & remove url params

        return domain;
    }

    render() {
        const website = this.extractDomain(this.state.website)

        return (
            <Card className="cardCustomization">
                <CardImg top src={iPhone} alt="Card image cap" className="previewAdImage" />
                <CardImgOverlay className="cardImageOverlayCustomiztion">
                    <CardBody className="cardBodyCustomiztion">
                        <Row>
                            <Col lg={{ size: 3 }} md={{ size: 3 }} sm={{ size: 3 }} xs={{ size: 3 }}>
                                <img src={maleIcon} className="previewAdPersonIcon" />

                            </Col>
                            <Col className="headerCustomization" lg={{ size: 9 }} md={{ size: 9 }} sm={{ size: 9 }} xs={{ size: 9 }}>
                                <Row>
                                    <Col lg={9} md={9} sm={9} xs={9}><Row><CardTitle className="previewAdPersonName">Your page</CardTitle></Row></Col>
                                    <Col lg={2} md={2} sm={2} xs={2}><Row><div className="previewAdPersonDot"></div></Row></Col>
                                </Row>
                                <Row>
                                    <CardSubtitle className="previewAdPersonSponsored">Sponsored</CardSubtitle>
                                </Row>
                            </Col>
                        </Row>
                        <CardText className="previewAdPersonDescription">{this.state.message || "Here's message text"}</CardText>
                    </CardBody>

                    {this.state.mediaType === "image" && <CardImg src={this.state.previewImage || dummy1024} className="previewAdDetailImage" />}

                    {this.state.mediaType === "video" && <Player playsInline src={this.state.previewVideo} fluid={false} height='52%' width='auto' autoPlay />}

                    {/* {this.state.mediaType === "video" && <Player playsInline  src={this.state.previewVideo}autoPlay />} */}


                    {this.state.mediaType === "dummy" && <CardImg src={this.state.previewImage || dummy1024} className="previewAdDetailImage" />}
                    {this.state.mediaType === undefined && <CardImg src={this.state.previewImage || dummy1024} className="previewAdDetailImage" />}
                    <CardFooter className="cardFooterCustomization">
                        <Row>
                            <Col lg={7} md={7} sm={7} xs={7}>
                                <Row><CardText className="footerTextUpper">{website || "example.com"}</CardText></Row>
                                {/* <Row><CardText className="footerTextLower">{this.state.description || "This is description text"}</CardText></Row> */}
                                <Row><CardText className="footerTextMiddle">{this.state.headline || "Headline"}</CardText></Row>
                                <Row><CardText className="footerTextLower">{this.state.description || "This is description text"}</CardText></Row>
                            </Col>
                            <Col lg={5} md={5} sm={5} xs={5}>
                                <Button className="adPreviewCustomButton" color="secondary">Learn more</Button>
                            </Col>
                        </Row>
                        <Row><CardImg src={likeComments} className="previewAdLikeComments" /></Row>
                    </CardFooter>
                </CardImgOverlay>
            </Card>
        );
    }
}

export default MobileAdPreview;