import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardImg, CardText, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import swal from 'sweetalert';
// import iconehome from '../../images/house.jpg';
import iconehome from '../../images/qqq.png';
import Apiservices from '../Apiservices/Urlservice';
import Header from '../Header/index.js';
import './Style.css';
const urlpost = new Apiservices();

// let description= this.state.description.substring(0, 80);
// const toggle = () => setModal(!modal);
class Newad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            alert: false,
            invalid: '',
            isLoaded: true,
            dropdownOpen: false,
            Draftlunchmodal: false,
            Draftlunchmodal2: false,
            list_campaingns: "",
            property: this.props.location.state.property,
            brokerLink: this.props.location.state.brokerLink,
            propertyname: this.props.location.state.name,
            description: this.props.location.state.description,
            propertyImages: this.props.location.state.propertyImages,
            name: "",
            _id: ""
        };
        this.toggle = this.toggle.bind(this);
        this.Drafttoggle = this.Drafttoggle.bind(this);
        this.Drafttoggle2 = this.Drafttoggle2.bind(this);
        this.onChange = this.onChange.bind(this);
        this.deletecampaign = this.deletecampaign.bind(this);
        console.log("propertyid", this.state.property);
        console.log("campaignlisttt", this.state.list_campaingns);
        console.log("images", this.state.propertyImages);
    }
    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    Drafttoggle() {
        this.setState({
            Draftlunchmodal: !this.state.Draftlunchmodal
        });
    }
    Drafttoggle2() {
        this.setState({
            Draftlunchmodal2: !this.state.Draftlunchmodal2
        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        return urlpost.list_campaign(this.state.property)
            // console.log("peopertyidd", this.state.property)
            .then(res => {
                // console.log(`sdfghjoiuy ${JSON.stringify(res.data)}`);
                this.setState({ list_campaingns: res.data });
            });
    }

    // deletecampaign() {
    //   //   console.log("jkgdjagdjasd", this.state,campaign_id)
    //   return urlpost.campaigndelete(this.state.list_campaingns[0]._id);
    //   swal("Oops", "kan inte ställa in datum till tidigare tid", "error");
    // }

    deletecampaign = () => {
        {
            urlpost
                .campaigndelete(this.state.list_campaingns._id)
                .then(result => {
                    let responseJson = result.data;
                    if (responseJson) {
                        this.setState({ redirect: true });
                        this.setState({ alert: true });
                    }
                    swal("Thnak you", "campaign deleted", "success")
                })
                .catch(err => {
                    this.setState({ alert: true });
                    this.setState({ invalid: err.response.data.error.message });
                    swal("Oops", "kan inte ställa in datum till tidigare tid", "error");
                });
        }
    };

    render() {
        if (this.state.redirect == true) {
            return window.location.replace("/home");
        }
        return (
            <div className="custom-container">
                <Header />
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Row>
                        <Col lg={12} md={12} sm={11} className="categories-crd-5">
                            <Row>
                                <Col lg={{ size: 10, offset: 1 }} md={10} sm={11}>
                                    <Row className="heading1">
                                        <h1>{this.state.propertyname}</h1>
                                    </Row>
                                    <Row className="heading1">
                                        <h4>{this.state.brokerLink}</h4>
                                    </Row>
                                    <Row className="heading1">
                                        <h5>{this.state.description}</h5>
                                    </Row>
                                </Col>
                                {this.state.propertyImages.map(image => {
                                    let images = 'https://realreach.s3.eu-north-1.amazonaws.com/' + image
                                    return (
                                        <Col lg={{ size: 2 }} md={2} sm={11} >
                                            <Card>
                                                <CardImg className="image_Fitting" src={images} height="auto" width="100%" alt="image" ></CardImg>

                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                        {/* <Col lg={12} md={12} sm={12} xs={12}>
          <Row>
                    <Row className="cardbodystyle">
                    <Link to={{
                  pathname: "/new-ad",
                //   state: {
                //     name: campaign.name,
                //     _id: campaign._id
                //   }
                }}>
          <Card id="oneTwo" >
              <CardBody className="Card_Body" style={{padding:2}}>
                <h5 className="textdecoration">{this.state.propertyname}</h5>
                <p className="textdecoration">{this.state.brokerLink}</p>
                <p className="textdecoration">{this.state.description}</p>
              </CardBody>
              <CardImg  src={this.state.imgUrl} height="400px" width="auto" alt="Card image cap" />
            </Card>
            </Link>
                    
            </Row>
          </Row>
        </Col>  */}
                        {!this.state.list_campaingns && (
                            <Row className="newadbtn">
                                <Link to={{
                                    pathname: "/new-campaign",
                                    state: {
                                        property: this.state.property,
                                        brokerLink: this.state.brokerLink,
                                        propertyname: this.state.propertyname,
                                        description: this.state.description,
                                        propertyImages: this.state.propertyImages

                                    }
                                }}>
                                    <Button className="newadd" >+  NEW CAMPAIGN</Button>
                                </Link>
                            </Row>
                        )}

                        {!this.state.list_campaingns && (
                            <Row className="bottomtext">
                                <p className="bottomtext">No pending campaign</p>
                            </Row>
                        )}

                        <Col lg={12} md={12} sm={12} xs={12}>
                            {this.state.list_campaingns && (
                                <Row className="capmainlisttext">
                                    <p>Campaign</p>
                                </Row>
                            )}

                            <Row>
                                {this.state.list_campaingns && (
                                    <Col className="cardcol" lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12}>

                                        <Card className="cardheight">
                                            <Row>
                                                <Col lg={4} md={4} sm={12} xs={12}>
                                                    <img src={iconehome} className="budgetimage" alt="doctor" />
                                                </Col>
                                                <Col className="col-profile-doc-heading" lg={6} md={6} sm={12} xs={12}>
                                                    <Link to={{
                                                        pathname: "/add-list",
                                                        state: {
                                                            name: this.state.list_campaingns.name,
                                                            _id: this.state.list_campaingns._id,
                                                            amount: this.state.list_campaingns.budget.amount,
                                                            endDate: this.state.list_campaingns.endDate,
                                                            startDate: this.state.list_campaingns.startDate,
                                                            website: this.state.list_campaingns.property.website,
                                                            property: this.state.property,
                                                            brokerLink: this.state.brokerLink,
                                                            propertyname: this.state.propertyname,
                                                            description: this.state.description,
                                                            propertyImages: this.state.propertyImages[0],
                                                            campaignType: this.state.list_campaingns.campaignType
                                                        }
                                                    }}>
                                                        <CardText >
                                                            <p className="headingtopp"> <h3>{this.state.list_campaingns.name}</h3></p>
                                                            <p className="heading2"><br />Amount:<span className="colorspan">{this.state.list_campaingns.budget.amount} SEK</span></p>
                                                        </CardText>
                                                    </Link>
                                                    <Row>
                                                        <Col>
                                                            <Link to={{
                                                                pathname: "/update-kampanj",
                                                                state: {
                                                                    // _id: campaign._id,
                                                                    name: this.state.list_campaingns.name,
                                                                    campaign_id: this.state.list_campaingns._id,
                                                                    paymentMode: this.state.list_campaingns.paymentMode,
                                                                    resourceName: this.state.list_campaingns.resourceName,
                                                                    resourceId: this.state.list_campaingns.resourceId,
                                                                    status: this.state.list_campaingns.status,
                                                                    type: this.state.list_campaingns.type,
                                                                    brokerLink: this.state.brokerLink,
                                                                    propertyname: this.state.propertyname,
                                                                    description: this.state.description,
                                                                    propertyImages: this.state.propertyImages,

                                                                }
                                                            }}>
                                                                <button className="updatebtn">Update</button>
                                                            </Link>
                                                        </Col>
                                                        <Col>
                                                            <button className="deletebtn"
                                                                onClick={e => {
                                                                    if (window.confirm("Är du säker på att du vill ta bort den här kampanjen?"))
                                                                        this.deletecampaign(e);
                                                                }}
                                                            >Delete</button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Modal isOpen={this.state.Draftlunchmodal} size="lg" className="Draft-modal-block-size" toggle={this.toggle}>
                    <Col xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}>
                        <div className="Draft-block-modal-body-costum">
                            <ModalHeader >sdadasdasda</ModalHeader>
                            <ModalBody>
                                <p className="modal-heading-text1">
                                    Faktureringsuppgifter saknas
                                </p>
                                <p className="modal-heading-text2">
                                    För att skapa en kampanj måste Faktureringsuppgifteranges. Gå till inställningar för att fylla i dem.
                                </p>
                                <Row>
                                    <ModalFooter className="modalfootercustom">
                                        <Col>  <p className="cancelbtn" onClick={this.Drafttoggle}>SENARE</p></Col>
                                        <Col lg={12}> <p className="addbtn" onClick={this.Drafttoggle2} >GÄ TILL  INSTÄLLNINGAR</p>{' '}</Col>


                                    </ModalFooter>
                                </Row>

                            </ModalBody>
                        </div>
                    </Col>
                </Modal>

                <Modal isOpen={this.state.Draftlunchmodal2} size="lg" className="Draft-modal-block-size" toggle={this.toggle}>
                    <Col xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}>
                        <div className="Draft-block-modal-body-costum">
                            <ModalBody>
                                <p className="modal2-heading-text1">
                                    Ändra Faktureringsuppgifteranges
                                </p>
                                <p className="modal-heading-text2">
                                    För att skapa en kampanj måste Faktureringsuppgifteranges. Gå till inställningar för att fylla i dem.
                                </p>
                                <Row className="date1">
                                    <Input
                                        type="text"
                                        placeholder="Organisationsnummer"
                                        id="date11"
                                    >
                                    </Input>
                                </Row>
                                <Row className="date2">
                                    <Input
                                        type="text"
                                        placeholder="Förnamn"
                                        id="date22">
                                    </Input>
                                </Row>
                                <Row className="date2">
                                    <Input
                                        type="text"
                                        placeholder="Efternamn"
                                        id="date33">
                                    </Input>
                                </Row>
                                <Row className="date2">
                                    <Input
                                        type="text"
                                        placeholder="Företagsnamn"
                                        id="date44">
                                    </Input>
                                </Row>
                                <Row className="date2">
                                    <Input
                                        type="text"
                                        placeholder="C/O adress"
                                        id="date55">
                                    </Input>
                                </Row>
                                <Row className="date2">
                                    <Input
                                        type="text"
                                        placeholder="Gatuadress"
                                        id="date66">
                                    </Input>
                                </Row>
                                <Row>
                                    <ModalFooter className="modalfootercustom">
                                        <Col>  <p className="cancelbtn" onClick={this.Drafttoggle2}>SENARE</p></Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>  <p className="addbtn" onClick={this.Drafttoggle2} >GÄ TILL  INSTÄLLNINGAR</p>{' '}</Col>


                                    </ModalFooter>
                                </Row>

                            </ModalBody>
                        </div>
                    </Col>
                </Modal>
            </div>
        );
    }
}

export default Newad;
