import React from 'react';
import { Link, withRouter } from 'react-router-dom';
// import * as Scroll from 'react-scroll';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Button, Col, Row } from 'reactstrap';
import Goback from '../../images/goback.png';
// import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Headerlogo from '../../images/BrightBee_Logo-DarkBlue.png';
import burgermenupic from '../../images/BrightBee_Hamburger.png';

import Login from '../Login/Login';
import './NewHeader.css';
import UserServices from "../Apiservices/UserService";

const userServices = new UserServices();
const Auth = new Login();
const userId = Auth.getId()
class NewHeader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            backHidden: props.backHidden ? props.backHidden : false,
            backButton: props.backButton ? props.backButton : "/dashboard",
            previousData: props.previousData ? props.previousData : {},
            role: '',
            showPopUp:true,
            width: 0,
            height: 0,
        };

        this.toggle = this.toggle.bind(this);
        this.changeNavItem = this.changeNavItem.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    // componentDidMount() {
    //     userServices.getUserProfile(userId)
    //         .then((result) => {
    //             // console.log(result, "Hello g ")
    //             let responseJson = result.data.user
    //             this.setState({ userProfile: responseJson });
    //             this.setState({ role: responseJson.role });
    //         })
    //         .catch(err => {
    //             alert("Oops", "oh no", "error");
    //         });

    // };

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }


    
    changeNavItem(currentRoute) {
        if (currentRoute == "/") {
            this.setState({
                hideValu: true
            });
        }
        if (currentRoute == "/pre-release" || currentRoute == "/parkingspace") {
            this.setState({
                hideNavOnLoginPage: true
            })
        }
    }

    handleLogout() {
        Auth.logout();
        this.setState({ redirect: true });
        window.location.replace("/")
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

     myFunction = () => {
         if(this.state.width > 767)
         {

         }
         else
         {
        this.setState({
            showPopUp: !this.state.showPopUp
        });
    }
      }

      componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        setTimeout(()=>{
           

            if(this.state.width <= 767)
            {
                this.setState({showPopUp:false})
            }

        },100)
        
        
      }
    
      componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
      }
    
      updateWindowDimensions = () => {
        // dimensions.height = window.innerHeight
        // dimensions.width = window.innerWidth
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };

    render() {
       
        return (
            <Navbar className="navbar-header2" light expand="md" style={{ padding: 0 }}>
                <Col lg={3} md={3} sm={12} xs={{ size: 12, offset: 0 }}  >
                    <Row>
                        <Col lg={{ size: 0, offset: 0, order: 1 }} md={{ size: 0, offset: 0, order: 1 }} sm={{ size: 2, offset: 1, order: 2 }} xs={{ size: 2, offset: 1, order: 2 }} style={{ padding: 0 }}>
                            <Link style={{
                                textDecoration:'none',
                            }} to={{
                                pathname: "/",
                            }}>
                                <img  onClick = {()=>{
                                    this.myFunction()
                                }} className="logoi" src={burgermenupic}></img>
                            </Link>
                        </Col>
                        <Col lg={{ size: 12, offset: 0  , order: 2 }} md={{ size: 12, offset: 0, order: 2 }} sm={{ size: 9, offset: 0, order: 1 }} xs={{ size: 9, offset: 0, order: 1 }} style={{ padding: 0 }}>
                            <Link to={{
                                pathname: "/",
                            }}>
                                <p className="logotitle">BrightBee</p>
                            </Link>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6} md={{ size: 5, offset: 0 }} sm={{ size: 12, offset: 0 }} xs={12} style={{ padding: 0 }} >
                    <div>
                        <p className="centertext">Sell your properties faster.</p>
                    </div>
                </Col>
                <Col lg={{ size: 3, offset: 0 }} md={4} sm={12} xs={{ size: 12, offset: 0 }}  >
                 
                  
                  

                 <Row className={this.state.showPopUp ? "butDisplay":"butHide"}  >
                        <Col lg={{ size: 4, offset: 3 }} md={{ size: 5, offset: 1 }} sm={{ size: 5, offset: 0 }} xs={{ size: 5, leftset: 0 }} style={{ padding: 0 }}>
                            <Link to={{
                                pathname: "/signup",
                            }}>
                                <button className="but1">Sign up</button>
                            </Link>
                        </Col>
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 5, offset: 1 }} sm={{ size: 5, offset: 2 }} xs={{ size: 5, offset: 2 }} style={{ padding: 0 }} >
                            <Link to={{
                                pathname: "/new-login",
                            }}>
                                <button className="but2">Log in</button>
                            </Link>
                        </Col>
                    </Row>  
                
    
                </Col>
            </Navbar>
        );
    }
}

export default withRouter(NewHeader);