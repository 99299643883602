import React, { Component, useState } from 'react';
import './Style.css';
import { Row, Col, Button, Input } from 'reactstrap';
import Left from '../../images/leftarrow.png'
import Right from '../../images/rightarrow.png'
// import Bg from '../../images/bg.png';    
import Header from '../Header';
import { Link, Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import Apiservices from '../Apiservices/Urlservice';

const urlpost = new Apiservices();

class Adsetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name1: this.props.location.state.name,
            campaign: this.props.location.state._id,
            dropdownOpen: false,
            Draftlunchmodal: false,
            Draftlunchmodal2: false,
            redirect: false,
            alert: false,
            gender: "",
            ageRange: "",
            name: "",
            // _id: this.props.location.state._id
        };
        this.onChange = this.onChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.Drafttoggle = this.Drafttoggle.bind(this);
        this.Drafttoggle2 = this.Drafttoggle2.bind(this);
        this.addgroup = this.addgroup.bind(this);
        console.log("id", this.props.location.state._id)
        console.log("name", this.props.location.state.name)
    }
    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    Drafttoggle() {
        this.setState({
            Draftlunchmodal: !this.state.Draftlunchmodal
        });
    }
    Drafttoggle2() {
        this.setState({
            Draftlunchmodal2: !this.state.Draftlunchmodal2
        });
    }

    onChange(e) {
        console.log("onchage", e.target.value)
        this.setState({ [e.target.name]: e.target.value });
    }

    addgroup = () => {
        console.log("id", this.state.campaign)
        console.log("name", this.state.name)
        console.log("age", this.state.gender)
        console.log("rang", this.state.ageRange)
        if (this.state.name && this.state.campaign && this.state.gender && this.state.ageRange) {
            urlpost
                .newgroup({
                    name: this.state.name,
                    campaign: this.state.campaign,
                    gender: Number(this.state.gender),
                    ageRange: Number(this.state.ageRange)
                })
                .then(result => {
                    let responseJson = result.data;
                    if (responseJson) {
                        this.setState({ redirect: true });
                        this.setState({ alert: true });
                    }
                    swal("bra gjort", "Group added", "success");
                })
                .catch(err => {
                    this.setState({ alert: true });
                    this.setState({ invalid: err.response.data.error.message });
                    swal("Oops", "AdGroup with the same name already exists for the campaign", "error");
                });
        }
    };

    render() {
        if (this.state.redirect == true) {
            return (
                window.location.replace("/home")
            )
        }
        return (
            <div>
                <Header />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="topheader1">
                        <p className="compname">{this.props.location.state.name}</p>
                    </Row>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="topheader2">
                        <Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                            <Row className="leftarrow">
                                <Link to={{
                                    pathname: "/budget",
                                    state: {
                                        name: this.state.name,
                                        startDate: this.state.startDate,
                                        endDate: this.state.endDate,
                                        redirect: true
                                    }
                                }}>
                                    <img src={Left} alt="" height="33px"></img>
                                </Link>
                            </Row>
                        </Col>
                        <Col lg={10} md={10} sm={8} xs={8}><Row className="centertext">Ad Settings</Row></Col>
                        <Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                            <Row className="rightarrow"><img src={Right} alt="rightarrow" height="20px"></img></Row>
                        </Col>
                    </Row>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Row className="displaydate33">
                        <Row className="label">
                            <p className="label1">Name</p>
                        </Row>
                        <Row className="stackholder">
                            <Input
                                type="text"
                                name="name"
                                placeholder="Enter name"
                                id="stackholder"
                                onChange={this.onChange}
                            >
                            </Input>
                        </Row>
                        {/* <Row className="label">
                    <p className="label1"> Intressenter</p>
                </Row>
            <Row className="stackholder">
                <Input
                    type="number"
                    placeholder="0"
                    id="stackholder"
                >
                </Input>
            </Row>
            <Row className="label">
                    <p className="label1"> Regioner</p>
                </Row>
            <Row className="stackholder">
                <Input
                    type="text"
                    placeholder="Regioner"
                    id="stackholder"
                >
                </Input>
            </Row>
            <Row className="label">
                    <p className="label1"> Hushåll</p>
                </Row>
                <Row className="checkboxes">
             <Col lg={{size:12}} md={{size:2}} sm={{size:2}}>
                 <Row className="radiobtnn">
                <label class="custom-container">
                    <input 
                        type="radio"
                        name="radio"
                        onChange={this.toggleChangeCharger}></input>Alla
                        <span class="checkmark"></span>
                </label>
                <label class="custom-container">
                    <input 
                        type="radio"
                        name="radio"
                        onChange={this.toggleChangeCharger}></input>Parhushåll
                        <span class="checkmark"></span>
                </label>
                <label class="custom-container">
                    <input 
                        type="radio"
                        name="radio"
                        onChange={this.toggleChangeCharger}></input>Ensamhushåll
                        <span class="checkmark"></span>
                </label>
                </Row>
             </Col >
             </Row>
             <Row className="label">
                    <p className="label1"> Barn hemma </p>
                </Row>
             <Row className="checkboxes">
             <Col lg={{size:12}} md={{size:2}} sm={{size:2}}>
                 <Row className="radiobtnn">
                <label class="custom-container">
                    <input 
                        type="radio"
                        name="radio"
                        onChange={this.toggleChangeCharger}></input>Alla
                        <span class="checkmark"></span>
                </label>
                <label class="custom-container">
                    <input 
                        type="radio"
                        name="radio"
                        onChange={this.toggleChangeCharger}></input>Ja
                        <span class="checkmark"></span>
                </label>
                <label class="custom-container">
                    <input 
                        type="radio"
                        name="radio"
                        onChange={this.toggleChangeCharger}></input>Nej
                        <span class="checkmark"></span>
                </label>
                </Row>
             </Col >
             </Row> */}
                        <Row className="label">
                            <p className="label1"> Gender </p>
                        </Row>
                        <Row className="checkboxes">
                            <Col lg={{ size: 12 }} md={{ size: 2 }} sm={{ size: 2 }}>
                                <Row className="radiobtnn">
                                    <label class="custom-container">
                                        <input
                                            type="radio"
                                            name="gender"
                                            value="10"
                                            onChange={this.onChange}></input>
                                        Male
                        <span class="checkmark"></span>
                                    </label>
                                    <label class="custom-container">
                                        <input
                                            type="radio"
                                            name="gender"
                                            value="11"
                                            onChange={this.onChange}></input>Woman
                        <span class="checkmark"></span>
                                    </label>
                                    <label class="custom-container">
                                        <input
                                            type="radio"
                                            name="gender"
                                            value="12"
                                            onChange={this.onChange}></input>Others
                        <span class="checkmark"></span>
                                    </label>
                                </Row>
                            </Col >
                        </Row>
                        <Row className="label">
                            <p className="label1"> Age</p>
                        </Row>
                        <Row className="checkboxes">
                            <Col lg={{ size: 2 }} md={{ size: 2 }} sm={{ size: 2 }}>
                                <label class="container2">
                                    <input
                                        type="radio"
                                        name="ageRange"
                                        onChange={this.onChange}
                                        value="503001"
                                    ></input>18-24
                        <span class="checkmark2"></span>
                                </label>
                            </Col >
                            <Col lg={{ size: 2 }} md={{ size: 2 }} sm={{ size: 2 }}>
                                <label class="container2">
                                    <input
                                        type="radio"
                                        name="ageRange"
                                        onChange={this.onChange}
                                        value="503002"
                                    >
                                    </input>25-34
                        <span class="checkmark2"></span>
                                </label>
                            </Col >
                            <Col lg={{ size: 2 }} md={{ size: 2 }} sm={{ size: 2 }}>
                                <label class="container2">
                                    <input
                                        type="radio"
                                        name="ageRange"
                                        onChange={this.onChange}
                                        value="503003"
                                    ></input>35-44
                        <span class="checkmark2"></span>
                                </label>
                            </Col >
                            <Col lg={{ size: 2 }} md={{ size: 2 }} sm={{ size: 2 }}>
                                <label class="container2">
                                    <input
                                        type="radio"
                                        name="ageRange"
                                        onChange={this.onChange}
                                        value="503004"
                                    ></input>45-54
                        <span class="checkmark2"></span>
                                </label>
                            </Col >
                            <Col lg={{ size: 2 }} md={{ size: 2 }} sm={{ size: 2 }}>
                                <label class="container2">
                                    <input
                                        type="radio"
                                        name="ageRange"
                                        onChange={this.onChange}
                                        value="503005"
                                    ></input>55-64
                        <span class="checkmark2"></span>
                                </label>
                            </Col >
                            <Col lg={{ size: 2 }} md={{ size: 2 }} sm={{ size: 2 }}>
                                <label class="container2">
                                    <input
                                        type="radio"
                                        name="ageRange"
                                        onChange={this.onChange}
                                        value="503006"
                                    ></input>65-Up
                        <span class="checkmark2"></span>
                                </label>
                            </Col >
                        </Row>
                    </Row>
                </Col>
                <Col lg={{ size: 12 }} md={{ size: 12 }} sm={{ size: 12 }} xs={{ size: 12 }}>
                    <Row className="updatesetting">
                        <Button className="addupdatebtn" onClick={this.addgroup}>Add</Button>
                    </Row>
                </Col>
            </div>
        );
    }
}

export default Adsetting;
