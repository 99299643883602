import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import swal from 'sweetalert';
import Cup from "../../images/map-pin.jpg";
import CampaignService from '../Apiservices/CampaignService';
import Header from '../Header/NewHeader';
import Budget from '../Budget/NewBudget';
// import './fasadPreview.css';
// import './../Setting/';
import Autosuggest from 'react-autosuggest';
import Login from "../Login/Login";
import CreatableSelect from 'react-select/creatable';
import FasadMobilePreview from './FasadMobilePreview';
import MobileAdPreview from '../MobileAdPreview';
import Headerlogo from '../../images/headerlogo.png';
import FasadServices from '../Apiservices/FasadServices';

const fasadService = new FasadServices();
const campaignService = new CampaignService();
const regionsArray = []

class FasadPreview extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            previousPath: props.location.state.previousPath ? props.location.state.previousPath : "",
            campaignType: 'listing',
            redirect: false,
            alert: false,
            currency: props.location.state.budget ? props.location.state.budget.currency : "kr",
            startDate: props.location.state.campaignFields.startDate ? props.location.state.campaignFields.startDate : new Date(date.setDate(date.getDate() + 1)).toISOString().substr(0, 10),
            endDate: props.location.state.campaignFields.endDate ? props.location.state.campaignFields.endDate : new Date(date.setDate(date.getDate() + 9)).toISOString().substr(0, 10),
            region: 'Sweden',
            budget: props.location.state.budget ? props.location.state.budget : {},
            budgetType: props.location.state.budget.budgetType ? props.location.state.budget.budgetType : "",
            geolocation: [],
            selectedRegion: {},
            listingAd: props.location.state.listingAd && props.location.state.listingAd ? props.location.state.listingAd : {},
            campaignFields: props.location.state.campaignFields ? props.location.state.campaignFields : {},
            estimatedReach: props.location.state.budget ? props.location.state.budget.estimatedReach : 0,
            regionsArray: props.location.state.campaignFields && props.location.state.campaignFields.regionsArray ? [props.location.state.campaignFields.regionsArray] : [],
            listingData: props.location.state.listingData ? props.location.state.listingData : {},
            description: props.location.state.listingData.description ? props.location.state.listingData.description : "",
            headline: props.location.state.listingData.headline ? props.location.state.listingData.headline : "",
            noOfRooms: props.location.state.listingData.noOfRooms ? props.location.state.listingData.noOfRooms : 0,
            message: props.location.state.listingData.message ? props.location.state.listingData.message : "",
            listingImges: props.location.state.listingData.listingImges ? props.location.state.listingData.listingImges : "",
            website: "https://brightbee.co/",
            bussinesName: props.location.state.listingData.bussinesName ? props.location.state.listingData.bussinesName : "",
            edited: true
        };

        this._onSuggestionSelected = this._onSuggestionSelected.bind(this);
    }

    componentDidMount() {
        // regionsArray.push(this.state.regionsArray)
        console.log(this.state.regionsArray, "regionsArray state")

        regionsArray = this.state.regionsArray
        console.log(regionsArray, "regionsArray")
    };

    geolocations = (newValue) => {
        campaignService.fbGeoLoaction(newValue.value)
            .then(result => {
                let responseJson = result.data;
                if (responseJson) {
                    this.setState({ geolocation: responseJson });
                    this.setState({ alert: true });
                }
            })
            .catch(err => {
                this.setState({ alert: true });
                swal("Oops", err.response.data.error.message, "error");
            });
    };

    getEstimates = (regions) => {
        let data = {
            region: regions
        }
        campaignService.getEstimatedUser(data)
            .then((result) => {
                this.setState({ estimatedReach: result.data.data.users });
                this.setState(prevState => ({
                    budget: {                   // object that we want to update
                        ...prevState.budget,    // keep all other key-value pairs
                        estimatedReach: result.data.data.users        // update the value of specific key
                    }
                }))

            })
    };

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    callbackBudget = (budget) => {
        this.setState({ budget: budget })
    }

    onChangeAutoSearch = (event, { newValue }) => {
        this.setState({ region: newValue })
    }


    handleRemoveSocial(idx) {
        let someArray = this.state.regionsArray;
        someArray.splice(idx, 1);
        this.setState({ regionsArray: someArray });
        this.getEstimates(someArray)
    }

    handleInputVlaueChange(e, idx) {
        let nextSocialData = this.state.regionsArray.slice();
        nextSocialData[idx] = e.target.value;
        this.setState({ regionsArray: nextSocialData });
    }

    _onSuggestionSelected(event, { suggestion, suggestionValue, index, method }) {
        regionsArray.push(suggestion)
        console.log(regionsArray, "suggestion selected")
        this.setState({ regionsArray: regionsArray });
        this.getEstimates(regionsArray)
    }

    render() {

        const inputProps = {
            placeholder: "Type to add more locations",
            value: this.state.region,
            onChange: this.onChangeAutoSearch
        };
        return (
            <div className="setting">

                <Row className="headerlogostyle">
                    <Link className="logostylefasad" to="/landin-page">
                        <img className="logostylefasad" src={Headerlogo}></img></Link>
                </Row>
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="settingmain">
                        <Col lg={{ size: 6, offset: 1 }} md={{ size: 6, offset: 1 }} sm={12} xs={12} >
                            <Row className="settingprotion1">
                                <p className="topheadingsetting">Settings.</p>
                            </Row>
                            <Row className="formsetting">

                                <Label className="settingname-label" > Period </Label>
                                <Row className="data1data2">
                                    <Col lg="6" md="6" sm="6" xs="6">
                                        <Row className="data1row">
                                            <Input id="startdateinput"
                                                type="date" name="startDate" value={this.state.startDate} onChange={this.onChange} >
                                            </Input>
                                        </Row>
                                    </Col>
                                    <Col lg="6" md="6" sm="6" xs="6">
                                        <Row className="data2row">
                                            <Input id="enddateinput"
                                                type="date" name="endDate" value={this.state.endDate} onChange={this.onChange}>
                                            </Input>
                                        </Row>
                                    </Col>
                                </Row>

                                <Col lg="12" md="12" sm="12" xs="12">
                                    <Row>
                                        <Label className="settingname-label" >Target region<span className="cityzip-label-span">(country/region/city/zip code)</span></Label>
                                        <Autosuggest

                                            suggestions={this.state.geolocation}
                                            onSuggestionsFetchRequested={this.geolocations}
                                            getSuggestionValue={(item) => {
                                                this.setState({ selectedRegion: item })

                                                return item.type === 'country' ? item.name : (item.type === 'region' || item.type === 'geo_market') ? item.name + ', ' + item.country_name : item.name + ', ' + item.region + ', ' + item.country_name
                                            }}
                                            renderSuggestion={(item) => {
                                                return (
                                                    <h6>{item.type === 'country' ? item.name : (item.type === 'region' || item.type === 'geo_market') ? item.name + ', ' + item.country_name : item.name + ', ' + item.region + ', ' + item.country_name}</h6>
                                                )
                                            }}
                                            onSuggestionSelected={this._onSuggestionSelected}
                                            inputProps={inputProps}
                                        />
                                    </Row>
                                    <table className="table mt-3 bordered table-hover  white-table addNewSocial">
                                        <tbody>
                                            {this.state.regionsArray.map((Social, idx) => (
                                                <tr key={idx} className="row Social">
                                                    <td className="col-11 socialInput">
                                                        <Input
                                                            className="selectedresults"
                                                            type="text"
                                                            placeholder={`Add New # ${idx + 1}`}
                                                            value={Social.type === 'country' ? Social.name : (Social.type === 'region' || Social.type === 'geo_market') ? Social.name + ', ' + Social.country_name : Social.name + ', ' + Social.region + ', ' + Social.country_name}
                                                            onChange={e => this.handleInputVlaueChange(e, idx)}
                                                        />
                                                    </td>
                                                    <Row className="crosssighns">
                                                        <p onClick={() => this.handleRemoveSocial(idx)}>x</p>
                                                    </Row>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={12} xs={12} >
                            <Row className="settingprotion2">
                                {/* <img src={Cup} width="100%" height="auto"></img> */}
                                <MobileAdPreview headline={this.state.headline} message={this.state.message} website={this.state.website} description={this.state.description} previewImage={this.state.listingImges} />

                            </Row>
                        </Col>
                        <Budget estimatedReach={this.state.estimatedReach} selectedBudget={this.callbackBudget} />
                        <Row className="settingsaverow">
                            <Col lg={{ size: 11, leftset: 1 }} md={{ size: 11, leftset: 1 }} sm={12} xs={12} >
                                <Link to={{
                                    pathname: this.state.previousPath.toString(),
                                    state: {
                                        campaignType: this.state.campaignType,
                                        previousPath: this.props.location.pathname,
                                        listingData: this.state.listingData,
                                        budget: this.state.budget,
                                        selectedRegion: this.state.regionsArray,
                                        listingAd: this.state.listingAd,
                                        edited: this.state.edited,
                                        campaignFields: {
                                            startDate: this.state.startDate,
                                            endDate: this.state.endDate,
                                            region: this.state.region,
                                            fbPageId: this.state.fbPageId,
                                            regionsArray: this.state.regionsArray
                                        },
                                    }
                                }}>
                                    <Button className="savesetting" type="submit">Next</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Row>

                </Col>
            </div>
        );
    }
}

export default FasadPreview;