import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  Row,
  InputGroup,
  InputGroupAddon,
  Tooltip,
} from "reactstrap";
import Graphchart from "../../images/state.png";
import Header from "../Header/NewHeader";
import "./Newstatistics.css";
import Login from "../Login/Login";
import CampaignService from "../Apiservices/CampaignService";
import { PropagateLoader } from "react-spinners";
import MobileAdPreview from "../MobileAdPreview";
import { IMG_URL } from "../../config";
import Chart from "react-google-charts";
import moment from "moment";
import Axios from "axios";
import { API_BASE } from "../../config";
import { saveAs } from "file-saver";
import swal from "@sweetalert/with-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import config from "../../config";
import {
  Container,
  StatContainer,
  GridContent,
  GridHeader,
  GridMenu,
  GridGraph,
  GridPreview,
  GridNumbers,
  OutlineButtton,
  PrimaryButton,
  SecondaryButton,
} from "../../templates/layouts";
const auth = new Login();
const campaignService = new CampaignService();

class Newstatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      alert: false,
      tooltipOpen: false,
      // currentCampaign: this.props.location.state.currentCampaign
      currentCampaign: this.props.location.state.currentCampaign,
      regions: this.props.location.state.currentCampaign.region,
      userProfile: this.props.location.state.userProfile,
      ShareVendorStatisticsURL:
        config.WEBSITE_BASE +
        "vendorstatistics/" +
        this.props.location.state.currentCampaign._id +
        "-" +
        this.props.location.state.currentCampaign.listingAd._id,
      loader: false,
      stats: [],
      ageGender: [],
      ctr: "-",
      clicks: "-",
      impressions: "-",
      reach: "-",
      frequency: "-",
      firstDay: ""

    };
    console.log("campaigin", this.state.currentCampaign.campaignResourceId)
    // this.parseData = this.parseData.bind(this);
  }

  componentDidMount() {
    this.setState({ loader: true });
    let campaignId = this.state.currentCampaign._id;
    campaignService
      .getStatsOfCampaign(campaignId)
      .then((result) => {
        this.setState({ stats: result.data.stats.data })
        if (result) {
          let totalImpressions = 0, totalReach = 0, uniqueLinks = 0, frequency = 0, ctr = 0
          let responseJsonSecond = result.data.ageGender.data
          if (responseJsonSecond.length > 0) {
            for (var i = 0; i < responseJsonSecond.length; i++) {
              totalImpressions += JSON.parse(responseJsonSecond[i].impressions);
              totalReach = totalReach + JSON.parse(responseJsonSecond[i].reach);
              uniqueLinks += JSON.parse(responseJsonSecond[i].unique_inline_link_clicks);
              frequency += JSON.parse(responseJsonSecond[i].frequency);
              ctr += JSON.parse(responseJsonSecond[i].ctr);
            }
            let ctrValue = (uniqueLinks / totalReach) * 100
            ctrValue = ctrValue.toFixed(2);
            this.setState({ clicks: uniqueLinks, impressions: totalImpressions, reach: totalReach, ctr: ctrValue + "%" })
          } else {
            alert("No stats to show", "No stats found", "error");
          }
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
        alert("No stats to show", "No stats found", "error");
      });
  }

  // parseData = () => {
  //   let data = [];
  //   data.push(["Date", "Impressions", "Reach", "Clicks"]);
  //   data.push(
  //     this.state.stats.forEach((element, index) => {
  //       return [
  //         element.date_start,
  //         Number(element.impressions),
  //         Number(element.reach),
  //         Number(element.clicks),
  //       ];
  //     })
  //   );
  //   console.log(data);
  //   return data;
  // };

  exportPDF = (e) => {
    e.preventDefault();
    this.setState({ loader: true });

    // let data = {
    //     campaign: this.state.currentCampaign,
    //     ctr: this.state.ctr,
    //     clicks: this.state.clicks,
    //     impressions: this.state.impressions,
    //     frequency: this.state.frequency,
    //     reach: this.state.reach
    // }

    let data = {
      statsURL: this.state.ShareVendorStatisticsURL,
    };

    if (!this.state.stats)
      return alert("No stats to show", "No stats found", "error");

    Axios({
      method: "post",
      url: API_BASE + "campaign/exportpdf",
      data: data,
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("token"))}`,
      },
    })
      .then((result) => {
        if (result.status == 200) {
          Axios({
            method: "get",
            url: API_BASE + "campaign/fetchpdf",
            responseType: "arraybuffer",
            headers: {
              Authorization: `Bearer ${JSON.parse(
                sessionStorage.getItem("token")
              )}`,
            },
          })
            .then((response) => {
              if (response.status == 200) {
                const blob = new Blob([response.data], {
                  type: "application/pdf",
                });
                saveAs(
                  blob,
                  (
                    this.state.currentCampaign.name +
                    Date.now() +
                    ".pdf"
                  ).replace(/\s/g, "")
                );
                this.setState({ loader: false });
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({ loader: false });
            });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loader: false });
      });
  };

  CopyCustomerStatsLink = () => {
    this.setState({ tooltipOpen: true });
  };

  ShareVendor = () => {
    swal({
      buttons: {
        cancel: "Close",
      },
      content: (
        <div>
          <h2>Share With Vendor</h2>
          <InputGroup>
            <Input value={this.state.ShareVendorStatisticsURL} />
            <span>
              <InputGroupAddon addonType="append">
                <CopyToClipboard text={this.state.ShareVendorStatisticsURL}>
                  <Button
                    color="info"
                    id="TooltipExample"
                    onClick={this.CopyCustomerStatsLink}
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
              </InputGroupAddon>
            </span>
          </InputGroup>
        </div>
      ),
    });
  };

  // extractStats = (age, gender, type) => {
  //   if (this.state.ageGender !== [] && type === "reach") {
  //     let obj = this.state.ageGender.filter(
  //       (item) => item.age == age && item.gender == gender
  //     );
  //     return obj.length !== 0 ? Number(obj[0].reach) : 0;
  //   }
  //   if (this.state.ageGender !== [] && type === "click") {
  //     let obj = this.state.ageGender.filter(
  //       (item) => item.age == age && item.gender == gender
  //     );
  //     return obj.length !== 0 ? Number(obj[0].unique_inline_link_clicks) : 0;
  //   }
  // };

  render() {
    const chart = (
      <Chart
        width={"600px"}
        height={"400px"}
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        //  data={this.parseData}
        data={[
          ["Date", "Time displayed", "People reached", "Clicks"],

          [
            this.state.stats[0]
              ? moment(this.state.stats[0].date_start).format("MMM Do")
              : 0,
            this.state.stats[0] ? Number(this.state.stats[0].impressions) : 0,
            this.state.stats &&
              this.state.stats.length &&
              this.state.stats[0].reach
              ? Number(this.state.stats[0].reach)
              : 0,
            this.state.stats &&
              this.state.stats.length &&
              this.state.stats[0].unique_inline_link_clicks
              ? Number(this.state.stats[0].unique_inline_link_clicks)
              : 0,
          ],
          [
            this.state.stats[1]
              ? moment(this.state.stats[1].date_start).format("MMM Do")
              : 0,
            this.state.stats[1] ? Number(this.state.stats[1].impressions) : 0,
            this.state.stats && this.state.stats.length && this.state.stats[1]
              ? Number(this.state.stats[1].reach)
              : 0,
            this.state.stats && this.state.stats.length && this.state.stats[1]
              ? Number(this.state.stats[1].unique_inline_link_clicks)
              : 0,
          ],
          [
            this.state.stats[2]
              ? moment(this.state.stats[2].date_start).format("MMM Do")
              : 0,
            this.state.stats[2] ? Number(this.state.stats[2].impressions) : 0,
            this.state.stats && this.state.stats.length && this.state.stats[2]
              ? Number(this.state.stats[2].reach)
              : 0,
            this.state.stats && this.state.stats.length && this.state.stats[2]
              ? Number(this.state.stats[2].unique_inline_link_clicks)
              : 0,
          ],
          [
            this.state.stats[3]
              ? moment(this.state.stats[3].date_start).format("MMM Do")
              : 0,
            this.state.stats[3] ? Number(this.state.stats[3].impressions) : 0,
            this.state.stats && this.state.stats.length && this.state.stats[3]
              ? Number(this.state.stats[3].reach)
              : 0,
            this.state.stats && this.state.stats.length && this.state.stats[3]
              ? Number(this.state.stats[3].unique_inline_link_clicks)
              : 0,
          ],
          [
            this.state.stats[4]
              ? moment(this.state.stats[4].date_start).format("MMM Do")
              : 0,
            this.state.stats[4] ? Number(this.state.stats[4].impressions) : 0,
            this.state.stats && this.state.stats.length && this.state.stats[4]
              ? Number(this.state.stats[4].reach)
              : 0,
            this.state.stats && this.state.stats.length && this.state.stats[4]
              ? Number(this.state.stats[4].unique_inline_link_clicks)
              : 0,
          ],
          [
            this.state.stats[5]
              ? moment(this.state.stats[5].date_start).format("MMM Do")
              : 0,
            this.state.stats[5] ? Number(this.state.stats[5].impressions) : 0,
            this.state.stats && this.state.stats.length && this.state.stats[5]
              ? Number(this.state.stats[5].reach)
              : 0,
            this.state.stats && this.state.stats.length && this.state.stats[5]
              ? Number(this.state.stats[5].unique_inline_link_clicks)
              : 0,
          ],
          // [this.state.stats[1].date_start, Number(this.state.stats[1].frequency), Number(this.state.stats[1].impressions), Number(this.state.stats[1].clicks)],
          // [this.state.stats[2].date_start, Number(this.state.stats[2].frequency), Number(this.state.stats[2].impressions), Number(this.state.stats[2].clicks)],
          // [this.state.stats[3].date_start, Number(this.state.stats[3].frequency), Number(this.state.stats[3].impressions), Number(this.state.stats[3].clicks)],
          // [this.state.stats[4].date_start, Number(this.state.stats[4].frequency), Number(this.state.stats[4].impressions), Number(this.state.stats[4].clicks)],
          // [this.state.stats[5].date_start, Number(this.state.stats[5].frequency), Number(this.state.stats[5].impressions), Number(this.state.stats[5].clicks)],
        ]}
        options={{
          chartArea: { width: "65%" },
          backgroundColor: "transparent",
          colors: ["#ffdfda", "#f9ab93", "#283447"],
          legend: { position: "bottom", maxLines: 1 },
        }}
        legendToggle
      />
    );

    // const menChart = <Chart
    //     width={'700px'}
    //     height={'400px'}
    //     chartType="ColumnChart"
    //     loader={<div>Loading Chart</div>}
    //     data={[
    //         ['Age', 'Reach(male)', 'Clicks(male)', 'Reach(female)', 'Clicks(female)'],
    //         ['13-17', this.state.ageGender.length > 0 ? this.extractStats('13-17', 'male', 'reach') : 0,
    //          this.state.ageGender.length > 0 ? this.extractStats('13-17', 'male', 'click') : 0,
    //           this.state.ageGender.length > 0 ? this.extractStats('13-17', 'female', 'reach') : 0,
    //            this.state.ageGender.length > 0 ? this.extractStats('13-17', 'female', 'click') : 0],

    //         ['18-24', this.state.ageGender.length > 0 ? this.extractStats('18-24', 'male', 'reach') : 0,
    //          this.state.ageGender.length > 0 ? this.extractStats('18-24', 'male', 'click') : 0,
    //           this.state.ageGender.length > 0 ? this.extractStats('18-24', 'female', 'reach') : 0,
    //            this.state.ageGender.length > 0 ? this.extractStats('18-24', 'female', 'click') : 0],

    //         ['25-34', this.state.ageGender.length > 0 ? this.extractStats('25-34', 'male', 'reach') : 0,
    //          this.state.ageGender.length > 0 ? this.extractStats('25-34', 'male', 'click') : 0,
    //           this.state.ageGender.length > 0 ? this.extractStats('25-34', 'female', 'reach') : 0,
    //            this.state.ageGender.length > 0 ? this.extractStats('25-34', 'female', 'click') : 0],

    //         ['35-44', this.state.ageGender.length > 0 ? this.extractStats('35-44', 'male', 'reach') : 0,
    //          this.state.ageGender.length > 0 ? this.extractStats('35-44', 'male', 'click') : 0,
    //           this.state.ageGender.length > 0 ? this.extractStats('35-44', 'female', 'reach') : 0,
    //            this.state.ageGender.length > 0 ? this.extractStats('35-44', 'female', 'click') : 0],

    //         ['45-54', this.state.ageGender.length > 0 ? this.extractStats('45-54', 'male', 'reach') : 0,
    //          this.state.ageGender.length > 0 ? this.extractStats('45-54', 'male', 'click') : 0,
    //           this.state.ageGender.length > 0 ? this.extractStats('45-54', 'female', 'reach') : 0,
    //            this.state.ageGender.length > 0 ? this.extractStats('45-54', 'female', 'click') : 0],

    //         ['55-64', this.state.ageGender.length > 0 ? this.extractStats('55-64', 'male', 'reach') : 0,
    //          this.state.ageGender.length > 0 ? this.extractStats('55-64', 'male', 'click') : 0,
    //           this.state.ageGender.length > 0 ? this.extractStats('55-64', 'female', 'reach') : 0,
    //            this.state.ageGender.length > 0 ? this.extractStats('55-64', 'female', 'click') : 0],

    //         ['65+', this.state.ageGender.length > 0 ? this.extractStats('65+', 'male', 'reach') : 0,
    //          this.state.ageGender.length > 0 ? this.extractStats('65+', 'male', 'click') : 0,
    //           this.state.ageGender.length > 0 ? this.extractStats('65+', 'female', 'reach') : 0,
    //            this.state.ageGender.length > 0 ? this.extractStats('65+', 'female', 'click') : 0]
    //         // ['13-17', this.state.ageGender.length > 0 ? this.extractStats('13-17', 'male', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('13-17', 'male', 'click') : 0],
    //         // ['18-24', this.state.ageGender.length > 0 ? this.extractStats('18-24', 'male', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('18-24', 'male', 'click') : 0],
    //         // ['25-34', this.state.ageGender.length > 0 ? this.extractStats('25-34', 'male', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('25-34', 'male', 'click') : 0],
    //         // ['35-44', this.state.ageGender.length > 0 ? this.extractStats('35-44', 'male', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('35-44', 'male', 'click') : 0],
    //         // ['45-54', this.state.ageGender.length > 0 ? this.extractStats('45-54', 'male', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('45-54', 'male', 'click') : 0],
    //         // ['55-64', this.state.ageGender.length > 0 ? this.extractStats('55-64', 'male', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('55-64', 'male', 'click') : 0],
    //         // ['65+', this.state.ageGender.length > 0 ? this.extractStats('65+', 'male', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('65+', 'male', 'click') : 0]
    //     ]}
    //     options={{
    //         chartArea: { width: '75%' },
    //         backgroundColor: 'transparent',
    //         colors: ['#14bda6', '#3e69d6', '#ffb8bf', '#fb928e'],
    //         legend: { position: 'bottom', maxLines: 1 },
    //     }}
    //     legendToggle
    // />

    // const womenChart = <Chart
    //     width={'600px'}
    //     height={'400px'}
    //     chartType="ColumnChart"
    //     loader={<div>Loading Chart</div>}
    //     data={[
    //         ['Age', 'Reach', 'Link Click'],
    //         ['13-17', this.state.ageGender.length > 0 ? this.extractStats('13-17', 'female', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('13-17', 'female', 'click') : 0],
    //         ['18-24', this.state.ageGender.length > 0 ? this.extractStats('18-24', 'female', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('18-24', 'female', 'click') : 0],
    //         ['25-34', this.state.ageGender.length > 0 ? this.extractStats('25-34', 'female', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('25-34', 'female', 'click') : 0],
    //         ['35-44', this.state.ageGender.length > 0 ? this.extractStats('35-44', 'female', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('35-44', 'female', 'click') : 0],
    //         ['45-54', this.state.ageGender.length > 0 ? this.extractStats('45-54', 'female', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('45-54', 'female', 'click') : 0],
    //         ['55-64', this.state.ageGender.length > 0 ? this.extractStats('55-64', 'female', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('55-64', 'female', 'click') : 0],
    //         ['65+', this.state.ageGender.length > 0 ? this.extractStats('65+', 'female', 'reach') : 0, this.state.ageGender.length > 0 ? this.extractStats('65+', 'female', 'click') : 0]
    //     ]}
    //     options={{
    //         chartArea: { width: '60%' },
    //         backgroundColor: 'transparent',
    //         colors: ['#14bda6', '#3e69d6'],
    //         legend: { position: 'bottom', maxLines: 1 },
    //     }}
    //     legendToggle
    // />

    if (!auth.loggedIn()) {
      return <Redirect to="/new-login" />;
    }

    return (
      <Container>
        <Header />
        <StatContainer>
          <GridHeader>
            <p>Statistics.</p>
          </GridHeader>

          <GridMenu>
            <OutlineButtton
              class="newstatisticspdf-btn"
              onClick={this.exportPDF}
            >
              Export to PDF
            </OutlineButtton>

            <SecondaryButton type="submit" onClick={this.ShareVendor}>
              Share to vendor
            </SecondaryButton>
            <Link
              to={{
                pathname: "/info-ad-setting",
                state: {
                  currentCampaign: this.state.currentCampaign,
                  userProfile: this.state.userProfile,
                },
              }}
              style={{ textDecoration: "none" }}
            >
              <PrimaryButton type="submit">Info &amp; Setting</PrimaryButton>
            </Link>
          </GridMenu>

          <GridContent>
            <GridNumbers>
              <Col>
                <Row className="newstatisticsdstate">
                  <Col>
                    <Row className="newstatisticsnumber">
                      <p>{this.state.clicks}</p>
                    </Row>
                    <Row className="newstatisticstextheading">
                      <p>Clicks</p>
                    </Row>
                    <Row className="newstatisticstext">
                      <p>
                        People have clicked your ad and viewed the property page
                      </p>
                    </Row>
                  </Col>

                  <Col>
                    <Row className="newstatisticsnumber">
                      <p>{this.state.ctr}</p>
                    </Row>
                    <Row className="newstatisticstextheading">
                      <p>Click frequency</p>
                    </Row>
                    <Row className="newstatisticstext">
                      <p>
                        The percentage of people that saw your ad and then
                        clicked to see your property page
                      </p>
                    </Row>
                  </Col>
                </Row>
                <Row className="newstatisticsdstate">
                  <Col>
                    <Row className="newstatisticsnumber">
                      <p>{this.state.reach}</p>
                    </Row>
                    <Row className="newstatisticstextheading">
                      <p>People reached</p>
                    </Row>
                    <Row className="newstatisticstext">
                      <p>The amount of unique individuals that saw your ad</p>
                    </Row>
                  </Col>

                  <Col>
                    <Row className="newstatisticsnumber">
                      <p>{this.state.impressions}</p>
                    </Row>
                    <Row className="newstatisticstextheading">
                      <p>Times displayed</p>
                    </Row>
                    <Row className="newstatisticstext">
                      <p>The total number of times your ad was shown</p>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </GridNumbers>

            <GridGraph>
              <Col>
                <Row>{chart}</Row>
              </Col>
            </GridGraph>

            <GridPreview>
              <Col>
                <Row>
                  {/* previewVideo */}

                  {this.state.currentCampaign.listingAd.listingAdImages[0] != null && <MobileAdPreview headline={this.state.currentCampaign.listingAd.headline}
                    message={this.state.currentCampaign.listingAd.message}
                    website={this.state.currentCampaign.listingAd.website}
                    description={
                      this.state.currentCampaign.listingAd.description
                    }
                    previewImage={
                      IMG_URL +
                      this.state.currentCampaign.listingAd.listingAdImages[0]
                    }
                    mediaType={"image"}
                  />
                  }
                  {this.state.currentCampaign.listingAd.listingAdVideos[0] != null
                    && <MobileAdPreview headline={this.state.currentCampaign.listingAd.headline}
                      message={this.state.currentCampaign.listingAd.message}
                      website={this.state.currentCampaign.listingAd.website}
                      description={
                        this.state.currentCampaign.listingAd.description
                      }
                      previewVideo={
                        IMG_URL +
                        this.state.currentCampaign.listingAd.listingAdVideos[0]
                      }
                      mediaType={"video"}
                    />
                  }
                </Row>
              </Col>
            </GridPreview>

            <div
              className={this.state.loader ? "parentDisable" : ""}
              width="100%"
            >
              <div className="overlay-box">
                <PropagateLoader
                  // css={override}
                  sizeUnit={"px"}
                  size={25}
                  color={"white"}
                  loading={this.state.loader}
                />
              </div>
            </div>
          </GridContent>
        </StatContainer>
      </Container>
    );
  }
}
export default Newstatistics;
