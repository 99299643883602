import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, Col, Input, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import swal from 'sweetalert';
import Apiservices from '../Apiservices/Urlservice';
import Header from '../Header/index.js';
import Login from '../Login/Login';
import './Style.css';
const auth = new Login();

const urlpost = new Apiservices();
// const toggle = () => setModal(!modal);
class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			url: '',
			// property_list: [],
			list_campaingns: [],
			userinfo: {},
			redirect: false,
			alert: false,
			invalid: '',
			// propertyImages:[0],
			isLoaded: true,
			dropdownOpen: false,
			Draftlunchmodal: false,
			loading: false
		};

		this.toggle = this.toggle.bind(this);
		this.Drafttoggle = this.Drafttoggle.bind(this);
		this.urlhemnet = this.urlhemnet.bind(this);
		this.onChange = this.onChange.bind(this);
		// console.log("pangaa", this.props.location.state.userinfo1)
	}

	// async componentDidMount() {
	//   const res = await urlpost.userprofile();
	//   // console.log(`sdfghjoiuy ${JSON.stringify(res.data)}`);
	//   this.setState({ userinfo: res.data.user });
	//   // this.setState({ propertyImages: this.state.property_list.propertyImages });
	// }

	async componentDidMount() {
		this.setState({ loading: true });
		const res = await urlpost.list_campaigns();
		const result = await urlpost.userprofile(auth.getId());
		// console.log(`sdfghjoiuy ${JSON.stringify(res.data)}`);
		this.setState({ list_campaingns: res.data });
		console.log("property", res.data)
		this.setState({ userinfo: result.data.user });
		this.setState({ loading: false });
		// this.setState({ propertyImages: this.state.campaigns_list.propertyImages });
	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	Drafttoggle() {
		this.setState({
			Draftlunchmodal: !this.state.Draftlunchmodal
		});
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	urlhemnet = e => {
		if (this.state.url) {
			urlpost
				.posturl(this.state)
				.then(result => {
					let responseJson = result.data;
					if (responseJson._id != null) {
						this.setState({ redirect: true });
						swal("Tack", "Glad to receive your campaign", "success");
					}
				})
				.catch(err => {
					this.setState({ alert: true });
					this.setState({ invalid: err.response.data.error.message });
					swal("Oops", "There is some problems ", "error");
				});
		}
	};



	render() {
		if (!auth.loggedIn()) {

			return (
				swal("Sorry!", "You Are Not LoggedIn", "error"),
				<Redirect to="/" />
			)
		}
		if (this.state.redirect == true) {
			return (
				window.location.replace("/home")
			)
		}
		return (
			<div className="custom-container">
				<Header />
				<Col lg={12} md={12} sm={12} xs={12}>
					<Row className="modeltext">
						<Col lg={{ size: 12 }} md={{ size: 12 }} sm={{ size: 12 }} xs={12} >
							<Row className="textpropertylist">
								<h2 className="heading-toppp">Campaign List</h2>
							</Row>
						</Col>
						{/* <Col className="btnnewpropert" xs={{size:6, order: 12}} md={{size:6, order: 12}} sm={{size:6, order: 12}} xs={{size:12, order: 1}} >
            <Link to="add-property">
            <button className="newproperty">add property</button>
            </Link>
            </Col> */}
						{/* <Col className="btnnewpropert" xs={{size:6, order: 12}} md={{size:6, order: 12}} sm={{size:6, order: 12}} xs={{size:12, order: 1}} >
            <Link to="campaign-type">
            <button className="newproperty">Add Campaign</button>
            </Link>
            </Col> */}
					</Row>
				</Col>


				<Modal isOpen={this.state.Draftlunchmodal} size="lg" className="Draft-modal-block-size" toggle={this.toggle}>
					<Col xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}>
						<div className="Draft-block-modal-body-costum">
							<ModalBody>
								<p className="Draft-heading-text1">
									Nytt objekt fran Hemnet
                                </p>
								<p className="Draft-heading-text2">
									Ga till en annons pa Hemnet, markera hela adressfalet, kopiera och klistra in adressen nedan.
                                </p>
								<Row>
									<Col className="parent-Draft-btn-custom" xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}>
										<Input
											type="text"
											name="url"
											placeholder="URL here"
											onChange={this.onChange}
											required
										></Input>
									</Col>
									<ModalFooter className="modalfootercustom">
										<Col>  <p className="cancelbtn" onClick={this.Drafttoggle}>AVBRYT</p></Col>
										<Col> <p className="addbtn" onClick={this.urlhemnet} >LAGG TILL</p></Col>
									</ModalFooter>
									{/* <Col className="parent-Draft-btn-custom" xs={{ size: 2, leftset: 1 }} sm={{ size: 2, offset: 0 }} md={{ size: 2, offset: 0 }}>
                                        <button className="Draft-block-custom-btn">
                                            <div className="Draft-btn-block-custom-size">
                                                DELETE
                                            </div>
                                        </button>
                                    </Col>
                                    <Col className="parent-Draft-btn-custom" xs={{ size: 2, offset: 0 }} sm={{ size: 2, offset: 0 }} md={{ size: 2, offset: 0 }}>
                                        <button onClick={this.Drafttoggle} className="Draft-block-btn-right-custom">
                                            <div className="Draft-block-btn-right-custom-size">
                                                <p> CANCEL </p>
                                            </div>
                                        </button>
                                    </Col> */}
								</Row>

							</ModalBody>
						</div>
					</Col>
				</Modal>


				{/* <Col lg={12} md={12} sm={12} xs={12}>
          <Row>
          {this.state.list_campaingns &&
                      this.state.list_campaingns.map((campaign) => {
                    return (
            <Col lg={6} md={6} sm={12} xs={12}>
                    <Row>
          <Card className="cardsizes" >
          <Link to={{
                  pathname: "/add-list",
                  state: {
                    name: campaign.name,
                    _id: campaign._id,
                    endDate: campaign.endDate,
                    startDate: campaign.startDate,
                    propertydetail: campaign.property,
                    campaignType: campaign.campaignType
                  }
                }}>
                  <CardImg>
                    <img src={campaign.property.name}></img>
                  </CardImg>
              <CardBody className="Card_Body" style={{padding:2}}>
                <Row className="mainrow"><p className="headingtopp1">{campaign.property.name}</p></Row>
                <Row className="subrow"><p className="heading2">
                    {"  "} Goal: <span className="colorspan">{campaign.campaignType}</span></p> </Row>
                    <Row className="spinnerfix">
               <Spinner  marginLeft={-100}  size={40} spinnerColor={"#000000"} spinnerWidth={1} visible={this.state.loading} />
        </Row>
              </CardBody>
              </Link>
            </Card>
                    
            </Row>
                  </Col> 
                  )})}
                  <Col lg={2} md={2} sm={2} xs={2}>
                  <Row>
                  <Link to={{
                  pathname: "/campaign-type",
                  state: {
                    userinfo: this.state.userinfo
                  }
                }}>
                    <button className="fixbutton">+</button>
                  </Link>
                  </Row>
                  </Col>
          </Row>
        </Col>  */}



				{/* <Col lg={12} md={12} sm={12} xs={12}>
          <Row>
          {this.state.property_list &&
                      this.state.property_list.map((property) => {
                    return (
            <Col lg={3} md={4} sm={6} xs={12}>
                    <Row>
                    <Link to={{
                  pathname: "/new-ad",
                  state: {
                    property: property._id,
                    name: property.name,
                    brokerLink: property.website,
                    propertyImages: property.propertyImages,
                    description: property.description.substring(0, 80),
                  }
                }}>
          <Card className="cardsizes" >
              <a href="/">
                <CardImg className="image_Fitting"> <div> {this.state.propertyImages && this.state.propertyImages.length > 0 && this.state.propertyImages.map((images) => 
                <img src={"https://realreach.s3.eu-north-1.amazonaws.com/" + images} width='20%' />)} </div>
                </CardImg>
                <CardImg className="image_Fitting" src={ 'https://realreach.s3.eu-north-1.amazonaws.com/'+property.propertyImages[0]} width='10%' alt="image" />
              </a>
              <CardBody className="Card_Body" style={{padding:2}}>
                <h5>{property.name}</h5>
                <p>{property.noOfRooms}</p>
              </CardBody>
            </Card>
            </Link>
                    
            </Row>
                  </Col> 
                  )})}
          </Row>
        </Col>  */}

				<Col lg={12} md={12} sm={12} xs={12}>
					<Row>
						{this.state.list_campaingns &&
							this.state.list_campaingns.map((campaign) => {
								return (

									<Col lg={4} md={4} sm={6} xs={12}>
										{campaign.agent && <Row>

											<Card className="cardsizes" >
												{/* <Link to={{
                  pathname: "/new-ad",
                }}> */}
												<img src={'https://realreach.s3.eu-north-1.amazonaws.com/' + campaign.agent.agentImages[0]} height="200"></img>
												{/* <CardImg src={'https://realreach.s3.eu-north-1.amazonaws.com/' + campaign.agent.agentImages[0]} alt="image" /> */}
												<CardBody width="100%" height="100%" className="Card_Body" style={{ padding: 2 }}>
													<Row className="text1overimg">
														<h5 >{campaign.agent.name}</h5>
													</Row>
												</CardBody>
												{/* </Link> */}
											</Card>
										</Row>}  {campaign.property &&
											<Row>

												<Card className="cardsizes" >
													{/* <Link to={{
                  pathname: "/new-ad",
                }}> */}
													<img src={'https://realreach.s3.eu-north-1.amazonaws.com/' + campaign.property.propertyImages[0]} atl="image" height="200"></img>
													{/* <CardImg width="100%" height="100%" src={'https://realreach.s3.eu-north-1.amazonaws.com/' + campaign.property.propertyImages[0]} alt="image" /> */}
													<CardBody className="Card_Body" style={{ padding: 2 }}>
														<Row className="text1overimg">
															<h5 >{campaign.property.name}</h5>
														</Row>
													</CardBody>
													{/* </Link> */}
												</Card>
											</Row>}
									</Col>
								)
							})}


					</Row>
				</Col>
				<Col lg={2} md={2} sm={2} xs={2}>
					<Row>
						<Link to={{
							pathname: "/campaign-type",
							state: {
								userinfo: this.state.userinfo
							}
						}}>
							<button className="fixbutton">+</button>
						</Link>
					</Row>
				</Col>

				{/* <Col>
          <Row>
            <button className="addplus" onClick={this.Drafttoggle}>+</button>
          </Row>
        </Col> */}
			</div>
		);
	}
}

export default Home;
