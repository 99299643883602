import React, { Component, useState } from 'react';
import './Style.css';
import { Row, Col, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardImg, CardBody } from 'reactstrap';
import swal from 'sweetalert';
// import iconehome from '../../images/house.jpg';
// import iconehome from '../../images/qqq.png';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header/index.js';
import Apiservices from '../Apiservices/Urlservice';
const urlpost = new Apiservices();

// let description= this.state.description.substring(0, 80);
// const toggle = () => setModal(!modal);
class Addlist extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: false,
			alert: false,
			invalid: '',
			isLoaded: true,
			name: this.props.location.state.name,
			campaign: this.props.location.state._id,
			amount: this.props.location.state.amount,
			endDate: this.props.location.state.endDate,
			startDate: this.props.location.state.startDate,
			// website: this.props.location.state.website,
			// property: this.props.location.state.property,
			// brokerLink: this.props.location.state.brokerLink,
			// propertyname: this.props.location.state.propertyname,
			// description: this.props.location.state.description,
			propertyImages: this.props.location.state.propertyImages,
			campaignType: this.props.location.state.campaignType,
			propertydetail: this.props.location.state.propertydetail,
			listadd: []
		};
		this.toggle = this.toggle.bind(this);
		this.Drafttoggle = this.Drafttoggle.bind(this);
		this.Drafttoggle2 = this.Drafttoggle2.bind(this);
		this.onChange = this.onChange.bind(this);
		this.deletecampaign = this.deletecampaign.bind(this);
		console.log("amount", this.state.amount);
		console.log("start", this.state.startDate);
		console.log("end", this.state.endDate);
		console.log("website", this.state.website);
		console.log("propertid", this.state.property);
		console.log("proname", this.state.propertyname);
		console.log("des", this.state.description);
		console.log("camname", this.state.name);
		console.log("campaigntype", this.state.campaignType);
		console.log("campaignid", this.state.campaign);
		console.log("propetiesimages", this.state.propertyImages);
	}
	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}
	Drafttoggle() {
		this.setState({
			Draftlunchmodal: !this.state.Draftlunchmodal
		});
	}
	Drafttoggle2() {
		this.setState({
			Draftlunchmodal2: !this.state.Draftlunchmodal2
		});
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	componentDidMount() {
		return urlpost.adlist(this.state.campaign)
			// console.log("peopertyidd", this.state.property)
			.then(res => {
				// console.log(`sdfghjoiuy ${JSON.stringify(res.data)}`);
				this.setState({ listadd: res.data });
			});
	}

	// deletecampaign() {
	//   //   console.log("jkgdjagdjasd", this.state,campaign_id)
	//   return urlpost.campaigndelete(this.state.list_campaingns[0]._id);
	//   swal("Oops", "kan inte ställa in datum till tidigare tid", "error");
	// }

	deletecampaign = () => {
		{
			urlpost
				.campaigndelete(this.state._id)
				.then(result => {
					let responseJson = result.data;
					if (responseJson) {
						this.setState({ redirect: true });
						this.setState({ alert: true });
					}
					alert("Thnak you", "campaign deleted", "success")
				})
				.catch(err => {
					this.setState({ alert: true });
					this.setState({ invalid: err.response.data.error.message });
					swal("Oops", "cannot set in date to past time", "error");
				});
		}
	};

	render() {
		if (this.state.redirect == true) {
			return window.location.replace("/home");
		}
		return (
			<div className="custom-container">
				<Header />
				<Col lg={12} md={12} sm={12} xs={12}>
					<Row className="modeltext">
						{/* <Col  lg={{size:6, order: 1}} md={{size:6, order: 1}} sm={{size:6, order: 1}} xs={12} xs={{size:12, order: 12}}>
            <Row className="textpropertylist">
            <h2 className="propertieslist">fastighetslista</h2>
            </Row>
            </Col> */}
						<Col className="btnnewpropert" xs={{ size: 6, order: 12 }} md={{ size: 6, order: 12 }} sm={{ size: 6, order: 12 }} xs={{ size: 12, order: 1 }} >
							<Link to={{
								pathname: "/Create-add",
								state: {
									name: this.state.name,
									campaign: this.state.campaign,
									// name: this.state.name,
									amount: this.state.amount,
									// endDate: this.state.endDate,
									// startDate: this.state.startDate,
									// website: this.state.website,
									// property: this.state.property,
									// brokerLink: this.state.brokerLink,
									// propertyname: this.state.propertyname,
									campaignType: this.state.campaignType,
									propertyImages: this.state.propertyImages,
									propertydetail: this.state.propertydetail,
									// description: this.state.description,
									// propertyImages: this.state.propertyImages,
								}
							}}>
								<button className="newproperty">Create add</button>
							</Link>
						</Col>
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12}>
					<Row>
						<Col lg={12} md={12} sm={11} className="categories-crd-5">
							<Row>
								<Col lg={{ size: 10, offset: 1 }} md={10} sm={11}>
									<Row className="capmainlisttextk">
										<p>Campaign</p>
									</Row>
									<Row className="heading1">
										<h1>{this.state.name}</h1>
									</Row>
									<Row className="heading1">
										<h5>{this.state.propertydetail.website}</h5>
									</Row>
									<Row className="heading1">
										<h5>{this.state.propertydetail.description}</h5>
									</Row>
								</Col>
								{this.state.propertydetail.propertyImages.map(image => {
									let images = 'https://realreach.s3.eu-north-1.amazonaws.com/' + image
									return (
										<Col lg={2} md={3} sm={11} xs={11} >
											<Card>
												<Row>
													<CardImg className="image_Fitting" src={images} height="auto" width="100%" alt="image" ></CardImg>
												</Row>
											</Card>
										</Col>
									)
								})}
							</Row>
						</Col>
						<Col lg={12} md={12} sm={12} xs={12}>
							<Row className="capmainlisttextt">
								<p>Ad's</p>
							</Row>

							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<Row>
										{this.state.listadd &&
											this.state.listadd.map((add) => {
												return (
													<Col lg={6} md={6} sm={12} xs={12}>
														<Row>
															{this.state.campaignType === "listing" && (
																<Card className="cardsizes23" >
																	{/* <CardImg className="image_Fitting"> <div> {this.state.propertyImages && this.state.propertyImages.length > 0 && this.state.propertyImages.map((images) => 
                <img src={"https://realreach.s3.eu-north-1.amazonaws.com/" + images} width='20%' />)} </div>
                </CardImg> */}

																	<CardImg className="image_Fitting" src={add.imageUrl} alt="image" />
																	<CardBody className="Card_Body" style={{ padding: 2 }}>
																		<Row className="mainrow23"><p className="headingtopp23">{add.heading1}</p></Row>
																		<Row className="subrow23"><p className="heading23"><br />{add.description}</p> </Row>
																		<Row className="subrow23"><p className="heading23"><br />{add.finalUrl}</p> </Row>
																	</CardBody>

																</Card>
															)}
															{this.state.campaignType === "agent" && (
																<Card className="cardsizes23" >
																	{/* <CardImg className="image_Fitting"> <div> {this.state.propertyImages && this.state.propertyImages.length > 0 && this.state.propertyImages.map((images) => 
                <img src={"https://realreach.s3.eu-north-1.amazonaws.com/" + images} width='20%' />)} </div>
                </CardImg> */}

																	{/* <CardImg className="image_Fitting" src={add.imageUrl} width='10%' alt="Card image cap" /> */}
																	<CardBody className="Card_Body" style={{ padding: 2 }}>
																		<Row className="mainrow23"><p className="headingtopp23">{add.heading1}</p></Row>
																		<Row className="subrow23"><p className="heading23"><br />{add.description}</p> </Row>
																		<Row className="subrow23"><p className="heading23"><br />{add.finalUrl}</p> </Row>
																	</CardBody>

																</Card>
															)}
															{this.state.campaignType === "agency" && (
																<Card className="cardsizes23" >
																	{/* <CardImg className="image_Fitting"> <div> {this.state.propertyImages && this.state.propertyImages.length > 0 && this.state.propertyImages.map((images) => 
                <img src={"https://realreach.s3.eu-north-1.amazonaws.com/" + images} width='20%' />)} </div>
                </CardImg> */}

																	{/* <CardImg className="image_Fitting" src={add.imageUrl} width='10%' alt="Card image cap" /> */}
																	<CardBody className="Card_Body" style={{ padding: 2 }}>
																		<Row className="mainrow23"><p className="headingtopp23">{add.heading1}</p></Row>
																		<Row className="subrow23"><p className="heading23"><br />{add.description}</p> </Row>
																		<Row className="subrow23"><p className="heading23"><br />{add.finalUrl}</p> </Row>
																	</CardBody>

																</Card>
															)}
														</Row>
													</Col>

												)
											})}
									</Row>
								</Col>
								{/* {this.state.listadd &&
                      this.state.listadd.map((add) => {
                        // this.setState({ campaignBudget: budget._id });
                    return (
                        <Col className="cardcol" lg={{ size:10, offset:1}} md={{ size:10, offset:1}} sm={12} xs={12}>
                           
                        <Card className="cardheight">
                              <Row>
                              <Col lg={4} md={4} sm={12} xs={12}>  
                              <img src={iconehome} className="budgetimage" alt="doctor"/>
                              </Col>
                              <Col className="col-profile-doc-heading" lg={6} md={6} sm={12} xs={12}>
                              <Link to={{
                  pathname: "/ad-group",
                  state: {
                    name: this.state.list_campaingns.name,
                    _id: this.state.list_campaingns._id
                  }
                }}>
                              <CardText >
                             <p className="headingtopp"> <h3>dasda</h3></p>
                              <p className="heading2"><br/>Amount:<span className="colorspan"> SEK</span></p> 
                              </CardText>
                              </Link>
                              <Row>
                                  <Col>
                              <Link to={{
                  pathname: "/update-kampanj",
                  state: {
                    // _id: campaign._id,
                    name: this.state.list_campaingns.name,
                    campaign_id: this.state.list_campaingns._id,
                    paymentMode: this.state.list_campaingns.paymentMode,
                    resourceName: this.state.list_campaingns.resourceName,
                    resourceId: this.state.list_campaingns.resourceId,
                    status: this.state.list_campaingns.status,
                    type: this.state.list_campaingns.type,
                    brokerLink: this.state.brokerLink,
                    propertyname: this.state.propertyname,
                    description: this.state.description,
                    propertyImages: this.state.propertyImages,

                  }
                }}>
                                  <button className="updatebtn">Update</button>
                </Link>
                </Col>
                <Col>
                                  <button className="deletebtn" 
                                  onClick={e => {
                                    if (window.confirm("Är du säker på att du vill ta bort den här kampanjen?"))
                                      this.deletecampaign(e);
                                  }}
                                  >Delete</button>
                                  </Col>
                              </Row>
                              </Col>
                              </Row>
                              </Card>
                              </Col>
                      )})} */}
							</Row>
						</Col>
					</Row>
				</Col>

				<Modal isOpen={this.state.Draftlunchmodal} size="lg" className="Draft-modal-block-size" toggle={this.toggle}>
					<Col xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}>
						<div className="Draft-block-modal-body-costum">
							<ModalHeader >sdadasdasda</ModalHeader>
							<ModalBody>
								<p className="modal-heading-text1">
									Faktureringsuppgifter saknas
                                </p>
								<p className="modal-heading-text2">
									För att skapa en kampanj måste Faktureringsuppgifteranges. Gå till inställningar för att fylla i dem.
                                </p>
								<Row>
									<ModalFooter className="modalfootercustom">
										<Col>  <p className="cancelbtn" onClick={this.Drafttoggle}>SENARE</p></Col>
										<Col lg={12}> <p className="addbtn" onClick={this.Drafttoggle2} >GÄ TILL  INSTÄLLNINGAR</p>{' '}</Col>


									</ModalFooter>
								</Row>

							</ModalBody>
						</div>
					</Col>
				</Modal>

				<Modal isOpen={this.state.Draftlunchmodal2} size="lg" className="Draft-modal-block-size" toggle={this.toggle}>
					<Col xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}>
						<div className="Draft-block-modal-body-costum">
							<ModalBody>
								<p className="modal2-heading-text1">
									Ändra Faktureringsuppgifteranges
                                </p>
								<p className="modal-heading-text2">
									För att skapa en kampanj måste Faktureringsuppgifteranges. Gå till inställningar för att fylla i dem.
                                </p>
								<Row className="date1">
									<Input
										type="text"
										placeholder="Organisationsnummer"
										id="date11"
									>
									</Input>
								</Row>
								<Row className="date2">
									<Input
										type="text"
										placeholder="Förnamn"
										id="date22">
									</Input>
								</Row>
								<Row className="date2">
									<Input
										type="text"
										placeholder="Efternamn"
										id="date33">
									</Input>
								</Row>
								<Row className="date2">
									<Input
										type="text"
										placeholder="Företagsnamn"
										id="date44">
									</Input>
								</Row>
								<Row className="date2">
									<Input
										type="text"
										placeholder="C/O adress"
										id="date55">
									</Input>
								</Row>
								<Row className="date2">
									<Input
										type="text"
										placeholder="Gatuadress"
										id="date66">
									</Input>
								</Row>
								<Row>
									<ModalFooter className="modalfootercustom">
										<Col>  <p className="cancelbtn" onClick={this.Drafttoggle2}>SENARE</p></Col>
										<Col lg={12} md={12} sm={12} xs={12}>  <p className="addbtn" onClick={this.Drafttoggle2} >GÄ TILL  INSTÄLLNINGAR</p>{' '}</Col>


									</ModalFooter>
								</Row>

							</ModalBody>
						</div>
					</Col>
				</Modal>
			</div>
		);
	}
}

export default Addlist;
