import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import Hemnet from '../../images/Resurs 770.png';
import Copy from '../../images/Resurs 780.png';
import Paste from '../../images/Resurs 790.png';
import Header from '../Header/NewHeader';
import './NewHow.css';
import Login from "../Login/Login";

const auth = new Login();

class NewHow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previousPath: props.location.state.previousPath,
            userProfile: props.location.state.userProfile,
            campaignType: props.location.state.campaignType ? props.location.state.campaignType : 'listing',
            redirect: false,
            alert: false,
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange = () => {
        this.setState({});
    };
    render() {
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }

        return (
            <div className="newhow">
                <Header backButton={this.state.previousPath}
                    previousData={{
                        userProfile: this.state.userProfile,
                        campaignType: this.state.campaignType,
                        previousPath: this.props.location.pathname
                    }}
                />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="newhowmain">
                        <Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={12} xs={12}>
                            <Row className="newhowprotion1">
                                <p className="newhowtopheading">How to copy/paste the link to your hemnet ad.</p>
                            </Row>
                            <Row className="newhowstate">
                                <Col lg={{ size: 4 }} md={{ size: 4 }} sm={12} xs={12}>
                                    <Row className="newhowimage1">
                                        <img src={Hemnet} width="100%"></img>
                                    </Row>
                                    <Row className="howtext">
                                        <p>Visit your ad page on Hemnet </p>
                                    </Row>
                                </Col>

                                <Col lg={{ size: 4 }} md={{ size: 4 }} sm={12} xs={12}>
                                    <Row className="newhowimage1">
                                        <img src={Copy} className="copyimagecenter" width="100%"></img>
                                    </Row>
                                    <Row className="howtexturl">
                                        <p>Mark the entire address field, right-click and choose <span className="boldtext">Copy</span></p>
                                    </Row>
                                </Col>
                                <Col lg={{ size: 4 }} md={{ size: 4 }} sm={12} xs={12}>
                                    <Row className="newhowimage1">
                                        <img src={Paste} width="100%"></img>
                                    </Row>
                                    <Row className="howtext">
                                        <p>Right-click and choose <span className="boldtext"> Paste </span> in the Hemnet URL field</p>
                                    </Row>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Col>

            </div>
        );
    }
}

export default NewHow;
