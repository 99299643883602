import React, { Component } from 'react';
import './Style.css';
import {
    Row,
    Col,
    Card,
    CardText,
    Button,
    Input,
    Form, ModalBody, FormGroup
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
// import Left from '../../images/leftarrow.png'
// import Right from '../../images/rightarrow.png'
import House from '../../images/dollar.jpg';
// import Googlemap from '../../images/googleimg.png';
import Header from '../Header';
import swal from 'sweetalert';
import Apiservices from '../Apiservices/Urlservice';
const urlpost = new Apiservices();

class Budget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            startDate: "",
            endDate: "",
            Country: "",
            target: "",
            campaignType: this.props.location.state.campaignType,
            userinfo: this.props.location.state.userinfo,
            agentdetails: this.props.location.state.agentdetails,
            propertydetails: this.props.location.state.property,
            geolocation: [],
            budget: [],
            campaignBudget: "",
            campaign: {},
            redirect: false,
            alert: false,
            Draftlunchmodal2: false,
            Draftlunchmodal: false,
            dropdownOpen: false
        }
        this.toggle = this.toggle.bind(this);
        this.Drafttoggle = this.Drafttoggle.bind(this);
        this.Drafttoggle2 = this.Drafttoggle2.bind(this);
        // this.addcampagin = this.addcampagin.bind(this);
        this.onChange = this.onChange.bind(this);
        this.geolocations = this.geolocations.bind(this);

        console.log("hhhh", this.state.campaignType)

    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    Drafttoggle() {
        this.setState({
            Draftlunchmodal: !this.state.Draftlunchmodal
        });
    }

    Drafttoggle2() {
        this.setState({
            Draftlunchmodal2: !this.state.Draftlunchmodal2
        });
    }

    async componentDidMount() {
        const res = await urlpost.budget();
        // console.log(`sdfghjoiuy ${JSON.stringify(res.data)}`);
        this.setState({ budget: res.data });
    }

    onChange(e) {
        console.log("ghfgj", e.target.value)
        this.setState({ [e.target.name]: e.target.value });
    }

    geolocations = (e) => {
        console.log("ghfgj", e.target.value)
        this.setState({ loading: true }); {
            urlpost
                .geoLoaction(e.target.value)
                .then(result => {
                    let responseJson = result.data;
                    if (responseJson) {
                        this.setState({ geolocation: responseJson });
                        this.setState({ redirect: true });
                        this.setState({ alert: true });
                        this.setState({ loading: false });
                    }
                })
                .catch(err => {
                    this.setState({ alert: true });
                    this.setState({ loading: false });
                    this.setState({ invalid: err.response.data.error.message });
                    swal("Oops", err.response.data.error.message, "error");
                });
        }
    };

    nameClicked(nameOFCity) {
        console.log(`city that was clicked: ${nameOFCity}`)

    }

    render() {
        return (
            <div>
                <Header />

                <Col lg={12} md={12} sm={12} xs={12}>
                    <Row className="budgettoprow">
                        <Col className="margins-budget" lg={6} md={6} sm={12} xs={12} >
                            <Form>
                                <ModalBody>
                                    <Row className="budget-topheading">
                                        <h1 className="budget-headings">Campaign Name</h1>
                                    </Row>
                                    <Row className="namefields">
                                        <label className="labelproperty">Name</label>
                                        <Col lg={12} md={12} sm={12} xs={12} >
                                            <FormGroup>
                                                <Input
                                                    id="datenew11"
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    onChange={this.onChange} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="budget-topheading">
                                        <h1 className="budget-headings">Periods</h1>
                                    </Row>
                                    <Row className="namefields">
                                        <label className="labelproperty">Start Date</label>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <FormGroup>
                                                <Input
                                                    id="datenew11"
                                                    type="date"
                                                    name="startDate"
                                                    onChange={this.onChange} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="namefields">
                                        <label className="labelproperty">End Date</label>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <FormGroup>
                                                <Input
                                                    id="datenew11"
                                                    type="date"
                                                    name="endDate"
                                                    onChange={this.onChange} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="budget-topheading">
                                        <h1 className="budget-headings">Region</h1>
                                    </Row>
                                    <Row className="namefields">
                                        {/* <label className="labelproperty">Select pictures</label> */}
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <FormGroup>
                                                <Input
                                                    id="datenew11"
                                                    type="text"
                                                    name="Country"
                                                    onChange={this.geolocations}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <FormGroup>
                                                <Input
                                                    id="datenew11"
                                                    type="select"
                                                    name="Country"
                                                    onChange={this.onChange}
                                                >
                                                    {this.state.geolocation &&
                                                        this.state.geolocation.map((object) => {
                                                            console.log(`in th map ${object}`);
                                                            return (
                                                                <option value={object.geo_target_constant.resource_name}>{object.geo_target_constant.canonical_name}</option>
                                                            )
                                                        })}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {/* {this.state.geolocation && 
this.state.geolocation.map((object) => {
  console.log(`in th map ${object}`);
  return (<Row>
<Input 
type="select"
value={object.geo_target_constant.name}
onClick={() => this.nameClicked(object.geo_target_constant.name)}>
  <option>{object.geo_target_constant.name}</option>
</Input>
  </Row>
  )})}  */}

                                    {/* <Row className="namefields">
            <label className="labelbudget">Target Region</label>
            <Col lg={12} md={12} sm={12} xs={12}>
            <FormGroup>            
            <Input 
            id="datenew11" 
            type="text" 
            name="target" 
            placeholder="Target Region" 
            onChange={this.onChange}/>
            </FormGroup>            
            </Col>
            </Row>  
            <Row className="namefields">
            <Col lg={12} md={12} sm={12} xs={12}>
              <img src={Googlemap} height="300" width="600"></img>
            </Col>
            </Row>   */}
                                </ModalBody>
                            </Form>
                        </Col>

                        <Col className="carrrrddddd" lg={{ size: 4, offset: 1 }} md={4} sm={4} xs={6}>
                            <div className="cardportion">
                                <h1 className="heading-add-publish">Select Budget</h1>
                                {this.state.budget &&
                                    this.state.budget.map((budgetdetail) => {
                                        return (
                                            <Col className="cardcol" lg={12} md={12} sm={12} xs={12}>
                                                <label className="budgettype">
                                                    <input
                                                        className="budgettype"
                                                        type="radio"
                                                        name="campaignBudget"
                                                        value={budgetdetail._id}
                                                        onChange={this.onChange}
                                                    >
                                                    </input>
                                                    <Card className="cardbudget">
                                                        <Row>
                                                            <Col lg={{ size: 4 }} md={4} sm={4} xs={12}>
                                                                <img src={House} className="budgetimage" alt="image" />
                                                            </Col>
                                                            <Col className="col-profile-doc-heading" lg={6} md={6} sm={4} xs={10}>
                                                                <CardText >
                                                                    <Row className="budgetname">
                                                                        <p className="headingtopp"> {budgetdetail.name}- {budgetdetail.amount} SEK</p>
                                                                    </Row>
                                                                    <Row className="budgetname">
                                                                        <p className="heading2"> Recommended for {budgetdetail.name} campaign</p>
                                                                    </Row>
                                                                    {/* <Row className="budgetname">
                <p className="heading2"><br/>Amount: <span className="colorspan">{budgetdetail.amount} SEK</span></p> 
                </Row> */}
                                                                </CardText>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </label>
                                            </Col>
                                        )
                                    })}
                                {/* <Col>
                <Row className="nextbtn">
                <Link to={{
                  pathname: "/ad-group",
                  state: {
                    name: this.state.name,
                  }
                }}>
                    <Button className="btnnext">Next</Button>
                    </Link>
                </Row>
            </Col> */}
                            </div>
                            {/* <Col lg={12} md={12} sm={12} xs={12}> 
            <Row className="creatrebudget">
               <Spinner marginLeft={-100}  size={40} spinnerColor={"#000000"} spinnerWidth={1} visible={this.state.loading} />
            <Button className="btnbtncreatebudget">Budget</Button>  
            </Row>
            </Col> */}
                        </Col>
                    </Row>
                </Col>
                <Col lg={{ size: 10, offset: 1 }} md={10} sm={12} xs={12} >
                    <Row>
                        <Col lg={6} md={6} sm={6} xs={6}>
                            <Row className="leftarrownew">
                                <Link to={{
                                    pathname: "/campaign-type",
                                    state: {
                                        // campaignType: this.state.campaignType,
                                        // userinfo: this.state.userinfo
                                    }
                                }}>
                                    <Button className="btnedit">Back</Button>
                                </Link>
                            </Row>
                        </Col>

                        <Col lg={6} md={6} sm={6} xs={6}>
                            <Row className="nextbtn1">
                                <Link to={{
                                    pathname: "/preview&Publish",
                                    state: {
                                        name: this.state.name,
                                        startDate: this.state.startDate,
                                        endDate: this.state.endDate,
                                        agentdetails: this.state.agentdetails,
                                        propertydetails: this.state.propertydetails,
                                        campaignType: this.state.campaignType,
                                        userinfo: this.state.userinfo,
                                        campaignBudget: this.state.campaignBudget,
                                        budget: this.state.budget,
                                        Country: this.state.Country
                                    }
                                }}>
                                    <Button className="btnapprove">Next</Button>
                                </Link>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div >
        );
    }
}

export default Budget;