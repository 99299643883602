import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import swal from 'sweetalert';
import Cup from "../../images/map-pin.jpg";
import CampaignService from '../Apiservices/CampaignService';
import Header from '../Header/NewHeader';
import Budget from '../Budget/NewBudget';
import './Setting.css';
import './Setting_Centered.css';
import Autosuggest from 'react-autosuggest';
import Login from "../Login/Login";
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';

const auth = new Login();
const campaignService = new CampaignService();
var regionsArray = []

class Setting extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            previousPath: props.location.state.previousPath,
            userProfile: props.location.state.userProfile,
            campaignType: props.location.state.campaignType ? props.location.state.campaignType : 'listing',
            redirect: false,
            alert: false,
            listingData: props.location.state.listingData,
            currency: this.props.location.state.currency,
            startDate: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
            // startDate: props.location.state.campaignFields && props.location.state.campaignFields.startDate ? props.location.state.campaignFields.startDate : new Date(date.setDate(date.getDate() + 1)).toISOString().substr(0, 10),
            endDate: props.location.state.campaignFields && props.location.state.campaignFields.endDate ? props.location.state.campaignFields.endDate : new Date(date.setDate(date.getDate() + 7)).toISOString().substr(0, 10),
            region: 'Sweden',
            changeInDate: false,
            // region: props.location.state.campaignFields && props.location.state.campaignFields.region ? props.location.state.campaignFields.region : 'Sweden',
            fbPageId: props.location.state.campaignFields && props.location.state.campaignFields.fbPageId ? props.location.state.campaignFields.fbPageId : '110018650442598',
            budget: props.location.state.budget ? props.location.state.budget : {},
            maxDays: 0,
            geolocation: [],
            campaignFields: {},
            selectedRegion: {},
            estimatedReach: props.location.state.estimatedReach ? props.location.state.estimatedReach : 0,
            regionsArray: props.location.state.campaignFields && props.location.state.campaignFields.region ? props.location.state.campaignFields.region : [],
            previewImage: props.location.state.previewImage ? props.location.state.previewImage : "",
            mediaType: props.location.state.mediaType,
            previewVideo: props.location.state.previewVideo ? props.location.state.previewVideo : "",
            redirect: false,
            maximumDays: ""

        };
        this._onSuggestionSelected = this._onSuggestionSelected.bind(this);
        // console.log(new Date().toJSON().slice(0, 10).replace(/-/g, '/'),)
    }

    componentDidMount() {
       
        // this.setState({ startDate: today })
        // let diffInTime = new Date(this.state.endDate).getTime() - new Date(this.state.startDate).getTime();
        // let diffInDays = diffInTime / (1000 * 3600 * 24) + 1;
        // this.setState({ maxDays: diffInDays })
        regionsArray = this.state.regionsArray
        // console.log(diffInDays, 'diffInDays');
    };

    geolocations = (newValue) => {
        campaignService.fbGeoLoaction(newValue.value)
            .then(result => {
                let responseJson = result.data;
                if (responseJson) {
                    this.setState({ geolocation: responseJson });
                    this.setState({ alert: true });
                }
            })
            .catch(err => {
                this.setState({ alert: true });
                swal("Oops", err.response.data.error.message, "error");
            });
    };

    getEstimates = (getRegion) => {
        let data = {
            region: getRegion
        }
        campaignService.getEstimatedUser(data)
            .then((result) => {
                this.setState({ estimatedReach: result.data.data.users });
            })
    };

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onDateChange = (e) => {
        if (e.target.name === 'startDate') {
            let diffInTime = new Date(this.state.endDate).getTime() - new Date(e.target.value).getTime();
            let diffInDays = diffInTime / (1000 * 3600 * 24);


            // if (diffInDays < 1 || diffInDays > 30) {
            //     return swal("Error", "Please select a period between 2-30 days", "error");
            // } else {
            this.setState({ maxDays: diffInDays })
            // }
        }

        if (e.target.name === 'endDate') {
            if (Object.keys(this.state.budget).length === 0) {
                let diffInTime = new Date(e.target.value).getTime() - new Date(this.state.startDate).getTime();
                let diffInDays = diffInTime / (1000 * 3600 * 24);
                this.setState({ maxDays: diffInDays, changeInDate: true })

            } else {
                this.setState({ changeInDate: true })
           
            }
         
        }

        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onChangeAutoSearch = (event, { newValue }) => {
        this.setState({ region: newValue })
    }

    callbackBudget = (budget) => {
        if (this.state.changeInDate === false) {
            let daysToAdd = budget.days - 1
            var someDate = new Date(this.state.startDate);
            someDate.setDate(someDate.getDate() + daysToAdd); //number  of days to add, e.x. 15 days
            var dateFormated = someDate.toISOString().substr(0, 10);
            this.setState({ endDate: dateFormated })
        }


        this.setState({ budget: budget })
    }

    handleRemoveSocial(idx) {
        let someArray = this.state.regionsArray;
        someArray.splice(idx, 1);
        this.setState({ regionsArray: someArray });
        this.getEstimates(someArray)
    }

    handleInputVlaueChange(e, idx) {
        let nextSocialData = this.state.regionsArray.slice();
        nextSocialData[idx] = e.target.value;
        this.setState({ regionsArray: nextSocialData });
    }

    _onSuggestionSelected(event, { suggestion, suggestionValue, index, method }) {
        regionsArray.push(suggestion)
        this.setState({ regionsArray: regionsArray });
        this.getEstimates(regionsArray)
    }
    nextHandler = () => {

        if (Object.keys(this.state.budget).length === 0 && this.state.regionsArray.length === 0) {
            swal("Attention!", "Please select your requirements!", "warning");
        }
        else if (Object.keys(this.state.budget).length === 0 && this.state.regionsArray.length !== 0) {
            swal("Attention!", "Please select your budget!", "warning");
        }
        else if (this.state.regionsArray.length === 0 && Object.keys(this.state.budget).length !== 0) {
            swal("Attention!", "Please select your region!", "warning");
        }
        else {

            let diffInTime = new Date(this.state.endDate).getTime() - new Date(this.state.startDate).getTime();
            let diffInDays = diffInTime / (1000 * 3600 * 24) + 1;

            let budgetDays = this.state.budget.days
            this.setState({ maximumDays: diffInDays })
            if (diffInDays < 1) {
                swal("Attention!", "Your end date should be greater!", "warning");
            }
            else if (diffInDays <= budgetDays) {
                this.setState({ redirect: true })
                // alert('go next')
            }
            else {
                swal("Attention!", "Your selected budget have " + this.state.budget.days + " days, Please choose a valid date", "warning");
            }


        }

    }
    render() {
        var today = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }

        const inputProps = {
            placeholder: "Type to add more locations",
            value: this.state.region,
            onChange: this.onChangeAutoSearch
        };
        if (this.state.redirect) {
            const { budget, maximumDays } = this.state
            let NewBudget = {
                amount: budget.amount,
                currency: budget.currency,
                days: maximumDays,
                estimatedReach: budget.estimatedReach,
                type: budget.type
            }
            return (
                <Redirect to={{
                    pathname: "/PublishAndPreview",
                    state: {
                        campaignType: this.state.campaignType,
                        userProfile: this.state.userProfile,
                        previousPath: this.props.location.pathname,
                        listingData: this.state.listingData,
                        budget: NewBudget,
                        estimatedReach: this.state.estimatedReach,
                        currency: this.state.currency,
                        selectedRegion: this.state.regionsArray,
                        previewImage: this.state.previewImage,
                        previewVideo: this.state.previewVideo,

                        mediaType: this.state.mediaType,
                        campaignFields: {
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                            region: this.state.regionsArray,
                            fbPageId: this.state.fbPageId,
                        },
                    }
                }} />
            )

        }
        return (
            <div className="setting">
                <Header backButton={this.state.previousPath}
                    previousData={{
                        userProfile: this.state.userProfile,
                        campaignType: this.state.campaignType,
                        previousPath: this.props.location.pathname,
                        listingData: this.state.listingData,
                    }}
                />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="settingmain">
                        <Col lg={{ size: 6, offset: 1 }} md={{ size: 6, offset: 1 }} sm={12} xs={12} >
                            <Row className="settingprotion1">
                                <p className="topheadingsetting">Settings.</p>
                            </Row>
                            <Row className="formsetting">

                                <Label className="settingname-label" > Period </Label>
                                <Row className="data1data2">
                                    <Col lg="6" md="6" sm="6" xs="6">
                                        <Row className="data1row">
                                            <Input id="startdateinput"
                                                type="date" name="startDate" value={this.state.startDate} onChange={this.onDateChange}>
                                            </Input>
                                        </Row>
                                    </Col>
                                    <Col lg="6" md="6" sm="6" xs="6">
                                        <Row className="data2row">
                                            <Input id="enddateinput"
                                                type="date" name="endDate" value={this.state.endDate} onChange={this.onDateChange}>
                                            </Input>
                                        </Row>
                                    </Col>
                                </Row>

                                <Col lg="12" md="12" sm="12" xs="12">
                                    <Row>
                                        <Label className="settingname-label" >Target region<span className="cityzip-label-span">(country/region/city/zip code)</span></Label>

                                        <Autosuggest

                                            suggestions={this.state.geolocation}
                                            onSuggestionsFetchRequested={this.geolocations}
                                            getSuggestionValue={(item) => {
                                                this.setState({ selectedRegion: item })

                                                return item.type === 'country' ? item.name : (item.type === 'region' || item.type === 'geo_market') ? item.name + ', ' + item.country_name : item.name + ', ' + item.region + ', ' + item.country_name
                                            }}
                                            renderSuggestion={(item) => {
                                                return (
                                                    <h6>{item.type === 'country' ? item.name : (item.type === 'region' || item.type === 'geo_market') ? item.name + ', ' + item.country_name : item.name + ', ' + item.region + ', ' + item.country_name}</h6>
                                                )
                                            }}
                                            onSuggestionSelected={this._onSuggestionSelected}
                                            inputProps={inputProps}
                                        />

                                    </Row>

                                    <table className="table mt-3 bordered table-hover  white-table addNewSocial">
                                        <tbody>
                                            {this.state.regionsArray.map((Social, idx) => (
                                                <tr key={idx} className="row Social">
                                                    <td className="col-11 socialInput">
                                                        <Input
                                                            className="selectedresults"
                                                            type="text"
                                                            placeholder={`Add New # ${idx + 1}`}
                                                            value={Social.type === 'country' ? Social.name : (Social.type === 'region' || Social.type === 'geo_market') ? Social.name + ', ' + Social.country_name : Social.name + ', ' + Social.region + ', ' + Social.country_name}
                                                            onChange={e => this.handleInputVlaueChange(e, idx)}
                                                        />
                                                    </td>

                                                    {/* <td className="col-1 closingLink" style={{padding: 0}}>
                                                        <div
                                                            className="crossarrow"
                                                            onClick={() => this.handleRemoveSocial(idx)}
                                                        >
                                                            X
                                                        </div>
                                                    </td> */}
                                                    <Row className="crosssighns">
                                                        <p onClick={() => this.handleRemoveSocial(idx)}>x</p>
                                                    </Row>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    {/* <Col lg={{ size: 4 }} md={{ size: 4 }} sm={12} xs={12}>
                                        <Row >
                                            {this.state.regionsArray &&
                                                this.state.regionsArray.map((regions) => {
                                                    return (
                                                        <CreatableSelect
                                                            isMulti
                                                            options={regions}
                                                            value={regions}
                                                        />
                                                    )
                                                })}
                                        </Row>
                                    </Col> */}

                                </Col>
                            </Row>
                        </Col>

                        <Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={12} xs={12} >
                            <Row className="settingprotion2">
                                <img src={Cup} width="100%" height="auto"></img>
                            </Row>
                        </Col>
                        <Budget estimatedReach={this.state.estimatedReach} selectedBudget={this.callbackBudget}
                            campaignType={this.state.campaignType} />
                        <Row className="settingsaverow">
                            <Col lg={{ size: 11, leftset: 1 }} md={{ size: 11, leftset: 1 }} sm={12} xs={12} >

                                {/* <Link to={{
                                    pathname: "/PublishAndPreview",
                                    state: {
                                        campaignType: this.state.campaignType,
                                        userProfile: this.state.userProfile,
                                        previousPath: this.props.location.pathname,
                                        listingData: this.state.listingData,
                                        budget: this.state.budget,
                                        estimatedReach: this.state.estimatedReach,
                                        currency: this.state.currency,
                                        selectedRegion: this.state.regionsArray,
                                        previewImage: this.state.previewImage,
                                        previewVideo: this.state.previewVideo,

                                        mediaType: this.state.mediaType,
                                        campaignFields: {
                                            startDate: this.state.startDate,
                                            endDate: this.state.endDate,
                                            region: this.state.regionsArray,
                                            fbPageId: this.state.fbPageId,
                                        },
                                    }
                                }}> */}
                                <Button className="savesetting" type="submit" onClick={this.nextHandler}>Next</Button>
                                {/* </Link> */}
                            </Col>
                        </Row>
                    </Row>

                </Col>
            </div>
        );
    }
}

export default Setting;
