import React, { Component } from "react";
import Modal from "react-responsive-modal";
import pdf from './Privacy_Policy_Brightee_Marketing_AB.pdf'
import './privacyPolicyModal.css';

class Privacy extends Component {
  render() {
    const { open, toggleModal } = this.props;
    return (
      <Modal
        open={open}
        onClose={toggleModal}
        styles={{
          modal: {
            maxWidth: "unset",
            width: "100%",
            height: "100%",
            padding: "unset"
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)"
          },
          closeButton: {
            background: "lightgrey"
          }
        }}
        center
      >
      <div className="modalPolicy">
       <div className="modalPolicyContent">
          <object width="100%" height="100%" data={pdf}></object>
       </div>
      </div>
      </Modal>
    );
  }
}

export default Privacy;
