import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Spinner from 'react-spinner-material';
import { Button, Col, Form, FormGroup, Input, ModalBody, Row } from 'reactstrap';
import swal from 'sweetalert';
import Apiservices from '../Apiservices/Urlservice';
import Header from '../Header/index.js';
import './Style.css';
const urlpost = new Apiservices();
// import Left from '../../images/leftarrow.png'
// import Right from '../../images/rightarrow.png'
// import DatePicker from 'react-date-picker';
var startDate;
var endDate;
// const toggle = () => setModal(!modal);
// const date = new Date()
// var day = date.getDate();
// var month = date.getMonth() + 1;
// var year = date.getFullYear();
// console.log(date, 'hello')
class Newcampaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			// startDate: new Date(),
			// endDate: new Date(),
			startDate: "",
			endDate: "",
			property: this.props.location.state.property,
			brokerLink: this.props.location.state.brokerLink,
			propertyname: this.props.location.state.propertyname,
			description: this.props.location.state.description,
			imgUrl: this.props.location.state.imgUrl,
			propertyImages: this.props.location.state.propertyImages,
			campaignType: this.props.location.state.campaignType,
			campaign: {},
			amount: "",
			redirect: false,
			alert: false,
			invalid: '',
			isLoaded: true,
			loading: false
		};
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.addcampagin = this.addcampagin.bind(this);
		console.log("propertyid", this.state.property)
		console.log("propertyname", this.state.propertyname)
		console.log("des", this.state.description)
		console.log("propertyname", this.state.brokerLink)
		console.log("campaigntype", this.state.campaignType)
		console.log("indeximage", this.state.propertyImages[0])
		var now = new Date(this.state.startDate);
		startDate = now.toLocaleDateString();

		var now1 = new Date(this.state.endDate);
		endDate = now1.toLocaleDateString();
	}

	handleChange = date => {
		// let newdate = new Date(date).toISOString();
		this.setState({
			startDate: date
		});
		console.log("fdadsd", date.toLocaleDateString())
		// console.log("fdadsd",  newdate.split('T', 1))
	};
	handleChangeend = date1 => {
		this.setState({
			endDate: date1
		});
		console.log("ghfgj", date1.toLocaleDateString())
	};

	onChange(e) {
		console.log("ghfgj", e.target.value)
		this.setState({ [e.target.name]: e.target.value });
	}

	addcampagin = () => {
		console.log("property", this.state.property)
			console.log("name", this.state.name)
		console.log("srt", this.state.startDate)
		console.log("end", this.state.endDate)
		if (this.state.name && this.state.startDate && this.state.endDate
			&& this.state.amount && this.state.property && this.state.campaignType)
			this.setState({ loading: true }); {
			urlpost
				.newcampaign(
					{
						name: this.state.name,
						startDate: this.state.startDate,
						endDate: this.state.endDate,
						amount: this.state.amount,
						property: this.state.property,
						campaignType: this.state.campaignType
					}
				)
				.then(result => {
					let responseJson = result.data;
					if (responseJson) {
						this.setState({ campaign: responseJson });
						this.setState({ redirect: true });
						this.setState({ alert: true });
						this.setState({ loading: false });
					}
				})
				.catch(err => {
					this.setState({ loading: false });
					this.setState({ alert: true });
					this.setState({ invalid: err.response.data.error.message });
					swal("Oops", "This property already has a campaign", "error");
				});
		}
	};

	render() {
		if (this.state.redirect == true) {
			return (
				<Redirect to={{
					pathname: "/Create-add",
					state: {
						name: this.state.name,
						startDate: this.state.startDate,
						endDate: this.state.endDate,
						property: this.state.property,
						brokerLink: this.state.brokerLink,
						propertydetail: this.state.campaign.property,
						description: this.state.description,
						propertyImages: this.state.propertyImages[0],
						campaign: this.state.campaign,
						amount: this.state.amount,
						campaignType: this.state.campaignType
					}
				}} />
			)
		}
		return (
			<div className="custom-container">
				<Header />
				{/* <Col lg={12} md={12} sm={12} xs={12} >
          <Row className="topheader1">
              <p className="compname">campaign name here</p>
          </Row>
          </Col> */}
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheadernew2">
						{/* <Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                  <Row className="leftarrow">
                  <Link to={{
                  pathname: "/new-ad",
                  state: {
                  property: this.state.property,
                  brokerLink: this.state.brokerLink,
                  propertyname: this.state.propertyname,
                  description: this.state.description,
                  imgUrl: this.state.imgUrl
                  }
                }}>
                    <img src={Left} alt="" height="33px"></img>
                    </Link>
                    </Row>
              </Col> */}
						{/* <Col lg={10} md={10} sm={8} xs={8}><Row className="centertext">Ny kampanj</Row></Col> */}
						{/* <Col  lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
                  <Row className="rightarrow">
                  <Link to={{
                  pathname: "/budget",
                  state: {
                    name: this.state.name,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    property: this.state.property,
                    brokerLink: this.state.brokerLink,
                    propertyname: this.state.propertyname,
                    description: this.state.description,
                    imgUrl: this.state.imgUrl,
                  }
                }}>
                  <img src={Right} alt="rightarrow" height="20px"></img>
                </Link>
                </Row>
                </Col> */}
					</Row>
				</Col>
				<Col className="marginnew" lg={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} sm={12} xs={12}>
					<h1 className="heading-toppp">Add a new campaign</h1>
					<Form>
						<ModalBody>
							<Row className="namefields">
								<label className="labelproperty">Campaign name</label>
								<Col lg={12} md={12} sm={12} xs={12} >
									<FormGroup>
										<Input type="text"
											name="name"
											id="compaignname"
											placeholder="Campaign name"
											// value= {this.state.propertyname}
											onChange={this.onChange}></Input>
									</FormGroup>
								</Col>
							</Row>
							<Row className="namefields">
								<label className="labelproperty">Start date</label>
								<Col lg={12} md={12} sm={12} xs={12} >
									<FormGroup>
										<Input
											type="date"
											name="startDate"
											id="datenew11"
											// value={this.state.endDate}
											onChange={this.onChange}></Input>
									</FormGroup>
								</Col>
							</Row>
							<Row className="namefields">
								<label className="labelproperty">End date</label>
								<Col lg={12} md={12} sm={12} xs={12} >
									<FormGroup>
										<Input
											type="date"
											name="endDate"
											id="datenew11"
											// value={this.state.endDate}
											onChange={this.onChange}>
										</Input>
									</FormGroup>
								</Col>
							</Row>
							<Row className="namefields">
								<label className="labelproperty">Amount</label>
								<Col lg={12} md={12} sm={12} xs={12}>
									<FormGroup>
										<Input id="datenew11"
											type="number"
											min="100"
											max="10000"
											name="amount"
											placeholder="100-10000"
											onChange={this.onChange} />
									</FormGroup>
								</Col>
							</Row>
							{/* <Row className="namefields">
            <label className="labelproperty">Campaign</label>
            <Col lg={12} md={12} sm={12} xs={12}>
            <FormGroup>
            <Input
                      type="select"
                      id="datenew11"
                      name="campaignType"
                      onChange={this.onChange}
                    >
                      <option>Select Campaign Type</option>
                      <option value="agency">AGENCY</option>
                      <option value="agent">AGENT</option>
                      <option value="listing">LISTING</option>
                    </Input>
            </FormGroup>
            </Col>
            </Row> */}
							{/* <Row className="namefields">
                <label className="labelproperty">Område</label>
            <Col lg={12} md={12} sm={12} xs={12} >
            <FormGroup>
            <Input
              type="select"
              name="region"
              id="datenew11"
              onChange={this.onChange}>
                <option>Select region</option>
                        <option>Sweden</option>
              </Input>
            </FormGroup>
            </Col>
            </Row>    */}
						</ModalBody>
					</Form>
					<Col lg={12} md={12} sm={12} xs={12} >
						<Row>
							<Col lg={6} md={6} sm={6} xs={6}>
								<Row className="leftarrownew">
									<Link to={{
										pathname: "/new-ad",
										state: {
											property: this.state.property,
											brokerLink: this.state.brokerLink,
											propertyname: this.state.propertyname,
											description: this.state.description,
											propertyImages: this.state.propertyImages,
											campaignType: this.state.campaignType
										}
									}}>
										{/* <img src={Left} alt="" height="33px"></img> */}
										<Button className="backbtn">Back</Button>
									</Link>
								</Row>
							</Col>

							<Col lg={6} md={6} sm={6} xs={6}>
								<Row className="nextbtn1">
									{/* <Link to={{
                  pathname: "/Create-add",
                  state: {
                    name: this.state.name,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    property: this.state.property,
                    brokerLink: this.state.brokerLink,
                    propertyname: this.state.propertyname,
                    description: this.state.description,
                    imgUrl: this.state.imgUrl,
                  }
                }}> */}
									<Col className="spinner">
										<Spinner marginLeft={-100} size={40} spinnerColor={"#000000"} spinnerWidth={1} visible={this.state.loading} />
									</Col>
									<Button className="nextbtnn" onClick={this.addcampagin}>Next</Button>
									{/* </Link> */}
								</Row>
							</Col>
						</Row>
					</Col>
				</Col>
				{/* <Col lg={12} md={12} sm={12} xs={12}>
            <Row className="comnamer">
                <p className="comname">kampanjens namn</p>
            </Row>
            <Row className="commpaignname">
                <Input
                type="text"
                name="name"
                id="compaignname"
                placeholder="Ange kampanj"
                // value= {this.state.propertyname}
                onChange={this.onChange}
                >
                </Input>
            </Row>
            <Row className="startdate">
                <p className="startum">Startdatum</p>
            </Row>
            <Input className="react-date-picker__wrapper"
                type="date"
                name="startDate"
                id="datenew11"
                // value={this.state.endDate}
                onChange={this.onChange}
                >
                </Input>
            <Row className="datenew1">
                <DatePicker className="react-date-picker__wrapper"
                type="date"
                name="startDate"
                id="datenew11"
                value={this.state.startDate}
                onChange={this.handleChange}
                >
                </DatePicker>
            </Row>
            <Row className="enddate">
            <p className="startum">Slutdatum</p>
            </Row>
            <Row className="datenew2">
            <Input className="react-date-picker__wrapper"
                type="date"
                name="endDate"
                id="datenew11"
                // value={this.state.endDate}
                onChange={this.onChange}
                >
                </Input>
            </Row>
            <Row className="datenew2">
            <DatePicker className="react-date-picker__wrapper"
                type="date"
                name="endDate"
                id="datenew11"
                value={this.state.endDate}
                onChange={this.handleChangeend}
                >
                </DatePicker>
            </Row>
            <Row className="enddate">
            <p className="startum">Region</p>
            </Row>
            <Row className="datenew2">
            <Input
                        type="select"
                        name="region"
                        id="datenew11"
                        onChange={this.onChange}
                      >
                        <option>Select region</option>
                        <option>Sweden</option>
                      </Input>
            </Row>


          </Col> */}


			</div>
		);
	}
}

export default Newcampaign;
