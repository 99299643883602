import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Input, Row } from 'reactstrap';
import swal from 'sweetalert';
import Left from '../../images/leftarrow.png';
import Right from '../../images/rightarrow.png';
import Apiservices from '../Apiservices/Urlservice';
import Header from '../Header/index.js';
import './Style.css';
const urlpost = new Apiservices();
var startDate;
var endDate;
// const toggle = () => setModal(!modal);
// const date = new Date()
// var day = date.getDate();
// var month = date.getMonth() + 1;
// var year = date.getFullYear();
// console.log(date, 'hello')
class Updatecampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props.location.state._id,
      campaign_id: this.props.location.state.campaign_id,
      namecam: this.props.location.state.name,
      paymentMode: this.props.location.state.paymentMode,
      resourceName: this.props.location.state.resourceName,
      resourceId: this.props.location.state.resourceId,
      amount: this.props.location.state.amount,
      status: this.props.location.state.status,
      type: this.props.location.state.type,
      brokerLink: this.props.location.state.brokerLink,
      // propertyname: this.props.location.state.propertyname,
      description: this.props.location.state.description,
      imgUrl: this.props.location.state.imgUrl,
      name: this.props.location.state.propertyname,
      startDate: "",
      endDate: "",
      region: "",
      updatecampaignn: {},
      redirect: false,
      alert: false,
      invalid: '',
      isLoaded: true
    };
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);

    //   console.log("campagignnn", this.state.campaign_id);
    //   console.log("name", this.state.namecam);
    console.log("propertyname", this.state.name);
    //   console.log("resid", this.state.startDate);
    //   console.log("amount", this.state.amount);
    //   console.log("status", this.state.status);
    //   console.log("type", this.state.type);

    var now = new Date(this.state.startDate);
    startDate = now.toLocaleDateString();

    var now1 = new Date(this.state.endDate);
    endDate = now1.toLocaleDateString();
  }

  handleChange = date => {
    // let newdate = new Date(date).toISOString();
    this.setState({
      startDate: date
    });
    console.log("fdadsd", date.toLocaleDateString())
    // console.log("fdadsd",  newdate.split('T', 1))
  };
  handleChangeend = date1 => {
    this.setState({
      endDate: date1
    });
    console.log("ghfgj", date1.toLocaleDateString())
  };

  onChange(e) {
    console.log("ghfgj", e.target.value)
    this.setState({ [e.target.name]: e.target.value });
  }

  updatescampaign = () => {
    console.log("id", this.state.campaign_id)
      console.log("name", this.state.name)
    console.log("srt", this.state.startDate)
    console.log("end", this.state.endDate)
    if (this.state.name && this.state.startDate && this.state.endDate) {
      urlpost
        .updatecampaign(this.state.campaign_id, {
          name: this.state.name,
          heading: this.state.heading,
          startDate: this.state.startDate,
          endDate: this.state.endDate
        })
        .then(result => {
          let responseJson = result.data;
          if (responseJson) {
            this.setState({ updatecampaignn: responseJson });
            this.setState({ redirect: true });
            this.setState({ alert: true });
          }
        })
        .catch(err => {
          this.setState({ alert: true });
          this.setState({ invalid: err.response.data.error.message });
          swal("Oops", "kan inte ställa in datum till tidigare tid", "error");
        });
    }
  };

  render() {
    if (this.state.redirect == true) {
      return window.location.replace("/home");
    }
    return (
      <div>
        <Header />
        {/* <Col lg={12} md={12} sm={12} xs={12} >
          <Row className="topheader1">
              <p className="compname">campaign name here</p>
          </Row>
          </Col> */}
        <Col lg={12} md={12} sm={12} xs={12} >
          <Row className="topheadernew2">
            <Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
              <Row className="leftarrow">
                <Link to={{
                  pathname: "/new-ad",
                  state: {
                    brokerLink: this.state.brokerLink,
                    name: this.state.name,
                    description: this.state.description,
                    imgUrl: this.state.imgUrl
                  }
                }}>
                  <img src={Left} alt="" height="33px"></img>
                </Link>
              </Row>
            </Col>
            <Col lg={10} md={10} sm={8} xs={8}><Row className="centertext33">uppdatera kampanjen</Row></Col>
            <Col lg={{ size: 1, offset: 0, leftset: 0 }} md={{ size: 1, offset: 0, leftset: 0 }} sm={2} xs={2}>
              <Row className="rightarrow">
                <Link to={{
                  pathname: "/budget",
                  state: {
                    _id: this.props.location.state._id,
                    name: this.props.location.state.name,
                    resourceName: this.props.location.resourceName,
                    resourceId: this.props.location.resourceId,
                    brokerLink: this.state.brokerLink,
                    propertyname: this.state.propertyname,
                    description: this.state.description,
                    imgUrl: this.state.imgUrl,
                    redirect: true
                  }
                }}>
                  <img src={Right} alt="rightarrow" height="20px"></img>
                </Link>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Row className="nameupdate">
            <p className="labels">namn</p>
          </Row>
          <Row className="datenew2">
            <Input className="react-date-picker__wrapper"
              type="text"
              name="name"
              id="datenew11"
              value={this.state.name}
              onChange={this.onChange}
            >
            </Input>
          </Row>
          <Row className="idupdate">
            <p className="labels">start datum</p>
          </Row>
          <Row className="commpaignname">
            <Input
              type="date"
              name="startDate"
              id="compaignname"
              // value={this.state.paymentMode}
              onChange={this.onChange}
            >
            </Input>
          </Row>
          <Row className="resourceupdate">
            <p className="labels">slutdatum</p>
          </Row>
          <Row className="datenew2">
            <Input className="react-date-picker__wrapper"
              type="date"
              name="endDate"
              id="datenew11"
              // value={this.state.resourceName}
              onChange={this.onChange}
            >
            </Input>
          </Row>
          <Row className="resourceidupdate">
            <p className="labels">område</p>
          </Row>
          <Row className="datenew2">
            <Input
              type="text"
              name="region"
              id="datenew11"
              value="Sweden"
              onChange={this.onChange}>
            </Input>
          </Row>
          <Row className="nextbtn33">
            <Button className="btnnext33" onClick={this.updatescampaign}>uppdatera kampanjen</Button>
          </Row>
        </Col>

      </div>
    );
  }
}

export default Updatecampaign;
