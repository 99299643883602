/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import './Modals.css';
const ModalExample = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div>
            <div className="readmorebutton2">
                <Link to={{
                    //  pathname: "/Modals",
                    // state: {
                    // userProfile: this.state.userProfile
                    // }
                }}>
                    <Button
                        type="submit"
                        className="readmore2"
                        onClick={toggle}>{buttonLabel}
                        Read more
                    </Button>
                </Link>
            </div>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Real estate marketing with the right results.</ModalHeader>
                <ModalBody>
                    Analyzing 000’s of data points, Bright Targeting™
                    system is custom built to identify real estate buyers
                    and sellers online. Our cutting edge technology deter-mines
                    the precise audience for each individual ad, opti-mizing
                    your ad spend and generating real leads. We
                    call it Bright Targeting™, our users call it real results.
                </ModalBody>
                <ModalFooter>
                    <Link to={{
                        pathname: "/signup",
                    }}>
                        <button className="but22">Sign up</button>
                    </Link>
                </ModalFooter>
            </Modal>
        </div >
    );
}

export default ModalExample;