import createHistory from 'history/createBrowserHistory';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import App from '../App';
import Active from '../components/Active/Active';
import Addlist from '../components/Addlist/Addlist';
import Addproperty from '../components/Addproperty/Addproperty';
import AdPreview from '../components/AdPreview/AdPreview';
import Adsetting from '../components/Adsetting/Adsetting';
import AgencyCampaign from '../components/AgencyCampaign/AgencyCampaign';
import Agentad from '../components/Agentad/Agent';
import NewAgentad from '../components/Agentad/NewAgentad';
import AgentCampaign from '../components/AgentCampaign/AgentCampaign';
import Annons from '../components/Annons/Annons';
import Annonsinfo from '../components/Annonsinfo/Annonsinfo';
import Bill from '../components/Billing/Billing';
import Budget from '../components/Budget/Budget';
import NewBudget from '../components/Budget/NewBudget';
import Campaigntype from '../components/Campaigntype/Campaigntype.js';
import NewCampaigntype from '../components/Campaigntype/NewCampaigntype';
import Card from '../components/Cardinfo/Cardinfo.js';
import Createadd from '../components/Createad/Createadd';
import Createadd2 from '../components/Createad2/Createad2';
import CreateAdNew from '../components/CreateAdNew/CreateAdNew';
import CreateAfterNote from '../components/CreateAfterNote/CreateAfterNote';
import CreateCampaign from '../components/CreateCampaign/CreateCampaign';
import CreateProfile from '../components/CreateProfile/CreateProfile';
import signupagency from '../components/CreateProfile/signupagency';

import Dashboard from '../components/Dashboard/Dashboard';
import Editad from '../components/Editad/Editad';
import EditListingAd from '../components/EditAdListing/EditListingAd';
import EditAdNew from '../components/EditAdNew/EditAdNew';
import EditAgent from '../components/Editagent/EditAgent';
import Header from '../components/Header/index.js';
import NewHeader from '../components/Header/NewHeader';
import Home from '../components/Home/Home.js';
import How from '../components/Howhemnet/How';
import NewHow from '../components/Howhemnet/NewHow';
import InfoAdSetting from '../components/InfoAdSetting/InfoAdSetting';
import ListingAd from '../components/ListingAd/ListingAd';
import ListingUrl from '../components/ListingUrl/ListingUrl';
import NewLogin from '../components/Login/NewLogin';
import NameOfCampaign from '../components/NameOfCampaign/NameOfCampaign';
import NewActiveAds from '../components/NewActiveAds/NewActiveAds';
import Newad from '../components/Newad/Newad.js';
import Newcampaign from '../components/Newcampaign/Newcampaign';
import Overview from '../components/Overview/Overview';
import NewPayment from '../components/Payment/NewPayment';
import PaymentMethod from '../components/Payment/PaymentMethod';
import Payment from '../components/Payment/Pay';
import PublishPreview from '../components/Publish/NewpublishPreview';
import Publish from '../components/Publish/Publish';
import Returnhome from '../components/Returnhome/Returnhome.js';
import Setting from '../components/Setting/Setting';
import ShowcasePortfolio from '../components/ShowcasePortfolio/ShowcasePortfolio';
import Soldcampaign from '../components/SoldCampaign/NewSoldCampaign';
import Newstatistics from '../components/Statistics/Newstatistics';
import Statistics from '../components/Statistics/Statistics';
import CustomerStatistics from '../components/Statistics/CustomerStatistics';
import Update from '../components/Updatecampaign/Update';
import Users from '../components/Users/Users';
import AllCampaigns from '../components/AllCampaigns/AllCampaigns';
import PasswordReset from '../components/PasswordReset/PasswordReset';
import LandingPage from '../components/LandingPage/LandingPage';
import Signup from '../components/Signup/Signup';
// import Signupagency from '../components/Signup/Signupagency';
import Modals from '../components/Modals/Modals';
import Modals2 from '../components/Modals2/Modals2';
import LandingPageHeader from '../components/Header/LandingPageHeader';
import Footer from '../components/Footer/Footer';
import ContactUs from '../components/ContactUs/ContactUs';
import FasadPreviewAndPubliah from '../components/FasadPreiew/FasadPreviewAndPublish';
import AdPreviewAndPublish from '../components/FasadPreiew/AdPreviewAndPublish';

import FasadPreview from '../components/FasadPreiew/FasadPreview';
import FasadEditAndSetting from '../components/FasadPreiew/FasadEditAndSetting';
import adEditListing from '../components/FasadPreiew/adEditListing';
import privacy_policy from '../components/Signup/privacyFullView';


// import Login from "../components/Login/Login.js";

export const history = createHistory();
const AppRouter = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path='/' component={App} />
                <Route exact path='/header' component={Header} />
                <Route exact path='/home' component={Home} />
                <Route exact path='/new-ad' component={Newad} />
                <Route exact path='/new-campaign' component={Newcampaign} />
                <Route exact path='/edit-ad' component={Editad} />
                <Route exact path='/ad-group' component={Adsetting} />
                <Route exact path='/budget' component={Budget} />
                <Route exact path='/bidding' component={Annons} />
                <Route exact path='/Payment' component={Payment} />
                <Route exact path='/back-to-home' component={Returnhome} />
                <Route exact path='/active' component={Active} />
                <Route exact path='/overview' component={Overview} />
                <Route exact path='/info-annons' component={Annonsinfo} />
                <Route exact path='/statistics' component={Statistics} />
                <Route exact path='/update-kampanj' component={Update} />
                <Route exact path='/Create-add' component={Createadd} />
                <Route exact path='/add-property' component={Addproperty} />
                <Route exact path='/card-info' component={Card} />
                <Route exact path='/billing' component={Bill} />
                <Route exact path='/add-list' component={Addlist} />
                <Route exact path='/campaign-type' component={Campaigntype} />
                <Route exact path='/agent-ad' component={Agentad} />
                <Route exact path='/preview&Publish' component={Publish} />
                <Route exact path='/gettning-url' component={How} />
                <Route exact path='/hemnet-url' component={ListingUrl} />
                <Route exact path='/edit-agent' component={EditAgent} />
                <Route exact path='/ad-create' component={Createadd2} />
                <Route exact path='/ad-edit' component={EditListingAd} />
                <Route exact path='/new-login' component={NewLogin} />
                <Route exact path='/new-Payment' component={NewPayment} />
                <Route exact path='/paymentMethod' component={PaymentMethod} />
                <Route exact path='/create-profile' component={CreateProfile} />
                <Route exact path='/signupagency' component={signupagency} />

                <Route exact path='/new-header' component={NewHeader} />
                <Route exact path='/Users' component={Users} />
                <Route exact path='/create-campaign' component={CreateCampaign} />
                <Route exact path='/listing-ad' component={ListingAd} />
                <Route exact path='/agency-campaign' component={AgencyCampaign} />
                <Route exact path='/agent-campaign' component={AgentCampaign} />
                <Route exact path='/campaign-name' component={NameOfCampaign} />
                <Route exact path='/showcase-portfolio' component={ShowcasePortfolio} />
                <Route exact path='/setting' component={Setting} />
                <Route exact path='/dashboard' component={Dashboard} />
                <Route exact path='/active-ads' component={NewActiveAds} />
                <Route exact path='/new-statistics' component={Newstatistics} />
                <Route exact path='/vendorstatistics/:campaignid' component={CustomerStatistics}/>
                <Route exact path='/new-how' component={NewHow} />
                <Route exact path='/edit-ad-new' component={EditAdNew} />
                <Route exact path='/info-ad-setting' component={InfoAdSetting} />
                <Route exact path='/new-campaigntype' component={NewCampaigntype} />
                <Route exact path='/new-agentad' component={NewAgentad} />
                <Route exact path='/new-budget' component={NewBudget} />
                <Route exact path='/PublishAndPreview' component={PublishPreview} />
                <Route exact path='/Sold-out' component={Soldcampaign} />
                <Route exact path='/create-ad-new' component={CreateAdNew} />
                <Route exact path='/create-after-note' component={CreateAfterNote} />
                <Route exact path='/ad-preview' component={AdPreview} />
                <Route exact path='/all-campaigns' component={AllCampaigns} />
                <Route exact path='/resetPassword' component={PasswordReset} />
                <Route exact path='/landing-page' component={LandingPage} />
                <Route exact path='/header2' component={LandingPageHeader} />
                <Route exact path='/footer' component={Footer} />
                <Route exact path='/contactUs' component={ContactUs} />
                <Route exact path='/fasadpreviewpublish' component={FasadPreviewAndPubliah} />
                <Route exact path='/Adpreviewpublish' component={AdPreviewAndPublish} /> 
                <Route exact path='/fasadpreview' component={FasadPreview} />
                <Route exact path='/signup' component={Signup} />
                {/* <Route exact path='/signupagency' component={Signupagency} /> */}
                <Route exact path='/modals' component={Modals} />
                <Route exact path='/modals2' component={Modals2} />
                <Route exact path='/edit-fasadListing' component={FasadEditAndSetting} />
                <Route exact path='/adEditListing' component={adEditListing} />
                <Route exact path='/privacy_policy' component={privacy_policy} />


            </Switch>
        </Router>
    )
}
export default AppRouter
