import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { PropagateLoader } from "react-spinners";
import { Button, Col, Input, Label, Row } from 'reactstrap';
import swal from 'sweetalert';
import Cup from "../../images/cup.png";
import PaymentServices from "../Apiservices/PaymentServices";
import Header from '../Header/NewHeader';
import Login from "../Login/Login";
import './Payment.css';

const auth = new Login();
const paymentServices = new PaymentServices();

class PaymentMethod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            alert: false,
            invalid: '',
            card: false,
            name: "",
            data: null,
            loader: false
        };
    }

    componentDidMount() {
        this.setState({ loader: false })
        let search = new URLSearchParams(window.location.search);
        console.log('props', search.get('session_id'))
        paymentServices.addPaymentMethod({ sessionId: search.get('session_id') })
            .then((result) => {
                let responseJson = result.data
                console.log(responseJson, 'hahahaahah')
                this.setState({ loader: false, data: responseJson })
            })
            .catch(err => {
                swal("Oops", err.response.data.error.message, "error");
            });
    };

    render() {
        if (!auth.loggedIn()) {
            return (<Redirect to="/new-login" />)
        }
        return (
            <div className="createprofile">
                <Header />
                <Col lg={12} md={12} sm={12} xs={12} >
                    <Row className="paymentmain">
                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="paymentMethodProtion1">
                                <p className="topheadingpayment">Card Added.</p>
                            </Row>
                            <Row className="formpayment">
                                <p className="paymentMethodDetail">Card holder name: {this.state.data && this.state.data.card.cardHolderName} </p>
                                <p className="paymentMethodDetail">Card type: {this.state.data && this.state.data.card.cardBrand} </p>
                                <p className="paymentMethodDetail">Card number: **** **** **** {this.state.data && this.state.data.card.lastDigits} </p>
                                <Link to="/dashboard">
                                    <Button className="paymentMethodbutton" type="submit"> OK </Button>
                                </Link>
                            </Row>
                        </Col>

                        <Col lg={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} sm={12} xs={12} >
                            <Row className="paymentMethodProtion2">
                                <img src={Cup} height="300" width="100%"></img>
                            </Row>
                        </Col>
                        <div className={this.state.loader ? 'parentDisable' : ''} width="100%">
                            <div className='overlay-box'>
                                <PropagateLoader
                                    // css={override}
                                    sizeUnit={"px"}
                                    size={25}
                                    color={'white'}
                                    loading={this.state.loader}
                                />
                            </div>
                        </div>

                    </Row>
                </Col>
            </div>
        );
    }
}

export default PaymentMethod;