import React from 'react';
// import * as Scroll from 'react-scroll';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    Container,
    NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
// import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
// import logo from '../../images/logo.png';
import './styles.css';
import { withRouter } from 'react-router-dom';
import Login from '../Login/Login';

const Auth = new Login();

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,

        };

        this.toggle = this.toggle.bind(this);
        this.changeNavItem = this.changeNavItem.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }


    changeNavItem(currentRoute) {
        if (currentRoute == "/") {
            this.setState({
                hideValu: true
            });
        }
        if (currentRoute == "/pre-release" || currentRoute == "/parkingspace") {
            this.setState({
                hideNavOnLoginPage: true
            })
        }
    }

    handleLogout() {
        Auth.logout();
        this.setState({ redirect: true });
        window.location.replace("/")
    }

    toggle = () => {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }

    render() {
        return (

            <Navbar className="fixed-top navbar-custom" color="white" light expand="lg">
                {/* <Container> */}
                        <NavbarBrand className="logo-style" tag={Link} to='/home'> <span className="headerlogo">BrightBee</span></NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                        <NavItem className="setting">
                <NavLink tag={Link} Link to="/card-info">Setting</NavLink>
              </NavItem>
             <NavItem className="home">
                <NavLink tag={Link} Link to="/home">Home</NavLink>
              </NavItem>
                    <NavItem>
              <Link to="/">
              <button onClick={this.handleLogout} className="btnlogout">
                  Logout
              </button>
              </Link>
             </NavItem>
             </Nav>
                    </Collapse>
                {/* </Container> */}
            </Navbar>
        );
    }
}
export default withRouter(Header);
