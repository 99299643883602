/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col,Row } from 'reactstrap';
import './Modals2.css';
const ModalExample = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div>
              <Row className="readmorebutton">
                                
                                    <Button
                                        type="submit"
                                        className="readmore"
                                        onClick={toggle}>{buttonLabel}
                                        Read more
                                        
</Button>
                            
                            </Row>
            
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Fully automated.


       
</ModalHeader>
                <ModalBody>

                Simplicity is one of our core values and with
Brightbee advertising on social media has never been 
easier. By using our portal you can create ads with only 
5 clicks or by integrating your company website your 
ads can be created automatically. Brightbee also has 
the ability to integrate with Real Estate CRM systems 
allowing you to target your existing leads.
        </ModalBody>
                <ModalFooter>
                <Link to={{
                    pathname: "/signup",
                }}>
                    <button className="but22">Sign up</button>
                </Link>
           
                </ModalFooter>
            </Modal>
        </div >
    );
}

export default ModalExample;