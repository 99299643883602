import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, Redirect } from 'react-router-dom';
import Spinner from 'react-spinner-material';
import { Button, Col, Row } from 'reactstrap';
import styled from 'styled-components';
import swal from 'sweetalert';
import Apiservices from '../Apiservices/Urlservice';
import Header from '../Header/index.js';
import Login from '../Login/Login';
import './Style.css';
const auth = new Login();
const urlpost = new Apiservices();
const StyledLoader = styled(LoadingOverlay)`
  width: 100%;
  height: auto;
  overflow: hidden;
  .MyLoader_overlay {
    background: rgba(255, 0, 0, 0.5);
  }
  &.MyLoader_wrapper--active {
    overflow: hidden;
  }
`

class publish extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.location.state.name,
			startDate: this.props.location.state.startDate,
			endDate: this.props.location.state.endDate,
			campaignType: this.props.location.state.campaignType,
			userinfo: {},
			agentdetails: this.props.location.state.agentdetails,
			//  property: this.props.location.state.agentdetails._id,
			campaignBudget: this.props.location.state.campaignBudget,
			propertydetails: this.props.location.state.propertydetails,
			//  Country: this.props.location.state.Country,
			budget: this.props.location.state.budget,
			campaigndetails: {},
			campaignbudgetdetail: {},
			resourceName: this.props.location.state.Country,
			redirect: false,
			alert: false,
			loading: false
		};
		this.onChange = this.onChange.bind(this);
		this.addcampagin = this.addcampagin.bind(this);
		this.onChangeFile = this.onChangeFile.bind(this);
		console.log("campaignname", this.state.name);
		console.log("enddate", this.state.endDate);
		console.log("start", this.state.startDate);
		console.log("budgetid", this.state.campaignBudget);
		console.log("campaigntype", this.state.userinfo);
		console.log("resourceName", this.state.resourceName);
	}

	onChange(e) {
		console.log("ghfgj", e.target.value)
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeFile(e) {
		console.log("file", e.target.files)
		this.setState({ [e.target.name]: e.target.files });
	}


	async componentDidMount() {
		let Id = auth.getId();
		const res = await urlpost.userprofile(Id);
		this.setState({ userinfo: res.data });
		const result = await urlpost.singlecampaign(this.props.location.state.campaignBudget);
		this.setState({ campaignbudgetdetail: result.data });
	}

	addcampagin = () => {

		let agent, property
		if (this.state.propertydetails) {
			property = this.state.propertydetails._id
		}
		if (this.state.agentdetails) {
			agent = this.state.agentdetails._id
		}
		if (this.state.name && this.state.startDate && this.state.endDate
			&& this.state.campaignBudget && this.state.campaignType && (agent || property) && this.state.resourceName)
			this.setState({ loading: true }); {
			urlpost
				.newcampaign(
					{
						name: this.state.name,
						startDate: this.state.startDate,
						endDate: this.state.endDate,
						campaignBudget: this.state.campaignBudget,
						agent, property,
						campaignType: this.state.campaignType,
						resourceName: this.state.resourceName,

					}
				)
				.then(result => {
					let responseJson = result.data;
					if (responseJson) {
						this.setState({ campaigndetails: responseJson });
						this.setState({ redirect: true });
						this.setState({ alert: true });
						this.setState({ loading: false });
					}
				})
				.catch(err => {
					this.setState({ alert: true });
					this.setState({ loading: false });
					this.setState({ invalid: err.response.data.error.message });
					swal("Oops", err.response.data.error.message, "error");
				});
		}
	};

	render() {
		if (this.state.redirect === true && this.state.userinfo.user.card === false) {
			return (
				<Redirect to={{
					pathname: "/card-info",
				}} />
			)
		}
		else
			if (this.state.redirect === true && this.state.userinfo.user.card === true) {
				return (
					<Redirect to={{
						pathname: "/billing",
						state: {
							userinfo: this.state.userinfo,
							campaignBudget: this.state.campaignBudget,
							campaigndetails: this.state.campaigndetails
						}
					}} />
				)
			}
		// if (this.state.redirect === true) {
		//       return (
		//         <Redirect to={{
		//           pathname: "/billing",
		//           state: {
		//             userinfo: this.state.userinfo,
		//             campaignBudget: this.state.campaignBudget
		//           }
		//         }} />
		//       )
		//   }
		return (
			<div className="custom-container">
				<Header />
				<LoadingOverlay
					active={false}
					spinner
					text='Loading your content...'
				>
					<Col lg={12} md={12} sm={12} xs={12}>
						<Row>
							<Col className="margins-publish" lg={6} md={6} sm={12} xs={12} >
								<Row className="publishtop">
									<Col lg={{ size: 4, offset: 2 }} md={{ size: 4, offset: 2 }} sm={7} xs={7}>
										<Row>
											<p>Period:</p>
										</Row>
									</Col>
									<Col lg={{ size: 4 }} md={{ size: 4 }} sm={4} xs={4}>
										<Row>
											<p> {this.state.startDate}</p>
										</Row>
										<Row>
											<p>  {this.state.endDate}</p>
										</Row>
									</Col>
									{/* <Col lg={{size:4, offset:2}} md={{size:4, offset:2}} sm={7} xs={7}>
                <Row>
                <p>Region/-s:</p>
                </Row>
                </Col>
                <Col lg={{size:4}} md={{size:4}} sm={4} xs={4}>
                <Row>
                <p>  {this.state.Country}</p>
                </Row>
                </Col> */}

									<Col lg={{ size: 4, offset: 2 }} md={{ size: 4, offset: 2 }} sm={7} xs={7}>
										<Row className="budgetpublish">
											<p>Budget:</p>
										</Row>
									</Col>
									{/* {this.state.budget &&
                      this.state.budget.map((budgetdetail) => {
                    return ( */}
									<Col lg={{ size: 4 }} md={{ size: 4 }} sm={4} xs={4}>
										<Row className="budgetpublish">
											<p> {this.state.campaignbudgetdetail.amount}</p>
										</Row>
									</Col>
									{/* )})} */}
									<Col lg={{ size: 4, offset: 2 }} md={{ size: 4, offset: 2 }} sm={7} xs={7}>
										<Row>
											<p>Estimated reach:</p>
										</Row>
									</Col>
									<Col lg={{ size: 4 }} md={{ size: 4 }} sm={4} xs={4}>
										<Row>
											<p> 1789</p>
										</Row>
									</Col>
								</Row>
							</Col>
							{this.state.campaignType === "agent" && (
								<Col className="preview-add" lg={6} md={6} sm={12} xs={12} order={1}>
									<h1 className="heading-add-publish">Ad Preview</h1>
									<Row className="mobilelayout">
										<Row className="imgpreview">
										</Row>
										<Row className="speakermobile">
										</Row>
										<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }}>
											<Row className="slider11">
												<Carousel autoPlay interval={3000} infiniteLoop showThumbs={false} showStatus={true}>
													{this.state.agentdetails.agentImages && this.state.agentdetails.agentImages.map(image => {
														let images = 'https://realreach.s3.eu-north-1.amazonaws.com/' + image
														return (
															<div>
																<img className="imgimg" src={images} />
															</div>
														)
													})}
												</Carousel>
											</Row>
										</Col>
										<Row className="headingpreviewad">
											<h5>{this.state.agentdetails.name}</h5>

										</Row>
										<Row className="websitepreviewad">
											<h5>{this.state.agentdetails.message}</h5>

										</Row>
										<Row className="websitepreviewad">
											<h5>{this.state.agentdetails.phoneNumber}</h5>
										</Row>
									</Row>
								</Col>
							)}

							{this.state.campaignType === "listing" && (
								<Col className="preview-add" lg={6} md={6} sm={12} xs={12} order={1}>
									<h1 className="heading-add-publish">Ad Preview</h1>
									<Row className="mobilelayout">
										<Row className="imgpreview">
										</Row>
										<Row className="speakermobile">
										</Row>
										<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }}>
											<Row className="slider11">
												<Carousel autoPlay interval={3000} infiniteLoop showThumbs={false} showStatus={true}>
													{this.state.propertydetails.propertyImages && this.state.propertydetails.propertyImages.map(image => {
														let images = 'https://realreach.s3.eu-north-1.amazonaws.com/' + image
														return (
															<div>
																<img className="imgimg" src={images} />
															</div>
														)
													})}
												</Carousel>
											</Row>
										</Col>
										<Row className="headingpreviewad">
											<h5>{this.state.propertydetails.name}</h5>

										</Row>
										<Row className="websitepreviewad">
											<h5>{this.state.area}</h5>

										</Row>
										<Row className="websitepreviewad">
											<h5>{this.state.propertydetails.type}</h5>
										</Row>
									</Row>
								</Col>
							)}
							{this.state.campaignType === "agency" && (
								<Col className="preview-add" lg={6} md={6} sm={12} xs={12} order={1}>
									<h1 className="heading-add-publish">Ad Preview</h1>
									<Row className="mobilelayout">
										<Row className="imgpreview">
										</Row>
										<Row className="speakermobile">
										</Row>
										<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }}>
											<Row className="slider11">
												<Carousel autoPlay interval={3000} infiniteLoop showThumbs={false} showStatus={true}>
													{this.state.propertydetails.propertyImages && this.state.propertydetails.propertyImages.map(image => {
														let images = 'https://realreach.s3.eu-north-1.amazonaws.com/' + image
														return (
															<div>
																<img className="imgimg" src={images} />
															</div>
														)
													})}
												</Carousel>
											</Row>
										</Col>
										<Row className="headingpreviewad">
											<h5>{this.state.propertydetails.name}</h5>

										</Row>
										<Row className="websitepreviewad">
											<h5>{this.state.propertydetails.area}</h5>

										</Row>
										<Row className="websitepreviewad">
											<h5>{this.state.propertydetails.type}</h5>
										</Row>
									</Row>
								</Col>
							)}


							<Col lg={{ size: 10, offset: 1 }} md={10} sm={12} xs={12} >
								<Row className="marginnextback">
									<Col lg={6} md={6} sm={6} xs={6}>
										<Row className="leftarrownew">
											<Link to={{
												pathname: "/budget",
												state: {
													campaignType: this.state.campaignType,
													userinfo: this.state.userinfo,
													propertydetails: this.state.propertydetails,
													agentdetails: this.state.agentdetails,
													campaignType: this.state.campaignType,
												}
											}}>
												<Button className="btnedit">Back</Button>
											</Link>
										</Row>
									</Col>

									<Col lg={6} md={6} sm={6} xs={6}>
										<Row className="spinnerfix">
											<Spinner marginLeft={-100} size={40} spinnerColor={"#000000"} spinnerWidth={1} visible={this.state.loading} />
										</Row>
										<Row className="nextbtn1">

											<Button className="btnapprove" onClick={this.addcampagin}>Publish</Button>

										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</LoadingOverlay>
			</div>
		)
	}
}
export default (publish);