import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Fill from '../../images/fill-1.png';
import './Style.css';
class Returnhome extends Component {
	constructor(props) {
		super(props);
	}
	render() {

		return (
			<div>
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheader33">
						<p className="compname33">campaign name here</p>
					</Row>
				</Col>
				<Col lg={12} md={12} sm={12} xs={12} >
					<Row className="topheader44">
						{/* <img className="fillimage" src={Oval} alt="fill-1.png"></img> */}
						<Row className="logoimgs">
							<img className="reallogooo" src={Fill} alt="" height="200vh"></img>
						</Row>
						<Row className="textmiddle">
							<p>Kampanjen är live!</p>
						</Row>
						<Row className="endbtnback">
							<Button className="backhome">Tillbaka hem</Button>
						</Row>
					</Row>

				</Col>
			</div>
		);
	}
}

export default Returnhome;
