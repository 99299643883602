import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, Redirect } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import { Button, Col, Form, FormGroup, Input, ModalBody, Row } from 'reactstrap';
import styled from 'styled-components';
import swal from 'sweetalert';
import Apiservices from '../Apiservices/Urlservice';
import Header from '../Header/index.js';
import './Style.css';
const urlpost = new Apiservices();

const StyledLoader = styled(LoadingOverlay)`
  width: 100%;
  height: auto;
  overflow: hidden;
  .MyLoader_overlay {
    background: rgba(255, 0, 0, 0.5);
  }
  &.MyLoader_wrapper--active {
    overflow: hidden;
  }
`

class EditListingAd extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// finalUrl: this.state.property.website ? this.state.property.website : '',
			// heading1: this.state.property.name ? this.state.property.name : '',
			// descriptionad: this.property.description ? this.state.property.description : '',
			// imageUrl: this.state.property.propertyImages ? this.state.property.propertyImages : '', 
			userinfo: this.props.location.state.userinfo,
			campaignType: this.props.location.state.campaignType,
			property: this.props.location.state.property,
			name: this.props.location.state.name,
			description: this.props.location.state.description,
			website: this.props.location.state.website,
			propertyImages: this.props.location.state.propertyImages,
			property: this.props.location.state.property,
			region: "native",
			redirect: false,
			alert: false,
			loading: false,
			active: false
		};
		this.onChange = this.onChange.bind(this);
		this.editproperty = this.editproperty.bind(this);
		this.onChangeFile = this.onChangeFile.bind(this);
	}

	onChange(e) {
		console.log("ghfgj", e.target.value)
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeFile(e) {
		console.log("file", e.target.files)
		this.setState({ [e.target.name]: e.target.files });
	}

	// async componentDidMount() {
	//   // console.log(`sdfghjoiuy ${JSON.stringify(res.data)}`);
	//   this.setState({ propertyImages: {Placeholderimage} });
	// }

	editproperty = () => {
		console.log("property", this.state.propertyImages)
		// let propertyImages = []
		// this.state.propertyImages.map((image) => {
		//     propertyImages.push(image)
		// })
		// console.log("property123", propertyImages)
		if (this.state.name && this.state.description && this.state.propertyImages
			&& this.state.website && this.state.campaignType && this.state.region)
			this.setState({ active: true });
		{
			let formData = new FormData();

			formData.append('name', this.state.name);
			formData.append('description', this.state.description);
			for (const propertyImages of this.state.propertyImages) {
				formData.append('propertyImages', propertyImages);
			}
			formData.append('propertyImages', this.state.propertyImages);
			formData.append('website', this.state.website);
			urlpost
				.editproperty(this.state.property._id, formData)
				.then(result => {
					let responseJson = result.data;
					if (responseJson) {
						this.setState({ property: responseJson });
						this.setState({ propertyImages: responseJson.propertyImages });

						this.setState({ redirect: true });
						this.setState({ alert: true });
						this.setState({ active: false });
					}
				})
				.catch(err => {
					this.setState({ alert: true });
					this.setState({ active: false });
					this.setState({ invalid: err.response.data.error.message });
					swal("Oops", err.response.data.error.message, "info");
				});
		}
	};
	render() {
		if (this.state.redirect == true) {
			return (
				<Redirect to={{
					pathname: "/ad-create",
					state: {
						campaignType: this.state.campaignType,
						userinfo: this.state.userinfo,
						name: this.state.name,
						email: this.state.email,
						message: this.state.message,
						phoneNumber: this.state.phoneNumber,
						propertyImages: this.state.propertyImages,
						property: this.state.property
					}
				}} />
			)
		}
		return (
			<div>

				<StyledLoader
					active={false}
					// spinner
					// text='Loading...'
					spinner={<BounceLoader />}
				>
					<Header />
					<Col lg={12} md={12} sm={12} xs={12}>
						<Row>
							<Col className="margins" lg={6} md={6} sm={12} xs={12} >
								<h1 className="heading-toppp">Add new property</h1>
								{console.log("campaigntype", this.state.campaignType)}
								<Form>
									<ModalBody>
										<Row className="namefields">
											<label className="labelproperty">Name</label>
											<Col lg={12} md={12} sm={12} xs={12} >
												<FormGroup>
													<Input
														id="datenew11"
														maxLength="30"
														type="text"
														name="name"
														placeholder="Name"
														value={this.state.name}
														onChange={this.onChange} />
												</FormGroup>
											</Col>
										</Row>
										<Row className="namefields">
											<label className="labelproperty">Description</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="textarea"
														maxLength="90"
														name="description"
														placeholder="Description"
														value={this.state.description}
														onChange={this.onChange} />
												</FormGroup>
											</Col>
										</Row>
										<Row className="namefields">
											<label className="labelproperty">Select pictures</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="file"
														name="propertyImages"
														onChange={this.onChangeFile} multiple />
												</FormGroup>
											</Col>
										</Row>

										<Row className="namefields">
											<label className="labelproperty">Website</label>
											<Col lg={12} md={12} sm={12} xs={12}>
												<FormGroup>
													<Input
														id="datenew11"
														type="text"
														name="website"
														placeholder="Website"
														value={this.state.website}
														onChange={this.onChange} required />
												</FormGroup>
											</Col>
										</Row>
										<Col lg={12} md={12} sm={12} xs={12}>
											<Row className="spinnerfix">
												{/* <Loader
         type="Puff"
         color="#00BFFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
         visible={this.state.loading}
      />  */}
											</Row>
											{/* <Row className="creatreproperties">
            <Button className="btnbtncreateproperties" onClick={this.addproperty}>Add property</Button>  
            </Row> */}
										</Col>

									</ModalBody>
								</Form>
							</Col>


							<Col className="preview-add" lg={6} md={6} sm={12} xs={12} order={1}>
								<h1 className="heading-add-edit">Ad Preview</h1>
								<Row className="mobilelayout">
									<Row className="imgpreview">
										{/* <label className="labelproperty">Headinng</label>
            <Col lg={7} md={7} sm={12} xs={12} >
            {this.state.propertydetail.propertyImages.map(image => {
                    let images = 'https://realreach.s3.eu-north-1.amazonaws.com/'+image
                    return(
            <Col lg={12} md={12} sm={12} xs={12} >
            <FormGroup>
            <img src={images} width="300" height="250"/>
            <button className="btn">x</button>
            </FormGroup>
             </Col> 
            )})}
            </Col> */}
									</Row>
									<Row className="speakermobile">
									</Row>
									<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }}>
										<Row className="slider11">
											<Carousel autoPlay interval={3000} infiniteLoop showThumbs={false} showStatus={true}>
												{this.state.propertyImages && this.state.propertyImages.map(image => {
													let images = 'https://realreach.s3.eu-north-1.amazonaws.com/' + image
													return (
														<div>
															<img className="imgimg" src={images} />
														</div>
													)
												})}
											</Carousel>
										</Row>
									</Col>
									{console.log("agentimages", this.state.property._id)}
									<Row className="headingpreviewad">
										{/* <h3>{this.state.propertydetail.name}</h3>    */}
										<h5>{this.state.name}</h5>

									</Row>
									<Row className="websitepreviewad">
										{/* <h5>{this.state.propertydetail.website}</h5>  */}
										<h5>{this.state.description}</h5>

									</Row>
									<Row className="websitepreviewad">
										{/* <h5>{this.state.propertydetail.description}</h5>  */}
										<h5>{this.state.type}</h5>
									</Row>
								</Row>
							</Col>
							<Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }} >
								<Row>
									<Col lg={6} md={6} sm={6} xs={6}>
										<Row className="leftarrownew">
											<Link to={{
												pathname: "/campaign-type",
												state: {
													campaignType: this.state.campaignType,
													userinfo: this.state.userinfo
												}
											}}>
												<Button className="btnedit">Back</Button>
											</Link>
										</Row>
									</Col>

									<Col lg={6} md={6} sm={6} xs={6}>
										<Row className="nextbtn1">
											{/* <Link to={{
                  pathname: "/ad-create",
                  state: {
                    userinfo: this.state.userinfo,
                    campaignType: this.state.campaignType,
                    name: this.state.name,
                    region: this.state.region,
                    description: this.state.description,
                    website: this.state.website,
                    propertyImages: this.state.propertyImages,
                    property: this.state.property,
                  }
                }}> */}
											<Button className="btnapprove" onClick={this.editproperty}>Next</Button>
											{/* </Link> */}
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</StyledLoader>
			</div>
		)
	}
}
export default (EditListingAd);